import BaseNavbarWithHistory from '@components/shared/BaseNavbarWithHistory/BaseNavbarWithHistory';
import i18next from 'i18next';
import React, { useEffect, useState } from 'react';
import { Col, Form, Row, Container, Button } from 'react-bootstrap';
import { ServiceTemperatureParametersDto } from '@models/dto/response/ServiceTemperatureParameters.dto';
import ProductTempSettingsServiceAddModal from '@components/ProductTempSettingsServiceAddModal/ProductTempSettingsServiceAddModal';
import { FrequencyJsonDto } from '@models/dto/FrequencyJson.dto';
import { FREQUENCY_JSON } from '@utils/constants';
import { useAppSelector, useAppDispatch } from '@store/hooks';
import { UpdateServiceParametersParams } from '@models/dto/request/ServiceParametersParams.dto';
import { toast } from 'react-toastify';
import { getServiceParameters, updateServiceParameters } from '@store/reducers/service-parameters';
import BaseNavbar from '@components/shared/BaseNavbar/BaseNavbar';
import styles from './ProductTempSettingsService.module.scss';

const ProductTempSettingsService = () => {
  const establishmentId = useAppSelector((state) => state.account.selectedEstablishment?.id);
  const serviceParameters = useAppSelector((state) => state.serviceParameters.serviceParameters);
  const accountType = useAppSelector((state) => state.account.type);
  const dispatch = useAppDispatch();
  const [showAddModal, setShowAddModal] = useState<boolean>(false);
  const [frequencyJson, setFrequencyJson] = useState<FrequencyJsonDto | undefined>(
    serviceParameters ? JSON.parse(serviceParameters.frequency.json) : FREQUENCY_JSON,
  );
  const [dishesArray, setDishesArray] = useState<{ name: string; value: number }[]>();

  // Get selected days from client
  const getShippingDays = () => {
    const json = JSON.parse(serviceParameters!.frequency.json);
    // Get only frequence starting by 'day' and true
    const array = Object.entries(json).filter(
      (frequency) => frequency[1] === true && frequency[0].startsWith('day'),
    );
    const daysToDisplay: string[] = [];
    // Create an array with each extract days
    array.forEach((day: [string, unknown]) => {
      daysToDisplay.push(i18next.t(`shared:days.${day[0]}`));
    });
    // Return as string separated by comma or all day if selected
    if (daysToDisplay.includes(i18next.t(`shared:days.dayAll`))) {
      return i18next.t(`shared:days.allWeek`);
    }
    return daysToDisplay.join(', ');
  };

  // Get selected slots from client
  const getSlots = () => {
    const json = JSON.parse(serviceParameters!.frequency.json);
    // Get only frequence starting by 'slot' and true
    const array = Object.entries(json).filter(
      (frequency) => frequency[1] === true && frequency[0].startsWith('slot'),
    );
    const slotsToDisplay: string[] = [];
    // Create an array with each extract slots
    array.forEach((slot: [string, unknown]) => {
      slotsToDisplay.push(i18next.t(`shared:daySlots.${slot[0]}`));
    });
    // Return as string separated by comma
    return slotsToDisplay.join(', ');
  };

  const [shippingDays, setShippingDays] = useState<string>(
    serviceParameters ? getShippingDays() : '',
  );
  const [slots, setSlots] = useState<string>(serviceParameters ? getSlots() : '');

  useEffect(() => {
    if (serviceParameters) {
      setFrequencyJson(JSON.parse(serviceParameters.frequency.json));
      setShippingDays(getShippingDays());
      setSlots(getSlots());
    }
  }, [serviceParameters?.frequency.json]);

  const buildDishesArrayToDisplay = (parameters: ServiceTemperatureParametersDto) => {
    const array = [];

    if (parameters.entree > 0) {
      array.push({
        name: i18next.t('productTemp:settings.shipping.modal.quantities.entree'),
        value: parameters.entree,
      });
    }
    if (parameters.dish > 0) {
      array.push({
        name: i18next.t('productTemp:settings.shipping.modal.quantities.dish'),
        value: parameters.dish,
      });
    }
    if (parameters.garnish > 0) {
      array.push({
        name: i18next.t('productTemp:settings.shipping.modal.quantities.garnish'),
        value: parameters.garnish,
      });
    }
    if (parameters.cheese > 0) {
      array.push({
        name: i18next.t('productTemp:settings.shipping.modal.quantities.cheese'),
        value: parameters.cheese,
      });
    }
    if (parameters.dessert > 0) {
      array.push({
        name: i18next.t('productTemp:settings.shipping.modal.quantities.dessert'),
        value: parameters.dessert,
      });
    }
    if (parameters.other > 0) {
      array.push({
        name: i18next.t('productTemp:settings.shipping.modal.quantities.other'),
        value: parameters.other,
      });
    }
    return array;
  };

  const updateParameters = (data: UpdateServiceParametersParams) => {
    toast.promise(dispatch(updateServiceParameters(data)), {
      pending: i18next.t('productTemp:toasts.pending.updateServiceParameters'),
      success: i18next.t('productTemp:toasts.success.updateServiceParameters'),
      error: i18next.t('productTemp:toasts.error.updateServiceParameters'),
    });
  };

  useEffect(() => {
    if (!serviceParameters && establishmentId) {
      dispatch(getServiceParameters(establishmentId)).unwrap();
    }
  }, []);

  useEffect(() => {
    if (serviceParameters) {
      setFrequencyJson(JSON.parse(serviceParameters.frequency.json));
    }
  }, [serviceParameters?.frequency.json]);

  const openModal = () => {
    setShowAddModal(true);
  };

  // Reset data when modal close
  useEffect(() => {
    if (!showAddModal) {
      // setFrequencyJson(FREQUENCY_JSON);
    } else if (serviceParameters) {
      setFrequencyJson(JSON.parse(serviceParameters.frequency.json));
      setShippingDays(getShippingDays());
      setSlots(getSlots());
    }
  }, [showAddModal]);

  useEffect(() => {
    if (serviceParameters) {
      setDishesArray(buildDishesArrayToDisplay(serviceParameters));
    }
  }, [serviceParameters]);

  return (
    <>
      <BaseNavbar accountType={accountType} parametersUrl="/settings" />
      <BaseNavbarWithHistory
        title={i18next.t('productTemp:settings.navbar.title')}
        showSettings={false}
        showHistory={false}
      />
      <Container fluid className="h-100 pt-5 cornerRound px-5">
        <Row>
          <Col md={12} className="d-flex justify-content-between align-items-center">
            <div className={styles.listTitle}>
              {i18next.t('productTemp:settings:service.title')}
            </div>
            <Button className={`${styles.addButton} btnXl`} onClick={openModal}>
              {i18next.t('shared:buttons.modify')}
            </Button>
          </Col>
        </Row>
        {serviceParameters ? (
          <Row className="mt-5">
            <Col md={12}>
              <Form>
                {shippingDays.length > 0 && slots.length > 0 && (
                  <>
                    <Row>
                      <Col md={12}>
                        {/* Shipping days */}
                        <Form.Group controlId="shippingDays">
                          <Form.Label className={styles.labels}>
                            {i18next.t('productTemp:settings.service.serviceTempDays')}
                          </Form.Label>
                          <Form.Control
                            className={styles.inputText}
                            plaintext
                            readOnly
                            value={shippingDays}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12}>
                        {/* Day slots */}
                        <Form.Group controlId="slots">
                          <Form.Label className={styles.labels}>
                            {i18next.t('productTemp:settings.service.serviceTempSlots')}
                          </Form.Label>
                          <Form.Control
                            className={styles.inputText}
                            plaintext
                            readOnly
                            value={slots}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  </>
                )}
                <Row>
                  {/* Dishes list */}
                  <Col md={12}>
                    <Form.Group as={Row} controlId="dishes">
                      <Form.Label className={styles.labels} column md="12">
                        {i18next.t('productTemp:settings.service.dishes')}
                      </Form.Label>
                      {dishesArray?.map((dish: { name: string; value: number }, index: number) => (
                        <Col key={index} md={6}>
                          <Form.Control
                            className={styles.inputText}
                            plaintext
                            readOnly
                            defaultValue={`${dish.name} X${dish.value}`}
                            value={`${dish.name} X${dish.value}`}
                          />
                        </Col>
                      ))}
                    </Form.Group>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        ) : (
          <Row className="h-100">
            <Col md={12} className="d-flex justify-content-center align-items-center">
              {i18next.t('productTemp:settings.service.empty')}
            </Col>
          </Row>
        )}
        {showAddModal ? (
          <ProductTempSettingsServiceAddModal
            showModal={showAddModal}
            setShowModal={setShowAddModal}
            frequencyJson={frequencyJson}
            setFrequencyJson={setFrequencyJson}
            establishmentId={establishmentId}
            updateParameters={updateParameters}
            serviceParameters={serviceParameters}
          />
        ) : (
          <></>
        )}
      </Container>
    </>
  );
};

export default ProductTempSettingsService;
