import React, { Dispatch, SetStateAction, useRef } from 'react';
import i18next from 'i18next';
import { Button, Col, Row, Image } from 'react-bootstrap';
import closeIcon from '@assets/images/svg/close-rond.svg';
import photoIcon from '@assets/images/svg/photo.svg';
import placeholderImage from '@assets/images/svg/placeholder.svg';
import styles from './BaseEditPhotoPicker.module.scss';

type Props = {
  photos: any;
  setPhotos: Dispatch<SetStateAction<any>>;
  picture?: string;
  replacePicture: () => void;
  title: string;
};

const BaseEditPhotoPicker = (props: Props) => {
  const { photos, setPhotos, title, picture, replacePicture } = props;
  const fileRef = useRef<any>(null);

  const handleClickButton = () => {
    if (fileRef.current !== null && fileRef.current instanceof HTMLInputElement) {
      fileRef.current.click();
    }
  };

  const handleChangeFiles = (files: FileList | null) => {
    if (files && files.length > 0) {
      const src = files[0];
      const array = [...photos, src];
      setPhotos(array);
      replacePicture();
    }
  };

  const removePhoto = (index: number) => {
    const copy = [...photos];
    copy.splice(index, 1);
    setPhotos(copy);
  };

  return (
    <>
      <div className={styles.label}>{title}</div>
      <Row>
        <Col md={12} lg={6}>
          <Button
            className={`${styles.addPhotoButton} btnXl mt-5 mb-4`}
            onClick={handleClickButton}
            variant="outline-secondary"
          >
            <Image src={photoIcon} width={37} height={28} className="mr-3" />
            {i18next.t('shared:photoPicker.replace-button')}
          </Button>
          <input
            ref={fileRef}
            type="file"
            accept="image/*"
            hidden
            onChange={(e) => handleChangeFiles(e.target.files)}
          />
        </Col>
      </Row>
      {/* Photos list */}
      <Row>
        <Col md={12}>
          {picture ? (
            <Image src={picture ?? placeholderImage} width={188} height={206} fluid />
          ) : (
            <>
              {photos &&
                photos.length > 0 &&
                photos.map((photo: any, index: number) => (
                  <div key={index}>
                    <Image
                      src={URL.createObjectURL(photo)}
                      onLoad={() => URL.revokeObjectURL(photo)}
                      width={188}
                      height={206}
                      fluid
                    />
                    {/* Close button */}
                    <Image
                      className={styles.closeIcon}
                      src={closeIcon}
                      width={46}
                      height={46}
                      onClick={() => removePhoto(index)}
                    />
                  </div>
                ))}
            </>
          )}
        </Col>
      </Row>
    </>
  );
};

export default BaseEditPhotoPicker;
