import React, { Dispatch, SetStateAction } from 'react';
import i18next from 'i18next';
import { Modal } from 'react-bootstrap';
import BaseModalFooter from '@components/shared/BaseModalFooter/BaseModalFooter';
import BaseModalHeader from '@components/shared/BaseModalHeader/BaseModalHeader';

type Props = {
  showModal: boolean;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  onValid: () => void;
};

const LogoutConfirmModal = (props: Props) => {
  const { showModal, setShowModal, onValid } = props;

  const handleValidate = () => {
    onValid();
    setShowModal(false);
  };

  return (
    <Modal
      show={showModal}
      onHide={() => setShowModal(false)}
      aria-labelledby="confirm-logout-modal"
      centered
    >
      <BaseModalHeader id="confirm-logout-modal" title={i18next.t('account:form.logout')} />
      <Modal.Body>
        <div className="text-center">
          <div>{i18next.t('account:logoutConfirm')}</div>
        </div>
      </Modal.Body>
      <BaseModalFooter handleValidate={handleValidate} setShowModal={setShowModal} />
    </Modal>
  );
};

export default LogoutConfirmModal;
