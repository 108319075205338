import SettingsCalendarExceptionsTable from '@components/SettingsCalendarExceptionsTable/SettingsCalendarExceptionsTable';
import SettingsCalendarExceptionsModal from '@components/SettingsCalendarExceptionsModal/SettingsCalendarExceptionsModal';
import SettingsCalendarExceptionsDeleteModal from '@components/SettingsCalendarExceptionsDeleteModal/SettingsCalendarExceptionsDeleteModal';
import BaseNavbarWithHistory from '@components/shared/BaseNavbarWithHistory/BaseNavbarWithHistory';
import i18next from 'i18next';
import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Row, Image } from 'react-bootstrap';
import {
  getCalendarExceptions,
  addCalendarException,
  editCalendarException,
  deleteCalendarException,
} from '@store/reducers/calendar-exceptions';
import { useAppSelector, useAppDispatch } from '@store/hooks';
import { ICalendarException } from '@models/dto/request/CalendarExceptionParams.dto';
import addIcon from '@assets/images/svg/plus-rond.svg';
import { CalendarExceptionDto } from '@models/dto/response/CalendarExceptionList.dto';

import BaseNavbar from '@components/shared/BaseNavbar/BaseNavbar';
import styles from './SettingsCalendarExceptions.module.scss';

const SettingsCalendarExceptions = () => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showModalDelete, setShowModalDelete] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<CalendarExceptionDto>();

  const calendarExceptionList = useAppSelector(
    (state) => state.calendarExceptions.calendarExceptionList,
  );
  const establishmentId = useAppSelector((state) => state.account.selectedEstablishment?.id);
  const accountType = useAppSelector((state) => state.account.type);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (establishmentId) {
      dispatch(getCalendarExceptions(establishmentId));
    }
  }, []);

  const dismissModalDetail = () => {
    setShowModal(false);
    setSelectedItem(undefined);
  };

  // Add new item: show modal
  const addException = () => {
    setShowModal(true);
  };
  // Add new item: send request to WS
  const confirmAddException = (data: ICalendarException) => {
    if (establishmentId) {
      dispatch(addCalendarException({ establishmentId, calendarException: data }));
      dismissModalDetail();
    }
  };

  // Edit item: show modal
  const onEditItem = (calendarException: CalendarExceptionDto) => {
    setSelectedItem(calendarException);
    setShowModal(true);
  };
  // Edit item: send request to WS
  const confirmEditException = (data: CalendarExceptionDto) => {
    if (establishmentId) {
      dispatch(editCalendarException({ calendarExceptionId: data.id, calendarException: data }));
      dismissModalDetail();
    }
  };

  // Delete item: show modal
  const onDeleteItem = (calendarException: CalendarExceptionDto) => {
    setSelectedItem(calendarException);
    setShowModalDelete(true);
  };
  // Delete item: send request to WS
  const confirmDeleteItem = (calendarExceptionId: number) => {
    dispatch(deleteCalendarException(calendarExceptionId));
    setSelectedItem(undefined);
  };

  return (
    <>
      <BaseNavbar accountType={accountType} />
      <BaseNavbarWithHistory
        title={i18next.t('settings:calendarExceptions.navbar.title')}
        showSettings={false}
        showHistory={false}
      />
      <Container fluid className="h-100 pt-5 cornerRound px-5">
        <Row className="mb-5">
          <Col md={12}>
            <p className={styles.informationText}>
              {i18next.t('settings:calendarExceptions.informationText')}
            </p>
            <p className={styles.informationSubText}>
              {i18next.t('settings:calendarExceptions.informationSubText')}
            </p>
          </Col>
        </Row>
        <Row>
          <Col md={12} className="d-flex justify-content-between align-items-center">
            <div className={styles.listTitle}>{i18next.t('settings:calendarExceptions.list')}</div>
            <Button
              className={`d-flex justify-content-center align-items-center ${styles.addButton} btnXl`}
              onClick={addException}
            >
              <Image src={addIcon} width={24} height={24} className={styles.iconAdd} />
              {i18next.t('settings:calendarExceptions.addException')}
            </Button>
          </Col>
        </Row>
        {calendarExceptionList?.length > 0 ? (
          <SettingsCalendarExceptionsTable
            exceptions={calendarExceptionList}
            onEditItem={onEditItem}
            onDeleteItem={onDeleteItem}
          />
        ) : (
          <Row className="d-flex justify-content-center align-items-center h-100">
            <Col md={12} className="text-center tableHeader">
              {i18next.t('settings:calendarExceptions.empty')}
            </Col>
          </Row>
        )}
        {selectedItem && (
          <SettingsCalendarExceptionsDeleteModal
            selectedItem={selectedItem}
            showModal={showModalDelete}
            setShowModal={setShowModalDelete}
            onConfirm={confirmDeleteItem}
          />
        )}
        <SettingsCalendarExceptionsModal
          selectedItem={selectedItem}
          showModal={showModal}
          setShowModal={dismissModalDetail}
          validAddForm={confirmAddException}
          validEditForm={confirmEditException}
        />
      </Container>
    </>
  );
};

export default SettingsCalendarExceptions;
