import React, { useState, useEffect, SetStateAction, Dispatch } from 'react';
import i18next from 'i18next';
import { Form } from 'react-bootstrap';
import { FormControl, MenuItem, Select } from '@material-ui/core';
import { SupplierDto } from '@models/dto/response/Supplier.dto';
import { IReceptionControlData } from '@pages/ReceptionControl/ReceptionControl';
import { ProductFamilyDto } from '@models/dto/response/ProductFamily.dto';
import { ProductSubFamilyDto } from '@models/dto/response/ProductSubFamily.dto';
import styles from './ReceptionControlForm.module.scss';

type Props = {
  productFamilies: ProductFamilyDto[];
  suppliers: SupplierDto[];
  onChange: Dispatch<SetStateAction<IReceptionControlData | undefined>>;
};

const ReceptionControlForm = (props: Props) => {
  const { productFamilies, suppliers, onChange } = props;
  const [selectedSupplier, setSelectedSupplier] = useState<SupplierDto>();
  const [selectedFamily, setSelectedFamily] = useState<ProductFamilyDto>();
  const [selectedSubFamily, setSelectedSubFamily] = useState<ProductSubFamilyDto>();

  useEffect(() => {
    const data = {
      supplier: selectedSupplier,
      family: selectedFamily,
      subFamily: selectedSubFamily,
    };
    onChange(data);
  }, [selectedFamily, selectedSubFamily, selectedSupplier]);

  return (
    <Form className="d-flex flex-column">
      {/* Supplier */}
      <Form.Group className="mt-5" controlId="supplier">
        <FormControl className="w-100">
          <Select
            value={JSON.stringify(selectedSupplier) ?? ''}
            onChange={(e) =>
              setSelectedSupplier(JSON.parse(e.target.value as string) as SupplierDto)
            }
            displayEmpty
            inputProps={{
              className: `${
                !selectedSupplier ? styles.selectInputEmpty : styles.selectInputFilled
              }`,
            }}
          >
            <MenuItem value="" disabled>
              {i18next.t('receptionControl:form.supplier')}
            </MenuItem>
            {suppliers.map((supplier: SupplierDto) => (
              <MenuItem key={supplier.id} value={JSON.stringify(supplier)}>
                {supplier.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Form.Group>
      {/* Product family */}
      <Form.Group className="mt-5" controlId="productFamily">
        <FormControl className="w-100">
          <Select
            disabled={!selectedSupplier}
            value={JSON.stringify(selectedFamily) ?? ''}
            onChange={(e) =>
              setSelectedFamily(JSON.parse(e.target.value as string) as ProductFamilyDto)
            }
            displayEmpty
            inputProps={{
              className: `${!selectedFamily ? styles.selectInputEmpty : styles.selectInputFilled}`,
            }}
            renderValue={(value) => (
              // To display disable style on placeholder, otherwise display value
              <span className={!selectedSupplier ? styles.disabledSelect : ''}>
                {value
                  ? (JSON.parse(value as string) as ProductFamilyDto).name
                  : i18next.t('receptionControl:form.family')}
              </span>
            )}
          >
            <MenuItem value="" disabled>
              {i18next.t('receptionControl:form.family')}
            </MenuItem>
            {productFamilies.map((family: ProductFamilyDto) => (
              <MenuItem key={family.id} value={JSON.stringify(family)}>
                {family.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Form.Group>
      {/* Product sub family */}
      <Form.Group className="mt-5" controlId="productSubFamily">
        <FormControl className="w-100">
          <Select
            disabled={!selectedSupplier || !selectedFamily}
            value={JSON.stringify(selectedSubFamily) ?? ''}
            onChange={(e) =>
              setSelectedSubFamily(JSON.parse(e.target.value as string) as ProductSubFamilyDto)
            }
            displayEmpty
            inputProps={{
              className: `${
                !selectedSubFamily ? styles.selectInputEmpty : styles.selectInputFilled
              }`,
            }}
            renderValue={(value) => (
              // To display disable style on placeholder, otherwise display value
              <span className={!selectedFamily ? styles.disabledSelect : ''}>
                {value
                  ? (JSON.parse(value as string) as ProductSubFamilyDto).name
                  : i18next.t('receptionControl:form.subFamily')}
              </span>
            )}
          >
            <MenuItem value="" disabled>
              {i18next.t('receptionControl:form.subFamily')}
            </MenuItem>
            {selectedFamily &&
              productFamilies
                .find((family: ProductFamilyDto) => family.id === selectedFamily.id)
                ?.subFamilies.map((sub: ProductSubFamilyDto) => (
                  <MenuItem key={sub.id} value={JSON.stringify(sub)}>
                    {sub.name}
                  </MenuItem>
                ))}
          </Select>
        </FormControl>
      </Form.Group>
    </Form>
  );
};

export default ReceptionControlForm;
