import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import i18next from 'i18next';
import { Form, Modal, Row } from 'react-bootstrap';
import { DISHES_FAMILY_QUANTITIES, FREQUENCY_JSON } from '@utils/constants';
import { FrequencyJsonDto } from '@models/dto/FrequencyJson.dto';
import { clearSession } from '@services/ErrorHandler';
import { history } from '@router/routes';
import { toast } from 'react-toastify';
import BaseDishesQuantitiesItem from '@components/shared/BaseDishesQuantitiesItem/BaseDishesQuantitiesItem';
import { UpdateServiceParametersParams } from '@models/dto/request/ServiceParametersParams.dto';
import { ServiceTemperatureParametersDto } from '@models/dto/response/ServiceTemperatureParameters.dto';
import BaseModalHeader from '@components/shared/BaseModalHeader/BaseModalHeader';
import BaseModalFooter from '@components/shared/BaseModalFooter/BaseModalFooter';
import { checkFormFrequencyValid, formatFrequencyToNumber } from '@utils/frequenciesUtils';
import { FrequencySurfaceEnum } from '@models/dto/request/FrequencySurfaceEnum.dto';
import BaseOccurenceForm from '@components/shared/BaseOccurenceForm/BaseOccurenceForm';
import { isEqual } from 'lodash';
import styles from './ProductTempSettingsServiceAddModal.module.scss';

const OCCURENCES = [FrequencySurfaceEnum.NONE, FrequencySurfaceEnum.DAILY];

type Props = {
  showModal: boolean;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  setFrequencyJson: Dispatch<SetStateAction<FrequencyJsonDto | undefined>>;
  frequencyJson?: FrequencyJsonDto;
  establishmentId?: number;
  updateParameters: (data: UpdateServiceParametersParams) => void;
  serviceParameters?: ServiceTemperatureParametersDto;
};

const ProductTempSettingsServiceAddModal = (props: Props) => {
  const {
    frequencyJson,
    setFrequencyJson,
    showModal,
    setShowModal,
    establishmentId,
    updateParameters,
    serviceParameters,
  } = props;

  const getDishesQuantities = () => {
    if (serviceParameters) {
      const dishesQuantities = {
        entree: serviceParameters.entree,
        dish: serviceParameters.dish,
        garnish: serviceParameters.garnish,
        cheese: serviceParameters.cheese,
        dessert: serviceParameters.dessert,
        other: serviceParameters.other,
      };

      return Object.entries(dishesQuantities);
    }
    return Object.entries(DISHES_FAMILY_QUANTITIES);
  };
  const [currentDishesQuantities, setCurrentDishesQuantities] = useState<[string, number][]>(
    getDishesQuantities(),
  );
  const [frequencyType, setFrequencyType] = useState<FrequencySurfaceEnum>(
    isEqual(frequencyJson, FREQUENCY_JSON) ? FrequencySurfaceEnum.NONE : FrequencySurfaceEnum.DAILY,
  );

  // Initialize dishes
  useEffect(() => {
    setCurrentDishesQuantities(getDishesQuantities());
  }, [serviceParameters]);

  const checkDishesForm = (data: UpdateServiceParametersParams): boolean => {
    const { entree, dish, cheese, garnish, dessert, other } = data;
    if (
      entree === 0 &&
      dish === 0 &&
      cheese === 0 &&
      garnish === 0 &&
      dessert === 0 &&
      other === 0
    ) {
      toast.warn(i18next.t('productTemp:toasts.warn.dishesQuantities'));
      return false;
    }
    return true;
  };

  const handleValidate = () => {
    if (!establishmentId) {
      clearSession();
      history.go(0);
      return;
    }

    const isFrequencyFormValid = checkFormFrequencyValid(frequencyJson!, 'form', frequencyType);

    if (isFrequencyFormValid) {
      const data: UpdateServiceParametersParams = {
        establishmentId,
        entree: currentDishesQuantities.filter((x) => x[0] === 'entree')[0][1],
        dish: currentDishesQuantities.filter((x) => x[0] === 'dish')[0][1],
        cheese: currentDishesQuantities.filter((x) => x[0] === 'cheese')[0][1],
        garnish: currentDishesQuantities.filter((x) => x[0] === 'garnish')[0][1],
        dessert: currentDishesQuantities.filter((x) => x[0] === 'dessert')[0][1],
        other: currentDishesQuantities.filter((x) => x[0] === 'other')[0][1],
        frequencyJson: JSON.stringify(formatFrequencyToNumber(frequencyJson!)),
      };

      if (checkDishesForm(data)) {
        updateParameters(data);
        setShowModal(false);
      }
    }
  };

  // Remove one quantity from current selected dishes
  const handleRemoveOne = (index: number) => {
    if (currentDishesQuantities[index][1] > 0) {
      setCurrentDishesQuantities((prevState) => {
        const quantities = [...prevState];
        quantities[index] = [...quantities[index]];
        // eslint-disable-next-line operator-assignment
        quantities[index][1] = quantities[index][1] - 1;
        return quantities;
      });
    }
  };

  // Add one quantity to current selected dishes
  const handleAddOne = (index: number) => {
    setCurrentDishesQuantities((prevState) => {
      const quantities = [...prevState];
      quantities[index] = [...quantities[index]];
      // eslint-disable-next-line operator-assignment
      quantities[index][1] = quantities[index][1] + 1;
      return quantities;
    });
  };

  useEffect(() => {
    if (!showModal) {
      setCurrentDishesQuantities(Object.entries(DISHES_FAMILY_QUANTITIES));
      setFrequencyType(FrequencySurfaceEnum.NONE);
    } else {
      setCurrentDishesQuantities(getDishesQuantities());
    }
  }, [showModal]);

  return (
    <Modal
      show={showModal}
      onHide={() => setShowModal(false)}
      size="lg"
      aria-labelledby="settings-service-add-modal"
      centered
    >
      <BaseModalHeader
        id="settings-service-add-modal"
        title={i18next.t('productTemp:settings.service.modal.title')}
      />
      <Modal.Body>
        <Form className="px-5">
          <BaseOccurenceForm
            frequencyJson={frequencyJson}
            setFrequencyJson={setFrequencyJson}
            frequencyType={frequencyType}
            setFrequencyType={setFrequencyType}
            occurences={OCCURENCES}
          />
          {/* Dishes quantities */}
          <Form.Group className="mt-5 d-flex flex-column" controlId="dishesQuantities">
            <Form.Label className={styles.inputLabel}>
              {i18next.t('productTemp:settings.shipping.modal.form.dishesQuantities')}
            </Form.Label>
            <Row className="mt-5">
              {currentDishesQuantities.map((dish: [string, number], index: number) => (
                <BaseDishesQuantitiesItem
                  key={index}
                  index={index}
                  dish={dish}
                  handleAddOne={handleAddOne}
                  handleRemoveOne={handleRemoveOne}
                />
              ))}
            </Row>
          </Form.Group>
        </Form>
      </Modal.Body>
      <BaseModalFooter handleValidate={handleValidate} setShowModal={setShowModal} />
    </Modal>
  );
};

export default ProductTempSettingsServiceAddModal;
