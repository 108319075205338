import React, { SetStateAction, Dispatch } from 'react';
import i18next from 'i18next';
import { Row, Col, Form, Button } from 'react-bootstrap';
import { OperationTypeEnumExtended } from '@pages/OperationsHistory/OperationsHistory';
import BaseDateIntervalPicker from '@components/shared/BaseDateIntervalPicker/BaseDateIntervalPicker';
import BaseNonCompliantCheckboxe from '@components/shared/BaseNonCompliantCheckboxe/BaseNonCompliantCheckboxe';
import styles from './OperationsHistoryFilters.module.scss';

type Props = {
  applyFilters: () => void;
  operationType: OperationTypeEnumExtended | undefined;
  setOperationType: Dispatch<SetStateAction<OperationTypeEnumExtended | undefined>>;
  startDate: Date | undefined;
  setStartDate: Dispatch<SetStateAction<Date | undefined>>;
  endDate: Date | undefined;
  setEndDate: Dispatch<SetStateAction<Date | undefined>>;
  resetFilters: () => void;
  operationTypes: any;
  hasNonCompliance: number;
  setHasNonCompliance: (hasNonCompliance: number) => void;
};

const OperationsHistoryFilters = (props: Props) => {
  const {
    operationType,
    setOperationType,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    resetFilters,
    operationTypes,
    hasNonCompliance,
    setHasNonCompliance,
  } = props;

  const handleFilters = () => {
    props.applyFilters();
  };

  return (
    <Form>
      <h4 className={styles.title}>{i18next.t('operationsHistory:filters.title')}</h4>
      <p className={styles.warning}>{i18next.t('operationsHistory:filters.warning')}</p>
      <Row className="mt-5">
        <Col sm={12} md={12} xl={6}>
          {/* Date picker */}
          <BaseDateIntervalPicker
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
          />
          {/** Nothing could be behind the datepicker because when we click on the arrow or date it not make the action of the date picker but the action of the element behind */}
        </Col>
        <Col sm={12} md={12} xl={6}>
          <Row className="align-items-center">
            <Col md={8}>
              {/* Operation type */}
              <Form.Group controlId="operationType">
                <Form.Label className={styles.inputLabel}>
                  {i18next.t('operationsHistory:filters.operationType')}
                </Form.Label>
                <Form.Control
                  as="select"
                  custom
                  className={`baseInputLg ${styles.typeInput}`}
                  value={operationType}
                  onChange={(e) => setOperationType(e.target.value as OperationTypeEnumExtended)}
                >
                  {Object.keys(operationTypes)
                    .filter((x) => x !== 'product')
                    .filter((x) => x !== 'document')
                    .filter((x) => x !== 'printing')
                    .map((key) => (
                      <option value={operationTypes[key]} key={key}>
                        {i18next.t(`operationsHistory:filters.operationTypes.${key}`)}
                      </option>
                    ))}
                </Form.Control>
              </Form.Group>
            </Col>
            <Col md={4} className="d-none d-md-block">
              <Button onClick={handleFilters} className={`btnXl mt-2 ${styles.filterButton}`}>
                {i18next.t('operationsHistory:filters.filterButton')}
              </Button>
            </Col>
          </Row>
          <Row>
            <Col md={8} className="d-flex flex-sm-row">
              <Button onClick={resetFilters} className={`btnXl mt-2 ${styles.filterButton}`}>
                {i18next.t('operationsHistory:filters.resetButton')}
              </Button>
              <Button
                onClick={handleFilters}
                className={`btnXl mt-2 d-block d-md-none ml-2 ml-md-0 ${styles.filterButton}`}
              >
                {i18next.t('operationsHistory:filters.filterButton')}
              </Button>
            </Col>
          </Row>

          <Row className="mt-5">
            <Col sm={12} md={12} xl={12}>
              {/* Checkbox non compliant */}
              <BaseNonCompliantCheckboxe
                hasNonCompliance={hasNonCompliance}
                onChange={(newValue) => setHasNonCompliance(newValue)}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </Form>
  );
};

export default OperationsHistoryFilters;
