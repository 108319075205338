import React, { Dispatch, SetStateAction, useRef } from 'react';
import { Col, Form, Row, Image } from 'react-bootstrap';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import fr from 'date-fns/locale/fr';
import calendarIcon from '@assets/images/svg/calendar.svg';
import i18next from 'i18next';
import styles from './BaseDatePicker.module.scss';

registerLocale('fr', fr);

type Props = {
  currentDate: Date;
  setCurrentDate: Dispatch<SetStateAction<Date>>;
  currentTime: string;
  setCurrentTime: Dispatch<SetStateAction<string>>;
  showTitle?: boolean;
  readOnlyCalendar?: boolean;
  readOnlyTime?: boolean;
};

const BaseDatePicker = (props: Props) => {
  const {
    currentDate,
    currentTime,
    setCurrentDate,
    setCurrentTime,
    showTitle,
    readOnlyCalendar,
    readOnlyTime,
  } = props;
  const calendarRef = useRef<DatePicker<any>>(null);
  const handleClickCalendarIcon = () => {
    if (calendarRef && calendarRef.current) {
      calendarRef.current.setOpen(true);
    }
  };
  return (
    <Form>
      {showTitle && <div className={styles.title}>{i18next.t('shared:datepicker.title')}</div>}
      <Row className="mt-5">
        <Col md={12} className="d-flex flex-row align-items-center flex-wrap">
          {/* Date picker */}
          <Form.Group
            className="mb-3 mr-lg-4 mr-0 d-flex flex-md-row flex-column align-items-md-center align-items-start"
            controlId="date"
          >
            <Form.Label className={styles.inputLabel}>
              {i18next.t('shared:datepicker.date')}
            </Form.Label>
            <div className="d-flex flex-row">
              <DatePicker
                locale="fr"
                selected={currentDate}
                onChange={(date: Date) => setCurrentDate(date)}
                className={styles.datePicker}
                dateFormat="dd/MM/yyyy"
                ref={calendarRef}
                disabled={readOnlyCalendar}
              />
              <Image
                src={calendarIcon}
                className={styles.calendarIcon}
                onClick={handleClickCalendarIcon}
              />
            </div>
          </Form.Group>
          {/* Time picker */}
          <Form.Group
            className="mb-3 d-flex flex-md-row flex-column align-items-md-center align-items-start"
            controlId="time"
          >
            <Form.Label className={styles.inputLabel}>
              {i18next.t('shared:datepicker.time')}
            </Form.Label>
            <Form.Control
              type="time"
              autoComplete="off"
              className={styles.timePicker}
              value={currentTime}
              onChange={(e) => setCurrentTime(e.target.value)}
              disabled={readOnlyTime}
            />
          </Form.Group>
        </Col>
      </Row>
    </Form>
  );
};

BaseDatePicker.defaultProps = {
  showTitle: false,
  readOnlyCalendar: false,
};

export default BaseDatePicker;
