import React, { Dispatch, SetStateAction, useRef } from 'react';
import i18next from 'i18next';
import { Row, Col, Form, Image } from 'react-bootstrap';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import fr from 'date-fns/locale/fr';
import calendarIcon from '@assets/images/svg/calendar.svg';
import styles from './BaseDateIntervalPicker.module.scss';

registerLocale('fr', fr);

type Props = {
  startDate: Date | undefined;
  setStartDate: Dispatch<SetStateAction<Date | undefined>>;
  endDate: Date | undefined;
  setEndDate: Dispatch<SetStateAction<Date | undefined>>;
  inline?: boolean;
};

const BaseDateIntervalPicker = (props: Props) => {
  const { startDate, setStartDate, endDate, setEndDate } = props;
  const firstCalendarRef = useRef<DatePicker<any>>(null);
  const secondCalendarRef = useRef<DatePicker<any>>(null);

  const handleClickFirstCalendarIcon = () => {
    if (firstCalendarRef && firstCalendarRef.current) {
      firstCalendarRef.current.setOpen(true);
    }
  };

  const handleClickSecondCalendarIcon = () => {
    if (secondCalendarRef && secondCalendarRef.current) {
      secondCalendarRef.current.setOpen(true);
    }
  };
  return (
    <Form.Group className="mb-3 mr-md-4 mr-0" controlId="date">
      <Form.Label className={styles.inputLabel}>{i18next.t('shared:datepicker.label')}</Form.Label>
      <Row>
        <Col
          md={12}
          className={`d-flex flex-column ${
            props.inline ? 'flex-lg-row' : 'flex-sm-row'
          } flex-column align-items-sm-center'`}
        >
          <div
            className={`d-flex flex-row mb-2 ${props.inline ? 'mb-lg-0' : 'mb-sm-0'}`}
            style={{ position: 'relative' }}
          >
            <DatePicker
              locale="fr"
              selected={startDate}
              onChange={(date: Date) => setStartDate(date)}
              className={styles.datePicker}
              dateFormat="dd/MM/yyyy"
              placeholderText={i18next.t('shared:datepicker.placeholderStartDate')}
              selectsStart
              startDate={startDate}
              endDate={endDate}
              ref={firstCalendarRef}
              wrapperClassName="custom-datepicker"
            />
            <Image
              src={calendarIcon}
              className={styles.calendarIcon}
              onClick={handleClickFirstCalendarIcon}
            />
          </div>
          <div className="d-flex flex-row mt-xs-3 mt-sm-0" style={{ position: 'relative' }}>
            <DatePicker
              locale="fr"
              selected={endDate}
              onChange={(date: Date) => setEndDate(date)}
              className={styles.datePicker}
              dateFormat="dd/MM/yyyy"
              placeholderText={i18next.t('shared:datepicker.placeholderEndDate')}
              selectsEnd
              startDate={startDate}
              endDate={endDate}
              ref={secondCalendarRef}
              wrapperClassName="custom-datepicker"
            />
            <Image
              src={calendarIcon}
              className={styles.calendarIcon}
              onClick={handleClickSecondCalendarIcon}
            />
          </div>
        </Col>
      </Row>
    </Form.Group>
  );
};

export default BaseDateIntervalPicker;
