import React, { useState } from 'react';
import { Row, Col, Button, Image } from 'react-bootstrap';
import ScrollMenu from 'react-horizontal-scrolling-menu';
import CleaningZoneListItem from '@components/CleaningZoneListItem/CleaningZoneListItem';
import CleaningZoneModal from '@components/CleaningZoneModal/CleaningZoneModal';
import i18next from 'i18next';
import { AreaDto } from '@models/dto/response/Area.dto';
import { SurfaceDto } from '@models/dto/response/Surface.dto';
import chevronIcon from '@assets/images/svg/chevron-right.svg';
import { history } from '@router/routes';
import styles from './CleaningZoneList.module.scss';

type Props = {
  zones: AreaDto[];
  selectedZone?: AreaDto;
  setSelectedZone: (area: AreaDto | undefined) => void;
  selectedSurfaces?: SurfaceDto[];
  setSelectedSurfaces: (surfaces: SurfaceDto[] | undefined) => void;
  disableSelection?: boolean;
};

const List = (list: AreaDto[]) => {
  return list.map((zone) => {
    return <CleaningZoneListItem text={zone.name} color={zone.color} key={zone.id} id={zone.id} />;
  });
};

const CleaningZoneList = (props: Props) => {
  const {
    zones,
    selectedZone,
    setSelectedZone,
    selectedSurfaces,
    setSelectedSurfaces,
    disableSelection,
  } = props;
  const [showModal, setShowModal] = useState<boolean>(false);

  const handleSelectZone = (key: string | number | null) => {
    if (key && !disableSelection) {
      const selectedItem = zones.find((el) => el.id === Number(key));
      if (selectedItem) {
        setSelectedZone(selectedItem);
        // Clean selected surfaces before choice
        setSelectedSurfaces(undefined);
        setShowModal(true);
      }
    }
  };

  const concatenateSurfaceList = () => {
    if (selectedSurfaces && selectedSurfaces.length > 0) {
      return selectedSurfaces
        .map((surface) => surface.name ?? undefined)
        .filter((x) => x !== undefined)
        .join(' / ');
    }
    return null;
  };

  const goToListView = () => {
    history.push('/cleaning/list');
  };

  const cancelModal = () => {
    setSelectedZone(undefined);
    setSelectedSurfaces(undefined);
  };

  return (
    <div>
      <Row>
        <Col md={12} className="d-flex justify-content-between">
          <div className={styles.title}>{i18next.t('cleaning:zones.title')}</div>
          <Button
            className={`btnSm d-flex flex-row align-items-center ${styles.seeAll}`}
            onClick={goToListView}
            disabled={disableSelection}
          >
            {i18next.t('shared:buttons.seeAll')}
            <Image src={chevronIcon} width={6} height={11} className="ml-2" />
          </Button>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <ScrollMenu
            data={List(zones)}
            onSelect={handleSelectZone}
            transition={2}
            wrapperClass={styles.forceBackgroundColor}
            innerWrapperClass={styles.forceBackgroundColor}
            itemClass={styles.forceBackgroundColor}
            alignCenter={false}
          />
        </Col>
      </Row>
      {selectedZone ? (
        <Row>
          <Col md={12}>
            <p className={styles.zoneSummary}>
              <span className={`${styles.zoneSummaryTitle} mr-2`}>{selectedZone.name} :</span>
              {concatenateSurfaceList()}
            </p>
          </Col>
        </Row>
      ) : undefined}
      {selectedZone ? (
        <CleaningZoneModal
          zoneName={selectedZone.name}
          surfaceList={selectedZone.surfaces}
          setSelectedSurfaces={setSelectedSurfaces}
          showModal={showModal}
          setShowModal={setShowModal}
          cancelModal={cancelModal}
        />
      ) : undefined}
    </div>
  );
};

export default CleaningZoneList;
