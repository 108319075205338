import React, { Dispatch, SetStateAction, useState } from 'react';
import i18next from 'i18next';
import { Form, Modal } from 'react-bootstrap';
import BasePhotoPicker from '@components/shared/BasePhotoPicker/BasePhotoPicker';
import BaseModalHeader from '@components/shared/BaseModalHeader/BaseModalHeader';
import BaseModalFooter from '@components/shared/BaseModalFooter/BaseModalFooter';
import { toast } from 'react-toastify';
import styles from './OilMonitoringSettingsModal.module.scss';

type Props = {
  showModal: boolean;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  createNewDeepFryer: (name: string, picture?: any) => void;
};

const OilMonitoringSettingsModal = (props: Props) => {
  const { showModal, setShowModal, createNewDeepFryer } = props;
  const [name, setName] = useState<string>();
  const [photos, setPhotos] = useState<any>([]);

  const resetForm = () => {
    setName('');
    setPhotos([]);
  };

  const handleValidate = () => {
    if (!name) {
      toast.warn(i18next.t('oilMonitoring:toasts.warn.name'));
      return;
    }

    createNewDeepFryer(name, photos[0]);
    resetForm();
    setShowModal(false);
  };

  return (
    <Modal
      show={showModal}
      onHide={() => setShowModal(false)}
      size="lg"
      aria-labelledby="oil-monitoring-settings-modal"
      centered
    >
      <BaseModalHeader
        id="oil-monitoring-settings-modal"
        title={i18next.t('oilMonitoring:settings.modal.title')}
      />
      <Modal.Body>
        {/* Name */}
        <Form.Group className="d-flex flex-row align-items-center mt-5" controlId="name">
          <Form.Label className={styles.inputLabel}>
            {i18next.t('oilMonitoring:settings.modal.form.name')}
          </Form.Label>
          <Form.Control
            type="text"
            autoComplete="off"
            className="baseInputLgWithFont ml-3"
            value={name ?? ''}
            onChange={(e: any) => setName(e.target.value)}
          />
        </Form.Group>
        {/* Input photo */}
        <div className="mt-5">
          <BasePhotoPicker
            photos={photos}
            setPhotos={setPhotos}
            title={i18next.t('shared:photoPicker.titleDeepFryer')}
            multiPictureAllowed={false}
          />
        </div>
      </Modal.Body>
      <BaseModalFooter handleValidate={handleValidate} setShowModal={setShowModal} />
    </Modal>
  );
};

export default OilMonitoringSettingsModal;
