import BaseNavbarWithHistory from '@components/shared/BaseNavbarWithHistory/BaseNavbarWithHistory';
import HardwareTempList from '@components/HardwareTempList/HardwareTempList';
import i18next from 'i18next';
import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import BaseDatePicker from '@components/shared/BaseDatePicker/BaseDatePicker';
import BaseSignatory from '@components/shared/BaseSignatory/BaseSignatory';
import BaseSaveButton from '@components/shared/BaseSaveButton/BaseSaveButton';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { getSignatories } from '@store/reducers/signatory';
import {
  getEquipments,
  resetEquipmentTemperature,
  selectEquipment,
  selectEquipmentId,
} from '@store/reducers/equipment';
import { EquipmentDto, EquipmentTemperatureDto } from '@models/dto/response/Equipment.dto';
import { SignatoryDto } from '@models/dto/response/Signatory.dto';
import AccountTypeEnum from '@models/enums/AccountType';
import { toast } from 'react-toastify';
import { createEquipmentTempReading } from '@store/reducers/operation';
import { CreateEquipmentTempReadingDto } from '@models/dto/request/CreateEquipmentTempReading.dto';
import { formatISO, format, setMinutes, setHours, isValid } from 'date-fns';
import { history } from '@router/routes';
import OperationTypeEnum from '@models/enums/OperationType';
import { setSelectedTaskId } from '@store/reducers/history';
import NonComplianceRedirectModal from '@components/NonComplianceRedirectModal/NonComplianceRedirectModal';
import { setNonCompliance } from '@store/reducers/non-compliance';
import { EquipmentTempReadingResponseDto } from '@models/dto/response/OperationEquipmentTemperatureReading.dto';
import { NonComplianceDto } from '@models/dto/response/NonCompliance.dto';
import styles from './HardwareTemp.module.scss';

const HardwareTemp = () => {
  // Store
  const signatories = useAppSelector((state) => state.signatory.signatories);
  const equipments = useAppSelector((state) => state.equipment.equipments);
  const selectedHardware = useAppSelector((state) => state.equipment.selectedEquipment);
  const selectedEquipmentId = useAppSelector((state) => state.equipment.selectedEquipmentId);
  const selectedEquipmentTemparature = useAppSelector(
    (state) => state.equipment.selectedEquipmentTemparature,
  );
  const establishmentId = useAppSelector((state) => state.account.selectedEstablishment?.id);
  // const selectedTaskId = useAppSelector((state) => state.history.selectedTaskId);
  const accountType = useAppSelector((state) => state.account.type);
  const dispatch = useAppDispatch();
  // Component State
  const [currentDate, setCurrentDate] = useState<Date>(new Date());
  const [currentTime, setCurrentTime] = useState<string>(format(new Date(), 'HH:mm'));
  const [selectedSignatory, setSelectedSignatory] = useState<SignatoryDto>();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [newNonCompliances, setNewNonCompliances] = useState<NonComplianceDto[]>([]);

  // Update Date with custom time
  const buildDate = () => {
    let formattedDate = currentDate;
    const timeArray = currentTime.split(':');
    formattedDate = setMinutes(formattedDate, Number(timeArray[1]));
    formattedDate = setHours(formattedDate, Number(timeArray[0]));
    return formattedDate;
  };

  const setSelectedHardware = (item?: EquipmentDto[]) => {
    dispatch(selectEquipment(item ?? []));
  };

  const create = (date: Date) => {
    if (!selectedEquipmentTemparature) {
      toast.warn(i18next.t('hardwareTemp:toasts.warn.equipment'));
      return;
    }

    if (selectedEquipmentTemparature.length !== selectedHardware?.length) {
      toast.warn(i18next.t('hardwareTemp:toasts.warn.equipment'));
      return;
    }

    const requests = selectedEquipmentTemparature?.map((equipment: EquipmentTemperatureDto) => {
      const data: CreateEquipmentTempReadingDto = {
        signatoryId: selectedSignatory!.id,
        temperature: equipment.temperature,
        equipmentId: equipment.equipmentId, // TODO
        realisationDate: formatISO(new Date(date)),
      };
      return dispatch(createEquipmentTempReading(data)).unwrap();
    });

    Promise.all(requests)
      .then((res) => {
        toast.success(i18next.t('hardwareTemp:toasts.success.create'));
        dispatch(resetEquipmentTemperature());
        dispatch(selectEquipment(undefined));
        dispatch(setSelectedTaskId(undefined));
        const nonCompliances = res
          .map((response: EquipmentTempReadingResponseDto) => {
            if (response.nonCompliances && response.nonCompliances.length > 0) {
              return response.nonCompliances[0];
            }
            return null;
          })
          .filter((element: any) => element !== null);

        if (nonCompliances && nonCompliances.length > 0) {
          // @ts-ignore
          dispatch(setNonCompliance(nonCompliances[0]));
          // @ts-ignore
          setNewNonCompliances(nonCompliances);
          setShowModal(true);
        } else {
          history.push('/dashboard');
        }
      })
      .catch(() => {
        toast.error(i18next.t('hardwareTemp:toasts.error.create'));
      });
  };

  const redirectToNonCompliance = () => {
    setShowModal(false);
    if (newNonCompliances && newNonCompliances.length > 1) {
      history.push('/operations-handling');
    } else {
      history.push('/operations-handling/solve');
    }
  };

  const handleCancel = () => {
    history.push('/dashboard');
  };

  const handleSave = () => {
    // Form check if completed
    if (!selectedHardware) {
      toast.warn(i18next.t('hardwareTemp:toasts.warn.equipment'));
      return;
    }
    if (!selectedEquipmentTemparature || selectedEquipmentTemparature.length === 0) {
      toast.warn(i18next.t('hardwareTemp:toasts.warn.temperature'));
      return;
    }
    if (!currentDate) {
      toast.warn(i18next.t('hardwareTemp:toasts.warn.date'));
      return;
    }
    if (!currentTime) {
      toast.warn(i18next.t('hardwareTemp:toasts.warn.time'));
      return;
    }
    if (!selectedSignatory) {
      toast.warn(i18next.t('hardwareTemp:toasts.warn.signatory'));
      return;
    }

    const date = buildDate();

    // Is date valid format
    if (isValid(date)) {
      create(date);
    }
  };

  useEffect(() => {
    if ((!equipments || equipments.length === 0) && establishmentId) {
      dispatch(getEquipments(establishmentId)).unwrap();
    }

    if ((!signatories || signatories.length === 0) && establishmentId) {
      dispatch(getSignatories(establishmentId)).unwrap();
    }

    return () => {
      dispatch(setSelectedTaskId(undefined));
    };
  }, []);

  useEffect(() => {
    if (selectedEquipmentId) {
      const equipment = equipments.find(
        (equip: EquipmentDto) => equip.id === selectedEquipmentId,
      ) as EquipmentDto;
      dispatch(selectEquipment([equipment] ?? []));
      setSelectedHardware([equipment]);
    }
    return () => {
      dispatch(selectEquipmentId(undefined));
    };
  }, [selectedEquipmentId, equipments]);

  return (
    <>
      <BaseNavbarWithHistory
        title={i18next.t('hardwareTemp:navbar.title')}
        showSettings={accountType === AccountTypeEnum.admin}
        typeOperation={OperationTypeEnum.equipment}
        onNavigate={() => {
          dispatch(selectEquipment(undefined));
          dispatch(resetEquipmentTemperature());
        }}
      />
      <Container fluid className="cornerRound px-0">
        <Row noGutters className={`${styles.hardwareContainer} pt-5 mb-5`}>
          <Col md={12} className="px-5">
            {equipments && equipments.length > 0 ? (
              <HardwareTempList
                hardwareList={equipments}
                selectedHardware={selectedHardware}
                setSelectedHardware={setSelectedHardware}
                disableSelection={selectedEquipmentId !== undefined}
              />
            ) : (
              <p className="tableHeader">{i18next.t('hardwareTemp:noEquipment')}</p>
            )}
          </Col>
        </Row>
        <Row className="mb-5 px-5">
          <Col md={12}>
            <BaseDatePicker
              currentDate={currentDate}
              setCurrentDate={setCurrentDate}
              currentTime={currentTime}
              setCurrentTime={setCurrentTime}
            />
          </Col>
        </Row>
        <hr style={{ width: '80%' }} />
        <BaseSignatory
          signatories={signatories}
          selectedSignatory={selectedSignatory}
          setSelectedSignatory={setSelectedSignatory}
        />
        <BaseSaveButton onSave={handleSave} text={i18next.t('shared:buttons.save')} />
        <NonComplianceRedirectModal
          showModal={showModal}
          setShowModal={setShowModal}
          handleValidate={redirectToNonCompliance}
          handleCancel={handleCancel}
          nonCompliances={newNonCompliances}
        />
      </Container>
    </>
  );
};

export default HardwareTemp;
