import React, { Dispatch, SetStateAction, useState } from 'react';
import i18next from 'i18next'; // i18next.t('pageName:exemple.exemple')
import { Form, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { DishSubFamilyDto } from '@models/dto/response/DishSubFamily.dto';
import { UpdateDishSubFamilyParams } from '@models/dto/request/UpdateDishSubFamilyParams.dto';
import BaseModalFooter from '@components/shared/BaseModalFooter/BaseModalFooter';
import BaseModalHeader from '@components/shared/BaseModalHeader/BaseModalHeader';
import styles from './ProductTempSettingsSubFamiliesEditModal.module.scss';

type Props = {
  showModal: boolean;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  editDishSubFamily: (data: UpdateDishSubFamilyParams) => void;
  selectedDishSubFamily: DishSubFamilyDto;
};

const ProductTempSettingsSubFamiliesEditModal = (props: Props) => {
  const { showModal, setShowModal, editDishSubFamily, selectedDishSubFamily } = props;

  const [name, setName] = useState<string | undefined>(selectedDishSubFamily.name);
  const [minTemperature, setMinTemperature] = useState<number | undefined>(
    selectedDishSubFamily.minTemperature,
  );
  const [maxTemperature, setMaxTemperature] = useState<number | undefined>(
    selectedDishSubFamily.maxTemperature,
  );

  const resetForm = () => {
    setName(undefined);
    setMinTemperature(undefined);
    setMaxTemperature(undefined);
  };

  const handleValidate = () => {
    if (!name) {
      toast.warn('Veuillez renseigner un nom');
      return;
    }

    if (!maxTemperature && maxTemperature !== 0) {
      toast.warn('Veuillez renseigner une température maximale');
      return;
    }

    if (!minTemperature && minTemperature !== 0) {
      toast.warn('Veuillez renseigner une température minimale');
      return;
    }
    if (minTemperature >= maxTemperature) {
      toast.warn(i18next.t('hardwareTemp:toasts.warn.wrongTemperatures'));
      return;
    }

    const data: UpdateDishSubFamilyParams = {
      dishSubFamilyId: selectedDishSubFamily.id,
      name,
      minTemperature,
      maxTemperature,
    };

    editDishSubFamily(data);
    resetForm();
    setShowModal(false);
  };
  return (
    <Modal
      show={showModal}
      onHide={() => setShowModal(false)}
      aria-labelledby="dishes-subfamilies-modal"
      centered
    >
      <BaseModalHeader
        id="dishes-subfamilies-modal"
        title={i18next.t('productTemp:settings.types.modal.editTitle')}
      />
      <Modal.Body>
        <Form>
          {/* Name */}
          <Form.Group className="d-flex flex-row align-items-center mt-5" controlId="name">
            <Form.Label className={styles.inputLabel}>
              {i18next.t('productTemp:settings.types.modal.form.name')}
            </Form.Label>
            <Form.Control
              type="text"
              autoComplete="off"
              className="baseInputLgWithFont ml-3"
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
            />
          </Form.Group>
          {/* Temperature min */}
          <Form.Group
            className="d-flex flex-row align-items-center mt-5 text-nowrap"
            controlId="tMin"
          >
            <Form.Label className={`${styles.inputLabel} ${styles.temperatureLabel}`}>
              {i18next.t('productTemp:settings.types.modal.form.tMin')}
            </Form.Label>
            <Form.Control
              type="number"
              autoComplete="off"
              className={`baseInputLgWithFont ml-3 ${styles.temperatureInput}`}
              value={minTemperature}
              onChange={(e) => {
                setMinTemperature(Number(e.target.value));
              }}
            />
            <span className="ml-2">°C</span>
          </Form.Group>
          {/* Temperature max */}
          <Form.Group
            className="d-flex flex-row align-items-center mt-5 text-nowrap"
            controlId="tMax"
          >
            <Form.Label className={`${styles.inputLabel} ${styles.temperatureLabel}`}>
              {i18next.t('productTemp:settings.types.modal.form.tMax')}
            </Form.Label>
            <Form.Control
              type="number"
              autoComplete="off"
              className={`baseInputLgWithFont ml-3 ${styles.temperatureInput}`}
              value={maxTemperature}
              onChange={(e) => {
                setMaxTemperature(Number(e.target.value));
              }}
            />
            <span className="ml-2">°C</span>
          </Form.Group>
        </Form>
      </Modal.Body>
      <BaseModalFooter handleValidate={handleValidate} setShowModal={setShowModal} />
    </Modal>
  );
};

export default ProductTempSettingsSubFamiliesEditModal;
