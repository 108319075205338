import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import historyService from '@services/history.service';
import { HistoryStatsDto } from '@models/dto/response/HistoryStats.dto';
import { NonComplianceDto } from '@models/dto/response/NonCompliance.dto';
import { NonComplianceAnalysisDto } from '@models/dto/response/NonComplianceAnalysis.dto';
import { GetHistoryOperationParams } from '@models/dto/request/HistoryOperation.dto';
import nonComplianceService from '@services/non-compliance.service';
import { TaskDto, TodoTaskDto } from '@models/dto/response/Task.dto';
import { GetLateTasksParams } from '@models/dto/request/LateTasks.dto';
import { AllOperationsDto } from '@models/dto/AllOperations.dto';
import { OperationTypeEnumExtended } from '@pages/OperationsHistory/OperationsHistory';
import operationService from '@services/operation.service';

export interface OperationsListDto {
  operation: AllOperationsDto;
  nonCompliances?: NonComplianceDto[];
}
// Define a type for the slice state
interface HistoryState {
  stats?: HistoryStatsDto;
  operations: OperationsListDto[]; // Mix of all operations DTO
  hasMoreOperation: boolean;
  hasMoreLateTask: boolean;
  hasMoreNonCompliantTask: boolean;
  lateTasks: TaskDto[];
  todoTasks: TodoTaskDto;
  todayOperationsDone: OperationsListDto[];
  nonCompliantTasks: (NonComplianceDto | NonComplianceAnalysisDto)[];
  selectedTaskId?: number;
  startDate?: Date;
  endDate?: Date;
  page: number;
  hasNonCompliance: number;
  operationType?: OperationTypeEnumExtended;
  isLoading: boolean;
}

// Define the initial state using that type
const initialState: HistoryState = {
  operations: [],
  hasMoreOperation: true,
  hasMoreLateTask: true,
  hasMoreNonCompliantTask: true,
  lateTasks: [],
  todoTasks: {
    data: [],
    meta: {
      status: 200,
      amount: {
        total: 0,
        equipment: 0,
        cleaning: 0,
        product: 0,
      },
    },
  },
  nonCompliantTasks: [],
  todayOperationsDone: [],
  page: 0,
  hasNonCompliance: 0,
  isLoading: false,
};

// REQUESTS
export const getStats = createAsyncThunk<AxiosResponse<HistoryStatsDto>, number>(
  'history/stats',
  async (establishmentId: number, { rejectWithValue }) => {
    try {
      const response = await historyService.getStats(establishmentId);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const getOperations = createAsyncThunk<OperationsListDto[], GetHistoryOperationParams>(
  'history/operations',
  async (params: GetHistoryOperationParams) => {
    const response = await historyService.getOperations(params.establishmentId, params);
    return response.data.data;
  },
);

export const getOperationsTodayDone = createAsyncThunk<
  OperationsListDto[],
  GetHistoryOperationParams
>('history/operationsDone', async (params: GetHistoryOperationParams) => {
  const response = await historyService.getOperations(params.establishmentId, params);
  return response.data.data;
});

export const getTaskTodo = createAsyncThunk<TodoTaskDto, GetLateTasksParams>(
  'task/todo',
  async (params: GetLateTasksParams) => {
    const { establishmentId, page, limit } = params;
    const response = await historyService.getTaskTodo(establishmentId, page, limit);
    return response.data;
  },
);

export const getLateTasks = createAsyncThunk<AxiosResponse<TaskDto[]>, GetLateTasksParams>(
  'history/lateTasks',
  async (params: GetLateTasksParams) => {
    const { establishmentId, page, limit } = params;
    const response = await historyService.getLateTasks(establishmentId, page, limit);
    return response.data;
  },
);

export const getNonCompliantTasks = createAsyncThunk<
  AxiosResponse<(NonComplianceDto | NonComplianceAnalysisDto)[]>,
  GetLateTasksParams
>('history/nonCompliant', async (params: GetLateTasksParams) => {
  const { establishmentId, page, limit } = params;
  const response = await historyService.getNonCompliantTasks(establishmentId, page, limit);
  return response.data;
});

// To avoid dependency cycle it has to be in this file in order to modify non compliant tasks
export const deleteNonCompliance = createAsyncThunk<AxiosResponse<void>, number>(
  'nonCompliance/delete',
  async (id: number) => {
    const response = await nonComplianceService.deleteNonCompliance(id);
    return response.data;
  },
);

// DELETE TASK
export const deleteTaskOperation = createAsyncThunk<AxiosResponse<any>, number>(
  'operation/deleteTask',
  async (taskId: number, { rejectWithValue }) => {
    try {
      const result = await operationService.deleteTask(taskId);
      return result.data.data.operation;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const HistorySlice = createSlice({
  name: 'history',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    resetOperations: (state) => {
      state.todayOperationsDone = [];
      state.hasMoreOperation = true;
    },
    resetHasMoreOperation: (state) => {
      state.hasMoreOperation = true;
    },
    setSelectedTaskId: (state, action: PayloadAction<number | undefined>) => {
      state.selectedTaskId = action.payload;
    },
    resetHistory: (state) => {
      state.operations = [];
      state.hasMoreOperation = true;
      state.hasMoreNonCompliantTask = true;
      state.hasMoreLateTask = true;
      state.nonCompliantTasks = [];
      state.lateTasks = [];
      state.hasNonCompliance = 0;
      state.startDate = undefined;
      state.endDate = undefined;
      state.page = 0;
      state.operationType = undefined;
    },
    setNonCompliantValue: (state, action: PayloadAction<number>) => {
      state.hasNonCompliance = action.payload;
    },
    setStartDateValue: (state, action: PayloadAction<Date | undefined>) => {
      state.startDate = action.payload;
    },
    setEndDateValue: (state, action: PayloadAction<Date | undefined>) => {
      state.endDate = action.payload;
    },
    setPageValue: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
    setOperationTypeValue: (
      state,
      action: PayloadAction<OperationTypeEnumExtended | undefined>,
    ) => {
      state.operationType = action.payload;
    },
  },
  extraReducers: (builder) => {
    // Side effects
    // Get stats
    builder.addCase(getStats.fulfilled, (state, action) => {
      state.stats = action.payload.data ?? [];
    });
    // Get operations history
    builder.addCase(getOperations.fulfilled, (state, action) => {
      state.isLoading = false;
      if (action.meta.arg.page === 0) {
        state.operations = action.payload ?? [];
      } else {
        state.operations = state.operations.concat(action.payload);
      }
      // page empty
      if (action.payload.length === 0) {
        state.hasMoreOperation = false;
      }
    });
    builder.addCase(getOperations.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getOperations.rejected, (state) => {
      state.isLoading = false;
    });
    // Get operations history done for today
    builder.addCase(getOperationsTodayDone.fulfilled, (state, action) => {
      if (action.meta.arg.page === 0) {
        state.todayOperationsDone = action.payload ?? [];
      } else {
        state.todayOperationsDone = state.todayOperationsDone.concat(action.payload);
      }
      // page empty
      if (action.payload.length === 0) {
        state.hasMoreOperation = false;
      }
    });
    // Get late tasks list
    builder.addCase(getLateTasks.fulfilled, (state, action) => {
      state.isLoading = false;
      if (action.meta.arg.page === 0) {
        state.lateTasks = action.payload.data ?? [];
      } else {
        state.lateTasks = state.lateTasks.concat(action.payload.data);
      }
      // page empty
      if (action.payload.data.length === 0) {
        state.hasMoreLateTask = false;
      }
    });
    builder.addCase(getLateTasks.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getLateTasks.rejected, (state) => {
      state.isLoading = false;
    });
    // Get non compliant tasks list
    builder.addCase(getNonCompliantTasks.fulfilled, (state, action) => {
      if (action.meta.arg.page === 0) {
        state.nonCompliantTasks = action.payload.data ?? [];
      } else {
        state.nonCompliantTasks = state.nonCompliantTasks.concat(action.payload.data);
      }
      // page empty
      if (action.payload.data.length === 0) {
        state.hasMoreNonCompliantTask = false;
      }
    });
    // Get todo tasks list
    builder.addCase(getTaskTodo.fulfilled, (state, action) => {
      state.todoTasks = action.payload ?? initialState.todoTasks;
    });
    // Delete non compliance from list (coming from non-compliance reducer)
    builder.addCase(deleteNonCompliance.fulfilled, (state, action) => {
      const index = state.nonCompliantTasks.findIndex((el) => el.id === action.meta.arg);
      state.nonCompliantTasks.splice(index, 1);
    });
    builder.addCase(deleteTaskOperation.fulfilled, (state, action) => {
      state.lateTasks = state.lateTasks.filter((task: TaskDto) => task.id !== action.meta.arg);
    });
  },
});

export const {
  resetOperations,
  resetHasMoreOperation,
  setSelectedTaskId,
  resetHistory,
  setNonCompliantValue,
  setStartDateValue,
  setEndDateValue,
  setPageValue,
  setOperationTypeValue,
} = HistorySlice.actions;

export default HistorySlice.reducer;
