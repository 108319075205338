import React, { Dispatch, SetStateAction } from 'react';
import { Col, Image, Row } from 'react-bootstrap';
import ScrollMenu from 'react-horizontal-scrolling-menu';
import roundedCheckIcon from '@assets/images/svg/check-rond.svg';
import { SignatoryDto } from '@models/dto/response/Signatory.dto';
import accountIcon from '@assets/images/svg/account.svg';
import i18next from 'i18next';
import styles from './BaseSignatory.module.scss';

type Props = {
  signatories: SignatoryDto[];
  selectedSignatory?: SignatoryDto;
  setSelectedSignatory: Dispatch<SetStateAction<SignatoryDto | undefined>>;
};

const List = (list: SignatoryDto[], selectedId: number | null) => {
  return list.map((signatory) => {
    return (
      <div key={signatory.id} className="text-center mr-md-5 mr-3 mt-3">
        {/* selected check icon */}
        {selectedId === signatory.id && (
          <Image src={roundedCheckIcon} className={styles.roundedCheck} />
        )}
        {/* Signatory picture */}
        <Image
          src={signatory.image ?? accountIcon}
          roundedCircle
          className={`menu-item ${styles.picture} ${
            selectedId === signatory.id ? styles.active : ''
          } ${selectedId && selectedId !== signatory.id ? styles.fade : ''} ${
            signatory.image ? '' : styles.backgroundImage
          }`}
        />
        <div className={styles.signatoryName}>
          {signatory.firstName} {`${signatory.lastName[0]}.`}
        </div>
      </div>
    );
  });
};

const BaseSignatory = (props: Props) => {
  const { signatories, selectedSignatory, setSelectedSignatory } = props;

  const handleSelectSignatory = (key: string | number | null) => {
    const selectedItem = signatories.find((el) => el.id === Number(key));
    if (selectedItem) {
      setSelectedSignatory(selectedItem);
    }
  };
  return (
    <Row className="my-5 px-md-5 px-2">
      <Col md={12}>
        {signatories && signatories.length > 0 ? (
          <ScrollMenu
            data={List(signatories, selectedSignatory ? selectedSignatory.id : null)}
            onSelect={(selectedItemKey: string | number | null) =>
              handleSelectSignatory(selectedItemKey)
            }
            transition={2}
            alignCenter={false}
          />
        ) : (
          <p className="tableHeader">{i18next.t('shared:emptySignatory')}</p>
        )}
      </Col>
    </Row>
  );
};

export default BaseSignatory;
