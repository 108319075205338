import BaseNavbarWithHistory from '@components/shared/BaseNavbarWithHistory/BaseNavbarWithHistory';
import i18next from 'i18next';
import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useAppSelector } from '@store/hooks';
import FileSettingsItem from '@components/FileSettingsItem/FileSettingsItem';
import { FolderDto } from '@models/dto/response/Folder.dto';
import { useLocation } from 'react-router-dom';
import { FileDto } from '@models/dto/response/File.dto';
import { format } from 'date-fns';

import styles from './DocumentsFile.module.scss';

interface IFolder {
  folder: FolderDto;
}

const DocumentsFile = () => {
  const folder = useLocation<IFolder>();
  const folderDetail = useAppSelector((state) =>
    state.document.folders.find((fold: FolderDto) => fold.id === folder.state.folder.id),
  );
  const filesList = folderDetail?.files;

  return (
    <>
      <BaseNavbarWithHistory
        title={i18next.t('documents:navbar.title')}
        showSettings={false}
        showHistory={false}
      />
      <Container fluid className="h-100 pt-5 cornerRound px-5">
        <Row>
          <Col md={12} className="d-flex justify-content-between align-items-center">
            <div>
              <div className={styles.listTitle}>{folder.state.folder.name}</div>
              <div className={styles.listSubTitle}>
                {i18next.t('documents:file.infoMAJ')}
                {!folder.state.folder.updateDate
                  ? format(new Date(folder.state.folder.creationDate), 'dd/MM/yyyy HH:mm')
                  : format(new Date(folder.state.folder.updateDate), 'dd/MM/yyyy HH:mm')}
              </div>
            </div>
          </Col>
        </Row>
        {filesList && filesList.length > 0 ? (
          <Row>
            {filesList.map((file: FileDto) => (
              <Col md={4} key={file.id} className="mt-4">
                <FileSettingsItem file={file} isEditMode={false} />
              </Col>
            ))}
          </Row>
        ) : (
          <Row className="d-flex justify-content-center align-items-center h-100">
            <Col md={12} className="text-center tableHeader">
              {i18next.t('documents:file.empty')}
            </Col>
          </Row>
        )}
      </Container>
    </>
  );
};

export default DocumentsFile;
