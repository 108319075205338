import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import calendarExceptionService from '@services/calendar-exception';
import {
  CreateSignatoriesParams,
  EditSignatoriesParams,
} from '@models/dto/request/CalendarExceptionParams.dto';
import { CalendarExceptionDto } from '@models/dto/response/CalendarExceptionList.dto';

// Define a type for the slice state
interface CalendarExceptionsState {
  calendarExceptionList: CalendarExceptionDto[];
}

// Define the initial state using that type
const initialState: CalendarExceptionsState = {
  calendarExceptionList: [],
};

// REQUESTS
export const getCalendarExceptions = createAsyncThunk<
  AxiosResponse<CalendarExceptionDto[]>,
  number
>('calendarExceptions/getAll', async (establishmentId: number) => {
  const response = await calendarExceptionService.getCalendarExceptions(establishmentId);
  return response.data;
});

export const addCalendarException = createAsyncThunk<
  AxiosResponse<CalendarExceptionDto>,
  CreateSignatoriesParams
>('calendarExceptions/add', async (params: CreateSignatoriesParams) => {
  const response = await calendarExceptionService.createCalendarException(
    params.establishmentId,
    params.calendarException,
  );
  return response.data;
});

export const editCalendarException = createAsyncThunk<
  AxiosResponse<CalendarExceptionDto>,
  EditSignatoriesParams
>('calendarExceptions/edit', async (params: EditSignatoriesParams) => {
  const response = await calendarExceptionService.updateCalendarException(
    params.calendarExceptionId,
    params.calendarException,
  );
  return response.data;
});

export const deleteCalendarException = createAsyncThunk<AxiosResponse<any>, number>(
  'calendarExceptions/delete',
  async (calendarExceptionId: number) => {
    const response = await calendarExceptionService.deleteCalendarException(calendarExceptionId);
    return response.data;
  },
);

export const CalendarExceptionsSlice = createSlice({
  name: 'calendarExceptions',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Side effects
    // Get calendarException list
    builder.addCase(getCalendarExceptions.fulfilled, (state, action) => {
      state.calendarExceptionList = action.payload.data ?? [];
    });
    builder.addCase(addCalendarException.fulfilled, (state, action) => {
      state.calendarExceptionList.push(action.payload.data);
    });
    builder.addCase(editCalendarException.fulfilled, (state, action) => {
      state.calendarExceptionList = state.calendarExceptionList.filter(
        (cal: CalendarExceptionDto) => cal.id !== action.payload.data.id,
      );
      state.calendarExceptionList.push(action.payload.data);
    });
    builder.addCase(deleteCalendarException.fulfilled, (state, action) => {
      state.calendarExceptionList = state.calendarExceptionList.filter(
        (cal: CalendarExceptionDto) => cal.id !== action.meta.arg,
      );
    });
  },
});

export default CalendarExceptionsSlice.reducer;
