import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import shared from './fr/shared.json';
import dashboard from './fr/dashboard.json';
import cleaning from './fr/cleaning.json';
import hardwareTemp from './fr/hardwareTemp.json';
import productTemp from './fr/productTemp.json';
import receptionControl from './fr/receptionControl.json';
import oilMonitoring from './fr/oilMonitoring.json';
import printingLabels from './fr/printingLabels.json';
import photosLabels from './fr/photosLabels.json';
import login from './fr/login.json';
import operationsHistory from './fr/operationsHistory.json';
import settings from './fr/settings.json';
import operationsDelayed from './fr/operationsDelayed.json';
import operationsHandling from './fr/operationsHandling.json';
import account from './fr/account.json';
import documents from './fr/documents.json';
import operationsToday from './fr/operationsToday.json';

// https://react.i18next.com/latest/typescript
export const defaultNS = 'shared';
export const resources = {
  fr: {
    shared,
    dashboard,
    cleaning,
    hardwareTemp,
    productTemp,
    receptionControl,
    oilMonitoring,
    printingLabels,
    photosLabels,
    login,
    operationsHistory,
    operationsDelayed,
    settings,
    operationsHandling,
    account,
    documents,
    operationsToday,
  },
} as const;

i18n.use(initReactI18next).init({
  lng: 'fr',
  ns: [
    'shared',
    'dashboard',
    'cleaning',
    'hardwareTemp',
    'productTemp',
    'receptionControl',
    'oilMonitoring',
    'printingLabels',
    'photosLabels',
    'login',
    'operationsHistory',
    'operationsDelayed',
    'settings',
    'operationsHandling',
    'account',
    'operationsToday',
  ],
  defaultNS,
  resources,
});

export default i18n;
