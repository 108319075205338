import React, { Dispatch, SetStateAction, useState, useEffect } from 'react';
import i18next from 'i18next';
import { Form, Modal, Row } from 'react-bootstrap';
import { FrequencyJsonDto } from '@models/dto/FrequencyJson.dto';
import { UpdateCustomerParams } from '@models/dto/request/CustomerParams.dto';
import { toast } from 'react-toastify';
import { CustomerDto } from '@models/dto/response/Customer.dto';
import BaseDishesQuantitiesItem from '@components/shared/BaseDishesQuantitiesItem/BaseDishesQuantitiesItem';
import BaseModalHeader from '@components/shared/BaseModalHeader/BaseModalHeader';
import BaseModalFooter from '@components/shared/BaseModalFooter/BaseModalFooter';
import { checkFormFrequencyValid } from '@utils/frequenciesUtils';
import { FrequencySurfaceEnum } from '@models/dto/request/FrequencySurfaceEnum.dto';
import { DISHES_FAMILY_QUANTITIES, FREQUENCY_JSON } from '@utils/constants';
import BaseOccurenceForm from '@components/shared/BaseOccurenceForm/BaseOccurenceForm';
import { isEqual } from 'lodash';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import styles from './ProductTempSettingsShippingEditModal.module.scss';

const OCCURENCES = [FrequencySurfaceEnum.NONE, FrequencySurfaceEnum.DAILY];

type Props = {
  showModal: boolean;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  frequencyJson?: FrequencyJsonDto;
  setFrequencyJson: Dispatch<SetStateAction<FrequencyJsonDto | undefined>>;
  clientToUpdate: CustomerDto;
  editClient: (data: UpdateCustomerParams) => void;
};

const ProductTempSettingsShippingEditModal = (props: Props) => {
  const { showModal, setShowModal, frequencyJson, setFrequencyJson, clientToUpdate, editClient } =
    props;

  const dishesQuantities = {
    entree: clientToUpdate.entree,
    dish: clientToUpdate.dish,
    garnish: clientToUpdate.garnish,
    cheese: clientToUpdate.cheese,
    dessert: clientToUpdate.dessert,
    other: clientToUpdate.other,
  };

  const [name, setName] = useState<string>(clientToUpdate.name);
  const [minTemp, setMinTemp] = useState<string>(
    clientToUpdate.minTemp ? clientToUpdate.minTemp.toString() : '',
  );
  const [maxTemp, setMaxTemp] = useState<string>(
    clientToUpdate.maxTemp ? clientToUpdate.maxTemp.toString() : '',
  );
  const [isColdDelivery, setIsColdDelivery] = useState<boolean>(
    clientToUpdate.deliveryTemp === 'delivery_cold',
  );
  const [currentDishesQuantities, setCurrentDishesQuantities] = useState<[string, number][]>(
    Object.entries(dishesQuantities),
  );
  const [frequencyType, setFrequencyType] = useState<FrequencySurfaceEnum>(
    isEqual(frequencyJson, FREQUENCY_JSON) ? FrequencySurfaceEnum.NONE : FrequencySurfaceEnum.DAILY,
  );

  const resetForm = () => {
    setFrequencyType(FrequencySurfaceEnum.NONE);
    setCurrentDishesQuantities(Object.entries(DISHES_FAMILY_QUANTITIES));
    setName('');
  };

  const checkDishesForm = (data: UpdateCustomerParams): boolean => {
    const { entree, dish, cheese, garnish, dessert } = data;
    if (entree === 0 && dish === 0 && cheese === 0 && garnish === 0 && dessert === 0) {
      toast.warn(i18next.t('productTemp:toasts.warn.dishesQuantities'));
      return false;
    }
    return true;
  };

  const handleValidate = () => {
    if (!name) {
      toast.warn(i18next.t('productTemp:toasts.warn.missingClientName'));
      return;
    }

    const isFrequencyFormValid = checkFormFrequencyValid(frequencyJson!, 'form', frequencyType);

    if (isFrequencyFormValid) {
      const data: UpdateCustomerParams = {
        customerId: clientToUpdate.id,
        name,
        entree: currentDishesQuantities.filter((x) => x[0] === 'entree')[0][1],
        dish: currentDishesQuantities.filter((x) => x[0] === 'dish')[0][1],
        cheese: currentDishesQuantities.filter((x) => x[0] === 'cheese')[0][1],
        garnish: currentDishesQuantities.filter((x) => x[0] === 'garnish')[0][1],
        dessert: currentDishesQuantities.filter((x) => x[0] === 'dessert')[0][1],
        other: currentDishesQuantities.filter((x) => x[0] === 'other')[0][1],
        frequencyJson: JSON.stringify(frequencyJson),
        deliveryTemp: isColdDelivery ? 'delivery_cold' : 'delivery_hot',
        minTemp: isColdDelivery ? minTemp : undefined,
        maxTemp: isColdDelivery ? maxTemp : undefined,
      };

      if (checkDishesForm(data)) {
        editClient(data);
        setShowModal(false);
      }
    }
  };

  // Remove one quantity from current selected dishes
  const handleRemoveOne = (index: number) => {
    if (currentDishesQuantities[index][1] > 0) {
      setCurrentDishesQuantities((prevState) => {
        const quantities = [...prevState];
        quantities[index] = [...quantities[index]];
        // eslint-disable-next-line operator-assignment
        quantities[index][1] = quantities[index][1] - 1;
        return quantities;
      });
    }
  };

  // Add one quantity to current selected dishes
  const handleAddOne = (index: number) => {
    setCurrentDishesQuantities((prevState) => {
      const quantities = [...prevState];
      quantities[index] = [...quantities[index]];
      // eslint-disable-next-line operator-assignment
      quantities[index][1] = quantities[index][1] + 1;
      return quantities;
    });
  };

  useEffect(() => {
    if (!showModal) {
      resetForm();
    } else {
      setName(clientToUpdate.name);
      setCurrentDishesQuantities(Object.entries(dishesQuantities));
    }
  }, [showModal]);
  return (
    <Modal
      show={showModal}
      onHide={() => setShowModal(false)}
      size="lg"
      aria-labelledby="settings-shipping-edit-modal"
      centered
    >
      <BaseModalHeader
        id="settings-shipping-edit-modal"
        title={i18next.t('productTemp:settings.shipping.modal.editTitle')}
      />
      <Modal.Body>
        <Form className="px-5">
          {/* Name */}
          <Form.Group className="d-flex flex-row align-items-center mt-5" controlId="name">
            <Form.Label className={styles.inputLabel}>
              {i18next.t('cleaning:settings.surfaces.modal.form.name')}
            </Form.Label>
            <Form.Control
              type="text"
              autoComplete="off"
              value={name}
              className="baseInputLg ml-3"
              onChange={(e) => setName(e.target.value)}
            />
          </Form.Group>
          <BaseOccurenceForm
            frequencyJson={frequencyJson}
            setFrequencyJson={setFrequencyJson}
            frequencyType={frequencyType}
            setFrequencyType={setFrequencyType}
            occurences={OCCURENCES}
            labelFrequency={i18next.t('productTemp:settings.shipping.frequency')}
            labelWeekday={i18next.t('productTemp:settings.shipping.shippingDays')}
            labelSlots={i18next.t('productTemp:settings.shipping.slots')}
          />
          {/* Dishes quantities */}
          <Form.Group className="mt-5 d-flex flex-column" controlId="dishesQuantities">
            <Form.Label className={styles.inputLabel}>
              {i18next.t('productTemp:settings.shipping.modal.form.dishesQuantities')}
            </Form.Label>
            <Row className="mt-5">
              {currentDishesQuantities.map((dish: [string, number], index: number) => (
                <BaseDishesQuantitiesItem
                  key={index}
                  index={index}
                  dish={dish}
                  handleAddOne={handleAddOne}
                  handleRemoveOne={handleRemoveOne}
                />
              ))}
            </Row>
          </Form.Group>
          <Form.Group className="mt-5 d-flex flex-column" controlId="deliveryType">
            <Form.Label className={styles.inputLabel}>
              {i18next.t('productTemp:settings.shipping.modal.form.deliveryType')}
            </Form.Label>
            <Row className="mt-2">
              <FormControlLabel
                className={`${!isColdDelivery ? 'checked' : ''}`}
                control={
                  <Checkbox
                    value={!isColdDelivery}
                    className={`${!isColdDelivery ? 'checked' : ''}`}
                    checked={!isColdDelivery ?? false}
                    onChange={(e: any) => setIsColdDelivery(!e.target.checked)}
                  />
                }
                label="Liaison chaude"
              />
              <FormControlLabel
                className={`${isColdDelivery ? 'checked' : ''}`}
                control={
                  <Checkbox
                    value={isColdDelivery}
                    className={`${isColdDelivery ? 'checked' : ''}`}
                    checked={isColdDelivery ?? false}
                    onChange={(e: any) => setIsColdDelivery(e.target.checked)}
                  />
                }
                label="Liaison froide"
              />
            </Row>
            {isColdDelivery && (
              <div className="d-flex justify-content-between">
                <Row className="mt-2 col-6 px-0">
                  <Form.Label className={styles.inputLabel}>
                    {i18next.t('productTemp:settings.shipping.modal.form.tempMin')}
                  </Form.Label>
                  <Form.Control
                    type="text"
                    autoComplete="off"
                    value={minTemp}
                    className="baseInputLg ml-3"
                    onChange={(e) => setMinTemp(e.target.value)}
                  />
                </Row>
                <Row className="mt-2 col-6 px-0">
                  <Form.Label className={styles.inputLabel}>
                    {i18next.t('productTemp:settings.shipping.modal.form.tempMax')}
                  </Form.Label>
                  <Form.Control
                    type="text"
                    autoComplete="off"
                    value={maxTemp}
                    className="baseInputLg ml-3"
                    onChange={(e) => setMaxTemp(e.target.value)}
                  />
                </Row>
              </div>
            )}
          </Form.Group>
        </Form>
      </Modal.Body>
      <BaseModalFooter handleValidate={handleValidate} setShowModal={setShowModal} />
    </Modal>
  );
};

export default ProductTempSettingsShippingEditModal;
