import BaseNavbarWithHistory from '@components/shared/BaseNavbarWithHistory/BaseNavbarWithHistory';
import ProductTempSettingsDishesItem from '@components/ProductTempSettingsDishesItem/ProductTempSettingsDishesItem';
import i18next from 'i18next';
import React, { useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { getDishesFamily } from '@store/reducers/dish';
import BaseNavbar from '@components/shared/BaseNavbar/BaseNavbar';
import styles from './ProductTempSettingsDishes.module.scss';

const ProductTempSettingsDishes = () => {
  const dishesFamily = useAppSelector((state) => state.dish.dishesFamily);
  const establishmentId = useAppSelector((state) => state.account.selectedEstablishment?.id);
  const accountType = useAppSelector((state) => state.account.type);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if ((!dishesFamily || dishesFamily.length === 0) && establishmentId) {
      dispatch(getDishesFamily(establishmentId)).unwrap();
    }
  }, []);

  return (
    <>
      <BaseNavbar accountType={accountType} parametersUrl="/settings" />
      <BaseNavbarWithHistory
        title={i18next.t('productTemp:settings.navbar.title')}
        showSettings={false}
        showHistory={false}
      />
      <Container fluid className="h-100 pt-5 cornerRound px-5">
        <Row>
          <Col md={12} className="d-flex justify-content-between align-items-center">
            <div className={styles.listTitle}>{i18next.t('productTemp:settings.dishes.title')}</div>
          </Col>
        </Row>
        {dishesFamily && dishesFamily.length > 0 ? (
          <Row>
            {dishesFamily.map((dish, index: number) => (
              <Col sm={6} md={4} key={dish.id} className="mt-4">
                <ProductTempSettingsDishesItem dish={dish} index={index} />
              </Col>
            ))}
          </Row>
        ) : (
          <Row className="d-flex justify-content-center align-items-center h-100">
            <Col md={12} className="text-center tableHeader">
              {i18next.t('productTemp:settings.dishes.empty')}
            </Col>
          </Row>
        )}
      </Container>
    </>
  );
};

export default ProductTempSettingsDishes;
