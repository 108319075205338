import Axios from './index';

export default {
  getEquipments: (establishmentId: number) => {
    return Axios.get(`/establishment/${establishmentId}/equipment`);
  },

  createEquipment: (establishmentId: number, data: FormData) => {
    return Axios.post(`/establishment/${establishmentId}/equipment`, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },

  getEquipment: (id: number) => {
    return Axios.get(`/establishment/equipment/${id}`);
  },

  updateEquipment: (id: number, data: FormData) => {
    return Axios.post(`/establishment/equipment/${id}`, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },

  deleteEquipment: (id: number) => {
    return Axios.delete(`/establishment/equipment/${id}`);
  },
};
