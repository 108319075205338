import React from 'react';
import i18next from 'i18next';
import { Table, Button, Image } from 'react-bootstrap';
import { format } from 'date-fns';
import fr from 'date-fns/locale/fr';

import OperationTempReadingTypeEnum from '@models/enums/OperationTempReadingType';
import tempDownIcon from '@assets/images/svg/temp-down.svg';
import tempUpIcon from '@assets/images/svg/temp-up.svg';
import { useAppDispatch } from '@store/hooks';
import {
  deleteProductTempCooling,
  deleteProductTempReheating,
  setSelectedUnfinishedTempReading,
} from '@store/reducers/product-temperature';
import {
  UnfinishedProductTempReadingCoolingDto,
  UnfinishedProductTempReadingReheatingDto,
} from '@models/dto/response/UnfinishedProductTempReading.dto';
import { history } from '@router/routes';
import { toast } from 'react-toastify';
import styles from './ProductTempUnfinishedTable.module.scss';

type Props = {
  unfinishedMeasure: (
    | UnfinishedProductTempReadingCoolingDto
    | UnfinishedProductTempReadingReheatingDto
  )[];
};

const getTemperature = (
  operation: UnfinishedProductTempReadingCoolingDto | UnfinishedProductTempReadingReheatingDto,
  index: number,
) => {
  if (
    operation.subType === OperationTempReadingTypeEnum.cooling ||
    operation.subType === OperationTempReadingTypeEnum.reheating
  ) {
    return (
      <td
        align="center"
        className={`${index === 0 ? 'border-top-0' : ''} ${styles.temperature} text-nowrap`}
      >
        <p>
          <span className={styles.labels}>{i18next.t(`productTemp:unfinished.temperature`)}</span>{' '}
          {(operation as UnfinishedProductTempReadingCoolingDto).startTemperature} °C
        </p>
        <span className={styles.labels} style={{ textAlign: 'center' }}>
          {format(new Date(operation.userRealisationDate), 'Pp', {
            locale: fr,
          })}
        </span>
      </td>
    );
  }

  return <td> </td>;
};

const getType = (type: OperationTempReadingTypeEnum) => {
  switch (type) {
    case OperationTempReadingTypeEnum.cooling:
      return (
        <div className={styles.cooling}>
          <div className={`${styles.coolingCircle} mr-2`}>
            <Image src={tempDownIcon} height={11} width={13} />
          </div>
          {i18next.t(`productTemp:unfinished.types.${type}`)}
        </div>
      );
    case OperationTempReadingTypeEnum.reheating:
      return (
        <div className={styles.reheating}>
          <div className={`${styles.reheatingCircle} mr-2`}>
            <Image src={tempUpIcon} height={11} width={13} />
          </div>
          {i18next.t(`productTemp:unfinished.types.${type}`)}
        </div>
      );
    default:
      return <div />;
  }
};

const ProductTempUnfinishedTable = (props: Props) => {
  const dispatch = useAppDispatch();

  const cancelMeasure = (measureId: number, type: OperationTempReadingTypeEnum) => {
    switch (type) {
      case OperationTempReadingTypeEnum.cooling:
        toast.promise(dispatch(deleteProductTempCooling(measureId)).unwrap(), {
          pending: i18next.t('productTemp:toasts.pending.deleteMeasure'),
          success: i18next.t('productTemp:toasts.success.deleteMeasure'),
          error: i18next.t('productTemp:toasts.error.deleteMeasure'),
        });
        break;
      case OperationTempReadingTypeEnum.reheating:
        toast.promise(dispatch(deleteProductTempReheating(measureId)).unwrap(), {
          pending: i18next.t('productTemp:toasts.pending.deleteMeasure'),
          success: i18next.t('productTemp:toasts.success.deleteMeasure'),
          error: i18next.t('productTemp:toasts.error.deleteMeasure'),
        });
        break;
      case OperationTempReadingTypeEnum.service:
      default:
        break;
    }
  };

  const finishMeasure = (
    measure: UnfinishedProductTempReadingCoolingDto | UnfinishedProductTempReadingReheatingDto,
  ) => {
    dispatch(setSelectedUnfinishedTempReading(measure));
    history.push('/product-temperature/unfinished/solve');
  };
  return (
    <Table responsive className="mt-5 border-top-0">
      <tbody>
        {props.unfinishedMeasure.map(
          (
            measure:
              | UnfinishedProductTempReadingCoolingDto
              | UnfinishedProductTempReadingReheatingDto,
            index: number,
          ) => (
            <tr key={index} className="py-5">
              <td className={`${index === 0 ? 'border-top-0' : ''} pl-5`}>
                {/* Date */}
                <div className={styles.realisationDate}>
                  {format(new Date(measure.realisationDate), 'dd/MM/yyyy')}
                </div>
                {/* Product */}
                <div className={styles.operationType}>
                  {measure.dishName !== '' ? measure.dishName : measure.dishSubFamily.name}
                </div>
                {/* Type */}
                {getType(measure.subType)}
              </td>
              {getTemperature(measure, index)}
              <td align="right" className={`${index === 0 ? 'border-top-0' : ''} pr-5`}>
                <Button
                  className={`${styles.fixButton} btnMd d-flex align-items-center w-100 justify-content-center text-nowrap`}
                  onClick={() => finishMeasure(measure)}
                >
                  {i18next.t('productTemp:unfinished.finishMeasureButton')}
                </Button>
                <Button
                  className={`${styles.editButton} btnMd d-flex align-items-center mt-3 w-100 justify-content-center`}
                  onClick={() => cancelMeasure(measure.id, measure.subType)}
                >
                  {i18next.t('shared:buttons.cancel')}
                </Button>
              </td>
            </tr>
          ),
        )}
      </tbody>
    </Table>
  );
};

export default ProductTempUnfinishedTable;
