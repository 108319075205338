import Axios from './index';

export default {
  getProductFamilies: () => {
    return Axios.get(`/establishment/productFamily`);
  },

  getProductFamily: (id: number) => {
    return Axios.get(`/establishment/productFamily/${id}`);
  },

  getProductSubFamilies: (productFamilyId: number) => {
    return Axios.get(`/establishment/productFamily/${productFamilyId}/productSubFamily`);
  },

  getProductSubFamily: (id: number) => {
    return Axios.get(`/establishment/productFamily/productSubFamily/${id}`);
  },
};
