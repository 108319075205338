import React, { useState } from 'react';
import { Image } from 'react-bootstrap';
import trashWhiteIcon from '@assets/images/svg/trash-white.svg';
import editIcon from '@assets/images/svg/edit.svg';
import plusIcon from '@assets/images/svg/plus-rond-vert.svg';
import i18next from 'i18next';
import { makeStyles, Table, TableBody, TableCell, TableRow } from '@material-ui/core';
import { DishDto } from '@models/dto/response/Dish.dto';
import { DishSubFamilyDto } from '@models/dto/response/DishSubFamily.dto';
import { useAppDispatch } from '@store/hooks';
import { toast } from 'react-toastify';
import {
  CreateDishParams,
  DeleteDishParams,
  UpdateDishParams,
} from '@models/dto/request/DishParams.dto';
import { createDish, deleteDish, updateDish } from '@store/reducers/dish';
import ProductTempSettingsDishesModal from '@components/ProductTempSettingsDishesModal/ProductTempSettingsDishesModal';
import styles from './ProductTempSettingsDishesSubTable.module.scss';

type Props = {
  subFamily: DishSubFamilyDto;
};

const useStyles = makeStyles({
  customTable: {
    '& .MuiTableCell-sizeSmall': {
      padding: '20px 0px 20px 16px',
    },
  },
});

const ProductTempSettingsDishesSubTable = (props: Props) => {
  const { subFamily } = props;
  const dispatch = useAppDispatch();
  const classes = useStyles();

  const [showModal, setShowModal] = useState<boolean>(false);
  const [isEdition, setIsEdition] = useState<boolean>(false);
  const [selectedDish, setSelectedDish] = useState<DishDto>();

  const addDish = (name: string) => {
    const data: CreateDishParams = {
      dishSubFamilyId: subFamily.id,
      name,
    };
    toast.promise(dispatch(createDish(data)).unwrap(), {
      pending: i18next.t('productTemp:toasts.pending.createDish'),
      success: i18next.t('productTemp:toasts.success.createDish'),
      error: i18next.t('productTemp:toasts.error.createDish'),
    });
  };

  const editDish = (id: number, name: string) => {
    const data: UpdateDishParams = {
      dishSubFamilyId: subFamily.id,
      dishId: id,
      name,
    };
    toast.promise(dispatch(updateDish(data)).unwrap(), {
      pending: i18next.t('productTemp:toasts.pending.updateDish'),
      success: i18next.t('productTemp:toasts.success.updateDish'),
      error: i18next.t('productTemp:toasts.error.updateDish'),
    });
  };

  const removeDish = (id: number) => {
    const data: DeleteDishParams = {
      dishSubFamilyId: subFamily.id,
      dishId: id,
    };
    toast.promise(dispatch(deleteDish(data)).unwrap(), {
      pending: i18next.t('productTemp:toasts.pending.updateDish'),
      success: i18next.t('productTemp:toasts.success.updateDish'),
      error: i18next.t('productTemp:toasts.error.updateDish'),
    });
  };

  const openModalAdd = () => {
    setIsEdition(false);
    setShowModal(true);
  };

  const openModalEdit = (dish: DishDto) => {
    setSelectedDish(dish);
    setIsEdition(true);
    setShowModal(true);
  };

  return (
    <Table size="small" aria-label="purchases" classes={{ root: classes.customTable }}>
      <TableBody>
        <TableRow>
          {/* Add a new dish row */}
          <TableCell
            component="th"
            scope="row"
            style={{
              borderBottom: 0,
            }}
            onClick={openModalAdd}
          >
            <Image
              src={plusIcon}
              width={32}
              height={32}
              className="mr-3"
              style={{ cursor: 'pointer' }}
            />
            <span className={styles.dishLabel}>
              {i18next.t('productTemp:settings.dishes.addDish')}
            </span>
          </TableCell>
        </TableRow>
        {/* Dishes list */}
        {subFamily.dishes.map((dish: DishDto) => (
          <TableRow key={dish.id}>
            <TableCell
              component="th"
              scope="row"
              style={{
                borderBottom: 0,
                marginTop: '15px',
              }}
              className="d-flex flex-row"
            >
              {/* Delete dish */}
              <div className={`mr-3 ${styles.circle}`}>
                <Image
                  src={trashWhiteIcon}
                  width={15}
                  height={19}
                  onClick={() => removeDish(dish.id)}
                  className={styles.trashIcon}
                />
              </div>
              {/* Dish name */}
              <span className={`${styles.dishLabel} mr-4`}>{dish.name}</span>
              {/* Edit dish */}
              <Image
                src={editIcon}
                width={23}
                height={23}
                onClick={() => openModalEdit(dish)}
                className="mr-3"
                style={{ cursor: 'pointer' }}
              />
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
      <ProductTempSettingsDishesModal
        showModal={showModal}
        setShowModal={setShowModal}
        isEdition={isEdition}
        selectedDish={selectedDish}
        addDish={addDish}
        editDish={editDish}
      />
    </Table>
  );
};

export default ProductTempSettingsDishesSubTable;
