// eslint-disable-next-line import/prefer-default-export
export enum FrequencySurfaceEnum {
  NONE = 'none',
  DAILY = 'dayAll',
  WEEKLY = 'weekly',
  MONTLHY = 'monthly',
  QUARTERLY = 'quarterly',
  SEMIANNUAL = 'semiAnnual',
  ANNUAL = 'annual',
}
