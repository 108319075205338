import React, { Dispatch, SetStateAction } from 'react';
import i18next from 'i18next';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import styles from './BaseModalFooter.module.scss';

type Props = {
  handleValidate: () => void;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  handleCancel?: () => void;
  cancelText?: string;
  validateText?: string;
};

const BaseModalFooter = (props: Props) => {
  const { handleValidate, setShowModal, handleCancel, cancelText, validateText } = props;
  return (
    <Modal.Footer>
      <Row className="w-100 flex-row flex-nowrap">
        <Col md={6} className={`text-center ${styles.borderButtons} h-100`}>
          <Button
            onClick={() => {
              if (handleCancel) {
                handleCancel();
              }
              setShowModal(false);
            }}
            variant="link"
            className={styles.cancelButton}
          >
            {cancelText || i18next.t('shared:buttons.cancel')}
          </Button>
        </Col>
        <Col md={6} className="text-center">
          <Button onClick={handleValidate} variant="link" className={styles.validateButton}>
            {validateText || i18next.t('shared:buttons.validate')}
          </Button>
        </Col>
      </Row>
    </Modal.Footer>
  );
};

export default BaseModalFooter;
