import BaseNavbarWithHistory from '@components/shared/BaseNavbarWithHistory/BaseNavbarWithHistory';
import i18next from 'i18next';
import React, { useEffect, useState } from 'react';
import { Col, ListGroup, ListGroupItem, Row, Image } from 'react-bootstrap';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import {
  deleteEquipmentTemperature,
  getEquipments,
  selectEquipment,
  selectEquipmentTemperature,
} from '@store/reducers/equipment';
import { EquipmentDto, EquipmentTemperatureDto } from '@models/dto/response/Equipment.dto';
// import { history } from '@router/routes';
import placeholderImage from '@assets/images/svg/placeholder.svg';
import BaseTemperature from '@components/shared/BaseTemperature/BaseTemperature';
import roundedCheckIcon from '@assets/images/svg/check-rond.svg';
import styles from './HardwareTempListView.module.scss';

const ItemList = ({ item }: { item: EquipmentDto }) => {
  const dispatch = useAppDispatch();

  const [temperature, setTemperature] = useState<string | undefined>();
  const selectedHardware = useAppSelector((state) => state.equipment.selectedEquipment);
  const selectedEquipmentTemparature = useAppSelector(
    (state) => state.equipment.selectedEquipmentTemparature,
  );

  const isSelected = !!selectedHardware?.find((hardware: EquipmentDto) => item.id === hardware.id);

  useEffect(() => {
    const isCompleted = selectedEquipmentTemparature?.find(
      (equipment: EquipmentTemperatureDto) => equipment.equipmentId === item.id,
    );
    if (isCompleted) {
      setTemperature(`${isCompleted.temperature}`);
    } else {
      setTemperature(undefined);
    }
  }, [selectedEquipmentTemparature]);

  useEffect(() => {
    if (temperature) {
      dispatch(
        selectEquipmentTemperature({ equipmentId: item.id, temperature: parseFloat(temperature) }),
      );
      const isAlreadySelected = selectedHardware?.find(
        (equipment: EquipmentDto) => equipment.id === item.id,
      );
      if (!isAlreadySelected) {
        if (selectedHardware) {
          dispatch(selectEquipment([...selectedHardware, item]));
        } else {
          dispatch(selectEquipment([item]));
        }
      }
    }
  }, [temperature]);

  return (
    <Row>
      <Col md={12} className="d-flex flex-row align-items-center">
        <Image src={item.picture ?? placeholderImage} width={110} height={110} />
        <div className="ml-5">
          <p>{item.name}</p>
          <BaseTemperature
            size="small"
            temperature={temperature}
            setTemperature={setTemperature}
            tMin={item?.minTemperature}
            tMax={item?.maxTemperature}
          />
        </div>
        {isSelected && (
          <Image src={roundedCheckIcon} className={styles.roundedCheck} width={48} height={48} />
        )}
      </Col>
    </Row>
  );
};

const HardwareTempListView = () => {
  const equipments = useAppSelector((state) => state.equipment.equipments);
  const establishmentId = useAppSelector((state) => state.account.selectedEstablishment?.id);
  const selectedHardware = useAppSelector((state) => state.equipment.selectedEquipment);
  const dispatch = useAppDispatch();

  const handleSelectEquipment = (item: EquipmentDto) => {
    const isSelected = selectedHardware?.find(
      (equipment: EquipmentDto) => equipment.id === item.id,
    );

    if (isSelected) {
      dispatch(deleteEquipmentTemperature(item.id));
      dispatch(
        selectEquipment(
          selectedHardware?.filter((equipment: EquipmentDto) => equipment.id !== item.id),
        ),
      );
    } else if (selectedHardware) {
      dispatch(selectEquipment([...selectedHardware, item]));
    } else {
      dispatch(selectEquipment([item]));
    }
    // history.goBack();
  };

  useEffect(() => {
    if ((!equipments || equipments.length === 0) && establishmentId) {
      dispatch(getEquipments(establishmentId)).unwrap();
    }
  }, []);

  return (
    <>
      <BaseNavbarWithHistory
        title={i18next.t('hardwareTemp:navbar.listTitle')}
        showSettings={false}
        showHistory={false}
        showValidate
      />
      <Row noGutters className="cornerRound">
        <Col md={12}>
          <p className="mr-4 mt-2 mb-2" style={{ textAlign: 'right' }}>
            sélectionner ({selectedHardware?.length})
          </p>
          {equipments && equipments.length > 0 ? (
            <ListGroup>
              {equipments.map((item: EquipmentDto, index: number) => (
                <ListGroupItem
                  key={index}
                  onClick={() => handleSelectEquipment(item)}
                  className={styles.groupItem}
                >
                  <ItemList item={item} />
                </ListGroupItem>
              ))}
            </ListGroup>
          ) : (
            <p>{i18next.t('hardwareTemp:noEquipment')}</p>
          )}
        </Col>
      </Row>
    </>
  );
};

export default HardwareTempListView;
