import React from 'react';
import { Card, Image, ListGroup, OverlayTrigger, Popover } from 'react-bootstrap';
import moreOptionsIcon from '@assets/images/svg/more-options.svg';
import { history } from '@router/routes';
import { AreaDto } from '@models/dto/response/Area.dto';
import i18next from 'i18next';
import styles from './CleaningZoneSettingsItem.module.scss';

type Props = {
  zone: AreaDto;
  setShowModalDelete: () => void;
};

const CleaningZoneSettingsItem = (props: Props) => {
  const { zone, setShowModalDelete } = props;

  const editZone = () => {
    history.push('/cleaning/settings/surfaces', { zone });
  };
  return (
    <Card style={{ backgroundColor: zone.color }} className={`h-100 mr-3 ${styles.cardContainer}`}>
      <Card.Body className="d-flex justify-content-center align-items-center text-wrap text-center">
        <OverlayTrigger
          placement="bottom"
          trigger="click"
          rootClose
          overlay={
            <Popover id={`zone-popover-${zone.id}`}>
              <Popover.Content>
                <ListGroup>
                  <ListGroup.Item className="border-0" onClick={editZone}>
                    {i18next.t('shared:buttons.edit')}
                  </ListGroup.Item>
                  <ListGroup.Item className="border-0" onClick={setShowModalDelete}>
                    {i18next.t('shared:buttons.delete')}
                  </ListGroup.Item>
                </ListGroup>
              </Popover.Content>
            </Popover>
          }
        >
          <div className={`${styles.moreOptions} pl-4 pt-1 pb-4`}>
            <Image src={moreOptionsIcon} width={6} height={27} />
          </div>
        </OverlayTrigger>
        <Card.Text className={styles.cardText}>{zone.name}</Card.Text>
      </Card.Body>
    </Card>
  );
};

export default CleaningZoneSettingsItem;
