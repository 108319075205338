import { CreateCleaningPlan } from '@models/dto/request/CreateCleaningPlan.dto';
import { CreateEquipmentTempReadingDto } from '@models/dto/request/CreateEquipmentTempReading.dto';
import { UpdateEquipmentTempReadingDto } from '@models/dto/request/UpdateEquipmentTemp.dto';
import {
  EquipmentTempReadingResponseDto,
  OperationEquipmentTemperatureReadingDto,
} from '@models/dto/response/OperationEquipmentTemperatureReading.dto';
import {
  InspectionUponReceiptResponseDto,
  OperationInspectionUponReceiptDto,
} from '@models/dto/response/OperationInspectionUponReceipt.dto';
import { OperationTypesDto } from '@models/dto/response/OperationTypes.dto';
import { ApiResponse } from '@models/dto/ApiResponse.dto';
import { AxiosResponse } from 'axios';
import Axios from './index';

export default {
  // For home page cards
  getOperationTypes: (): Promise<AxiosResponse<ApiResponse<OperationTypesDto[]>>> => {
    return Axios.get(`/operation/types`);
  },

  // EQUIPMENT TEMP

  getEquipmentTemp: (
    id: number,
  ): Promise<AxiosResponse<ApiResponse<OperationEquipmentTemperatureReadingDto>>> => {
    return Axios.get(`/operation/equipmentTempReading/${id}`);
  },

  // Create equipment temperature reading operation
  createEquipmentTemp: (
    data: CreateEquipmentTempReadingDto,
  ): Promise<AxiosResponse<ApiResponse<EquipmentTempReadingResponseDto>>> => {
    return Axios.post(`/operation/equipmentTempReading`, data);
  },

  // Create equipment temperature reading operation
  updateEquipmentTemp: (
    id: number,
    data: UpdateEquipmentTempReadingDto,
  ): Promise<AxiosResponse<ApiResponse<EquipmentTempReadingResponseDto>>> => {
    return Axios.put(`/operation/equipmentTempReading/${id}`, data);
  },

  // CLEANING

  getCleaningPlan: (id: number) => {
    return Axios.get(`/operation/cleaningPlan/${id}`);
  },

  // Create cleaning plan operation
  createCleaningPlan: (data: CreateCleaningPlan) => {
    return Axios.post(`/operation/cleaningPlan`, data);
  },

  // RECEPTION CONTROL

  getInspectionUponReceipt: (
    id: number,
  ): Promise<AxiosResponse<ApiResponse<OperationInspectionUponReceiptDto>>> => {
    return Axios.get(`/operation/inspectionUponReceipt/${id}`);
  },

  createInspectionUponReceipt: (
    data: FormData,
  ): Promise<AxiosResponse<ApiResponse<InspectionUponReceiptResponseDto>>> => {
    return Axios.post(`/operation/inspectionUponReceipt`, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },

  addPhotoToInspectionUponReceipt: (
    id: number,
    data: FormData,
  ): Promise<AxiosResponse<ApiResponse<InspectionUponReceiptResponseDto>>> => {
    return Axios.post(`/operation/inspectionUponReceipt/${id}/addPhoto`, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },

  // LABELS

  createLabelPhoto: (data: FormData) => {
    return Axios.post(`/operation/labelPhoto`, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },

  addPhotoToLabel: (labelId: number, photo: FormData) => {
    return Axios.post(`/operation/labelPhoto/${labelId}/addPhoto`, photo, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },

  // OIL MONITORING

  createOilMonitoring: (data: FormData) => {
    return Axios.post(`/operation/oilMonitoring`, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },

  addPhotoToOilMonitoring: (oilMonitoringId: number, photo: FormData) => {
    return Axios.post(`/operation/oilMonitoring/${oilMonitoringId}/addPhoto`, photo, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },

  deleteTask: (taskId: number) => {
    return Axios.delete(`/task/${taskId}`);
  },
};
