import Axios from './index';

interface IDishSubFamily {
  name: string;
  minTemperature: number;
  maxTemperature: number;
}

export default {
  getDishesSubFamily: (subFamilyId: number) => {
    return Axios.get(`/establishment/dishSubFamily/${subFamilyId}/dish`);
  },

  createDish: (subFamilyId: number, name: string) => {
    return Axios.post(`/establishment/dishSubFamily/${subFamilyId}/dish`, { name });
  },

  getDish: (dishId: number) => {
    return Axios.get(`/establishment/dishSubFamily/dish/${dishId}`);
  },

  updateDish: (dishId: number, name: string) => {
    return Axios.put(`/establishment/dishSubFamily/dish/${dishId}`, { name });
  },

  deleteDish: (dishId: number) => {
    return Axios.delete(`/establishment/dishSubFamily/dish/${dishId}`);
  },

  getDishFamilies: (establishmentId: number) => {
    return Axios.get(`/establishment/${establishmentId}/dishFamily`);
  },

  getDishFamily: (id: number) => {
    return Axios.get(`/establishment/dishFamily/${id}`);
  },

  createDishSubFamily: (dishFamilyId: number, subFamily: IDishSubFamily) => {
    return Axios.post(`/establishment/dishFamily/${dishFamilyId}/dishSubFamily`, subFamily);
  },

  getDishSubFamily: (id: number) => {
    return Axios.get(`/establishment/dishFamily/dishSubFamily/${id}`);
  },

  updateDishSubFamily: (id: number, subFamily: IDishSubFamily) => {
    return Axios.put(`/establishment/dishFamily/dishSubFamily/${id}`, subFamily);
  },

  deleteDishSubFamily: (id: number) => {
    return Axios.delete(`/establishment/dishFamily/dishSubFamily/${id}`);
  },

  // Get all the dishSubFamilies of a dishFamily
  getDishSubFamilies: (id: number) => {
    return Axios.get(`/establishment/dishFamily/${id}/dishSubFamily`);
  },
};
