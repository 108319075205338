import Axios from './index';

export default {
  getFolders: (establishmentId: number) => {
    return Axios.get(`/establishment/${establishmentId}/folder`);
  },

  createFolder: (establishmentId: number, name: string) => {
    return Axios.post(`/establishment/${establishmentId}/folder`, { name });
  },

  deleteFolder: (folderId: number) => {
    return Axios.delete(`/establishment/folder/${folderId}`);
  },

  getFiles: (folderId: number) => {
    return Axios.get(`/establishment/folder/${folderId}/file`);
  },

  createFile: (folderId: number, data: FormData) => {
    return Axios.post(`/establishment/folder/${folderId}/file`, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },

  deleteFile: (fileId: number) => {
    return Axios.delete(`/establishment/folder/file/${fileId}`);
  },
};
