import React, { useState, useEffect } from 'react';
import i18next from 'i18next';
import { Table, Image } from 'react-bootstrap';
import { DeepFryerDto } from '@models/dto/response/DeepFryer.dto';
import OilMonitoringSettingsEditModal from '@components/OilMonitoringSettingsEditModal/OilMonitoringSettingsEditModal';
import { useAppDispatch } from '@store/hooks';
import { deleteDeepFryer, updateDeepFryer } from '@store/reducers/deep-fryer';
import placeholderImage from '@assets/images/svg/placeholder.svg';
import { toast } from 'react-toastify';
import BaseTableButtons from '@components/shared/BaseTableButtons/BaseTableButtons';
import styles from './OilMonitoringSettingsTable.module.scss';

type Props = {
  deepFryerList: DeepFryerDto[];
};

const OilMonitoringSettingsTable = (props: Props) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [deepFryerToUpdate, setDeepFryerToUpdate] = useState<DeepFryerDto | undefined>();
  const dispatch = useAppDispatch();

  const removeDeepFryer = (id: number) => {
    toast.promise(dispatch(deleteDeepFryer(id)).unwrap(), {
      pending: i18next.t('oilMonitoring:toasts.pending.delete'),
      success: i18next.t('oilMonitoring:toasts.success.delete'),
      error: i18next.t('oilMonitoring:toasts.error.delete'),
    });
  };

  const editDeepFryer = (deepFryer: DeepFryerDto) => {
    if (deepFryerToUpdate) {
      toast.promise(
        dispatch(
          updateDeepFryer({
            name: deepFryer.name,
            picture: deepFryer.picture,
            deepFryerId: deepFryerToUpdate.id,
          }),
        ).unwrap(),
        {
          pending: i18next.t('oilMonitoring:toasts.pending.update'),
          success: i18next.t('oilMonitoring:toasts.success.update'),
          error: i18next.t('oilMonitoring:toasts.error.update'),
        },
      );
    }
  };

  const openModal = (deepFryer: DeepFryerDto) => {
    setDeepFryerToUpdate(deepFryer);
  };

  useEffect(() => {
    if (deepFryerToUpdate) {
      setShowModal(true);
    }
  }, [deepFryerToUpdate]);
  return (
    <>
      <Table responsive className="mt-5">
        <thead className="border-top-0">
          <tr className="tableHeader">
            <th> </th>
            <th>{i18next.t('oilMonitoring:settings.table.headers.name')}</th>
            <th> </th>
          </tr>
        </thead>
        <tbody>
          {props.deepFryerList.map((deepFryer: DeepFryerDto, index: number) => {
            return (
              <tr key={index} className="py-5">
                <td>
                  <Image
                    src={deepFryer.picture ?? placeholderImage}
                    width={53}
                    height={53}
                    className={styles.picture}
                  />
                </td>
                <td className="tableFirstColumn">{deepFryer.name}</td>
                <td>
                  <BaseTableButtons
                    editAction={() => openModal(deepFryer)}
                    deleteAction={() => removeDeepFryer(deepFryer.id)}
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
      {deepFryerToUpdate && (
        <OilMonitoringSettingsEditModal
          showModal={showModal}
          setShowModal={setShowModal}
          updateDeepFryer={editDeepFryer}
          deepFryerToUpdate={deepFryerToUpdate}
          setDeepFryerToUpdate={setDeepFryerToUpdate}
        />
      )}
    </>
  );
};

export default OilMonitoringSettingsTable;
