import BaseNavbarWithHistory from '@components/shared/BaseNavbarWithHistory/BaseNavbarWithHistory';
import i18next from 'i18next';
import React, { useEffect } from 'react';
import { Col, ListGroup, ListGroupItem, Row, Image } from 'react-bootstrap';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { history } from '@router/routes';
import { getDeepFryers, selectDeepFryer } from '@store/reducers/deep-fryer';
import { DeepFryerDto } from '@models/dto/response/DeepFryer.dto';
import placeholderImage from '@assets/images/svg/placeholder.svg';
import styles from './OilMonitoringListView.module.scss';

const OilMonitoringListView = () => {
  const deepFryers = useAppSelector((state) => state.deepFryer.deepFryers);
  const establishmentId = useAppSelector((state) => state.account.selectedEstablishment?.id);
  const dispatch = useAppDispatch();

  const handleSelectDeepFryer = (item: DeepFryerDto) => {
    dispatch(selectDeepFryer(item));
    history.goBack();
  };

  useEffect(() => {
    if ((!deepFryers || deepFryers.length === 0) && establishmentId) {
      dispatch(getDeepFryers(establishmentId)).unwrap();
    }
  }, []);
  return (
    <>
      <BaseNavbarWithHistory
        title={i18next.t('oilMonitoring:navbar.listTitle')}
        showSettings={false}
        showHistory={false}
      />
      <Row noGutters>
        <Col md={12}>
          {deepFryers && deepFryers.length > 0 ? (
            <ListGroup>
              {deepFryers.map((item: DeepFryerDto, index: number) => (
                <ListGroupItem
                  key={index}
                  onClick={() => handleSelectDeepFryer(item)}
                  className={styles.groupItem}
                >
                  <Row>
                    <Col md={12} className="d-flex flex-row align-items-center">
                      <Image
                        src={item.picture ?? placeholderImage}
                        width={110}
                        height={110}
                        className={styles.image}
                      />
                      <div className="ml-5">{item.name}</div>
                    </Col>
                  </Row>
                </ListGroupItem>
              ))}
            </ListGroup>
          ) : (
            <p>{i18next.t('hardwareTemp:noEquipment')}</p>
          )}
        </Col>
      </Row>
    </>
  );
};

export default OilMonitoringListView;
