const BASE_URL = process.env.REACT_APP_BASE_API_URL ?? '';

const FREQUENCY_JSON = {
  dayAll: false,
  dayMonday: false,
  dayTuesday: false,
  dayWednesday: false,
  dayThursday: false,
  dayFriday: false,
  daySaturday: false,
  daySunday: false,
  slotMorning: false,
  slotMidday: false,
  slotAfternoon: false,
  slotEvening: false,
  slotNoMatter: false,
  amount: null,
  monthly: false,
  quarterly: false,
  semiAnnual: false,
  annual: false,
  startDate: null, // Set default date tomorrow,
  nextDate_do_not_use: null,
};

const DAYS_FREQUENCY = {
  dayAll: false,
  dayMonday: false,
  dayTuesday: false,
  dayWednesday: false,
  dayThursday: false,
  dayFriday: false,
  daySaturday: false,
  daySunday: false,
};

const SLOTS_FREQUENCY = {
  slotMorning: false,
  slotMidday: false,
  slotAfternoon: false,
  slotEvening: false,
  slotNoMatter: false,
};

const DISHES_FAMILY_QUANTITIES = {
  entree: 0,
  dish: 0,
  cheese: 0,
  garnish: 0,
  dessert: 0,
  other: 0,
};

const DEFAULT_PAGE = 0;
const DEFAULT_LIMIT = 20;

const TEMPERATURE_MAX = 63;
const TEMPERATURE_MIN = 10;

export {
  BASE_URL,
  FREQUENCY_JSON,
  DAYS_FREQUENCY,
  SLOTS_FREQUENCY,
  DISHES_FAMILY_QUANTITIES,
  DEFAULT_LIMIT,
  DEFAULT_PAGE,
  TEMPERATURE_MAX,
  TEMPERATURE_MIN,
};
