import React from 'react';
import i18next from 'i18next';
import { Card, OverlayTrigger, Popover, ListGroup, Image } from 'react-bootstrap';
import { history } from '@router/routes';
import moreOptionsIcon from '@assets/images/svg/more-options.svg';
import { DishFamilyDto } from '@models/dto/response/DishFamily.dto';
import styles from './ProductTempSettingsDishesItem.module.scss';

type Props = {
  dish: DishFamilyDto;
  index: number;
};

const ProductTempSettingsDishesItem = (props: Props) => {
  const { dish, index } = props;

  const editZone = () => {
    history.push('/product-temperature/settings/dishes/types', { id: dish.id, name: dish.name });
  };
  return (
    <Card className={`h-100 mr-3 ${styles.cardContainer} ${styles[`color${index + 1}`]}`}>
      <Card.Body className="d-flex justify-content-center align-items-center text-wrap text-center">
        <OverlayTrigger
          placement="bottom"
          trigger="click"
          rootClose
          overlay={
            <Popover id={`zone-popover-${dish.id}`}>
              <Popover.Content>
                <ListGroup>
                  <ListGroup.Item className="border-0" onClick={editZone}>
                    {i18next.t('shared:buttons.edit')}
                  </ListGroup.Item>
                  <ListGroup.Item className="border-0">
                    {i18next.t('shared:buttons.delete')}
                  </ListGroup.Item>
                </ListGroup>
              </Popover.Content>
            </Popover>
          }
        >
          <div className={`${styles.moreOptions} pl-4 pt-1 pb-4`}>
            <Image src={moreOptionsIcon} width={6} height={27} />
          </div>
        </OverlayTrigger>
        <Card.Text className={styles.cardText}>{dish.name}</Card.Text>
      </Card.Body>
    </Card>
  );
};

export default ProductTempSettingsDishesItem;
