import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import i18next from 'i18next';
import { Form, Modal } from 'react-bootstrap';
import { DeepFryerDto } from '@models/dto/response/DeepFryer.dto';
import BaseEditPhotoPicker from '@components/shared/BaseEditPhotoPicker/BaseEditPhotoPicker';
import BaseModalHeader from '@components/shared/BaseModalHeader/BaseModalHeader';
import BaseModalFooter from '@components/shared/BaseModalFooter/BaseModalFooter';
import styles from './OilMonitoringSettingsEditModal.module.scss';

type Props = {
  showModal: boolean;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  updateDeepFryer: (deepFryer: DeepFryerDto) => void;
  deepFryerToUpdate: DeepFryerDto;
  setDeepFryerToUpdate: Dispatch<SetStateAction<DeepFryerDto | undefined>>;
};

const OilMonitoringSettingsEditModal = (props: Props) => {
  const { showModal, setShowModal, updateDeepFryer, deepFryerToUpdate, setDeepFryerToUpdate } =
    props;
  const [name, setName] = useState<string>(deepFryerToUpdate.name);
  const [photos, setPhotos] = useState<any>([]);
  const [picture, setPicture] = useState<string | undefined>(deepFryerToUpdate.picture);

  const resetForm = () => {
    setName('');
    setPhotos([]);
    setDeepFryerToUpdate(undefined);
  };

  const handleValidate = () => {
    if (name && (photos.length > 0 || picture)) {
      const currentDeepFryer: DeepFryerDto = {
        ...deepFryerToUpdate,
        name,
        // Replace photo or keep current picture
        picture: photos.length > 0 ? photos[0] : null,
      };
      updateDeepFryer(currentDeepFryer);
      setShowModal(false);
    }
  };

  useEffect(() => {
    if (!showModal) {
      resetForm();
    }
  }, [showModal]);
  return (
    <Modal
      show={showModal}
      onHide={() => setShowModal(false)}
      size="lg"
      aria-labelledby="oil-monitoring-edit-modal"
      centered
    >
      <BaseModalHeader
        id="oil-monitoring-edit-modal"
        title={i18next.t('oilMonitoring:settings.modal.editTitle')}
      />
      <Modal.Body>
        {/* Name */}
        <Form.Group className="d-flex flex-row align-items-center mt-5" controlId="name">
          <Form.Label className={styles.inputLabel}>
            {i18next.t('oilMonitoring:settings.modal.form.name')}
          </Form.Label>
          <Form.Control
            type="text"
            autoComplete="off"
            className="baseInputLgWithFont ml-3"
            value={name ?? ''}
            onChange={(e) => setName(e.target.value)}
          />
        </Form.Group>
        {/* Input photo */}
        <div className="mt-5">
          <BaseEditPhotoPicker
            photos={photos}
            setPhotos={setPhotos}
            title={i18next.t('shared:photoPicker.titleProduct')}
            replacePicture={() => setPicture(undefined)}
            picture={picture}
          />
        </div>
      </Modal.Body>
      <BaseModalFooter handleValidate={handleValidate} setShowModal={setShowModal} />
    </Modal>
  );
};

export default OilMonitoringSettingsEditModal;
