import { IFrequencyDto } from '@models/dto/response/Frequency.dto';
import Axios from './index';

export default {
  getFrequencies: (establishmentId: number) => {
    return Axios.get(`/establishment/${establishmentId}/frequencies`);
  },

  updateFrequency: (establishmentId: number, frequency: IFrequencyDto) => {
    return Axios.put(`/establishment/${establishmentId}/frequencies`, frequency);
  },
};
