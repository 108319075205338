import React, { Dispatch, SetStateAction } from 'react';
import i18next from 'i18next';
import { ListGroup, Modal } from 'react-bootstrap';
import BaseModalHeader from '@components/shared/BaseModalHeader/BaseModalHeader';
import BaseModalFooter from '@components/shared/BaseModalFooter/BaseModalFooter';
import { NonComplianceDto } from '@models/dto/response/NonCompliance.dto';
import styles from './NonComplianceRedirectModal.module.scss';

type Props = {
  showModal: boolean;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  handleValidate: () => void;
  handleCancel?: () => void;
  nonCompliances?: NonComplianceDto[];
};

const NonComplianceRedirectModal = (props: Props) => {
  const { showModal, setShowModal, handleValidate, handleCancel, nonCompliances } = props;

  return (
    <Modal show={showModal} aria-labelledby="non-compliance-redirect-modal" centered>
      <BaseModalHeader
        id="non-compliance-redirect-modal"
        title={i18next.t('shared:nonComplianceModal.title')}
        closeButton={false}
      />
      {/* In case of multiple non compliance */}
      {nonCompliances && nonCompliances.length > 0 && nonCompliances.length > 1 ? (
        <Modal.Body>
          <ListGroup>
            {nonCompliances.map((nonCompliance, index: number) => (
              <ListGroup.Item key={index} style={{ cursor: 'pointer' }}>
                {/* Type */}
                <span className={styles.operation}>
                  {i18next.t(`operationsHandling:table.types.${nonCompliance.operation.type}`)} :
                </span>
                <br />
                {/* Nature */}
                <span className={styles.nature}>{nonCompliance.nature}</span>
                {/* Subject */}
                <p className={`${styles.subject} mt-2`}>
                  <span className="font-weight-bold">
                    {i18next.t('shared:nonComplianceModal.subject')}
                  </span>{' '}
                  {nonCompliance.subject}
                </p>
              </ListGroup.Item>
            ))}
          </ListGroup>
        </Modal.Body>
      ) : (
        // If only one non compliance
        <Modal.Body className={styles.message}>
          {i18next.t('shared:nonComplianceModal.message')}
        </Modal.Body>
      )}
      <BaseModalFooter
        handleValidate={handleValidate}
        setShowModal={setShowModal}
        handleCancel={handleCancel}
        cancelText={i18next.t('shared:buttons.later')}
        validateText={i18next.t('shared:buttons.now')}
      />
    </Modal>
  );
};

export default NonComplianceRedirectModal;
