import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import i18next from 'i18next';
import { Col, Form, Modal, Row, Image } from 'react-bootstrap';
import BasePhotoPicker from '@components/shared/BasePhotoPicker/BasePhotoPicker';
import { ISignatory } from '@models/dto/request/SignatoriesParams.dto';
import { SignatoryDto } from '@models/dto/response/Signatory.dto';

import BaseModalHeader from '@components/shared/BaseModalHeader/BaseModalHeader';
import BaseModalFooter from '@components/shared/BaseModalFooter/BaseModalFooter';
import placeholderImage from '@assets/images/svg/placeholder.svg';
import { toast } from 'react-toastify';
import styles from './SettingsSignatoriesAddModal.module.scss';

type Props = {
  selectedSignatory?: SignatoryDto;
  showModal: boolean;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  addNewSignatory: (item: ISignatory) => void;
  editNewSignatory: (item: ISignatory, signatoryId: number) => void;
};

const SettingsSignatoriesAddModal = (props: Props) => {
  const { selectedSignatory, showModal, setShowModal, addNewSignatory, editNewSignatory } = props;
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [photos, setPhotos] = useState<any>([]);
  const [firstName, setFirstName] = useState<string>(selectedSignatory?.firstName ?? '');
  const [lastName, setLastName] = useState<string>(selectedSignatory?.lastName ?? '');

  useEffect(() => {
    setIsEditMode(!!selectedSignatory);
    setFirstName(selectedSignatory?.firstName ?? '');
    setLastName(selectedSignatory?.lastName ?? '');
    if (!selectedSignatory || selectedSignatory?.image === null) {
      setPhotos([]);
    }
  }, [selectedSignatory]);

  const handleValidate = () => {
    // Form check if completed
    if (!firstName) {
      toast.warn(i18next.t('settings:signatories.toasts.warn.firstname'));
      return;
    }
    if (!lastName) {
      toast.warn(i18next.t('settings:signatories.toasts.warn.lastname'));
      return;
    }
    if (isEditMode && selectedSignatory?.id) {
      editNewSignatory({ lastName, firstName, photo: photos[0] }, selectedSignatory.id);
    } else {
      addNewSignatory({ lastName, firstName, photo: photos[0] });
      setIsEditMode(false);
      setFirstName('');
      setLastName('');
      setPhotos([]);
    }
  };

  return (
    <Modal
      show={showModal}
      onHide={() => setShowModal(false)}
      size="lg"
      aria-labelledby="settings-signatories-add-modal"
      centered
    >
      <BaseModalHeader
        id="settings-signatories-add-modal"
        title={
          isEditMode
            ? i18next.t('settings:signatories.modals.editTitle')
            : i18next.t('settings:signatories.modals.addTitle')
        }
      />
      <Modal.Body>
        <Form className="d-flex flex-md-row flex-column align-items-md-center mb-5 px-3">
          {/* Firstname */}
          <Form.Group
            className="d-flex flex-md-row flex-column align-items-md-center mt-md-5 mt-3"
            controlId="firstName"
          >
            <Form.Label className={styles.inputLabel}>
              {i18next.t('settings:signatories.modals.form.firstName')}
            </Form.Label>
            <Form.Control
              type="text"
              autoComplete="off"
              className="baseInputLg ml-0 ml-md-3"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
          </Form.Group>
          {/* lastName */}
          <Form.Group
            className="d-flex flex-md-row flex-column align-items-md-center mt-3 mt-md-5 ml-0 ml-md-4"
            controlId="lastName"
          >
            <Form.Label className={styles.inputLabel}>
              {i18next.t('settings:signatories.modals.form.lastName')}
            </Form.Label>
            <Form.Control
              type="text"
              autoComplete="off"
              className="baseInputLg ml-0 ml-md-3"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
          </Form.Group>
        </Form>
        <Row className="px-3">
          <Col md={12}>
            <BasePhotoPicker
              photos={photos}
              setPhotos={setPhotos}
              title={i18next.t('shared:photoPicker.titleSignatory')}
            />
            {isEditMode && selectedSignatory?.image && !photos.length && (
              <>
                <Image
                  src={selectedSignatory?.image ?? placeholderImage}
                  width={188}
                  height={206}
                  fluid
                />
              </>
            )}
          </Col>
        </Row>
      </Modal.Body>
      <BaseModalFooter handleValidate={handleValidate} setShowModal={setShowModal} />
    </Modal>
  );
};

export default SettingsSignatoriesAddModal;
