import React from 'react';
import i18next from 'i18next';
import { Row, Col, Card, Image } from 'react-bootstrap';
import { format } from 'date-fns';
import fr from 'date-fns/locale/fr';
import OperationTempReadingTypeEnum from '@models/enums/OperationTempReadingType';
// SVG
import tempDownIcon from '@assets/images/svg/temp-down.svg';
import tempUpIcon from '@assets/images/svg/temp-up.svg';
import {
  UnfinishedProductTempReadingCoolingDto,
  UnfinishedProductTempReadingReheatingDto,
} from '@models/dto/response/UnfinishedProductTempReading.dto';
import styles from './ProductTempUnfinishedSolveInfo.module.scss';

type Props = {
  informations: UnfinishedProductTempReadingCoolingDto | UnfinishedProductTempReadingReheatingDto;
};

const ProductTempUnfinishedSolveInfo = (props: Props) => {
  const { informations } = props;
  const getCardBody = () => {
    switch (informations.subType) {
      case OperationTempReadingTypeEnum.cooling:
        return (
          <>
            <Card.Title>
              <Image src={tempDownIcon} height={60} fluid />
            </Card.Title>
            <Card.Text className={styles.cardText}>
              {i18next.t(`productTemp:unfinished.types.${informations.subType}`)}
            </Card.Text>
          </>
        );
      case OperationTempReadingTypeEnum.reheating:
        return (
          <>
            <Card.Title>
              <Image src={tempUpIcon} height={60} fluid />
            </Card.Title>
            <Card.Text className={styles.cardText}>
              {i18next.t(`productTemp:unfinished.types.${informations.subType}`)}
            </Card.Text>
          </>
        );
      default:
        return <></>;
    }
  };

  return (
    <Row className={`${styles.container} p-5`}>
      <Col md={3}>
        <Card className={`h-100 ${styles.cardText} ${styles[`${informations.subType}_Card`]}`}>
          <Card.Body className="d-flex justify-content-center align-items-center flex-column px-0">
            {getCardBody()}
          </Card.Body>
        </Card>
      </Col>
      <Col md={9}>
        <p className={styles.family}>{informations.dishFamily.name}</p>
        <p className={styles.subFamily}>{informations.dishSubFamily.name}</p>
        <p className={styles.dish}>{informations.dishName}</p>
        <p className={styles.temperatureLabel}>
          {i18next.t(`productTemp:unfinished.lastTemperature`)}
          <span className={styles.temperatureValue}>
            {' '}
            {(informations as UnfinishedProductTempReadingCoolingDto).startTemperature} °C
          </span>
        </p>
        <p className={styles.temperatureLabel}>
          {'Le '}
          {format(
            new Date((informations as UnfinishedProductTempReadingCoolingDto).userRealisationDate),
            'Pp',
            {
              locale: fr,
            },
          )}
        </p>
      </Col>
    </Row>
  );
};

export default ProductTempUnfinishedSolveInfo;
