import React from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import styles from './BaseSaveButton.module.scss';

type Props = {
  onSave: () => void;
  text: string;
  disabled?: boolean;
  hoverText?: string;
};

const BaseSaveButton = (props: Props) => {
  return (
    <Row className={styles.container}>
      <Col md={12} className="text-center">
        <Button
          onClick={props.onSave}
          className={`${styles.buttonSave}`}
          disabled={props.disabled}
          title={props.hoverText}
        >
          {props.text}
        </Button>
      </Col>
    </Row>
  );
};

BaseSaveButton.defaultProps = {
  disabled: false,
};

export default BaseSaveButton;
