import React from 'react';
import { Card, Image } from 'react-bootstrap';
import roundedCheckIcon from '@assets/images/svg/check-rond.svg';
import { useAppSelector } from '@store/hooks';
import styles from './CleaningZoneListItem.module.scss';

type Props = {
  id: number;
  text: string;
  color: string;
};

const CleaningZoneListItem = (props: Props) => {
  const { id, text, color } = props;
  const selectedZone = useAppSelector((state) => state.area.selectedArea);
  return (
    <Card
      style={{ backgroundColor: color }}
      className={`${styles.cardContainer} ${
        !(selectedZone?.id === id) && selectedZone ? styles.fade : ''
      } h-100 mr-3 my-5`}
    >
      <Card.Body className="d-flex justify-content-center align-items-center text-wrap text-center border-top">
        {selectedZone?.id === id && (
          <Image src={roundedCheckIcon} className={styles.roundedCheck} width={48} height={48} />
        )}
        <Card.Text className={styles.cardText}>{text}</Card.Text>
      </Card.Body>
    </Card>
  );
};

export default CleaningZoneListItem;
