import React, { useState, useEffect } from 'react';
import BaseNavbarWithHistory from '@components/shared/BaseNavbarWithHistory/BaseNavbarWithHistory';
import SettingsFrequenciesSlots from '@components/SettingsFrequenciesSlots/SettingsFrequenciesSlots';
import SettingsFrequenciesSlotsModal from '@components/SettingsFrequenciesSlotsModal/SettingsFrequenciesSlotsModal';
import i18next from 'i18next';
import { Container, Row, Col } from 'react-bootstrap';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { getFrequenciesList, updateFrequencyDetail } from '@store/reducers/frequencies';
import { RootState } from '@store/index';
import { FrequencyDto } from '@models/dto/response/Frequency.dto';
import { isEqual } from 'lodash';
import { set, isBefore, isAfter, sub, add } from 'date-fns';
import { toast } from 'react-toastify';

import BaseNavbar from '@components/shared/BaseNavbar/BaseNavbar';
import styles from './SettingsFrequencies.module.scss';

const SettingsFrequencies = () => {
  const dispatch = useAppDispatch();
  const [selectedSlot, setSelectedSlot] = useState<FrequencyDto>();
  const [showModal, setShowModal] = useState<boolean>(false);
  const listFrequencies = useAppSelector((state: RootState) => state.frequencies.listFrequencies);
  const establishmentId = useAppSelector((state) => state.account.selectedEstablishment?.id);
  const accountType = useAppSelector((state) => state.account.type);

  useEffect(() => {
    if (establishmentId) {
      dispatch(getFrequenciesList(establishmentId)).unwrap();
    }
  }, []);

  const handleDimissModal = () => {
    setSelectedSlot(undefined);
    setShowModal(false);
  };

  const handleClickEdit = (slot: FrequencyDto) => {
    setSelectedSlot(slot);
    setShowModal(true);
  };

  const updateFrequency = (frequency: FrequencyDto) => {
    if (establishmentId) {
      toast.promise(
        dispatch(
          updateFrequencyDetail({
            establishmentId,
            frequency: { ...frequency, active: frequency.active ? 1 : 0 },
          }),
        ),
        {
          pending: i18next.t('settings:frequencies.toasts.pending.update'),
          success: i18next.t('settings:frequencies.toasts.success.update'),
          error: i18next.t('settings:frequencies.toasts.error.update'),
        },
      );
    }
  };

  const handleSubmitForm = (frequency: FrequencyDto) => {
    setShowModal(false);

    // if value has been changed
    if (!isEqual(frequency, selectedSlot)) {
      const currentFrequencyStart = set(new Date(), {
        hours: frequency.startHour,
        minutes: frequency.startMinutes,
      });
      const currentFrequencyEnd = set(new Date(), {
        hours: frequency.endHour,
        minutes: frequency.endMinutes,
      });

      // Time is changed
      if (
        frequency.startHour !== selectedSlot?.startHour ||
        frequency.startMinutes !== selectedSlot?.startMinutes ||
        frequency.endHour !== selectedSlot?.endHour ||
        frequency.endMinutes !== selectedSlot?.endMinutes
      ) {
        let isSlotBefore = true;
        // compare current slot edit with array of (alls) slots (frequencies)
        listFrequencies.forEach((element: FrequencyDto) => {
          // check if is the same slot
          if (isEqual(element, selectedSlot)) {
            isSlotBefore = false;
            return;
          }
          let newSlot;
          const dateStart = set(new Date(), {
            hours: element.startHour,
            minutes: element.startMinutes,
          });
          const dateEnd = set(new Date(), {
            hours: element.endHour,
            minutes: element.endMinutes,
          });

          if (isSlotBefore) {
            // element is before the slot edit
            if (isBefore(dateEnd, currentFrequencyStart)) {
              // OK
            } else {
              const changeSlot = sub(currentFrequencyStart, { minutes: 1 });
              newSlot = {
                ...element,
                endHour: changeSlot.getHours(),
                endMinutes: changeSlot.getMinutes(),
              } as FrequencyDto;
              // NO OK : CHANGE HOUR/MINUTES OF END
            }
          } else if (isAfter(dateStart, currentFrequencyEnd)) {
            // element is before the slot edit
            // OK
          } else {
            const changeSlot = add(currentFrequencyEnd, { minutes: 1 });
            newSlot = {
              ...element,
              startHour: changeSlot.getHours(),
              startMinutes: changeSlot.getMinutes(),
            } as FrequencyDto;
            // NO OK : CHANGE HOUR/MINUTES OF START
          }

          if (newSlot) {
            updateFrequency(newSlot);
          }
        });
      }
      updateFrequency(frequency);
    }
    setSelectedSlot(undefined);
  };

  return (
    <>
      <BaseNavbar accountType={accountType} />
      <BaseNavbarWithHistory
        title={i18next.t('settings:frequencies.navbar.title')}
        showSettings={false}
        showHistory={false}
      />
      <Container fluid className="h-100 pt-md-5 pt-3 cornerRound px-md-5 px-4">
        {/* Text */}
        <Row className="mb-5">
          <Col md={12}>
            <p className={styles.informationText}>
              {i18next.t('settings:frequencies.informationText')}
            </p>
            <p className={styles.informationSubText}>
              {i18next.t('settings:frequencies.informationSubText')}
            </p>
          </Col>
        </Row>
        <div className={styles.weekdayLabel}>{i18next.t('settings:frequencies.slots')}</div>
        {/* Time slots */}
        {listFrequencies?.length > 0 && (
          <Row className="mt-4">
            {listFrequencies.map((slot: FrequencyDto, index: number) => (
              <Col key={index} sm={6} md={3} className="mb-4">
                <SettingsFrequenciesSlots slot={slot} handleClickEdit={handleClickEdit} />
              </Col>
            ))}
          </Row>
        )}
        {selectedSlot ? (
          <SettingsFrequenciesSlotsModal
            selectedSlot={selectedSlot}
            showModal={showModal}
            dimissModal={handleDimissModal}
            onValidForm={handleSubmitForm}
          />
        ) : undefined}
      </Container>
    </>
  );
};

export default SettingsFrequencies;
