import React from 'react';
import { Card, Image } from 'react-bootstrap';
import { history } from '@router/routes';
import dishesIcon from '@assets/images/svg/famille-de-plats.svg';
import shippingIcon from '@assets/images/svg/temp-livraison.svg';
import serviceIcon from '@assets/images/svg/temp-service.svg';
import styles from './ProductTempSettingsItem.module.scss';

type Props = {
  icon: string;
  name: string;
  type: string;
  colorClass: string;
};

const ProductTempSettingsItem = (props: Props) => {
  const { icon, name, type, colorClass } = props;

  const getIcon = () => {
    switch (icon) {
      case 'famille-de-plats':
        return dishesIcon;
      case 'temp-livraison':
        return shippingIcon;
      case 'temp-service':
        return serviceIcon;
      default:
        return '';
    }
  };

  const handleClick = () => {
    history.push(`/product-temperature/settings/${type}`);
  };
  return (
    <Card className={`${styles.cardContainer} ${styles[colorClass]}`} onClick={() => handleClick()}>
      <Card.Body className="d-flex flex-column align-items-start justify-content-start p-5">
        <Card.Title className="text-left">
          <Image src={getIcon()} height={60} fluid />
        </Card.Title>
        <Card.Text className={styles.cardText}>{name}</Card.Text>
      </Card.Body>
    </Card>
  );
};

export default ProductTempSettingsItem;
