import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import i18next from 'i18next';
import { Form } from 'react-bootstrap';
import { FrequencyJsonDto } from '@models/dto/FrequencyJson.dto';
import { FREQUENCY_JSON, SLOTS_FREQUENCY } from '@utils/constants';
import { FormControlLabel, Checkbox } from '@material-ui/core';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { applyFrequenciesRestrictions } from '@utils/frequenciesUtils';
import { getFrequenciesList } from '@store/reducers/frequencies';
import styles from './BaseDaySlotsCheckboxes.module.scss';

export interface ISlotsArray {
  slot: string;
  checked: boolean;
}

type Props = {
  setFrequencyJson: Dispatch<SetStateAction<FrequencyJsonDto | undefined>>;
  frequencyJson?: FrequencyJsonDto;
  label?: string;
};

const BaseDaySlotsCheckboxes = (props: Props) => {
  const { setFrequencyJson, frequencyJson, label } = props;

  const [selectedScheduleSlots, setSelectedScheduleSlots] = useState<ISlotsArray[]>([]);

  const establishmentId = useAppSelector((state) => state.account.selectedEstablishment?.id);
  const frequencies = useAppSelector((state) => state.frequencies.listFrequencies);
  const dispatch = useAppDispatch();

  // Create array for day slot to display checkbox
  const createScheduleSlotsArray = () => {
    // Get default value
    let updatedSlotsFrequency = { ...SLOTS_FREQUENCY };
    if (frequencyJson) {
      // If frequency json already filled we get existing values
      updatedSlotsFrequency = {
        slotMorning: frequencyJson.slotMorning,
        slotMidday: frequencyJson.slotMidday,
        slotAfternoon: frequencyJson.slotAfternoon,
        slotEvening: frequencyJson.slotEvening,
        slotNoMatter: frequencyJson.slotNoMatter,
      };
    }

    const result = Object.entries(updatedSlotsFrequency)
      .filter((el) => applyFrequenciesRestrictions(el[0], frequencies) === true)
      .map((item) => {
        return {
          slot: item[0],
          checked: item[1],
        };
      });
    return result;
  };

  // Initialize checkbox
  useEffect(() => {
    if ((!frequencies || frequencies.length === 0) && establishmentId) {
      dispatch(getFrequenciesList(establishmentId));
    }
    const scheduleSlotsArray = createScheduleSlotsArray();
    setSelectedScheduleSlots(scheduleSlotsArray);
  }, [frequencyJson]);

  // Update Checkbox layout if restrictions change
  useEffect(() => {
    const scheduleSlotsArray = createScheduleSlotsArray();
    setSelectedScheduleSlots(scheduleSlotsArray);
  }, [frequencies]);

  // Allow to change checkbox state to checked or unchecked and update frequencyJson in parent
  const handleCheckBoxSlots = (index: number, checked: boolean, slot: string) => {
    let frequency = frequencyJson ? { ...frequencyJson } : { ...FREQUENCY_JSON };
    // To uncheck all other checkboxes if slot no matter
    if (slot === 'slotNoMatter' && checked) {
      // handle check no matter
      frequency = { ...frequency, [slot]: checked };
      // handle check other checkboxes
      selectedScheduleSlots.forEach((item: ISlotsArray, idx: number) => {
        if (item.slot !== 'slotNoMatter') {
          frequency = { ...frequency, [selectedScheduleSlots[idx].slot]: false };
        }
      });
      // Uncheck slot no matter if other checkbox is checked
    } else if (slot !== 'slotNoMatter' && frequency.slotNoMatter && checked) {
      frequency = { ...frequency, slotNoMatter: false, [slot]: checked };
    } else {
      frequency = { ...frequency, [slot]: checked };
    }

    setFrequencyJson(frequency);
  };
  return (
    <Form.Group className="mt-5 d-flex flex-column" controlId="scheduleSlots">
      <Form.Label className={styles.inputLabel}>
        {label || i18next.t('productTemp:settings.shipping.modal.form.scheduleSlots')}
      </Form.Label>
      <div className="mt-4">
        {selectedScheduleSlots &&
          selectedScheduleSlots.length > 0 &&
          selectedScheduleSlots.map((item, index) => (
            <FormControlLabel
              key={index}
              className={`${item.checked ? 'checked' : ''}`}
              control={
                <Checkbox
                  value={item.slot}
                  className={`${item.checked ? 'checked' : ''}`}
                  checked={item.checked ?? false}
                  onChange={(e: any) => handleCheckBoxSlots(index, e.target.checked, item.slot)}
                />
              }
              label={i18next.t(`shared:daySlots.${item.slot}`)}
            />
          ))}
      </div>
    </Form.Group>
  );
};

export default BaseDaySlotsCheckboxes;
