import React, { Dispatch, SetStateAction, useState, useEffect } from 'react';
import i18next from 'i18next';
import { Form, Modal } from 'react-bootstrap';
import BaseOccurenceForm from '@components/shared/BaseOccurenceForm/BaseOccurenceForm';
import { CreateEquipmentDto } from '@models/dto/request/CreateEquipment.dto';
import { FrequencyJsonDto } from '@models/dto/FrequencyJson.dto';
import { FREQUENCY_JSON } from '@utils/constants';
import BasePhotoPicker from '@components/shared/BasePhotoPicker/BasePhotoPicker';
import BaseModalFooter from '@components/shared/BaseModalFooter/BaseModalFooter';
import BaseModalHeader from '@components/shared/BaseModalHeader/BaseModalHeader';
import { FrequencySurfaceEnum } from '@models/dto/request/FrequencySurfaceEnum.dto';
import { toast } from 'react-toastify';
import { checkFormFrequencyValid, formatFrequencyToNumber } from '@utils/frequenciesUtils';
import styles from './HardwareTempSettingsModal.module.scss';

const OCCURENCES = [FrequencySurfaceEnum.NONE, FrequencySurfaceEnum.DAILY];

type Props = {
  showModal: boolean;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  createNewEquipment: (equipment: CreateEquipmentDto) => void;
};

const HardwareTempSettingsModal = (props: Props) => {
  const { showModal, setShowModal, createNewEquipment } = props;

  const [name, setName] = useState<string>();
  const [temperatureMin, setTemperatureMin] = useState<number>();
  const [temperatureMax, setTemperatureMax] = useState<number>();
  const [frequencyJson, setFrequencyJson] = useState<FrequencyJsonDto | undefined>(FREQUENCY_JSON);
  const [photos, setPhotos] = useState<any>([]);
  const [frequencyType, setFrequencyType] = useState<FrequencySurfaceEnum>(
    FrequencySurfaceEnum.NONE,
  );

  const resetForm = () => {
    setName(undefined);
    setTemperatureMin(undefined);
    setTemperatureMax(undefined);
    setFrequencyJson(FREQUENCY_JSON);
    setFrequencyType(FrequencySurfaceEnum.NONE);
    setPhotos([]);
  };

  const handleValidate = () => {
    // Form check if completed
    if (!name) {
      toast.warn(i18next.t('hardwareTemp:toasts.warn.name'));
      return;
    }
    if (!temperatureMin && temperatureMin !== 0) {
      toast.warn(i18next.t('hardwareTemp:toasts.warn.minTemp'));
      return;
    }
    if (!temperatureMax && temperatureMax !== 0) {
      toast.warn(i18next.t('hardwareTemp:toasts.warn.maxTemp'));
      return;
    }
    if (temperatureMin >= temperatureMax) {
      toast.warn(i18next.t('hardwareTemp:toasts.warn.wrongTemperatures'));
      return;
    }

    if (frequencyJson) {
      const isFrequencyFormValid = checkFormFrequencyValid(frequencyJson, 'form', frequencyType);

      if (isFrequencyFormValid) {
        const equipment: CreateEquipmentDto = {
          name,
          minTemperature: temperatureMin,
          maxTemperature: temperatureMax,
          frequencyJson: JSON.stringify(formatFrequencyToNumber(frequencyJson!)),
          picture: photos[0],
        };

        createNewEquipment(equipment);
        resetForm();
        setShowModal(false);
      }
    }
  };

  useEffect(() => {
    if (!showModal) {
      resetForm();
    }
  }, [showModal]);

  return (
    <Modal
      show={showModal}
      size="lg"
      onHide={() => setShowModal(false)}
      aria-labelledby="hardware-settings-temp-modal"
      centered
    >
      <BaseModalHeader
        id="hardware-settings-temp-modal"
        title={i18next.t('hardwareTemp:settings.modal.title')}
      />
      <Modal.Body className="p-4">
        <Form>
          {/* Name */}
          <Form.Group className="d-flex flex-row align-items-center mt-5" controlId="name">
            <Form.Label className={styles.inputLabel}>
              {i18next.t('hardwareTemp:settings.modal.form.name')}
            </Form.Label>
            <Form.Control
              type="text"
              autoComplete="off"
              className="baseInputLgWithFont ml-3"
              value={name || ''}
              onChange={(e) => {
                setName(e.target.value);
              }}
            />
          </Form.Group>
          {/* Input photo */}
          <div className="mt-5">
            <BasePhotoPicker
              photos={photos}
              setPhotos={setPhotos}
              title={i18next.t('shared:photoPicker.titleEquipment')}
              multiPictureAllowed={false}
            />
          </div>
          {/* Temperature max */}
          <Form.Group
            className="d-flex flex-row align-items-center mt-5 text-nowrap"
            controlId="tMax"
          >
            <Form.Label className={`${styles.inputLabel} ${styles.temperatureLabel}`}>
              {i18next.t('hardwareTemp:settings.modal.form.tMax')}
            </Form.Label>
            <Form.Control
              type="number"
              autoComplete="off"
              className={`baseInputLgWithFont ml-3 ${styles.temperatureInput}`}
              value={temperatureMax?.toString() ?? ''}
              onChange={(e) => {
                setTemperatureMax(Number(e.target.value));
              }}
            />
            <span className="ml-2">°C</span>
          </Form.Group>
          {/* Temperature min */}
          <Form.Group
            className="d-flex flex-row align-items-center mt-5 text-nowrap"
            controlId="tMin"
          >
            <Form.Label className={`${styles.inputLabel} ${styles.temperatureLabel}`}>
              {i18next.t('hardwareTemp:settings.modal.form.tMin')}
            </Form.Label>
            <Form.Control
              type="number"
              autoComplete="off"
              className={`baseInputLgWithFont ml-3 ${styles.temperatureInput}`}
              value={temperatureMin?.toString() ?? ''}
              onChange={(e) => {
                setTemperatureMin(Number(e.target.value));
              }}
            />
            <span className="ml-2">°C</span>
          </Form.Group>
          {/* Occurence */}
          <BaseOccurenceForm
            frequencyJson={frequencyJson}
            setFrequencyJson={setFrequencyJson}
            frequencyType={frequencyType}
            setFrequencyType={setFrequencyType}
            occurences={OCCURENCES}
          />
        </Form>
      </Modal.Body>
      <BaseModalFooter handleValidate={handleValidate} setShowModal={setShowModal} />
    </Modal>
  );
};

export default HardwareTempSettingsModal;
