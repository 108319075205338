import { ProductFamilyDto } from '@models/dto/response/ProductFamily.dto';
import { ProductSubFamilyDto } from '@models/dto/response/ProductSubFamily.dto';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import productService from '@services/product.service';
import { AxiosResponse } from 'axios';

// Define a type for the slice state
interface ProductState {
  productFamilies: ProductFamilyDto[];
  productSubFamilies: ProductSubFamilyDto[];
}

// Define the initial state using that type
const initialState: ProductState = {
  productFamilies: [],
  productSubFamilies: [],
};

export const getProductFamilies = createAsyncThunk<AxiosResponse<ProductFamilyDto[]>, void>(
  'product/getAllFamilies',
  async () => {
    const response = await productService.getProductFamilies();
    return response.data;
  },
);

export const getProductFamily = createAsyncThunk<AxiosResponse<ProductFamilyDto>, number>(
  'product/getFamily',
  async (productFamilyId: number) => {
    const response = await productService.getProductFamily(productFamilyId);
    return response.data;
  },
);

export const getProductSubFamilies = createAsyncThunk<AxiosResponse<ProductSubFamilyDto[]>, number>(
  'product/getAllSubFamilies',
  async (productFamilyId: number) => {
    const response = await productService.getProductSubFamilies(productFamilyId);
    return response.data;
  },
);

export const getDishesFamily = createAsyncThunk<AxiosResponse<ProductSubFamilyDto>, number>(
  'product/getSubFamily',
  async (productSubFamilyId: number) => {
    const response = await productService.getProductSubFamily(productSubFamilyId);
    return response.data;
  },
);

export const ProductSlice = createSlice({
  name: 'product',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Side effects
    // Get product family list
    builder.addCase(getProductFamilies.fulfilled, (state, action) => {
      state.productFamilies = action.payload.data ?? [];
    });
    // Get product sub family list
    builder.addCase(getProductSubFamilies.fulfilled, (state, action) => {
      state.productSubFamilies = action.payload.data ?? [];
    });
  },
});

// export const {} = ProductSlice.actions;

export default ProductSlice.reducer;
