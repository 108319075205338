import React, { useState } from 'react';
import { Nav, Navbar, Image, Button, Dropdown } from 'react-bootstrap';
import navbarLogo from '@assets/images/svg/logo-hygiap-white.svg';
import { Link } from 'react-router-dom';
import AccountTypeEnum from '@models/enums/AccountType';
import settingsIcon from '@assets/images/svg/settings.svg';
import accountIcon from '@assets/images/svg/account.svg';
import { history } from '@router/routes';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import LoginEstablishmentModal from '@components/LoginEstablishmentModal/LoginEstablishmentModal';
import { EstablishmentDto } from '@models/dto/response/Account.dto';
import { setSelectedEstablishment } from '@store/reducers/account';
import { toast } from 'react-toastify';
import i18next from 'i18next';
import { getRunningProductTemp } from '@store/reducers/product-temperature';
import { getOperationTypes } from '@store/reducers/operation';
import { getSignatories } from '@store/reducers/signatory';
import { getStats, resetHistory } from '@store/reducers/history';
import styles from './BaseNavbar.module.scss';

type Props = {
  accountType?: AccountTypeEnum;
  parametersUrl?: string;
};

const BaseNavbar = (props: Props) => {
  const { accountType, parametersUrl } = props;
  const [showModal, setShowModal] = useState<boolean>(false);

  const dispatch = useAppDispatch();
  const selector = useAppSelector((state) => state.account);
  const currentEstablishment = useAppSelector((state) => state.account.selectedEstablishment);

  const logout = () => {
    // eslint-disable-next-line no-undef
    localStorage.removeItem('token');
    history.push('/login');
  };

  const closeModal = (selectedEstablishment: EstablishmentDto) => {
    setShowModal(false);
    dispatch(setSelectedEstablishment(selectedEstablishment));
    // Get operations type list
    dispatch(getOperationTypes());
    // Reset history operations
    dispatch(resetHistory());
    // Get signatories list
    dispatch(getSignatories(selectedEstablishment.id));
    // Get stats list
    dispatch(getStats(selectedEstablishment.id));
    // Get still running temp measure
    dispatch(getRunningProductTemp(selectedEstablishment.id));
    toast.success(i18next.t('dashboard:toasts.success.changeEstablishment'));
    history.replace('/dashboard');
  };

  return (
    <>
      <Navbar className="navbarHygiap">
        <Navbar.Brand className={styles.brandLogo}>
          <Link to="/dashboard">
            <Image src={navbarLogo} className={styles.navbarLogo} />
          </Link>
        </Navbar.Brand>
        <Nav className="ml-auto">
          {accountType === AccountTypeEnum.admin && parametersUrl && (
            <Link to={parametersUrl}>
              <Button className={`${styles.navbarButton} btnSm`}>
                <span className="d-none d-lg-inline-block">
                  {i18next.t('shared:navbar.settings')}
                </span>
                <Image src={settingsIcon} width={15} height={15} className="ml-0 ml-lg-2" />
              </Button>
            </Link>
          )}
          <Dropdown>
            <Dropdown.Toggle className={`${styles.navbarButton} btnSm ml-2`}>
              <span className="d-none d-lg-inline-block">{i18next.t('shared:navbar.account')}</span>
              <Image src={accountIcon} width={15} height={15} className="ml-0 ml-lg-2" />
            </Dropdown.Toggle>

            <Dropdown.Menu className={styles.dropdownMenu}>
              <Dropdown.Item as={Link} to="/account">
                {i18next.t('shared:navbar.dropdown.profile')}
              </Dropdown.Item>
              {accountType === AccountTypeEnum.admin && selector.establishments.length > 1 && (
                <Dropdown.Item onClick={() => setShowModal(true)}>
                  {i18next.t('shared:navbar.dropdown.changeEstablishment')}
                </Dropdown.Item>
              )}
              <Dropdown.Item onClick={logout}>{i18next.t('shared:navbar.logout')}</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Nav>
      </Navbar>
      <LoginEstablishmentModal
        showModal={showModal}
        setShowModal={setShowModal}
        onSelectEstablishment={closeModal}
        establishments={selector.establishments}
        currentEstablishment={currentEstablishment}
      />
    </>
  );
};

export default BaseNavbar;
