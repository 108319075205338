import BaseNavbarWithHistory from '@components/shared/BaseNavbarWithHistory/BaseNavbarWithHistory';
import i18next from 'i18next';
import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Button, Image } from 'react-bootstrap';
import { getFolders, createFolders, deleteFolder } from '@store/reducers/documents';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import FolderSettingsItem from '@components/FolderSettingsItem/FolderSettingsItem';
import FolderSettingsModal from '@components/FolderSettingsModal/FolderSettingsModal';
import { FolderDto } from '@models/dto/response/Folder.dto';
import { toast } from 'react-toastify';
import plusWhite from '@assets/images/svg/plus-rond.svg';
import BaseNavbar from '@components/shared/BaseNavbar/BaseNavbar';
import styles from './DocumentsSettings.module.scss';

const DocumentsSettings = () => {
  const establishmentId = useAppSelector((state) => state.account.selectedEstablishment?.id);
  const accountType = useAppSelector((state) => state.account.type);
  const folders = useAppSelector((state) => state.document.folders);
  const [showModal, setShowModal] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (establishmentId && (!folders || folders.length === 0)) {
      dispatch(getFolders(establishmentId)).unwrap();
    }
  }, []);

  const addFolderModal = () => {
    setShowModal(true);
  };

  const createFolder = (name: string) => {
    if (establishmentId) {
      toast.promise(dispatch(createFolders({ establishmentId, name })), {
        pending: i18next.t('documents:settings.folder.toasts.pending.createFolder'),
        success: i18next.t('documents:settings.folder.toasts.success.createFolder'),
        error: i18next.t('documents:settings.folder.toasts.error.createFolder'),
      });
    }
  };

  const removeFolder = (folderId: number) => {
    toast.promise(dispatch(deleteFolder(folderId)).unwrap(), {
      pending: i18next.t('documents:settings.folder.toasts.pending.deleteFolder'),
      success: i18next.t('documents:settings.folder.toasts.success.deleteFolder'),
      error: i18next.t('documents:settings.folder.toasts.error.deleteFolder'),
    });
  };

  return (
    <>
      <BaseNavbar accountType={accountType} parametersUrl="/settings" />
      <BaseNavbarWithHistory
        title={i18next.t('documents:settings.navbar.title')}
        showSettings={false}
        showHistory={false}
      />
      <Container fluid className="h-100 pt-5 cornerRound px-5">
        <Row>
          <Col md={12} className="d-flex justify-content-between align-items-center">
            <div className={styles.listTitle}>{i18next.t('documents:settings.folder.list')}</div>
            <Button
              className={`${styles.addButton} d-flex justify-content-center align-items-center btnXl`}
              onClick={addFolderModal}
            >
              <Image src={plusWhite} width={24} height={24} className="mr-2" />
              {i18next.t('documents:settings.folder.addFolder')}
            </Button>
          </Col>
        </Row>
        {folders?.length > 0 ? (
          <Row>
            {folders.map((folder: FolderDto) => (
              <Col md={4} key={folder.id} className="mt-4">
                <FolderSettingsItem
                  folder={folder}
                  setShowModalDelete={() => removeFolder(folder.id)}
                  isEditMode
                />
              </Col>
            ))}
          </Row>
        ) : (
          <Row className="d-flex justify-content-center align-items-center h-100">
            <Col md={12} className="text-center tableHeader">
              {i18next.t('documents:settings.folder.empty')}
            </Col>
          </Row>
        )}
        <FolderSettingsModal
          showModal={showModal}
          setShowModal={setShowModal}
          onValidForm={createFolder}
        />
      </Container>
    </>
  );
};

export default DocumentsSettings;
