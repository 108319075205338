import React, { useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import BaseNavbar from '@components/shared/BaseNavbar/BaseNavbar';
import DashboardStatistics from '@components/DashboardStatistics/DashboardStatistics';
import DashboardOperations from '@components/DashboardOperations/DashboardOperations';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { getAccount, refreshAccount } from '@store/reducers/account';
import { getRunningProductTemp } from '@store/reducers/product-temperature';
import { getOperationTypes } from '@store/reducers/operation';
import { history } from '@router/routes';
import { getStats } from '@store/reducers/history';
import { toast } from 'react-toastify';
import i18next from 'i18next';

import styles from './Dashboard.module.scss';

const Dashboard = () => {
  const isActive = useAppSelector((state) => state.account.active);
  const establishment = useAppSelector((state) => state.account.selectedEstablishment);
  const operationTypes = useAppSelector((state) => state.operation.types);
  const accountType = useAppSelector((state) => state.account.type);
  const stats = useAppSelector((state) => state.history.stats);
  const unfinishedProductTempMeasure = useAppSelector(
    (state) => state.productTemperature.runningProductTempMeasure,
  );
  const dispatch = useAppDispatch();

  useEffect(() => {
    // Get establishment data
    if (!accountType && !establishment) {
      dispatch(getAccount());
    } else {
      dispatch(refreshAccount());
    }
    // Get operations type list
    if (!operationTypes || operationTypes.length === 0) {
      dispatch(getOperationTypes());
    }
    if (establishment) {
      // Get stats list
      dispatch(getStats(establishment.id));
      // Get still running temp measure
      dispatch(getRunningProductTemp(establishment.id));
    }
  }, []);

  useEffect(() => {
    if (isActive === false) {
      // eslint-disable-next-line no-undef
      localStorage.removeItem('token');
      history.push('/login');
      toast.warn(i18next.t('shared:accountDisabled'));
    }
  }, [isActive]);

  const isAppReady = () => {
    return !!(operationTypes && establishment?.name && stats);
  };

  if (isAppReady()) {
    return (
      <>
        <BaseNavbar accountType={accountType} parametersUrl="/settings" />
        <Row className={`${styles.statisticsContainer} py-3 py-lg-5`}>
          <Col md={{ span: 10, offset: 1 }}>
            <DashboardStatistics establishmentName={establishment?.name} stats={stats!} />
          </Col>
        </Row>
        <Row className="cornerRound">
          <Col md={{ span: 10, offset: 1 }}>
            <DashboardOperations
              operationsList={operationTypes}
              unfinishedTempReading={unfinishedProductTempMeasure.length}
            />
          </Col>
        </Row>
      </>
    );
  }
  return null;
};

export default Dashboard;
