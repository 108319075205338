import i18next from 'i18next';
import OperationTypeEnum from '@models/enums/OperationType';
import TaskTypeEnum from '@models/enums/TaskType';
import OperationTempReadingTypeEnum from '@models/enums/OperationTempReadingType';
import { OperationCoolingDto } from '@models/dto/response/OperationCooling.dto';
import { OperationServiceDto } from '@models/dto/response/OperationService.dto';
import { OperationDeliveryDto } from '@models/dto/response/OperationDelivery.dto';
import { AllOperationsDto } from '@models/dto/AllOperations.dto';
import { OperationCleaningPlan } from '@models/dto/response/OperationCleaningPlan.dto';
import { OperationEquipmentTemperatureReadingDto } from '@models/dto/response/OperationEquipmentTemperatureReading.dto';
import { OperationInspectionUponReceiptDto } from '@models/dto/response/OperationInspectionUponReceipt.dto';
import { SurfaceDto } from '@models/dto/response/Surface.dto';
import { OperationOilMonitoringDto } from '@models/dto/response/OperationOilMonitoring.dto';
import { OperationLabelPhotoDto } from '@models/dto/response/OperationLabelPhoto.dto';
import NonComplianceTypeEnum from '@models/enums/NonComplianceType';
import { NonComplianceDto } from '@models/dto/response/NonCompliance.dto';

export const getTitleOperation = (
  type: OperationTypeEnum,
  subType?: OperationTempReadingTypeEnum,
): string => {
  switch (type) {
    case OperationTypeEnum.cleaning:
      return i18next.t('shared:operationType.cleaning');
    case OperationTypeEnum.document:
      return i18next.t('shared:operationType.document');
    case OperationTypeEnum.equipment:
      return i18next.t('shared:operationType.equipment');
    case OperationTypeEnum.product:
      if (subType) {
        return i18next.t(`productTemp:unfinished.types.${subType}`);
      }
      return i18next.t('shared:operationType.product');
    case OperationTypeEnum.inspection:
      return i18next.t('shared:operationType.inspection');
    case OperationTypeEnum.photo:
      return i18next.t('shared:operationType.photo');
    case OperationTypeEnum.printing:
      return i18next.t('shared:operationType.printing');
    case OperationTypeEnum.oil:
      return i18next.t('shared:operationType.oil');
    case OperationTypeEnum.analysis:
      return i18next.t('shared:operationType.analysis');
    default:
      return '';
  }
};

export const getTypeNonCompliance = (
  type: NonComplianceTypeEnum,
  other?: OperationTypeEnum,
): string => {
  switch (type) {
    case NonComplianceTypeEnum.cooling:
      return i18next.t('productTemp:unfinished.types.operation_temp_cooling');
    case NonComplianceTypeEnum.reheating:
      return i18next.t('productTemp:unfinished.types.operation_temp_reheating');
    case NonComplianceTypeEnum.service:
      return i18next.t('productTemp:unfinished.types.operation_temp_service');
    case NonComplianceTypeEnum.delivery:
      return i18next.t('productTemp:unfinished.types.operation_temp_delivery');
    default:
      return i18next.t(`operationsHandling:table.types.${other}`);
  }
};

export const getSubjectOperation = (
  type: OperationTypeEnum,
  data: AllOperationsDto,
  nonCompliances?: NonComplianceDto[],
): string | any => {
  switch (type) {
    case OperationTypeEnum.cleaning:
      return (data as OperationCleaningPlan).area.name;
    case OperationTypeEnum.equipment:
      return (data as OperationEquipmentTemperatureReadingDto).equipment.name;
    case OperationTypeEnum.product:
      // eslint-disable-next-line no-case-declarations
      let dish = '';
      if ((data as any).dishFamily && (data as any).dishFamily.name) {
        dish = `${i18next.t('operationsHistory:dishesFamilyName')}${(data as any).dishFamily.name}`;
      }
      if ((data as any).dishSubFamilyDto && (data as any).dishSubFamilyDto.name) {
        dish = `${(data as any).dishSubFamilyDto.name}`;
      }
      if (
        (data as OperationDeliveryDto).dishSubFamily &&
        (data as OperationDeliveryDto).dishSubFamily.name
      ) {
        dish = `${(data as OperationDeliveryDto).dishSubFamily.name}`;
      }
      if (
        (data as OperationDeliveryDto).dishName &&
        (data as OperationDeliveryDto).dishName !== ''
      ) {
        dish = `${(data as OperationDeliveryDto).dishName}`;
      }
      // @ts-ignore
      // eslint-disable-next-line no-case-declarations
      const customer = (data as OperationDeliveryDto).customer
        ? // @ts-ignore
          (data as OperationDeliveryDto).customer.name
        : '';
      if (customer) {
        return `${customer}||${dish}`;
      }
      return `${dish}`;
    case OperationTypeEnum.inspection:
      // eslint-disable-next-line no-case-declarations
      const supplier = (data as OperationInspectionUponReceiptDto).supplier.name ?? '';
      // eslint-disable-next-line no-case-declarations
      const family = (data as OperationInspectionUponReceiptDto).productFamily.name ?? '';
      // eslint-disable-next-line no-case-declarations
      const subFamily = (data as OperationInspectionUponReceiptDto).productSubFamily.name ?? '';
      return `${supplier}||${family}||${subFamily}`;
    case OperationTypeEnum.oil:
      return (data as OperationOilMonitoringDto).deepFryer.name ?? '';
    case OperationTypeEnum.photo:
    case OperationTypeEnum.printing:
    case OperationTypeEnum.document:
      return '';
    case OperationTypeEnum.analysis:
      return nonCompliances && nonCompliances.length ? nonCompliances[0].subject : '';
    default:
      return '';
  }
};

export const getInformationOperation = (
  type: OperationTypeEnum,
  data: AllOperationsDto,
  subType?: OperationTempReadingTypeEnum,
  nonCompliance?: NonComplianceDto[],
): any => {
  switch (type) {
    case OperationTypeEnum.cleaning:
      return (data as OperationCleaningPlan).surfaces
        .map((surface: SurfaceDto) => surface.name)
        .join(', ');
    case OperationTypeEnum.equipment:
      if (
        (data as OperationEquipmentTemperatureReadingDto).temperature ||
        (data as OperationEquipmentTemperatureReadingDto).temperature === 0
      ) {
        return `${i18next.t('shared:reading')} ${
          (data as OperationEquipmentTemperatureReadingDto).temperature
        }°C`;
      }
      return '';
    case OperationTypeEnum.product:
      switch (subType) {
        case OperationTempReadingTypeEnum.cooling:
        case OperationTempReadingTypeEnum.reheating: {
          const firstReading = `${i18next.t('shared:firstReading')} ${
            (data as OperationCoolingDto).startTemperature
          } °C`;
          const secondReading = (data as OperationCoolingDto).endTemperature
            ? `${i18next.t('shared:secondReading')} ${
                (data as OperationCoolingDto).endTemperature
              } °C`
            : '';
          const duration = (data as OperationCoolingDto).duration
            ? `${new Date((data as OperationCoolingDto).duration * 1000)
                .toISOString()
                .substr(11, 5)
                .replace(':', 'h ')}m`
            : '';
          return `${firstReading}||${secondReading}||${duration}`;
        }
        case OperationTempReadingTypeEnum.service:
          return `${i18next.t('shared:reading')} ${(data as OperationServiceDto).temperature} °C`;
        case OperationTempReadingTypeEnum.delivery: {
          const firstReading = (data as OperationDeliveryDto).productionSiteExitTemperature
            ? `${i18next.t('shared:deliveryReading')} ${
                (data as OperationDeliveryDto).productionSiteExitTemperature
              } °C`
            : '';
          const secondReading = (data as OperationDeliveryDto).deliveryTemperature
            ? `${i18next.t('shared:exitReading')} ${
                (data as OperationDeliveryDto).deliveryTemperature
              } °C`
            : '';
          return `${firstReading}||${secondReading}`;
        }
        default:
          return '';
      }
    case OperationTypeEnum.inspection: {
      const temperature = (data as OperationInspectionUponReceiptDto).temperature
        ? `${i18next.t('shared:reading')} ${
            (data as OperationInspectionUponReceiptDto).temperature
          } °C`
        : '';
      let isDeliveryCompliant = true;
      if (
        (data as OperationInspectionUponReceiptDto).nonCompliances.length ||
        (data as OperationInspectionUponReceiptDto).nonCompliancesTreated !== 0
      ) {
        isDeliveryCompliant = false;
      }
      const delivery = !isDeliveryCompliant
        ? i18next.t('shared:deliveryNotCompliant')
        : i18next.t('shared:deliveryCompliant');
      return `${temperature}||${delivery}`;
    }
    case OperationTypeEnum.oil:
      if ((data as OperationOilMonitoringDto).actionType) {
        return i18next.t(
          `oilMonitoring:form.actions.${(data as OperationOilMonitoringDto).actionType}`,
        );
      }
      return '';
    case OperationTypeEnum.photo:
      if ((data as OperationLabelPhotoDto).pictures.length > 1) {
        return `${(data as OperationLabelPhotoDto).pictures.length} photos`;
      }
      return `${(data as OperationLabelPhotoDto).pictures.length} photo`;
    case OperationTypeEnum.printing:
    case OperationTypeEnum.document:
      return '';
    case OperationTypeEnum.analysis:
      if (nonCompliance && nonCompliance.length && nonCompliance[0].nature !== '') {
        return 'Non Conforme';
      }
      return 'Conforme';
    default:
      return '';
  }
};

export const getUrlByOperation = (type: OperationTypeEnum): string => {
  switch (type) {
    case OperationTypeEnum.cleaning:
      return '/cleaning';
    case OperationTypeEnum.document:
      return '/documents';
    case OperationTypeEnum.equipment:
      return '/hardware-temperature';
    case OperationTypeEnum.product:
      return '/product-temperature';
    case OperationTypeEnum.inspection:
      return '/reception-control';
    case OperationTypeEnum.photo:
      return '/photos-labels';
    case OperationTypeEnum.printing:
      return '/printing-labels';
    case OperationTypeEnum.oil:
      return '/oil-monitoring';
    default:
      return '/';
  }
};

export const getTitleTask = (type: TaskTypeEnum): string => {
  switch (type) {
    case TaskTypeEnum.equipmentReading:
      return i18next.t('shared:taskType.task_equipment_temp_reading');
    case TaskTypeEnum.productReadingDelivery:
      return i18next.t('shared:taskType.task_product_temp_reading_delivery');
    case TaskTypeEnum.productReadingService:
      return i18next.t('shared:taskType.task_product_temp_reading_service');
    case TaskTypeEnum.cleaning:
      return i18next.t('shared:taskType.task_cleaning_plan');
    default:
      return type;
  }
};

export const getDishType = (type: string): string => {
  switch (type) {
    case 'dish_entree':
      return i18next.t('shared:dishType.entree');
    case 'dish_cheese':
      return i18next.t('shared:dishType.cheese');
    case 'dish_dish':
      return i18next.t('shared:dishType.dish');
    case 'dish_garnish':
      return i18next.t('shared:dishType.garnish');
    case 'dish_dessert':
      return i18next.t('shared:dishType.dessert');
    case 'dish_other':
      return i18next.t('shared:dishType.other');
    default:
      return type;
  }
};

export const getUrlByTask = (type: TaskTypeEnum): string => {
  switch (type) {
    case TaskTypeEnum.equipmentReading:
      return '/hardware-temperature';
    case TaskTypeEnum.productReadingDelivery:
    case TaskTypeEnum.productReadingService:
      return '/product-temperature';
    case TaskTypeEnum.cleaning:
      return '/cleaning';
    default:
      return '/';
  }
};

// Typescript safe function to check if a prop exist in an object
export function hasOwnProperty<X extends {}, Y extends PropertyKey>(
  obj: X,
  prop: Y,
): obj is X & Record<Y, unknown> {
  return Object.prototype.hasOwnProperty.call(obj, prop);
}

/**
 * Get param for request according compliant checkboxe value
 * @param hasNonCompliance
 * @returns
 */
export function getHasNonComplianceParam(hasNonCompliance: number): number | undefined {
  // Actually we want have only the operations with compliances or all operation (with and without compliances)
  // So if we don't checked hasNonCompliance button we need to send nothing to the backend to not filter the list
  // that keep only compliant operation when we send hasNonCompliant = 0
  return hasNonCompliance === 0 ? undefined : hasNonCompliance;
}
