import React, { useEffect, useState } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import CleaningZoneList from '@components/CleaningZoneList/CleaningZoneList';
import BaseDatePicker from '@components/shared/BaseDatePicker/BaseDatePicker';
import BaseSignatory from '@components/shared/BaseSignatory/BaseSignatory';
import i18next from 'i18next';
import BaseNavbarWithHistory from '@components/shared/BaseNavbarWithHistory/BaseNavbarWithHistory';
import BaseSaveButton from '@components/shared/BaseSaveButton/BaseSaveButton';
import { formatISO, format, isValid, setHours, setMinutes } from 'date-fns';
import { useAppSelector, useAppDispatch } from '@store/hooks';
import { SignatoryDto } from '@models/dto/response/Signatory.dto';
import { getSignatories } from '@store/reducers/signatory';
import { getAreas, selectSurfaces, selectArea, selectSurfaceId } from '@store/reducers/area';
import { AreaDto } from '@models/dto/response/Area.dto';
import { CreateCleaningPlan } from '@models/dto/request/CreateCleaningPlan.dto';
import { SurfaceDto } from '@models/dto/response/Surface.dto';
import { toast } from 'react-toastify';
import { history } from '@router/routes';
import { createCleaningPlan } from '@store/reducers/operation';
import AccountTypeEnum from '@models/enums/AccountType';
import OperationTypeEnum from '@models/enums/OperationType';

import styles from './Cleaning.module.scss';

const Cleaning = () => {
  // Store
  const signatories = useAppSelector((state) => state.signatory.signatories);
  const areas = useAppSelector((state) => state.area.areas);
  const establishmentId = useAppSelector((state) => state.account.selectedEstablishment?.id);
  const selectedZone = useAppSelector((state) => state.area.selectedArea);
  const selectedSurfaces = useAppSelector((state) => state.area.selectedSurfaces);
  const selectedSurfaceId = useAppSelector((state) => state.area.selectedSurfaceId);
  const accountType = useAppSelector((state) => state.account.type);
  // Component state
  const [currentDate, setCurrentDate] = useState<Date>(new Date());
  const [currentTime, setCurrentTime] = useState<string>(format(new Date(), 'HH:mm'));
  const [selectedSignatory, setSelectedSignatory] = useState<SignatoryDto>();

  const dispatch = useAppDispatch();

  const resetForm = () => {
    dispatch(selectSurfaceId(undefined));
    dispatch(selectSurfaces(undefined));
    dispatch(selectArea(undefined));
  };

  // Get mandatory data
  useEffect(() => {
    if ((!signatories || signatories.length === 0) && establishmentId) {
      dispatch(getSignatories(establishmentId)).unwrap();
    }
    if (establishmentId) {
      dispatch(getAreas(establishmentId)).unwrap();
    }
    return () => {
      resetForm();
    };
  }, []);

  // Update Date with custom time
  const buildDate = () => {
    let formattedDate = currentDate;
    const timeArray = currentTime.split(':');
    formattedDate = setMinutes(formattedDate, Number(timeArray[1]));
    formattedDate = setHours(formattedDate, Number(timeArray[0]));
    return formattedDate;
  };

  // When coming from a task, apply data
  useEffect(() => {
    if (selectedSurfaceId && areas?.length > 0) {
      const findArea = areas.find((area: AreaDto) =>
        area.surfaces.find((surface: SurfaceDto) => surface.id === selectedSurfaceId),
      ) as AreaDto;
      const findSurface = findArea?.surfaces.filter((surface) => surface.id === selectedSurfaceId);
      dispatch(selectArea(findArea));
      dispatch(selectSurfaces(findSurface ?? []));
    }
  }, [selectSurfaceId, areas]);

  const setSelectedZone = (area: AreaDto | undefined) => {
    dispatch(selectArea(area));
  };

  const setSelectedSurfaces = (surfaces: SurfaceDto[] | undefined) => {
    dispatch(selectSurfaces(surfaces));
  };

  const handleSave = () => {
    // Form check if completed
    if (!selectedSurfaces?.length) {
      toast.warn(i18next.t('cleaning:toasts.warn.surface'));
      return;
    }
    if (!currentDate) {
      toast.warn(i18next.t('cleaning:toasts.warn.date'));
      return;
    }
    if (!currentTime) {
      toast.warn(i18next.t('cleaning:toasts.warn.time'));
      return;
    }
    if (!selectedSignatory) {
      toast.warn(i18next.t('cleaning:toasts.warn.signatory'));
      return;
    }

    const date = buildDate();
    // Is date valid format
    if (isValid(date)) {
      const data: CreateCleaningPlan = {
        signatoryId: selectedSignatory.id,
        surfaceIds: selectedSurfaces.map((surface: SurfaceDto) => surface.id).join(','),
        realisationDate: formatISO(date),
      };
      dispatch(createCleaningPlan(data))
        .unwrap()
        .then(() => {
          toast.success(i18next.t('cleaning:toasts.success.create'));
          history.push('/dashboard');
        })
        .catch(() => {
          toast.error(i18next.t('cleaning:toasts.error.create'));
        });
    }
  };

  return (
    <>
      <BaseNavbarWithHistory
        title={i18next.t('cleaning:navbar.title')}
        showSettings={accountType === AccountTypeEnum.admin}
        typeOperation={OperationTypeEnum.cleaning}
        onNavigate={() => {
          resetForm();
        }}
      />
      <Container fluid className="cornerRound px-0">
        <Row noGutters className={`${styles.cleaningZoneContainer} pt-5`}>
          <Col md={12} className="px-5">
            {areas && areas.length > 0 ? (
              <CleaningZoneList
                zones={areas.filter((area) => area.surfaces.length > 0)}
                selectedZone={selectedZone}
                setSelectedZone={setSelectedZone}
                selectedSurfaces={selectedSurfaces}
                setSelectedSurfaces={setSelectedSurfaces}
                disableSelection={selectedSurfaceId !== undefined}
              />
            ) : (
              <p className="tableHeader">{i18next.t('cleaning:zones.noZone')}</p>
            )}
          </Col>
        </Row>
        <Row className="mb-5">
          <Col md={12} className="px-5 pt-5">
            <BaseDatePicker
              currentDate={currentDate}
              setCurrentDate={setCurrentDate}
              currentTime={currentTime}
              setCurrentTime={setCurrentTime}
              showTitle
            />
          </Col>
        </Row>
        <hr style={{ width: '80%' }} />
        <BaseSignatory
          signatories={signatories}
          selectedSignatory={selectedSignatory}
          setSelectedSignatory={setSelectedSignatory}
        />
        <BaseSaveButton onSave={handleSave} text={i18next.t('shared:buttons.save')} />
      </Container>
    </>
  );
};

export default Cleaning;
