import React, { Dispatch, SetStateAction, useState } from 'react';
import i18next from 'i18next';
import { Col, Form, Modal, Row } from 'react-bootstrap';
import BasePhotoPicker from '@components/shared/BasePhotoPicker/BasePhotoPicker';
import BaseModalHeader from '@components/shared/BaseModalHeader/BaseModalHeader';
import BaseModalFooter from '@components/shared/BaseModalFooter/BaseModalFooter';
import styles from './FileSettingsModal.module.scss';

type Props = {
  showModal: boolean;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  onValidForm: (name: string, file: any) => void;
};

const FileSettingsModal = (props: Props) => {
  const { showModal, setShowModal, onValidForm } = props;
  const [name, setName] = useState<string>('');
  const [document, setDocument] = useState<any>([]);

  const handleValidate = () => {
    setShowModal(false);
    onValidForm(name, document);
    if (name && document) {
      setName('');
      setDocument([]);
    }
  };

  return (
    <Modal
      show={showModal}
      size="lg"
      onHide={() => setShowModal(false)}
      aria-labelledby="file-settings-modal"
      centered
    >
      <BaseModalHeader
        id="file-settings-modal"
        title={i18next.t('documents:settings.file.modal.title')}
      />
      <Modal.Body className="p-4">
        <Form>
          {/* Name */}
          <Form.Group className="d-flex flex-row align-items-center mt-5" controlId="name">
            <Form.Label className={styles.inputLabel}>
              {i18next.t('documents:settings.file.modal.form.name')}
            </Form.Label>
            <Form.Control
              type="text"
              autoComplete="off"
              className="baseInputLg ml-3"
              onChange={(e) => setName(e.target.value)}
            />
          </Form.Group>
        </Form>
        <Row className="px-3">
          <Col md={12}>
            <BasePhotoPicker
              photos={document}
              setPhotos={setDocument}
              title={i18next.t('shared:photoPicker.titleFile')}
              btnLabel={i18next.t('shared:photoPicker.buttonFile')}
              acceptType="*"
            />
          </Col>
        </Row>
      </Modal.Body>
      <BaseModalFooter handleValidate={handleValidate} setShowModal={setShowModal} />
    </Modal>
  );
};

export default FileSettingsModal;
