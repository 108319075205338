import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import i18next from 'i18next';
import { Form, Modal } from 'react-bootstrap';
import BaseOccurenceForm from '@components/shared/BaseOccurenceForm/BaseOccurenceForm';
import { CreateEquipmentDto } from '@models/dto/request/CreateEquipment.dto';
import { FrequencyJsonDto } from '@models/dto/FrequencyJson.dto';
import BaseEditPhotoPicker from '@components/shared/BaseEditPhotoPicker/BaseEditPhotoPicker';
import { EquipmentDto } from '@models/dto/response/Equipment.dto';
import { FREQUENCY_JSON } from '@utils/constants';
import BaseModalHeader from '@components/shared/BaseModalHeader/BaseModalHeader';
import BaseModalFooter from '@components/shared/BaseModalFooter/BaseModalFooter';
import { toast } from 'react-toastify';
import { FrequencySurfaceEnum } from '@models/dto/request/FrequencySurfaceEnum.dto';
import { checkFormFrequencyValid, formatFrequencyToNumber } from '@utils/frequenciesUtils';
import { isEqual } from 'lodash';
import BasePhotoPicker from '@components/shared/BasePhotoPicker/BasePhotoPicker';
import styles from './HardwareTempSettingsEditModal.module.scss';

const OCCURENCES = [FrequencySurfaceEnum.NONE, FrequencySurfaceEnum.DAILY];

type Props = {
  showModal: boolean;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  updateCurrentEquipment: (equipment: CreateEquipmentDto) => void;
  setEquipmentToUpdate: Dispatch<SetStateAction<EquipmentDto | undefined>>;
  equipmentToUpdate: EquipmentDto;
};

const HardwareTempSettingsEditModal = (props: Props) => {
  const {
    showModal,
    setShowModal,
    updateCurrentEquipment,
    equipmentToUpdate,
    setEquipmentToUpdate,
  } = props;

  const [name, setName] = useState<string>(equipmentToUpdate.name);
  const [temperatureMin, setTemperatureMin] = useState<number>(equipmentToUpdate.minTemperature);
  const [temperatureMax, setTemperatureMax] = useState<number>(equipmentToUpdate.maxTemperature);
  const [frequencyJson, setFrequencyJson] = useState<FrequencyJsonDto | undefined>(
    equipmentToUpdate.frequency ? JSON.parse(equipmentToUpdate.frequency.json) : undefined,
  );
  const [photos, setPhotos] = useState<any>([]);
  const [picture, setPicture] = useState<string | undefined>(equipmentToUpdate.picture);
  const [frequencyType, setFrequencyType] = useState<FrequencySurfaceEnum>(
    isEqual(frequencyJson, FREQUENCY_JSON) ? FrequencySurfaceEnum.NONE : FrequencySurfaceEnum.DAILY,
  );

  useEffect(() => {
    if (equipmentToUpdate) {
      if (equipmentToUpdate.name !== name) {
        setName(equipmentToUpdate.name);
      }
      const newFrequencyJson = JSON.parse(equipmentToUpdate.frequency.json);
      if (!isEqual(frequencyJson, newFrequencyJson)) {
        setFrequencyJson(newFrequencyJson);
        setFrequencyType(FrequencySurfaceEnum.DAILY);
      }
    } else {
      setName('');
      setFrequencyJson(FREQUENCY_JSON);
    }
  }, [equipmentToUpdate]);

  const resetForm = () => {
    setName('');
    setTemperatureMin(0);
    setTemperatureMax(0);
    setFrequencyJson(FREQUENCY_JSON);
    setPhotos([]);
    setEquipmentToUpdate(undefined);
  };

  const handleValidate = () => {
    // Form check if completed
    if (!name) {
      toast.warn(i18next.t('hardwareTemp:toasts.warn.name'));
      return;
    }
    if (!temperatureMin && temperatureMin !== 0) {
      toast.warn(i18next.t('hardwareTemp:toasts.warn.minTemp'));
      return;
    }
    if (!temperatureMax && temperatureMax !== 0) {
      toast.warn(i18next.t('hardwareTemp:toasts.warn.maxTemp'));
      return;
    }

    if (temperatureMin >= temperatureMax) {
      toast.warn(i18next.t('hardwareTemp:toasts.warn.wrongTemperatures'));
      return;
    }
    if (frequencyJson) {
      const isFrequencyFormValid = checkFormFrequencyValid(frequencyJson, 'form', frequencyType);

      if (isFrequencyFormValid) {
        const formattedFrequency = formatFrequencyToNumber(frequencyJson!);
        const equipment: CreateEquipmentDto = {
          name,
          minTemperature: temperatureMin,
          maxTemperature: temperatureMax,
          frequencyJson: JSON.stringify(formattedFrequency),
          // Replace photo or keep current picture
          picture: photos.length > 0 ? photos[0] : null,
        };
        updateCurrentEquipment(equipment);
        resetForm();
        setShowModal(false);
      }
    }
  };

  const replacePicture = () => {
    setPicture(undefined);
  };

  return (
    <Modal
      show={showModal}
      size="lg"
      onHide={() => setShowModal(false)}
      aria-labelledby="hardware-settings-temp-edit-modal"
      centered
    >
      <BaseModalHeader
        id="hardware-settings-temp-edit-modal"
        title={i18next.t('hardwareTemp:settings.modal.editTitle')}
      />
      <Modal.Body className="p-4">
        <Form>
          {/* Name */}
          <Form.Group className="d-flex flex-row align-items-center mt-5" controlId="name">
            <Form.Label className={styles.inputLabel}>
              {i18next.t('hardwareTemp:settings.modal.form.name')}
            </Form.Label>
            <Form.Control
              type="text"
              autoComplete="off"
              className="baseInputLgWithFont ml-3"
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
            />
          </Form.Group>
          {/* Input photo */}
          <div className="mt-5">
            {picture === null ? (
              <BasePhotoPicker
                photos={photos}
                setPhotos={setPhotos}
                title={i18next.t('shared:photoPicker.titleEquipment')}
                multiPictureAllowed={false}
              />
            ) : (
              <BaseEditPhotoPicker
                photos={photos}
                setPhotos={setPhotos}
                picture={picture}
                title={i18next.t('shared:photoPicker.titleProduct')}
                replacePicture={replacePicture}
              />
            )}
          </div>
          {/* Temperature max */}
          <Form.Group
            className="d-flex flex-row align-items-center mt-5 text-nowrap"
            controlId="tMax"
          >
            <Form.Label className={`${styles.inputLabel} ${styles.temperatureLabel}`}>
              {i18next.t('hardwareTemp:settings.modal.form.tMax')}
            </Form.Label>
            <Form.Control
              type="number"
              autoComplete="off"
              className={`baseInputLgWithFont ml-3 ${styles.temperatureInput}`}
              value={temperatureMax}
              onChange={(e) => {
                setTemperatureMax(Number(e.target.value));
              }}
            />
            <span className="ml-2">°C</span>
          </Form.Group>
          {/* Temperature min */}
          <Form.Group
            className="d-flex flex-row align-items-center mt-5 text-nowrap"
            controlId="tMin"
          >
            <Form.Label className={`${styles.inputLabel} ${styles.temperatureLabel}`}>
              {i18next.t('hardwareTemp:settings.modal.form.tMin')}
            </Form.Label>
            <Form.Control
              type="number"
              autoComplete="off"
              className={`baseInputLgWithFont ml-3 ${styles.temperatureInput}`}
              value={temperatureMin}
              onChange={(e) => {
                setTemperatureMin(Number(e.target.value));
              }}
            />
            <span className="ml-2">°C</span>
          </Form.Group>
          {/* Occurence */}
          <BaseOccurenceForm
            frequencyJson={frequencyJson}
            setFrequencyJson={setFrequencyJson}
            frequencyType={frequencyType}
            setFrequencyType={setFrequencyType}
            occurences={OCCURENCES}
          />
        </Form>
      </Modal.Body>
      <BaseModalFooter handleValidate={handleValidate} setShowModal={setShowModal} />
    </Modal>
  );
};

export default HardwareTempSettingsEditModal;
