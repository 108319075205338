import React, { useEffect, useRef, useState } from 'react';
import i18next from 'i18next';
import { Table, Badge, Button, Image, Spinner } from 'react-bootstrap';
import { format } from 'date-fns';
import fr from 'date-fns/locale/fr';
import { history } from '@router/routes';
import {
  getTitleOperation,
  getSubjectOperation,
  getInformationOperation,
} from '@utils/operationsUtils';

import chevronIcon from '@assets/images/svg/chevron-right.svg';
import { useAppDispatch } from '@store/hooks';
import { setNonCompliance } from '@store/reducers/non-compliance';
import NonComplianceRedirectModal from '@components/NonComplianceRedirectModal/NonComplianceRedirectModal';
import { OperationsListDto } from '@store/reducers/history';
import { NonComplianceDto } from '@models/dto/response/NonCompliance.dto';
import OperationTypeEnum from '@models/enums/OperationType';
import { OperationCoolingDto } from '@models/dto/response/OperationCooling.dto';
import { OperationServiceDto } from '@models/dto/response/OperationService.dto';
import { OperationDeliveryDto } from '@models/dto/response/OperationDelivery.dto';
import styles from './OperationsHistoryList.module.scss';

type Props = {
  operations: OperationsListDto[];
  loadMore: () => void;
  isLoading: boolean;
};

const OperationsHistoryList = (props: Props) => {
  const dispatch = useAppDispatch();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [selectedNonCompliances, setSelectedNonCompliances] = useState<NonComplianceDto[]>([]);

  const loader = useRef(null);

  const handleObserver = (entities: any) => {
    const target = entities[0];
    if (target.isIntersecting) {
      props.loadMore();
    }
  };

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 1.0,
    };

    // initialize IntersectionObserver
    // and attaching to Load More div
    const observer = new IntersectionObserver(handleObserver, options);
    if (loader.current) {
      observer.observe(loader.current);
    }
    return () => {
      if (loader.current) {
        observer.unobserve(loader.current);
      }
    };
  }, []);

  const handleClickOperation = (operation: OperationsListDto) => {
    history.push('/operations-history/details', { operation });
  };

  const isNonCompliant = (operation: OperationsListDto) => {
    return (
      (operation.nonCompliances &&
        operation.nonCompliances.length > 0 &&
        operation.nonCompliances.find((nc: NonComplianceDto) => nc.processed === false)) ||
      // Error format from API
      // @ts-ignore
      operation.nonCompliances?.[1]
    );
  };

  const isCompleted = (operation: OperationsListDto) => {
    return (
      (operation.operation.nonCompliancesTreated > 0 &&
        operation.nonCompliances &&
        operation.nonCompliances.length &&
        operation.operation.nonCompliancesTreated ===
          operation.nonCompliances.filter((nc: NonComplianceDto) => nc.processed === true)
            .length) ||
      (operation.operation.type === OperationTypeEnum.analysis &&
        operation.nonCompliances &&
        operation.nonCompliances.length &&
        operation.nonCompliances[0].processed)
    );
  };

  const redirectToNonCompliance = () => {
    setShowModal(false);
    history.push('/operations-handling/solve');
  };

  const goToNonCompliance = (
    e: React.MouseEvent<HTMLButtonElement>,
    operation: OperationsListDto,
  ) => {
    e.preventDefault();
    e.stopPropagation();
    const nonCompliances =
      operation && operation.nonCompliances
        ? operation.nonCompliances.filter((nc: NonComplianceDto) => nc.processed === false)
        : [];
    if (nonCompliances?.length === 1) {
      dispatch(setNonCompliance(nonCompliances[0]));
      history.push('/operations-handling/solve');
      // @ts-ignore
    } else if (nonCompliances?.length) {
      setSelectedNonCompliances(nonCompliances ?? []);
      setShowModal(true);
    } else {
      // Error format from API
      // @ts-ignore
      setSelectedNonCompliances([nonCompliances?.[1]] ?? []);
      setShowModal(true);
    }
  };

  return (
    <>
      <Table responsive className="mt-5">
        <thead className="border-top-0">
          <tr className="tableHeader">
            <th>{i18next.t('operationsHistory:table.headers.date')}</th>
            <th>{i18next.t('operationsHistory:table.headers.type')}</th>
            <th>{i18next.t('operationsHistory:table.headers.subject')}</th>
            <th>{i18next.t('operationsHistory:table.headers.info')}</th>
          </tr>
        </thead>
        <tbody>
          {/* Sort by date DESC */}
          {props.operations.map((operation: OperationsListDto, index: number) => {
            return (
              <tr
                key={index}
                className={`py-5 ${isNonCompliant(operation) ? styles.nonCompliantRow : ''}`}
                onClick={() => handleClickOperation(operation)}
              >
                <td className={styles.tableColumnStyleOne}>
                  {operation.operation.userRealisationDate &&
                    format(new Date(operation.operation.userRealisationDate), 'Pp', {
                      locale: fr,
                    })}
                </td>
                <td className={styles.tableColumnStyleTwo}>
                  <div className="d-flex flex-column">
                    {/*
                    // @ts-ignore */}
                    {getTitleOperation(operation.operation.type, operation.operation.subType)}
                    {isNonCompliant(operation) && (
                      <Badge className={`${styles.badge} ${styles.negative}`}>
                        {i18next.t('operationsHistory:table.nonCompliant')}
                      </Badge>
                    )}
                    {isCompleted(operation) ? (
                      <Badge className={`${styles.badge} ${styles.info}`}>
                        {i18next.t('operationsHistory:table.nonCompliantTreated')}
                      </Badge>
                    ) : (
                      <></>
                    )}
                  </div>
                </td>
                <td className={styles.tableColumnStyleTwo}>
                  {getSubjectOperation(
                    operation.operation.type,
                    operation.operation,
                    operation.nonCompliances,
                  )
                    .split('||')
                    .map((res: any, i: number) => (
                      <p key={i}>{res}</p>
                    ))}
                </td>
                <td>
                  <div className="d-flex flex-row align-items-center">
                    <div className={styles.tableColumnStyleOne}>
                      {operation.operation.type === OperationTypeEnum.product
                        ? getInformationOperation(
                            operation.operation.type,
                            operation.operation,
                            (
                              operation.operation as
                                | OperationCoolingDto
                                | OperationServiceDto
                                | OperationDeliveryDto
                            ).subType,
                          )
                            .split('||')
                            .map((res: any, i: number) => <p key={i}>{res}</p>)
                        : getInformationOperation(
                            operation.operation.type,
                            operation.operation,
                            undefined,
                            operation.nonCompliances,
                          )
                            .split('||')
                            .map((res: any, i: number) => <p key={i}>{res}</p>)}
                    </div>
                    {isNonCompliant(operation) && (
                      <Button
                        className={`btnXs d-flex flex-row align-items-center ml-4 text-nowrap ${styles.solveButton}`}
                        onClick={(e: React.MouseEvent<HTMLButtonElement>) =>
                          goToNonCompliance(e, operation)
                        }
                      >
                        {i18next.t('shared:buttons.toSolve')}
                        <Image src={chevronIcon} width={6} height={11} className="ml-2" />
                      </Button>
                    )}
                  </div>
                </td>
              </tr>
            );
          })}
          <tr ref={loader} onClick={props.loadMore}>
            {props.isLoading && (
              <td>
                <Spinner animation="border" role="status" variant="info">
                  <span className="sr-only">{i18next.t('shared:loading')}</span>
                </Spinner>
              </td>
            )}
          </tr>
        </tbody>
      </Table>
      <div>{props.operations.length} opérations</div>
      {selectedNonCompliances && selectedNonCompliances.length > 0 && (
        <NonComplianceRedirectModal
          showModal={showModal}
          setShowModal={setShowModal}
          handleValidate={redirectToNonCompliance}
          nonCompliances={selectedNonCompliances}
        />
      )}
    </>
  );
};

export default OperationsHistoryList;
