import BaseNavbarWithHistory from '@components/shared/BaseNavbarWithHistory/BaseNavbarWithHistory';
import ReceptionControlSettingsTable from '@components/ReceptionControlSettingsTable/ReceptionControlSettingsTable';
import ReceptionControlSettingsModal from '@components/ReceptionControlSettingsModal/ReceptionControlSettingsModal';
import i18next from 'i18next';
import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Button, Image } from 'react-bootstrap';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { getSuppliers, addSupplier, editSupplier, deleteSupplier } from '@store/reducers/supplier';
import { toast } from 'react-toastify';
import { SupplierDto } from '@models/dto/response/Supplier.dto';
import plusWhite from '@assets/images/svg/plus-rond.svg';
import BaseNavbar from '@components/shared/BaseNavbar/BaseNavbar';
import styles from './ReceptionControlSettings.module.scss';

const ReceptionControlSettings = () => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<SupplierDto>();
  const suppliers = useAppSelector((state) => state.supplier.suppliers);
  const establishmentId = useAppSelector((state) => state.account.selectedEstablishment?.id);
  const accountType = useAppSelector((state) => state.account.type);
  const dispatch = useAppDispatch();

  // load suppliers
  useEffect(() => {
    if ((!suppliers || suppliers.length === 0) && establishmentId) {
      dispatch(getSuppliers(establishmentId)).unwrap();
    }
  }, []);

  const addSupplierModal = () => {
    setShowModal(true);
  };

  const createSupplier = (name: string) => {
    if (establishmentId) {
      setShowModal(false);
      toast.promise(dispatch(addSupplier({ establishmentId, name })), {
        pending: i18next.t('receptionControl:settings.toasts.pending.createSupplier'),
        success: i18next.t('receptionControl:settings.toasts.success.createSupplier'),
        error: i18next.t('receptionControl:settings.toasts.error.createSupplier'),
      });
    }
  };

  // Edit item: show modal
  const editSupplierModal = (supplier: SupplierDto) => {
    setSelectedItem(supplier);
    setShowModal(true);
  };

  const updateSupplier = (supplierId: number, name: string) => {
    setShowModal(false);
    toast.promise(dispatch(editSupplier({ supplierId, name })), {
      pending: i18next.t('receptionControl:settings.toasts.pending.updateSupplier'),
      success: i18next.t('receptionControl:settings.toasts.success.updateSupplier'),
      error: i18next.t('receptionControl:settings.toasts.error.updateSupplier'),
    });
    setSelectedItem(undefined);
  };

  const deleteSupplierItem = (supplierId: number) => {
    toast.promise(dispatch(deleteSupplier(supplierId)), {
      pending: i18next.t('receptionControl:settings.toasts.pending.deleteSupplier'),
      success: i18next.t('receptionControl:settings.toasts.success.deleteSupplier'),
      error: i18next.t('receptionControl:settings.toasts.error.deleteSupplier'),
    });
  };

  return (
    <>
      <BaseNavbar accountType={accountType} parametersUrl="/settings" />
      <BaseNavbarWithHistory
        title={i18next.t('receptionControl:settings.title')}
        showSettings={false}
        showHistory={false}
      />
      <Container fluid className="h-100 pt-5 cornerRound px-5">
        <Row>
          <Col md={12} className="d-flex justify-content-between align-items-center">
            <div className={styles.listTitle}>{i18next.t('receptionControl:settings.list')}</div>
            <Button
              className={`${styles.addButton} d-flex justify-content-center align-items-center btnXl`}
              onClick={addSupplierModal}
            >
              <Image src={plusWhite} width={24} height={24} className="mr-2" />
              {i18next.t('receptionControl:settings.addSupplier')}
            </Button>
          </Col>
        </Row>
        {suppliers && suppliers.length > 0 ? (
          <ReceptionControlSettingsTable
            supplierList={suppliers}
            onEditItem={editSupplierModal}
            onDeleteItem={deleteSupplierItem}
          />
        ) : (
          <Row className="d-flex justify-content-center align-items-center h-100">
            <Col md={12} className="text-center tableHeader">
              {i18next.t('receptionControl:settings.empty')}
            </Col>
          </Row>
        )}
        <ReceptionControlSettingsModal
          selectedItem={selectedItem}
          showModal={showModal}
          setShowModal={setShowModal}
          onValidFormCreate={createSupplier}
          onValidFormEdit={updateSupplier}
        />
      </Container>
    </>
  );
};

export default ReceptionControlSettings;
