import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import documentService from '@services/document.service';
import { FolderDto } from '@models/dto/response/Folder.dto';
import { FileDto } from '@models/dto/response/File.dto';
import { CreateFolderParams } from '@models/dto/request/FolderParams.dto';
import { CreateFileParams } from '@models/dto/request/FileParams.dto';

// Define a type for the slice state
interface DocumentsState {
  folders: FolderDto[];
  documents: FileDto[];
}

// Define the initial state using that type
const initialState: DocumentsState = {
  folders: [],
  documents: [],
};

// REQUESTS
export const getFolders = createAsyncThunk<AxiosResponse<FolderDto[]>, number>(
  'folder/getAll',
  async (establishmentId: number) => {
    const response = await documentService.getFolders(establishmentId);
    return response.data;
  },
);

export const createFolders = createAsyncThunk<AxiosResponse<FolderDto>, CreateFolderParams>(
  'folder/create',
  async (params: CreateFolderParams, { rejectWithValue }) => {
    try {
      const response = await documentService.createFolder(params.establishmentId, params.name);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const deleteFolder = createAsyncThunk<AxiosResponse<void>, number>(
  'folder/delete',
  async (fodlerId: number, { rejectWithValue }) => {
    try {
      const response = await documentService.deleteFolder(fodlerId);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const getFiles = createAsyncThunk<AxiosResponse<FolderDto[]>, number>(
  'files/getAll',
  async (folderId: number) => {
    const response = await documentService.getFiles(folderId);
    return response.data;
  },
);

export const createFile = createAsyncThunk<AxiosResponse<FileDto>, CreateFileParams>(
  'file/create',
  async (params: CreateFileParams, { rejectWithValue }) => {
    try {
      const formData = new FormData();
      formData.append('file', params.file);
      formData.append('name', params.name ?? '');
      const response = await documentService.createFile(params.folderId, formData);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const deleteFile = createAsyncThunk<AxiosResponse<void>, number>(
  'file/delete',
  async (fileId: number, { rejectWithValue }) => {
    try {
      const response = await documentService.deleteFile(fileId);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const DocumentsSlice = createSlice({
  name: 'documents',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Side effects
    // Get folder list
    builder.addCase(getFolders.fulfilled, (state, action) => {
      state.folders = action.payload.data ?? [];
    });
    // Add folder
    builder.addCase(createFolders.fulfilled, (state, action) => {
      state.folders.push(action.payload.data);
    });
    // Delete folder
    builder.addCase(deleteFolder.fulfilled, (state, action) => {
      state.folders = state.folders.filter((folder: FolderDto) => folder.id !== action.meta.arg);
    });
    builder.addCase(createFile.fulfilled, (state, action) => {
      // Create file into folder
      state.folders
        .find((folder: FolderDto) => folder.id === action.meta.arg.folderId)
        ?.files.push(action.payload.data);
    });
    // Delete file
    builder.addCase(deleteFile.fulfilled, (state, action) => {
      state.folders = state.folders.map((folder: FolderDto) => {
        return {
          ...folder,
          files: folder.files.filter((file: FileDto) => file.id !== action.meta.arg),
        };
      });
    });
  },
});

export default DocumentsSlice.reducer;
