import { CreateCustomerDto } from '@models/dto/request/CreateCustomer.dto';
import Axios from './index';

export default {
  getCustomers: (establishmentId: number) => {
    return Axios.get(`/establishment/${establishmentId}/customer`);
  },

  createCustomer: (establishmentId: number, customer: CreateCustomerDto) => {
    return Axios.post(`/establishment/${establishmentId}/customer`, customer);
  },

  getCustomer: (id: number) => {
    return Axios.get(`/establishment/customer/${id}`);
  },

  updateCustomer: (id: number, customer: CreateCustomerDto) => {
    return Axios.put(`/establishment/customer/${id}`, customer);
  },

  deleteCustomer: (id: number) => {
    return Axios.delete(`/establishment/customer/${id}`);
  },
};
