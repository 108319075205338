import React, { Dispatch, SetStateAction } from 'react';
import i18next from 'i18next';
import { Modal, Image } from 'react-bootstrap';
import 'react-datepicker/dist/react-datepicker.css';
import calendarIcon from '@assets/images/svg/calendar.svg';
import { CalendarExceptionDto } from '@models/dto/response/CalendarExceptionList.dto';
import { format } from 'date-fns';
import BaseModalHeader from '@components/shared/BaseModalHeader/BaseModalHeader';
import BaseModalFooter from '@components/shared/BaseModalFooter/BaseModalFooter';

type Props = {
  selectedItem: CalendarExceptionDto;
  showModal: boolean;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  onConfirm: (id: number) => void;
};

const SettingsCalendarExceptionsDeleteModal = (props: Props) => {
  const { selectedItem, showModal, setShowModal, onConfirm } = props;

  const handleValidate = () => {
    onConfirm(selectedItem.id);
    setShowModal(false);
  };

  return (
    <Modal
      show={showModal}
      onHide={() => setShowModal(false)}
      aria-labelledby="delete-calendar-exceptions-modal"
      centered
    >
      <BaseModalHeader
        id="delete-calendar-exceptions-modal"
        title={i18next.t('settings:calendarExceptions.modal.deleteTitle')}
      />
      <Modal.Body>
        <div className="text-center">
          <Image src={calendarIcon} className="menu-item" width={50} height={50} />
          <div className="mt-3">{`Du ${format(
            new Date(selectedItem.startDate),
            'dd/MM/yyyy',
          )} au ${format(new Date(selectedItem.endDate), 'dd/MM/yyyy')}`}</div>
        </div>
      </Modal.Body>
      <BaseModalFooter handleValidate={handleValidate} setShowModal={setShowModal} />
    </Modal>
  );
};

export default SettingsCalendarExceptionsDeleteModal;
