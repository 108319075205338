import React, { Dispatch, SetStateAction, useRef } from 'react';
import i18next from 'i18next';
import { Button, Col, Row, Image } from 'react-bootstrap';
import closeIcon from '@assets/images/svg/close-rond.svg';
import photoIcon from '@assets/images/svg/photo.svg';
import pdfIcon from '@assets/images/svg/icpdf.svg';
import styles from './BasePhotoPicker.module.scss';

type Props = {
  photos: any;
  setPhotos: Dispatch<SetStateAction<any>>;
  title?: string;
  multiPictureAllowed?: boolean;
  isCenter?: boolean;
  acceptType?: string;
  btnLabel?: string;
};

const BasePhotoPicker = (props: Props) => {
  const { photos, setPhotos, title, multiPictureAllowed, acceptType, btnLabel } = props;
  const fileRef = useRef<any>(null);

  const handleClickButton = () => {
    if (fileRef.current !== null && fileRef.current instanceof HTMLInputElement) {
      fileRef.current.click();
    }
  };

  const handleChangeFiles = (files: FileList | null) => {
    if (files && files.length > 0) {
      const src = files[0];
      const array = [...photos, src];
      setPhotos(array);
    }
  };

  const removePhoto = (index: number) => {
    const copy = [...photos];
    copy.splice(index, 1);
    setPhotos(copy);
  };

  return (
    <>
      {title ? <div className={styles.label}>{title}</div> : null}
      <Row>
        <Col md={12} lg={props.isCenter ? 12 : 6}>
          <Button
            className={`${styles.addPhotoButton} btnXl mt-md-5 mt-3 mb-4`}
            onClick={handleClickButton}
            variant="outline-secondary"
            disabled={photos.length >= 1 && !multiPictureAllowed}
          >
            <Image src={photoIcon} width={37} height={28} className="mr-3" />
            {btnLabel ?? i18next.t('shared:photoPicker.button')}
          </Button>
          <input
            ref={fileRef}
            type="file"
            accept={acceptType ?? 'image/*'}
            hidden
            onChange={(e) => handleChangeFiles(e.target.files)}
          />
        </Col>
      </Row>
      {/* Photos list */}
      <Row>
        <Col md={12} className="d-flex">
          {photos &&
            photos.length > 0 &&
            photos.map((photo: any, index: number) => (
              <div key={index}>
                {photo.type === 'application/pdf' ? (
                  <Image src={pdfIcon} width={188} height={206} fluid />
                ) : (
                  <Image
                    src={URL.createObjectURL(photo)}
                    onLoad={() => URL.revokeObjectURL(photo)}
                    width={188}
                    height={206}
                    fluid
                  />
                )}
                {/* Close button */}
                <Image
                  className={styles.closeIcon}
                  src={closeIcon}
                  width={46}
                  height={46}
                  onClick={() => removePhoto(index)}
                />
              </div>
            ))}
        </Col>
      </Row>
    </>
  );
};

BasePhotoPicker.default = {
  multiPictureAllowed: true,
};

export default BasePhotoPicker;
