import React, { Dispatch, SetStateAction } from 'react';
import i18next from 'i18next';
import { Modal, Image } from 'react-bootstrap';
import BaseModalFooter from '@components/shared/BaseModalFooter/BaseModalFooter';
import BaseModalHeader from '@components/shared/BaseModalHeader/BaseModalHeader';
import placeholderImage from '@assets/images/svg/placeholder.svg';
import styles from './SettingsSignatoriesDeleteModal.module.scss';

type Props = {
  showModal: boolean;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  onValid: () => void;
  name: any;
  picture: any;
};

const SettingsSignatoriesDeleteModal = (props: Props) => {
  const { showModal, setShowModal, onValid, name, picture } = props;

  const handleValidate = () => {
    onValid();
    setShowModal(false);
  };

  return (
    <Modal
      show={showModal}
      onHide={() => setShowModal(false)}
      aria-labelledby="delete-signatory-modal"
      centered
    >
      <BaseModalHeader
        id="delete-signatory-modal"
        title={i18next.t('settings:signatories.modals.deleteTitle')}
      />
      <Modal.Body>
        <div className="text-center">
          <Image
            src={picture ?? placeholderImage}
            roundedCircle
            className={`menu-item ${styles.picture}`}
            width={200}
            height={200}
          />
          <div className={styles.signatoryName}>{name}</div>
        </div>
      </Modal.Body>
      <BaseModalFooter handleValidate={handleValidate} setShowModal={setShowModal} />
    </Modal>
  );
};

export default SettingsSignatoriesDeleteModal;
