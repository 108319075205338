import { SignatoryDto } from '@models/dto/response/Signatory.dto';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import signatoryService from '@services/signatory.service';
import { AxiosResponse } from 'axios';
import {
  EditSignatoriesParams,
  SignatoriesParams,
} from '@models/dto/request/SignatoriesParams.dto';

// Define a type for the slice state
interface SignatoryState {
  signatories: SignatoryDto[];
}

// Define the initial state using that type
const initialState: SignatoryState = {
  signatories: [],
};

// REQUESTS
export const getSignatories = createAsyncThunk<AxiosResponse<SignatoryDto[]>, number>(
  'signatory/getAll',
  async (establishmentId: number) => {
    const response = await signatoryService.getSignatories(establishmentId);
    return response.data;
  },
);

export const addSignatory = createAsyncThunk<AxiosResponse<SignatoryDto>, SignatoriesParams>(
  'signatory/add',
  async (params: SignatoriesParams) => {
    const formData = new FormData();
    if (params.signatory.photo) {
      formData.append('picture', params.signatory.photo);
    }
    formData.append('firstName', params.signatory.firstName ?? '');
    formData.append('lastName', params.signatory.lastName ?? '');
    const response = await signatoryService.createSignatory(params.establishmentId, formData);
    return response.data;
  },
);

export const editSignatory = createAsyncThunk<AxiosResponse<SignatoryDto>, EditSignatoriesParams>(
  'signatory/edit',
  async (params: EditSignatoriesParams) => {
    const formData = new FormData();
    if (params.signatory.photo) {
      formData.append('picture', params.signatory.photo);
    }
    formData.append('firstName', params.signatory.firstName ?? '');
    formData.append('lastName', params.signatory.lastName ?? '');
    const response = await signatoryService.updateSignatory(params.signatoryId, formData);
    return response.data;
  },
);

export const deleteSignatory = createAsyncThunk<AxiosResponse<SignatoryDto>, number>(
  'signatory/delete',
  async (signatoryId: number) => {
    const response = await signatoryService.deleteSignatory(signatoryId);
    return response.data;
  },
);

export const SignatorySlice = createSlice({
  name: 'signatory',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Side effects
    // Get signatories list
    builder.addCase(getSignatories.fulfilled, (state, action) => {
      state.signatories = action.payload.data ?? [];
    });
    builder.addCase(addSignatory.fulfilled, (state, action) => {
      state.signatories.push(action.payload.data);
    });
    builder.addCase(editSignatory.fulfilled, (state, action) => {
      state.signatories = state.signatories.filter(
        (sign: SignatoryDto) => sign.id !== action.payload.data.id,
      );
      state.signatories.push(action.payload.data);
    });
    builder.addCase(deleteSignatory.fulfilled, (state, action) => {
      const index = state.signatories.findIndex((el) => el.id === action.meta.arg);
      state.signatories.splice(index, 1);
    });
  },
});

export default SignatorySlice.reducer;
