import BaseNavbarWithHistory from '@components/shared/BaseNavbarWithHistory/BaseNavbarWithHistory';
import OperationsHandlingTable from '@components/OperationsHandlingTable/OperationsHandlingTable';
import i18next from 'i18next';
import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useAppSelector, useAppDispatch } from '@store/hooks';
import { getNonCompliantTasks, resetHistory } from '@store/reducers/history';
import { setNonCompliance, setNonComplianceAnalysis } from '@store/reducers/non-compliance';

const OperationsHandling = () => {
  // Store
  const establishment = useAppSelector((state) => state.account.selectedEstablishment);
  const nonCompliantTasks = useAppSelector((state) => state.history.nonCompliantTasks);
  const hasMoreNonCompliantTask = useAppSelector((state) => state.history.hasMoreNonCompliantTask);
  const dispatch = useAppDispatch();
  // Component state
  const [page, setPage] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    // Clean store from non compliance
    dispatch(setNonCompliance(undefined));
    dispatch(setNonComplianceAnalysis(undefined));
  }, []);

  const loadNextPage = () => {
    setPage((currentPage: number) => currentPage + 1);
  };

  useEffect(() => {
    if (hasMoreNonCompliantTask) {
      setIsLoading(true);
      dispatch(
        getNonCompliantTasks({
          establishmentId: establishment!.id,
          page,
        }),
      ).finally(() => setIsLoading(false));
    }
  }, [page]);

  useEffect(() => {
    return () => {
      dispatch(resetHistory());
    };
  }, []);

  return (
    <>
      {/* Navbar */}
      <BaseNavbarWithHistory
        title={i18next.t('operationsHandling:navbar.title')}
        showSettings={false}
        showHistory={false}
      />
      <Container fluid className="cornerRound">
        {nonCompliantTasks && nonCompliantTasks.length > 0 ? (
          <Row className="mb-5">
            <Col md={12} className="mb-5 px-0">
              <OperationsHandlingTable
                operations={nonCompliantTasks}
                loadNextPage={loadNextPage}
                isLoading={isLoading}
              />
            </Col>
          </Row>
        ) : (
          <div className="d-flex justify-content-center align-items-center h-100 pt-5 tableHeader">
            {i18next.t('operationsHandling:empty')}
          </div>
        )}
      </Container>
    </>
  );
};

export default OperationsHandling;
