import React, { Dispatch, SetStateAction } from 'react';
import i18next from 'i18next';
import AnalysisTypologyEnum from '@models/enums/AnalysisTypology';
import { Col, Row, Card, Image } from 'react-bootstrap';
import roundedCheckIcon from '@assets/images/svg/check-rond.svg';
import styles from './NonCompliantAnalysisTypology.module.scss';

type Props = {
  selectedTypology?: AnalysisTypologyEnum;
  setSelectedTypology: Dispatch<SetStateAction<AnalysisTypologyEnum | undefined>>;
};

const NonCompliantAnalysisTypology = (props: Props) => {
  const { selectedTypology, setSelectedTypology } = props;

  const handleSelectTypology = (typology: AnalysisTypologyEnum) => {
    setSelectedTypology(typology);
  };

  return (
    <Row className="my-5">
      {/* Product */}
      <Col sm={4}>
        <Card
          className={`h-100 ${styles.cardText} ${styles.productCard} ${
            selectedTypology === AnalysisTypologyEnum.product ? styles.active : ''
          } p-4`}
          onClick={() => handleSelectTypology(AnalysisTypologyEnum.product)}
        >
          <Card.Body className="d-flex justify-content-center align-items-center">
            {selectedTypology === AnalysisTypologyEnum.product && (
              <Image
                src={roundedCheckIcon}
                className={styles.roundedCheck}
                width={48}
                height={48}
              />
            )}
            {i18next.t(`operationsHandling:analysis.types.${AnalysisTypologyEnum.product}`)}
          </Card.Body>
        </Card>
      </Col>
      {/* Surface */}
      <Col sm={4}>
        <Card
          className={`h-100 ${styles.cardText} ${styles.surfaceCard} ${
            selectedTypology === AnalysisTypologyEnum.surface ? styles.active : ''
          } p-4`}
          onClick={() => handleSelectTypology(AnalysisTypologyEnum.surface)}
        >
          <Card.Body className="d-flex justify-content-center align-items-center">
            {selectedTypology === AnalysisTypologyEnum.surface && (
              <Image
                src={roundedCheckIcon}
                className={styles.roundedCheck}
                width={48}
                height={48}
              />
            )}
            {i18next.t(`operationsHandling:analysis.types.${AnalysisTypologyEnum.surface}`)}
          </Card.Body>
        </Card>
      </Col>
      {/* Water */}
      <Col sm={4}>
        <Card
          className={`h-100 ${styles.cardText} ${styles.waterCard} ${
            selectedTypology === AnalysisTypologyEnum.water ? styles.active : ''
          } p-4`}
          onClick={() => handleSelectTypology(AnalysisTypologyEnum.water)}
        >
          <Card.Body className="d-flex justify-content-center align-items-center">
            {selectedTypology === AnalysisTypologyEnum.water && (
              <Image
                src={roundedCheckIcon}
                className={styles.roundedCheck}
                width={48}
                height={48}
              />
            )}
            {i18next.t(`operationsHandling:analysis.types.${AnalysisTypologyEnum.water}`)}
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

export default NonCompliantAnalysisTypology;
