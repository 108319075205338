import Axios from './index';

export default {
  getSuppliers: (establishmentId: number) => {
    return Axios.get(`/establishment/${establishmentId}/supplier`, {
      params: {
        limit: 40,
      },
    });
  },

  createSupplier: (establishmentId: number, supplier: string) => {
    return Axios.post(`/establishment/${establishmentId}/supplier`, { name: supplier });
  },

  getSupplier: (id: number) => {
    return Axios.get(`/establishment/supplier/${id}`);
  },

  updateSupplier: (id: number, supplier: string) => {
    return Axios.put(`/establishment/supplier/${id}`, { name: supplier });
  },

  deleteSupplier: (id: number) => {
    return Axios.delete(`/establishment/supplier/${id}`);
  },
};
