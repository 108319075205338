import React, { Dispatch, SetStateAction } from 'react';
import i18next from 'i18next'; // i18next.t('pageName:exemple.exemple')
import { Modal, Button } from 'react-bootstrap';
import BaseModalHeader from '@components/shared/BaseModalHeader/BaseModalHeader';
import { history } from '@router/routes';
import styles from './DashboardOperationsProductTempModal.module.scss';

type Props = {
  showModal: boolean;
  setShowModal: Dispatch<SetStateAction<boolean>>;
};

const DashboardOperationsProductTempModal = (props: Props) => {
  const { showModal, setShowModal } = props;

  const finishMeasure = () => {
    history.push('/product-temperature/unfinished');
    setShowModal(false);
  };

  const newMeasure = () => {
    history.push('/product-temperature');
    setShowModal(false);
  };

  return (
    <Modal
      show={showModal}
      onHide={() => setShowModal(false)}
      aria-labelledby="dashboard-product-temp-modal"
      centered
    >
      <BaseModalHeader
        id="dashboard-product-temp-modal"
        title={i18next.t('dashboard:modal.title')}
      />
      <Modal.Body className="d-flex flex-column align-items-center justify-content-center p-5">
        <Button className={`${styles.fixButton} btnLg w-100 mt-3`} onClick={finishMeasure}>
          {i18next.t('dashboard:modal.finishMeasure')}
        </Button>
        <Button className={`${styles.fixButton} btnLg w-100 mt-3`} onClick={newMeasure}>
          {i18next.t('dashboard:modal.newMeasure')}
        </Button>
        <Button
          className={`${styles.editButton} btnLg mt-3 w-100`}
          onClick={() => setShowModal(false)}
        >
          {i18next.t('shared:buttons.cancel')}
        </Button>
      </Modal.Body>
    </Modal>
  );
};

export default DashboardOperationsProductTempModal;
