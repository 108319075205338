import { AccountDto } from '@models/dto/response/Account.dto';
import { ApiResponse } from '@models/dto/ApiResponse.dto';
import { AxiosResponse } from 'axios';
import Axios from './index';

export default {
  login: (login: string, password: string): Promise<AxiosResponse<ApiResponse<AccountDto>>> => {
    return Axios.post(`/account/login`, {
      login,
      password,
    });
  },

  getAccount: (): Promise<AxiosResponse<ApiResponse<AccountDto>>> => {
    return Axios.get(`/account`);
  },

  refreshToken: (refreshToken: string) => {
    return Axios.post('/account/token/refresh', { refreshToken });
  },

  editPassword: (oldPassword: string, newPassword: string) => {
    return Axios.put(`/account/password`, {
      oldPassword,
      newPassword,
    });
  },

  // Sends an email to the user with a link/token to reinitialize his password
  forgotPassword: (login: string) => {
    return Axios.post(`/account/forgotpassword`, { login });
  },
};
