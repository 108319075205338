import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import areaService from '@services/area.service';
import { AreaDto } from '@models/dto/response/Area.dto';
import {
  CreateAreaParams,
  CreateSurfaceParams,
  EditSurfaceParams,
} from '@models/dto/request/AreaParams.dto';
import { SurfaceDto } from '@models/dto/response/Surface.dto';

// Define a type for the slice state
interface AreaState {
  areas: AreaDto[];
  selectedArea?: AreaDto;
  selectedSurfaces?: SurfaceDto[];
  selectedSurfaceId?: number;
}

// Define the initial state using that type
const initialState: AreaState = {
  areas: [],
};

// REQUESTS
export const getAreas = createAsyncThunk<AxiosResponse<AreaDto[]>, number>(
  'area/getAll',
  async (establishmentId: number) => {
    const response = await areaService.getAreas(establishmentId);
    return response.data;
  },
);

export const addAreas = createAsyncThunk<AxiosResponse<AreaDto>, CreateAreaParams>(
  'area/create',
  async (params: CreateAreaParams, { rejectWithValue }) => {
    try {
      const response = await areaService.createArea(params.establishmentId, params.name);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const addSurface = createAsyncThunk<AxiosResponse<SurfaceDto>, CreateSurfaceParams>(
  'surface/create',
  async (params: CreateSurfaceParams, { rejectWithValue }) => {
    try {
      const response = await areaService.createSurface(
        params.areaId,
        params.name,
        params.frequencyJson,
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const editSurface = createAsyncThunk<AxiosResponse<SurfaceDto>, EditSurfaceParams>(
  'surface/edit',
  async (params: EditSurfaceParams, { rejectWithValue }) => {
    try {
      const response = await areaService.updateSurface(
        params.surfaceId,
        params.name,
        params.frequencyJson,
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const deleteArea = createAsyncThunk<AxiosResponse<void>, number>(
  'area/delete',
  async (areaId: number, { rejectWithValue }) => {
    try {
      const response = await areaService.deleteArea(areaId);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const deleteSurface = createAsyncThunk<AxiosResponse<void>, number>(
  'surface/delete',
  async (surfaceId: number, { rejectWithValue }) => {
    try {
      const response = await areaService.deleteSurface(surfaceId);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const getSurfaces = createAsyncThunk<AxiosResponse<SurfaceDto[]>, number>(
  'surface/getAll',
  async (areaId: number) => {
    const response = await areaService.getSurfaces(areaId);
    return response.data;
  },
);

export const AreaSlice = createSlice({
  name: 'area',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    selectArea: (state, action: PayloadAction<AreaDto | undefined>) => {
      state.selectedArea = action.payload;
    },
    selectSurfaces: (state, action: PayloadAction<SurfaceDto[] | undefined>) => {
      state.selectedSurfaces = action.payload;
    },
    selectSurfaceId: (state, action: PayloadAction<number | undefined>) => {
      state.selectedSurfaceId = action.payload;
    },
  },
  extraReducers: (builder) => {
    // Side effects
    // Get area list
    builder.addCase(getAreas.fulfilled, (state, action) => {
      state.areas = action.payload.data ?? [];
    });
    // Add area
    builder.addCase(addAreas.fulfilled, (state, action) => {
      state.areas.push(action.payload.data);
    });
    // Add surface
    builder.addCase(addSurface.fulfilled, (state, action) => {
      state.areas
        .find((area: AreaDto) => area.id === action.meta.arg.areaId)
        ?.surfaces.push(action.payload.data);
    });
    // Update surface
    builder.addCase(editSurface.fulfilled, (state, action) => {
      state.areas = state.areas.map((area: AreaDto) => {
        const surfaces = [...area.surfaces];
        const surfaceIndex = surfaces.findIndex(
          (surface) => surface.id === action.meta.arg.surfaceId,
        );
        surfaces[surfaceIndex] = action.payload.data;
        return {
          ...area,
          surfaces,
        };
      });
    });
    // Delete surface
    builder.addCase(deleteSurface.fulfilled, (state, action) => {
      state.areas = state.areas.map((area: AreaDto) => {
        return {
          ...area,
          surfaces: area.surfaces.filter((surface: SurfaceDto) => surface.id !== action.meta.arg),
        };
      });
    });
    // Delete area
    builder.addCase(deleteArea.fulfilled, (state, action) => {
      state.areas = state.areas.filter((area: AreaDto) => area.id !== action.meta.arg);
    });
  },
});

export const { selectArea, selectSurfaces, selectSurfaceId } = AreaSlice.actions;

export default AreaSlice.reducer;
