import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import {
  CreateProductTempReadingDeliveryDto,
  CreateProductTempReadingDto,
  CreateProductTempReadingServiceDto,
  UpdateProductTempReadingParams,
  UpdateProductTempReadingDeliveryParams,
  UpdateProductTempReadingServiceParams,
} from '@models/dto/request/ProductTempReading.dto';
import { OperationServiceResponseDto } from '@models/dto/response/OperationService.dto';
import { ProductTemperatureDataDto } from '@models/dto/response/ProductTemperatureData.dto';
import { OperationCoolingResponseDto } from '@models/dto/response/OperationCooling.dto';
import { OperationReheatingResponseDto } from '@models/dto/response/OperationReheating.dto';
import { OperationDeliveryResponseDto } from '@models/dto/response/OperationDelivery.dto';
import {
  UnfinishedProductTempReadingCoolingDto,
  UnfinishedProductTempReadingReheatingDto,
} from '@models/dto/response/UnfinishedProductTempReading.dto';
import OperationTempReadingTypeEnum from '@models/enums/OperationTempReadingType';
import productTemperatureService from '@services/product-temperature.service';

// Define a type for the slice state
interface ProductTemperatureState {
  productTempData?: ProductTemperatureDataDto;
  runningProductTempMeasure: (
    | UnfinishedProductTempReadingCoolingDto
    | UnfinishedProductTempReadingReheatingDto
  )[];
  selectedUnfinishedTempReading?:
    | UnfinishedProductTempReadingCoolingDto
    | UnfinishedProductTempReadingReheatingDto;
  operationTempReadingTypeSelected?: OperationTempReadingTypeEnum; // Used to preselect operation type coming from task
  selectedOperationType?: OperationTempReadingTypeEnum; // Used for keeping data between screen when navigating to dish list and come back to operation
  dishFamilySelected?: string; // Used to preselect operation type coming from task
}

// Define the initial state using that type
const initialState: ProductTemperatureState = {
  productTempData: undefined,
  runningProductTempMeasure: [],
};

// PRODUCT TEMP
export const getProductTempData = createAsyncThunk<ProductTemperatureDataDto, number>(
  'productTemp/productTempData',
  async (establishmentId: number) => {
    const response = await productTemperatureService.getProductTempData(establishmentId);
    return response.data.data;
  },
);

export const getRunningProductTemp = createAsyncThunk<
  (UnfinishedProductTempReadingCoolingDto | UnfinishedProductTempReadingReheatingDto)[],
  number
>('productTemp/getRunningProductTemp', async (establishmentId: number) => {
  const response = await productTemperatureService.getRunningProductTemp(establishmentId);
  return response.data.data;
});

// COOLING
export const createCoolingTempReading = createAsyncThunk<
  OperationCoolingResponseDto,
  CreateProductTempReadingDto
>('productTemp/cooling', async (params: CreateProductTempReadingDto, { rejectWithValue }) => {
  try {
    const result = await productTemperatureService.createProductTempCooling(params);
    return result.data.data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const updateCoolingTempReading = createAsyncThunk<
  OperationCoolingResponseDto,
  UpdateProductTempReadingParams
>(
  'productTemp/coolingUpdate',
  async (params: UpdateProductTempReadingParams, { rejectWithValue }) => {
    const { productTempReadingId, ...rest } = params;
    try {
      const result = await productTemperatureService.updateProductTempCooling(
        productTempReadingId,
        rest,
      );
      return result.data.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const deleteProductTempCooling = createAsyncThunk<boolean, number>(
  'productTemp/deleteCooling',
  async (id: number, { rejectWithValue }) => {
    try {
      const result = await productTemperatureService.deleteProductTempCooling(id);
      return result.data.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

// REHEATING
export const createReheatingTempReading = createAsyncThunk<
  OperationReheatingResponseDto,
  CreateProductTempReadingDto
>('productTemp/reheating', async (params: CreateProductTempReadingDto, { rejectWithValue }) => {
  try {
    const result = await productTemperatureService.createProductTempReheating(params);
    return result.data.data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const updateReheatingTempReading = createAsyncThunk<
  OperationReheatingResponseDto,
  UpdateProductTempReadingParams
>(
  'productTemp/reheatingUpdate',
  async (params: UpdateProductTempReadingParams, { rejectWithValue }) => {
    const { productTempReadingId, ...rest } = params;
    try {
      const result = await productTemperatureService.updateProductTempReheating(
        productTempReadingId,
        rest,
      );
      return result.data.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const deleteProductTempReheating = createAsyncThunk<boolean, number>(
  'productTemp/deleteReheating',
  async (id: number, { rejectWithValue }) => {
    try {
      const result = await productTemperatureService.deleteProductTempReheating(id);
      return result.data.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

// SERVICE
export const createServiceTempReading = createAsyncThunk<
  OperationServiceResponseDto,
  CreateProductTempReadingServiceDto
>(
  'productTemp/service',
  async (params: CreateProductTempReadingServiceDto, { rejectWithValue }) => {
    try {
      const result = await productTemperatureService.createProductTempService(params);
      return result.data.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const updateServiceTempReading = createAsyncThunk<
  OperationServiceResponseDto,
  UpdateProductTempReadingServiceParams
>(
  'productTemp/serviceUpdate',
  async (params: UpdateProductTempReadingServiceParams, { rejectWithValue }) => {
    const { productTempReadingId, ...rest } = params;
    try {
      const result = await productTemperatureService.updateProductTempService(
        productTempReadingId,
        rest,
      );
      return result.data.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

// DELIVERY
export const createDeliveryTempReading = createAsyncThunk<
  OperationDeliveryResponseDto,
  CreateProductTempReadingDeliveryDto
>(
  'productTemp/delivery',
  async (params: CreateProductTempReadingDeliveryDto, { rejectWithValue }) => {
    try {
      const result = await productTemperatureService.createProductTempDelivery(params);
      return result.data.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const updateDeliveryTempReading = createAsyncThunk<
  OperationDeliveryResponseDto,
  UpdateProductTempReadingDeliveryParams
>(
  'productTemp/deliveryUpdate',
  async (params: UpdateProductTempReadingDeliveryParams, { rejectWithValue }) => {
    const { productTempReadingId, ...rest } = params;
    try {
      const result = await productTemperatureService.updateProductTempDelivery(
        productTempReadingId,
        rest,
      );
      return result.data.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const deleteProductTempDelivery = createAsyncThunk<boolean, number>(
  'productTemp/deleteDelivery',
  async (id: number, { rejectWithValue }) => {
    try {
      const result = await productTemperatureService.deleteProductTempDelivery(id);
      return result.data.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const ProductTemperatureSlice = createSlice({
  name: 'productTemperature',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setSelectedUnfinishedTempReading: (
      state,
      action: PayloadAction<
        | UnfinishedProductTempReadingCoolingDto
        | UnfinishedProductTempReadingReheatingDto
        | undefined
      >,
    ) => {
      state.selectedUnfinishedTempReading = action.payload;
    },
    selectOperationProductType: (
      state,
      action: PayloadAction<OperationTempReadingTypeEnum | undefined>,
    ) => {
      state.operationTempReadingTypeSelected = action.payload;
    },
    selectDishFamilyType: (state, action: PayloadAction<string | undefined>) => {
      state.dishFamilySelected = action.payload;
    },
    setSelectedOperationType: (
      state,
      action: PayloadAction<OperationTempReadingTypeEnum | undefined>,
    ) => {
      state.selectedOperationType = action.payload;
    },
  },
  extraReducers: (builder) => {
    // Side effects
    // Get product temperature types
    builder.addCase(getProductTempData.fulfilled, (state, action) => {
      state.productTempData = action.payload;
    });
    // Get running product temperature measure
    builder.addCase(getRunningProductTemp.fulfilled, (state, action) => {
      state.runningProductTempMeasure = action.payload ?? [];
    });
    // Delete cooling
    builder.addCase(deleteProductTempCooling.fulfilled, (state, action) => {
      const index = state.runningProductTempMeasure.findIndex((el) => el.id === action.meta.arg);
      state.runningProductTempMeasure.splice(index, 1);
    });
    // Delete reheating
    builder.addCase(deleteProductTempReheating.fulfilled, (state, action) => {
      const index = state.runningProductTempMeasure.findIndex((el) => el.id === action.meta.arg);
      state.runningProductTempMeasure.splice(index, 1);
    });
    // Delete delivery
    builder.addCase(deleteProductTempDelivery.fulfilled, (state, action) => {
      const index = state.runningProductTempMeasure.findIndex((el) => el.id === action.meta.arg);
      state.runningProductTempMeasure.splice(index, 1);
    });
  },
});

export const {
  setSelectedUnfinishedTempReading,
  selectOperationProductType,
  setSelectedOperationType,
  selectDishFamilyType,
} = ProductTemperatureSlice.actions;

export default ProductTemperatureSlice.reducer;
