import { BASE_URL } from '@utils/constants';
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { history } from '@router/routes';
import Bugsnag from '@bugsnag/js';

/**
 * Est-ce qu'il s'agit d'une erreur réseau ?
 * @param {*} error
 */
function isNetworkError(error: AxiosError) {
  return !!error.isAxiosError && !error.response;
}

/**
 * Est-ce qu'il s'agit d'une erreur HTTP 401 Unauthorized ?
 * @param {*} error
 */
function isUnauthorizedError(error: AxiosError) {
  return error.isAxiosError && error.response && error.response.status === 401;
}

/**
 * Est-ce qu'il s'agit d'une erreur HTTP 403 Forbidden ?
 * @param {*} error
 */
function isForbiddenError(error: AxiosError) {
  return error.isAxiosError && error.response && error.response.status === 403;
}

export const clearSession = () => {
  // eslint-disable-next-line no-undef
  localStorage.clear();
};

/**
 * Handler de traitement des erreurs
 * @param {*} error
 */
export default async function errorHandler(error: AxiosError) {
  const originalRequest = error.config as AxiosRequestConfig;

  Bugsnag.notify({
    name: `Error ${error?.response?.status}`,
    message: JSON.stringify(error),
  });

  if (isNetworkError(error)) {
    // En cas de Network Error
  } else if (isForbiddenError(error) || isUnauthorizedError(error)) {
    // eslint-disable-next-line no-undef
    const refreshToken = localStorage.getItem('refreshToken');
    // Deconnexion ou refresh token
    if (refreshToken) {
      // TODO: find a solution to redirect or refresh token on 401
      try {
        const result = (await axios.post(`${BASE_URL}/account/token/refresh`, {
          refreshToken,
        })) as AxiosResponse;

        const newToken = result.data.data.token;
        const newRefreshToken = result.data.data.refreshToken;

        // eslint-disable-next-line no-undef
        localStorage.setItem('refreshToken', newRefreshToken);
        // eslint-disable-next-line no-undef
        localStorage.setItem('token', newToken);
        originalRequest.headers.Authorization = `Bearer ${newToken}`;
        return await axios(originalRequest);
      } catch (_error) {
        clearSession();
        if (history.location.pathname !== '/login') {
          history.push('/login');
        }
      }
    }
    // TRY to not logout user for any error from API.
    // else {
    // clearSession();
    // if (history.location.pathname !== '/login') {
    //   history.push('/login');
    // }
    // }
  }

  // affichage de l'erreur ds la console
  // eslint-disable-next-line no-console
  console.error(error);

  return Promise.reject(error);
}
