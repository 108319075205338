import React from 'react';
import i18next from 'i18next';
import { Card, Button, ListGroup, Image } from 'react-bootstrap';
import trashIcon from '@assets/images/svg/trash.svg';
import styles from './SettingsNotificationsCard.module.scss';

type Props = {
  title: string;
  text: string;
  emailList: string[];
  onAdd: () => void;
  onDelete: (email: string) => void;
};

const SettingsNotificationsCard = (props: Props) => {
  const { title, text, emailList, onAdd, onDelete } = props;
  return (
    <Card className={`h-100 mr-3 ${styles.cardContainer}`}>
      <Card.Body className={`d-flex flex-column justify-content-end ${styles.cardBodyBlue}`}>
        <Card.Title className={styles.cardTitle}>{title}</Card.Title>
        <Card.Text className={`${styles.cardText} mb-0`}>{text}</Card.Text>
        <Button className={`${styles.addReceiver} btnSm mt-4`} onClick={() => onAdd()}>
          {i18next.t('settings:notifications.cards.addReceiver')}
        </Button>
      </Card.Body>
      <Card.Body className={styles.cardBodyWhite}>
        <ListGroup variant="flush">
          {emailList.length > 0
            ? emailList.map((email: any, index: number) => (
                <ListGroup.Item
                  key={index}
                  className="d-flex flex-row justify-content-between border-bottom-0"
                >
                  <div>{email}</div>
                  <Image
                    src={trashIcon}
                    className={styles.trashIcon}
                    height={20}
                    width={20}
                    onClick={() => onDelete(email)}
                  />
                </ListGroup.Item>
              ))
            : i18next.t('settings:notifications.cards.empty')}
        </ListGroup>
      </Card.Body>
    </Card>
  );
};

export default SettingsNotificationsCard;
