import React from 'react';
import { Card, Image, ListGroup, OverlayTrigger, Popover } from 'react-bootstrap';
import { history } from '@router/routes';
import { FolderDto } from '@models/dto/response/Folder.dto';
import folderIcon from '@assets/images/svg/folder.svg';
import i18next from 'i18next';
import { format } from 'date-fns';

import styles from './FolderSettingsItem.module.scss';

type Props = {
  folder: FolderDto;
  isEditMode: boolean;
  setShowModalDelete?: () => void;
  children?: any;
};

const ContainerFolder = (props: Props) => {
  const { folder, setShowModalDelete } = props;

  const editZone = () => {
    history.push('/documents/settings/file', { folder });
  };

  const goInsideFolder = () => {
    history.push('/documents/file', { folder });
  };

  if (props.isEditMode) {
    return (
      <OverlayTrigger
        placement="bottom"
        trigger="click"
        rootClose
        overlay={
          <Popover id={`zone-popover-${folder.id}`}>
            <Popover.Content>
              <ListGroup>
                <ListGroup.Item className="border-0" onClick={editZone}>
                  {i18next.t('shared:buttons.edit')}
                </ListGroup.Item>
                <ListGroup.Item className="border-0" onClick={setShowModalDelete}>
                  {i18next.t('shared:buttons.delete')}
                </ListGroup.Item>
              </ListGroup>
            </Popover.Content>
          </Popover>
        }
      >
        <Card className={`h-100 mr-3 ${styles.cardContainer}`}>{props.children}</Card>
      </OverlayTrigger>
    );
  }
  return (
    <Card className={`h-100 mr-3 ${styles.cardContainer}`} onClick={goInsideFolder}>
      {props.children}
    </Card>
  );
};

const FolderSettingsItem = (props: Props) => {
  const { folder } = props;

  return (
    <ContainerFolder {...props}>
      <Card.Body className="d-flex flex-column align-items-center text-wrap text-center">
        <Image src={folderIcon} style={{ width: '60%' }} />
        <Card.Text className={styles.titleFolder}>{folder.name}</Card.Text>
        <Card.Text className={styles.infoDate}>{i18next.t('documents:folder.infoMAJ')}</Card.Text>
        <Card.Text className={styles.date}>
          {!folder.updateDate
            ? format(new Date(folder.creationDate), 'dd/MM/yyyy HH:mm')
            : format(new Date(folder.updateDate), 'dd/MM/yyyy HH:mm')}
        </Card.Text>
      </Card.Body>
    </ContainerFolder>
  );
};

export default FolderSettingsItem;
