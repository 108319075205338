import BaseNavbarWithHistory from '@components/shared/BaseNavbarWithHistory/BaseNavbarWithHistory';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { getRunningProductTemp } from '@store/reducers/product-temperature';
import i18next from 'i18next';
import React, { useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import ProductTempUnfinishedTable from '@components/ProductTempUnfinishedTable/ProductTempUnfinishedTable';

const ProductTempUnfinished = () => {
  const establishment = useAppSelector((state) => state.account.selectedEstablishment);
  const unfinishedMeasure = useAppSelector(
    (state) => state.productTemperature.runningProductTempMeasure,
  );
  const dispatch = useAppDispatch();

  useEffect(() => {
    // Get unfinish product temperature measure
    if ((!unfinishedMeasure || unfinishedMeasure.length === 0) && establishment) {
      dispatch(getRunningProductTemp(establishment.id));
    }
  }, []);

  return (
    <>
      {/* Navbar */}
      <BaseNavbarWithHistory
        title={i18next.t('productTemp:navbar.unfinishedTitle')}
        showSettings={false}
        showHistory={false}
      />
      <Container fluid className="cornerRound h-100">
        {unfinishedMeasure && unfinishedMeasure.length > 0 ? (
          <Row>
            <Col md={12} className="mb-5 px-0">
              <ProductTempUnfinishedTable unfinishedMeasure={unfinishedMeasure} />
            </Col>
          </Row>
        ) : (
          <div className="d-flex justify-content-center align-items-center h-100 tableHeader">
            {i18next.t('productTemp:unfinished.empty')}
          </div>
        )}
      </Container>
    </>
  );
};

export default ProductTempUnfinished;
