import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import operationService from '@services/operation.service';
import { OperationTypesDto } from '@models/dto/response/OperationTypes.dto';
import { AxiosResponse } from 'axios';
import {
  EquipmentTempReadingResponseDto,
  OperationEquipmentTemperatureReadingDto,
} from '@models/dto/response/OperationEquipmentTemperatureReading.dto';
import { OperationCleaningPlan } from '@models/dto/response/OperationCleaningPlan.dto';
import { CreateEquipmentTempReadingDto } from '@models/dto/request/CreateEquipmentTempReading.dto';
import { CreateCleaningPlan } from '@models/dto/request/CreateCleaningPlan.dto';
import {
  CreateOilMonitoringOperationDto,
  UpdateOilMonitoringOperationDto,
} from '@models/dto/request/CreateOilMonitoringOperation.dto';
import { CreateReceptionControlOperationDto } from '@models/dto/request/CreateReceptionControlOperation.dto';
import { OperationLabelPhotoDto } from '@models/dto/response/OperationLabelPhoto.dto';
import {
  CreateLabelPhotoOperationDto,
  UpdateLabelPhotoOperationDto,
} from '@models/dto/request/LabelPhotoOperation.dto';
import { OperationOilMonitoringDto } from '@models/dto/response/OperationOilMonitoring.dto';
import OilMonitoringActionTypeEnum from '@models/enums/OilMonitoringActionType';
import { UpdateEquipmentTempReadingParams } from '@models/dto/request/UpdateEquipmentTemp.dto';
import {
  InspectionUponReceiptResponseDto,
  OperationInspectionUponReceiptDto,
} from '@models/dto/response/OperationInspectionUponReceipt.dto';
import { UpdateReceptionControlOperationDto } from '@models/dto/request/UpdateReceptionControl.dto';

// Define a type for the slice state
interface OperationState {
  types: OperationTypesDto[];
}

// Define the initial state using that type
const initialState: OperationState = {
  types: [],
};

// REQUESTS
export const getOperationTypes = createAsyncThunk<OperationTypesDto[]>(
  'operation/types',
  async () => {
    const response = await operationService.getOperationTypes();
    return response.data.data;
  },
);

// EQUIPMENT TEMP
export const createEquipmentTempReading = createAsyncThunk<
  EquipmentTempReadingResponseDto,
  CreateEquipmentTempReadingDto
>(
  'operation/createTempReading',
  async (data: CreateEquipmentTempReadingDto, { rejectWithValue }) => {
    try {
      const result = await operationService.createEquipmentTemp(data);
      return result.data.data;
    } catch (err: any) {
      return rejectWithValue(err);
    }
  },
);

export const getEquipmentTempReading = createAsyncThunk<
  OperationEquipmentTemperatureReadingDto,
  number
>('operation/getTempReading', async (id: number, { rejectWithValue }) => {
  try {
    const result = await operationService.getEquipmentTemp(id);
    return result.data.data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const updateEquipmentTempReading = createAsyncThunk<
  EquipmentTempReadingResponseDto,
  UpdateEquipmentTempReadingParams
>(
  'operation/updateTempReading',
  async (data: UpdateEquipmentTempReadingParams, { rejectWithValue }) => {
    const { operationId, ...rest } = data;
    try {
      const result = await operationService.updateEquipmentTemp(operationId, rest);
      return result.data.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

// CLEANING
export const getCleaningPlan = createAsyncThunk<AxiosResponse<OperationCleaningPlan>, number>(
  'operation/getCleaningPlan',
  (id: number) => {
    return operationService.getCleaningPlan(id);
  },
);

export const createCleaningPlan = createAsyncThunk<
  AxiosResponse<OperationCleaningPlan>,
  CreateCleaningPlan
>('operation/createCleaningPlan', async (data: CreateCleaningPlan, { rejectWithValue }) => {
  try {
    const result = await operationService.createCleaningPlan(data);
    return result.data.data.operation;
  } catch (err) {
    return rejectWithValue(err);
  }
});

// OIL MONITORING
export const CreateOilMonitoringOperation = createAsyncThunk<
  AxiosResponse<OperationOilMonitoringDto>,
  CreateOilMonitoringOperationDto
>(
  'operation/createOilMonitoring',
  async (data: CreateOilMonitoringOperationDto, { rejectWithValue }) => {
    const formData = new FormData();
    if (data.picture) {
      formData.append('picture', data.picture);
    }
    formData.append('actionType', data.actionType ?? OilMonitoringActionTypeEnum.none);
    formData.append('deepFryerId', data.deepFryerId.toString() ?? '');
    formData.append('realisationDate', data.realisationDate ?? '');
    formData.append('signatoryId', data.signatoryId.toString() ?? '');
    try {
      const result = await operationService.createOilMonitoring(formData);
      return result.data.data.operation;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const updateOilMonitoringOperation = createAsyncThunk<
  AxiosResponse<OperationOilMonitoringDto>,
  UpdateOilMonitoringOperationDto
>('operation/updateOilMonitoring', async (data: UpdateOilMonitoringOperationDto) => {
  const formData = new FormData();
  formData.append('picture', data.picture);
  const result = await operationService.addPhotoToOilMonitoring(data.oilMonitoringId, formData);
  return result.data;
});

// RECEPTION CONTROL
export const CreateReceptionControlOperation = createAsyncThunk<
  InspectionUponReceiptResponseDto,
  CreateReceptionControlOperationDto
>(
  'operation/createReceptionControl',
  async (data: CreateReceptionControlOperationDto, { rejectWithValue }) => {
    const formData = new FormData();
    if (data.picture) {
      formData.append('picture', data.picture);
    }
    formData.append('signatoryId', data.signatoryId.toString());
    if (data.temperature) {
      formData.append('temperature', data.temperature?.toString());
    }
    formData.append('productSubFamilyId', data.productSubFamilyId.toString());
    formData.append('supplierId', data.supplierId.toString());
    formData.append('realisationDate', data.realisationDate);
    formData.append('nonCompliance', data.nonCompliance.toString());
    if (data.nonComplianceComment) {
      formData.append('nonComplianceComment', data.nonComplianceComment?.toString());
    }

    try {
      const result = await operationService.createInspectionUponReceipt(formData);
      return result.data.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const UpdateReceptionControlOperation = createAsyncThunk<
  InspectionUponReceiptResponseDto,
  UpdateReceptionControlOperationDto
>(
  'operation/updateReceptionControl',
  async (data: UpdateReceptionControlOperationDto, { rejectWithValue }) => {
    const formData = new FormData();
    if (data.picture) {
      formData.append('picture', data.picture);
    }

    try {
      const result = await operationService.addPhotoToInspectionUponReceipt(data.id, formData);
      return result.data.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const getReceptionControlOperation = createAsyncThunk<
  OperationInspectionUponReceiptDto,
  number
>('operation/getReceptionControl', async (id: number, { rejectWithValue }) => {
  try {
    const result = await operationService.getInspectionUponReceipt(id);
    return result.data.data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

// LABELS
export const createLabelPhotoOperation = createAsyncThunk<
  AxiosResponse<OperationLabelPhotoDto>,
  CreateLabelPhotoOperationDto
>('operation/createLabelPhoto', async (data: CreateLabelPhotoOperationDto) => {
  const formData = new FormData();
  formData.append('picture', data.picture);
  formData.append('signatoryId', data.signatoryId.toString() ?? '');
  formData.append('realisationDate', data.realisationDate ?? '');
  const result = await operationService.createLabelPhoto(formData);
  return result.data;
});

export const updateLabelPhotoOperation = createAsyncThunk<
  AxiosResponse<OperationLabelPhotoDto>,
  UpdateLabelPhotoOperationDto
>('operation/createLabelPhoto', async (data: UpdateLabelPhotoOperationDto) => {
  const formData = new FormData();
  formData.append('picture', data.picture);
  const result = await operationService.addPhotoToLabel(data.labelPhotoId, formData);
  return result.data;
});

export const OperationSlice = createSlice({
  name: 'operation',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Side effects
    // Get operation types
    builder.addCase(getOperationTypes.fulfilled, (state, action) => {
      state.types = action.payload ?? [];
    });
  },
});

export default OperationSlice.reducer;
