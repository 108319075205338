import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { UpdateServiceParametersParams } from '@models/dto/request/ServiceParametersParams.dto';
import { ServiceTemperatureParametersDto } from '@models/dto/response/ServiceTemperatureParameters.dto';
import serviceParametersService from '../../services/service-parameters.service';

// Define a type for the slice state
interface ServiceParametersState {
  serviceParameters?: ServiceTemperatureParametersDto;
}

// Define the initial state using that type
const initialState: ServiceParametersState = {
  serviceParameters: undefined,
};

// REQUESTS
export const getServiceParameters = createAsyncThunk<
  AxiosResponse<ServiceTemperatureParametersDto>,
  number
>('serviceParameters/get', async (establishmentId: number) => {
  const response = await serviceParametersService.getServiceParameters(establishmentId);
  return response.data;
});

export const updateServiceParameters = createAsyncThunk<
  AxiosResponse<ServiceTemperatureParametersDto>,
  UpdateServiceParametersParams
>('serviceParameters/update', async (params: UpdateServiceParametersParams) => {
  const { establishmentId, ...rest } = params;
  const response = await serviceParametersService.updateServiceParameters(establishmentId, rest);
  return response.data;
});

export const ServiceParametersSlice = createSlice({
  name: 'serviceParameters',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Side effects
    // Get service parameters
    builder.addCase(getServiceParameters.fulfilled, (state, action) => {
      state.serviceParameters = action.payload.data;
    });
    // Update service parameters
    builder.addCase(updateServiceParameters.fulfilled, (state, action) => {
      state.serviceParameters = action.payload.data;
    });
  },
});

// export const { } = ServiceParametersSlice.actions

export default ServiceParametersSlice.reducer;
