import React, { useState } from 'react';
import { Navbar, Container, Row, Col, Image, Form } from 'react-bootstrap';
import navbarLogo from '@assets/images/svg/logo-hygiap-white.svg';
import i18next from 'i18next';
import backButton from '@assets/images/svg/back.svg';
import { Link } from 'react-router-dom';
import BaseSaveButton from '@components/shared/BaseSaveButton/BaseSaveButton';
import { useAppDispatch } from '@store/hooks';
import { forgotPassword } from '@store/reducers/account';
import AccountTypeEnum from '@models/enums/AccountType';
import styles from './LoginForgotPassword.module.scss';

const LoginForgotPassword = () => {
  const [isAdmin, setIsAdmin] = useState<boolean | undefined>();
  const [login, setLogin] = useState<string>();
  const dispatch = useAppDispatch();

  const onValidForm = () => {
    if (login) {
      dispatch(forgotPassword(login))
        .unwrap()
        .then((res) => {
          const type = res.data as AccountTypeEnum;
          setIsAdmin(type === AccountTypeEnum.admin);
        });
    }
  };

  return (
    <>
      <Navbar className="navbarHygiap d-flex justify-content-center">
        <Navbar.Brand as={Link} to="/login" className={styles.backButton}>
          <Image src={backButton} width={23} height={20} />
        </Navbar.Brand>
        <Image src={navbarLogo} width={171} height={49} />
      </Navbar>
      <Container fluid className={`px-5 ${styles.container} cornerRound`}>
        {isAdmin === undefined ? (
          <>
            <h4 className={styles.title}>{i18next.t('login:forgot.title')}</h4>
            <Form>
              <Form.Group className="mt-5" controlId="login">
                <Form.Label className={styles.inputLabel}>{i18next.t('login:login')}</Form.Label>
                <Form.Control
                  type="text"
                  className="baseInputLg mt-3"
                  value={login}
                  onChange={(e) => setLogin(e.target.value)}
                />
              </Form.Group>
            </Form>
            <BaseSaveButton
              disabled={!login}
              onSave={onValidForm}
              text={i18next.t('login:forgot.button')}
            />
          </>
        ) : (
          <></>
        )}
        {isAdmin === true ? (
          <Row className="d-flex justify-content-center align-items-center h-100">
            <Col md={12}>
              <h4 className={styles.title}>{i18next.t('login:forgot.title')}</h4>
              <p className={styles.message}>{i18next.t('login:forgot.messageAdmin')}</p>
            </Col>
          </Row>
        ) : (
          <></>
        )}
        {isAdmin === false ? (
          <Row className="d-flex justify-content-center align-items-center h-100">
            <Col md={12}>
              <h4 className={styles.title}>{i18next.t('login:forgot.title')}</h4>
              <p className={styles.message}>
                {i18next.t('login:forgot.message')}
                <br />
                <br />
                {i18next.t('login:forgot.message-bis')}
              </p>
            </Col>
          </Row>
        ) : (
          <></>
        )}
      </Container>
    </>
  );
};

export default LoginForgotPassword;
