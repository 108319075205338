import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import profileService from '@services/profile.service';
import { ProfileParams } from '@models/dto/request/ProfileParams.dto';
import { ProfileDto } from '@models/dto/response/Account.dto';
import { AxiosResponse } from 'axios';
// Define a type for the slice state
interface ProfileState {
  profile?: ProfileDto;
}

// Define the initial state using that type
const initialState: ProfileState = {
  profile: undefined,
};

// REQUESTS
export const getProfile = createAsyncThunk<AxiosResponse<ProfileDto>>('profile/get', async () => {
  const response = await profileService.getProfile();
  return response.data;
});

export const updateProfile = createAsyncThunk<AxiosResponse<ProfileDto>, ProfileParams>(
  'profile/update',
  async (params: ProfileParams, { rejectWithValue }) => {
    try {
      const response = await profileService.editProfile(params.firstName, params.lastName);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const ProfileSlice = createSlice({
  name: 'profile',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Get profile
    builder.addCase(getProfile.fulfilled, (state, action) => {
      state.profile = action.payload.data;
    });
    builder.addCase(updateProfile.fulfilled, (state, action) => {
      state.profile = action.payload.data;
    });
  },
});

export default ProfileSlice.reducer;
