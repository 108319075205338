import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import supplierService from '@services/supplier.service';
import { SupplierDto } from '@models/dto/response/Supplier.dto';
import { CreateSupplierParams, EditSupplierParams } from '@models/dto/request/SupplierParams.dto';

// Define a type for the slice state
interface SupplierState {
  suppliers: SupplierDto[];
}

// Define the initial state using that type
const initialState: SupplierState = {
  suppliers: [],
};

// REQUESTS
export const getSuppliers = createAsyncThunk<AxiosResponse<SupplierDto[]>, number>(
  'supplier/getAll',
  async (establishmentId: number) => {
    const response = await supplierService.getSuppliers(establishmentId);
    return response.data;
  },
);

export const addSupplier = createAsyncThunk<AxiosResponse<SupplierDto>, CreateSupplierParams>(
  'supplier/add',
  async (params: CreateSupplierParams) => {
    const response = await supplierService.createSupplier(params.establishmentId, params.name);
    return response.data;
  },
);

export const editSupplier = createAsyncThunk<AxiosResponse<SupplierDto>, EditSupplierParams>(
  'supplier/edit',
  async (params: EditSupplierParams) => {
    const response = await supplierService.updateSupplier(params.supplierId, params.name);
    return response.data;
  },
);

export const deleteSupplier = createAsyncThunk<AxiosResponse<SupplierDto>, number>(
  'supplier/delete',
  async (supplierId: number) => {
    const response = await supplierService.deleteSupplier(supplierId);
    return response.data;
  },
);

export const SupplierSlice = createSlice({
  name: 'supplier',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Side effects
    // Get supplier list
    builder.addCase(getSuppliers.fulfilled, (state, action) => {
      state.suppliers = action.payload.data ?? [];
    });
    builder.addCase(addSupplier.fulfilled, (state, action) => {
      state.suppliers.push(action.payload.data);
    });
    builder.addCase(editSupplier.fulfilled, (state, action) => {
      state.suppliers = state.suppliers.filter(
        (sign: SupplierDto) => sign.id !== action.payload.data.id,
      );
      state.suppliers.push(action.payload.data);
    });
    builder.addCase(deleteSupplier.fulfilled, (state, action) => {
      state.suppliers = state.suppliers.filter((sign: SupplierDto) => sign.id !== action.meta.arg);
    });
  },
});

export default SupplierSlice.reducer;
