import React from 'react';
import i18next from 'i18next';
import { Col, Form, Image } from 'react-bootstrap';
import lessIcon from '@assets/images/svg/big-moins.svg';
import plusIcon from '@assets/images/svg/big-plus.svg';
import styles from './BaseDishesQuantitiesItem.module.scss';

type Props = {
  index: number;
  dish: [string, number];
  handleAddOne: (index: number) => void;
  handleRemoveOne: (index: number) => void;
};

const BaseDishesQuantitiesItem = (props: Props) => {
  const { index, dish, handleAddOne, handleRemoveOne } = props;
  return (
    <Col key={index} md={6} className="d-flex align-items-center">
      <Form.Group
        className="d-flex flex-column align-items-center text-nowrap"
        controlId="quantity"
      >
        <Form.Label className={`${styles.quantityLabel} mb-4`}>
          {i18next.t(`productTemp:settings.shipping.modal.quantities.${dish[0]}`)}
        </Form.Label>
        <div className="d-flex flex-row">
          <Image
            src={lessIcon}
            width={72}
            height={72}
            onClick={() => handleRemoveOne(index)}
            className={styles.icons}
          />
          <Form.Control
            type="number"
            custom
            className={`${styles.quantityValue} baseInputLg ml-3 text-center`}
            value={dish[1]}
            readOnly
          />
          <Image
            src={plusIcon}
            width={72}
            height={72}
            onClick={() => handleAddOne(index)}
            className={`${styles.icons} ml-3`}
          />
        </div>
      </Form.Group>
    </Col>
  );
};

export default BaseDishesQuantitiesItem;
