import ProductTempSettingsSubFamiliesModal from '@components/ProductTempSettingsSubFamiliesModal/ProductTempSettingsSubFamiliesModal';
import ProductTempSettingsDishesTypesTable from '@components/ProductTempSettingsDishesTypesTable/ProductTempSettingsDishesTypesTable';
import BaseNavbarWithHistory from '@components/shared/BaseNavbarWithHistory/BaseNavbarWithHistory';
import i18next from 'i18next';
import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Button, Image } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { toast } from 'react-toastify';
import { createDishSubFamily, getDishSubFamilies } from '@store/reducers/dish';
import { CreateDishSubFamilyParams } from '@models/dto/request/CreateDishSubFamilyParams.dto';
import { history } from '@router/routes';
import plusWhite from '@assets/images/svg/plus-rond.svg';
import BaseNavbar from '@components/shared/BaseNavbar/BaseNavbar';
import styles from './ProductTempSettingsDishesTypes.module.scss';

const ProductTempSettingsDishesTypes = () => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const location = useLocation<{ id: number; name: string }>();
  const selectedSubFamilies = useAppSelector((state) => state.dish.selectedDishSubFamilies);
  const accountType = useAppSelector((state) => state.account.type);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (location.state?.id) {
      dispatch(getDishSubFamilies(location.state.id)).unwrap();
    } else if (!location.state) {
      history.replace('/product-temperature/settings/dishes');
    }
  }, []);

  const openModal = () => {
    setShowModal(true);
  };

  const createNewDishSubFamily = (data: CreateDishSubFamilyParams) => {
    toast.promise(dispatch(createDishSubFamily(data)), {
      pending: i18next.t('productTemp:toasts.pending.createSubFamily'),
      success: i18next.t('productTemp:toasts.success.createSubFamily'),
      error: i18next.t('productTemp:toasts.error.createSubFamily'),
    });
  };

  return (
    <>
      <BaseNavbar accountType={accountType} parametersUrl="/settings" />
      <BaseNavbarWithHistory
        title={i18next.t('productTemp:settings.navbar.title')}
        showSettings={false}
        showHistory={false}
      />
      <Container fluid className="h-100 pt-5 cornerRound px-5">
        <Row>
          <Col md={12} className="d-flex justify-content-between align-items-center">
            <div className={styles.listTitle}>{location.state?.name ?? ''}</div>
            <Button
              className={`${styles.addButton} d-flex justify-content-center align-items-center btnXl`}
              onClick={openModal}
            >
              <Image src={plusWhite} width={24} height={24} className="mr-2" />
              {i18next.t('productTemp:settings.types.button')}
            </Button>
          </Col>
        </Row>
        {selectedSubFamilies && selectedSubFamilies.length > 0 ? (
          <ProductTempSettingsDishesTypesTable subFamilies={selectedSubFamilies} />
        ) : (
          <Row className="d-flex justify-content-center align-items-center h-100">
            <Col md={12} className="text-center tableHeader">
              {i18next.t('productTemp:settings.types.empty')}
            </Col>
          </Row>
        )}
        <ProductTempSettingsSubFamiliesModal
          showModal={showModal}
          setShowModal={setShowModal}
          dishFamilyId={location.state?.id}
          createNewDishSubFamily={createNewDishSubFamily}
        />
      </Container>
    </>
  );
};

export default ProductTempSettingsDishesTypes;
