import React, { useEffect, useState } from 'react';
import BaseNavbarWithHistory from '@components/shared/BaseNavbarWithHistory/BaseNavbarWithHistory';
import PrintingLabelsDates from '@components/PrintingLabelsDates/PrintingLabelsDates';
import BaseSaveButton from '@components/shared/BaseSaveButton/BaseSaveButton';
import BaseSignatory from '@components/shared/BaseSignatory/BaseSignatory';
import i18next from 'i18next';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { Col, Container, Form, Row, Image } from 'react-bootstrap';
import BaseDishesForm from '@components/shared/BaseDishesForm/BaseDishesForm';
import {
  getDishesFamily,
  setSelectedDishesList,
  setSelectedDishFamily as setSelectedDishFamilyAction,
  setSelectedDishSubFamily as setSelectedDishSubFamilyAction,
} from '@store/reducers/dish';
import { DishSubFamilyDto } from '@models/dto/response/DishSubFamily.dto';
import { DishFamilyDto } from '@models/dto/response/DishFamily.dto';
import { getSignatories } from '@store/reducers/signatory';
import { add, format } from 'date-fns';
import { SignatoryDto } from '@models/dto/response/Signatory.dto';
import { fr } from 'date-fns/locale';
import { toast } from 'react-toastify';
import { LabelDto } from '@models/dto/request/Label.dto';
import { getProductTempData } from '@store/reducers/product-temperature';
import lessIcon from '@assets/images/svg/big-moins.svg';
import plusIcon from '@assets/images/svg/big-plus.svg';
import styles from './PrintingLabels.module.scss';

const PrintingLabels = () => {
  const [dlc, setDlc] = useState<number>(3);
  const [openDate, setOpenDate] = useState<Date>(new Date());
  const [selectedSignatory, setSelectedSignatory] = useState<SignatoryDto>();
  const [labelQuantity, setLabelQuantity] = useState<number>(1);

  // Get data from store when navigating back from dishes list
  const dishFromListView = useAppSelector((state) => state.dish.selectedDish);
  const dishFamilyFromListView = useAppSelector((state) => state.dish.selectedDishFamily);
  const dishSuFamilyFromListView = useAppSelector((state) => state.dish.selectedDishSubFamily);
  const productTempData = useAppSelector((state) => state.productTemperature.productTempData);

  const [selectedDishFamily, setSelectedDishFamily] = useState<DishFamilyDto | undefined>(
    dishFamilyFromListView,
  );
  const [selectedDishSubFamily, setSelectedDishSubFamily] = useState<DishSubFamilyDto | undefined>(
    dishSuFamilyFromListView,
  );
  const [dishName, setDishName] = useState<string>(dishFromListView?.name ?? '');

  const establishmentId = useAppSelector((state) => state.account.selectedEstablishment?.id);
  const dishesFamily = useAppSelector((state) => state.dish.dishesFamily);
  const signatories = useAppSelector((state) => state.signatory.signatories);
  const dispatch = useAppDispatch();

  // load dishes family and signatories
  useEffect(() => {
    if ((!dishesFamily || dishesFamily.length === 0) && establishmentId) {
      dispatch(getDishesFamily(establishmentId)).unwrap();
    }
    if ((!signatories || signatories.length === 0) && establishmentId) {
      dispatch(getSignatories(establishmentId)).unwrap();
    }
    if (!productTempData && establishmentId) {
      dispatch(getProductTempData(establishmentId)).unwrap();
    }
  }, []);

  const resetForm = () => {
    dispatch(setSelectedDishFamilyAction(undefined));
    dispatch(setSelectedDishSubFamilyAction(undefined));
    dispatch(setSelectedDishesList([]));
  };

  const handleSave = () => {
    // Form check if completed
    if (!selectedDishFamily) {
      toast.warn(i18next.t('printingLabels:toasts.warn.noDishFamily'));
      return;
    }
    if (!selectedDishSubFamily) {
      toast.warn(i18next.t('printingLabels:toasts.warn.noDishType'));
      return;
    }
    if (!openDate) {
      toast.warn(i18next.t('printingLabels:toasts.warn.noOpenDate'));
      return;
    }
    if (!selectedSignatory) {
      toast.warn(i18next.t('printingLabels:toasts.warn.noSignatory'));
      return;
    }

    const day =
      dlc && dlc !== 0 ? format(add(openDate, { days: dlc }), 'cccccc', { locale: fr }) : '';

    const data = {
      title: dishName !== '' ? dishName : selectedDishSubFamily.name,
      date: format(openDate, 'dd/MM/yyyy'),
      dlc: dlc && dlc !== 0 ? format(add(openDate, { days: dlc }), 'dd/MM/yyyy') : '',
      author: `${selectedSignatory?.firstName} ${selectedSignatory?.lastName[0]}.`,
      day:
        day !== 'ma' && day !== 'me' ? day[0]?.toUpperCase() : `${day[0].toUpperCase()}${day[1]}`,
    } as LabelDto;
    if (data.dlc === '' || !data.dlc || dlc === 0) {
      // eslint-disable-next-line no-undef
      window.location.href = `hygiap://print/test?&title=${data.title}&open=${data.date}&author=${data.author}&quantity=${labelQuantity}`;
    } else {
      // eslint-disable-next-line no-undef
      window.location.href = `hygiap://print/test?letter=${data.day}&title=${data.title}&open=${data.date}&dlc=${data.dlc}&author=${data.author}&quantity=${labelQuantity}`;
    }
    resetForm();
  };

  return (
    <>
      {/* Navbar */}
      <BaseNavbarWithHistory
        title={i18next.t('printingLabels:navbar.title')}
        showSettings={false}
        showHistory={false}
      />
      <Container fluid className="cornerRound px-5">
        {/* Select dishes */}
        <Row className="mb-5">
          <Col md={12}>
            <BaseDishesForm
              selectedDishFamily={selectedDishFamily}
              selectedDishSubFamily={selectedDishSubFamily}
              setSelectedDishFamily={setSelectedDishFamily}
              setSelectedDishSubFamily={setSelectedDishSubFamily}
              dishFamilies={dishesFamily}
              dishName={dishName}
              setDishName={setDishName}
            />
          </Col>
        </Row>
        <hr />
        {/* Date picker */}
        <div className="my-5">
          <PrintingLabelsDates
            openDate={openDate}
            setOpenDate={setOpenDate}
            dlc={dlc}
            setDlc={setDlc}
          />
        </div>
        <hr />
        {/* Print */}
        <Row>
          <Col md={12} className="py-4">
            <Form.Group
              className="d-flex flex-row align-items-center text-nowrap"
              controlId="quantity"
            >
              <Form.Label className={`${styles.quantityLabel} mr-5`}>
                {i18next.t(`printingLabels:quantity`)}
              </Form.Label>
              <div className="d-flex flex-row">
                <Image
                  src={lessIcon}
                  width={72}
                  height={72}
                  onClick={() => setLabelQuantity(labelQuantity - 1)}
                  className={styles.icons}
                />
                <Form.Control
                  type="number"
                  autoComplete="off"
                  custom
                  className={`${styles.quantityValue} baseInputLg ml-3 text-center`}
                  value={labelQuantity}
                  inputMode="tel"
                  onChange={(e) => {
                    setLabelQuantity(parseInt(e.target.value, 10));
                  }}
                />
                <Image
                  src={plusIcon}
                  width={72}
                  height={72}
                  onClick={() => setLabelQuantity(labelQuantity + 1)}
                  className={`${styles.icons} ml-3`}
                />
              </div>
            </Form.Group>
          </Col>
        </Row>
        <hr />
        {/* Signatories */}
        <div className="pt-3">
          <BaseSignatory
            signatories={signatories}
            selectedSignatory={selectedSignatory}
            setSelectedSignatory={setSelectedSignatory}
          />
        </div>
        <BaseSaveButton onSave={handleSave} text={i18next.t('printingLabels:printButton')} />
      </Container>
    </>
  );
};

export default PrintingLabels;
