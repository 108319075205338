import React, { useEffect } from 'react';
import BaseNavbarWithHistory from '@components/shared/BaseNavbarWithHistory/BaseNavbarWithHistory';
import OperationsHistoryDetailsPhoto from '@components/OperationsHistoryDetailsPhoto/OperationsHistoryDetailsPhoto';
import i18next from 'i18next';
import { Container, Row, Col, ListGroup } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import { format } from 'date-fns';
import fr from 'date-fns/locale/fr';
import { getTitleOperation, hasOwnProperty } from '@utils/operationsUtils';
import OperationTypeEnum from '@models/enums/OperationType';
import { SurfaceDto } from '@models/dto/response/Surface.dto';
import { OperationCleaningPlan } from '@models/dto/response/OperationCleaningPlan.dto';
import { OperationEquipmentTemperatureReadingDto } from '@models/dto/response/OperationEquipmentTemperatureReading.dto';
import { OperationInspectionUponReceiptDto } from '@models/dto/response/OperationInspectionUponReceipt.dto';
import { OperationOilMonitoringDto } from '@models/dto/response/OperationOilMonitoring.dto';
import { OperationLabelPhotoDto } from '@models/dto/response/OperationLabelPhoto.dto';
import { OperationDeliveryDto } from '@models/dto/response/OperationDelivery.dto';
import { useAppDispatch } from '@store/hooks';
import { getNonCompliance } from '@store/reducers/non-compliance';
import { OperationCoolingDto } from '@models/dto/response/OperationCooling.dto';
import { OperationsListDto } from '@store/reducers/history';
import {
  CauseDto,
  CorrectiveActionDto,
  NonComplianceDto,
} from '@models/dto/response/NonCompliance.dto';

import styles from './OperationsHistoryDetails.module.scss';

const OperationsHistoryDetails = () => {
  const location = useLocation<{ operation: OperationsListDto }>();
  const { operation, nonCompliances } = location.state.operation;

  const dispatch = useAppDispatch();
  const nonCompliancesTreated = nonCompliances?.filter(
    (nc: NonComplianceDto) => nc.processed === true,
  );
  const nonCompliancesNotTreated = nonCompliances?.filter(
    (nc: NonComplianceDto) => nc.processed === false,
  );

  useEffect(() => {
    const op = operation as OperationCleaningPlan;
    if (op.nonCompliances && op.nonCompliances.length) {
      dispatch(
        getNonCompliance({
          id: parseInt((operation as OperationCleaningPlan).nonCompliances[0], 10),
          analysis: 0,
        }),
      );
    }
  }, []);

  const getDetailOperation = () => {
    switch (operation.type) {
      case OperationTypeEnum.cleaning:
        return (
          <>
            <div className={styles.titlePhoto}>
              {(operation as OperationCleaningPlan).area.name}
            </div>
            <Row className="my-5">
              {(operation as OperationCleaningPlan).surfaces.map(
                (surface: SurfaceDto, index: number) => (
                  <Col md={3} className="mb-5" key={index}>
                    <OperationsHistoryDetailsPhoto title={surface.name} />
                  </Col>
                ),
              )}
            </Row>
          </>
        );
      case OperationTypeEnum.equipment:
        return (
          <>
            <div className={styles.titlePhoto}>
              {i18next.t(`operationsHistory:details.title.${operation.type}`)}
            </div>
            <Row className="my-5">
              <Col md={3} className="mb-5">
                <OperationsHistoryDetailsPhoto
                  picture={(operation as OperationEquipmentTemperatureReadingDto).equipment.picture}
                  title={(operation as OperationEquipmentTemperatureReadingDto).equipment.name}
                  temperature={(operation as OperationEquipmentTemperatureReadingDto).temperature}
                />
              </Col>
            </Row>
          </>
        );
      case OperationTypeEnum.inspection:
        return (
          <>
            <div className={styles.titlePhoto} style={{ marginBottom: 25 }}>
              {`${(operation as OperationInspectionUponReceiptDto).productFamily.name}: ${
                (operation as OperationInspectionUponReceiptDto).productSubFamily.name
              }`}
            </div>
            <div className={styles.titlePhoto}>
              {i18next.t(`operationsHistory:details.title.${operation.type}`)}
            </div>
            <Row className="my-5">
              <Col md={3} className="mb-5">
                <OperationsHistoryDetailsPhoto
                  title={(operation as OperationInspectionUponReceiptDto).supplier.name}
                  temperature={(operation as OperationInspectionUponReceiptDto).temperature}
                />
              </Col>
            </Row>
            <Row className="my-5">
              {/* OLD Version */}
              {(operation as OperationInspectionUponReceiptDto).picture && (
                <OperationsHistoryDetailsPhoto
                  picture={(operation as OperationInspectionUponReceiptDto).picture}
                />
              )}
              {/* New Version: 1.5.4 and + */}
              {(operation as OperationInspectionUponReceiptDto).pictures?.map(
                (photo: string, index: number) => (
                  <Col md={3} className="mb-5" key={index}>
                    <OperationsHistoryDetailsPhoto picture={photo} />
                  </Col>
                ),
              )}
            </Row>
          </>
        );
      case OperationTypeEnum.oil:
        return (
          <>
            <div className={styles.titlePhoto} style={{ marginBottom: 25 }}>
              {i18next.t(
                `oilMonitoring:form.actions.${(operation as OperationOilMonitoringDto).actionType}`,
              )}
            </div>
            <div className={styles.titlePhoto}>
              {i18next.t(`operationsHistory:details.title.${operation.type}`)}
            </div>
            <Row className="my-5">
              <OperationsHistoryDetailsPhoto
                title={(operation as OperationOilMonitoringDto).deepFryer.name}
                picture={(operation as OperationOilMonitoringDto).deepFryer.picture}
              />
            </Row>
            <Row className="my-5">
              {(operation as OperationOilMonitoringDto).pictures.map(
                (photo: string, index: number) => (
                  <Col md={3} className="mb-5" key={index}>
                    <OperationsHistoryDetailsPhoto picture={photo} />
                  </Col>
                ),
              )}
            </Row>
          </>
        );
      case OperationTypeEnum.photo:
        if (
          (hasOwnProperty(operation, 'picture') && operation.picture) ||
          (hasOwnProperty(operation, 'pictures') &&
            operation.pictures &&
            operation.pictures?.length > 0)
        ) {
          return (
            <>
              <div className={styles.titlePhoto}>
                {i18next.t(`operationsHistory:details.title.${operation.type}`)}
              </div>
              <Row className="my-5">
                {(operation as OperationLabelPhotoDto).pictures.map(
                  (photo: string, index: number) => (
                    <Col md={3} className="mb-5" key={index}>
                      <OperationsHistoryDetailsPhoto picture={photo} />
                    </Col>
                  ),
                )}
              </Row>
            </>
          );
        }
        return <></>;
      case OperationTypeEnum.product:
        return (
          <>
            <div className={styles.titlePhoto}>
              {i18next.t(`operationsHistory:details.title.${operation.type}`)}
            </div>
            <Row className="my-5">
              <Col md={12} className="mb-5">
                {(operation as OperationDeliveryDto).dishName &&
                (operation as OperationDeliveryDto).dishName !== '' ? (
                  <p>{`${i18next.t('operationsHistory:dishesName')}${
                    (operation as OperationDeliveryDto).dishName
                  }`}</p>
                ) : (
                  <></>
                )}
                {(operation as OperationDeliveryDto).dishSubFamily &&
                (operation as OperationDeliveryDto).dishSubFamily.name ? (
                  <p>{`${i18next.t('operationsHistory:dishesTypeName')}${
                    (operation as OperationDeliveryDto).dishSubFamily.name
                  }`}</p>
                ) : (
                  <></>
                )}
                {(operation as any).dishSubFamilyDto && (operation as any).dishSubFamilyDto.name ? (
                  <p>{`${i18next.t('operationsHistory:dishesTypeName')}${
                    (operation as any).dishSubFamilyDto.name
                  }`}</p>
                ) : (
                  <></>
                )}
                {(operation as any).dishFamily && (operation as any).dishFamily.name ? (
                  <p>{`${i18next.t('operationsHistory:dishesFamilyName')}${
                    (operation as any).dishFamily.name
                  }`}</p>
                ) : (
                  <></>
                )}
                {(operation as OperationCoolingDto).duration
                  ? `Durée: ${new Date((operation as OperationCoolingDto).duration * 1000)
                      .toISOString()
                      .substr(11, 5)
                      .replace(':', 'h ')}m`
                  : ''}
                <Row>
                  {(operation as OperationInspectionUponReceiptDto).temperature ? (
                    <OperationsHistoryDetailsPhoto
                      title={i18next.t('operationsHistory:temperature')}
                      temperature={(operation as OperationInspectionUponReceiptDto).temperature}
                    />
                  ) : (
                    <></>
                  )}
                  {(operation as any).startTemperature ? (
                    <OperationsHistoryDetailsPhoto
                      title={i18next.t('operationsHistory:lastTemperature')}
                      temperature={(operation as any).startTemperature}
                    />
                  ) : (
                    <></>
                  )}
                  {(operation as OperationDeliveryDto).productionSiteExitTemperature ? (
                    <OperationsHistoryDetailsPhoto
                      title={i18next.t('shared:deliveryReading')}
                      temperature={
                        (operation as OperationDeliveryDto).productionSiteExitTemperature
                      }
                    />
                  ) : (
                    <></>
                  )}
                  {(operation as OperationDeliveryDto).deliveryTemperature ? (
                    <OperationsHistoryDetailsPhoto
                      title={i18next.t('shared:exitReading')}
                      temperature={(operation as OperationDeliveryDto).deliveryTemperature}
                    />
                  ) : (
                    <></>
                  )}
                  {(operation as any).endTemperature ? (
                    <OperationsHistoryDetailsPhoto
                      title={i18next.t('operationsHistory:finishMeasure')}
                      temperature={(operation as any).endTemperature}
                    />
                  ) : (
                    <></>
                  )}
                </Row>
              </Col>
            </Row>
          </>
        );
      case OperationTypeEnum.analysis:
        if (
          (hasOwnProperty(operation, 'picture') && operation.picture) ||
          (hasOwnProperty(operation, 'pictures') &&
            operation.pictures &&
            operation.pictures?.length > 0)
        ) {
          return (
            <>
              <Row className="my-5">
                {(operation as OperationLabelPhotoDto).pictures.map(
                  (photo: string, index: number) => (
                    <Col md={3} className="mb-5" key={index}>
                      <OperationsHistoryDetailsPhoto picture={photo} />
                    </Col>
                  ),
                )}
              </Row>
            </>
          );
        }
        return <></>;
      default:
        return null;
    }
  };
  return (
    <>
      {/* Navbar */}
      <BaseNavbarWithHistory
        title={getTitleOperation(operation.type)}
        showSettings={false}
        showHistory={false}
      />
      <Container fluid className="px-5 pt-5 cornerRound">
        <Row className="mb-5">
          <Col md={12} className="mb-3">
            <p className={styles.labels}>
              {i18next.t('operationsHistory:details.date')}{' '}
              <span className={styles.values}>{`${format(
                new Date(operation.userRealisationDate),
                'Pp',
                { locale: fr },
              )}`}</span>
            </p>
            <p className={styles.labels}>
              {i18next.t('operationsHistory:details.action')}{' '}
              <span className={styles.values}>
                {getTitleOperation(operation.type, (operation as OperationDeliveryDto)?.subType)}
              </span>
            </p>
            <p className={styles.labels}>
              {i18next.t('operationsHistory:details.signatory')}{' '}
              <span className={styles.values}>
                {`${operation.signatory.firstName} ${operation.signatory.lastName}`}
              </span>
            </p>
            {(operation as OperationDeliveryDto).customer?.name ? (
              <p className={styles.labels}>
                {i18next.t('shared:customer')}
                <span className={styles.values}>{`${
                  (operation as OperationDeliveryDto).customer.name
                }`}</span>
              </p>
            ) : null}
            {operation.type === OperationTypeEnum.inspection ? (
              <p className={styles.labels}>
                {'Statut: '}
                <span className={styles.values}>
                  {(operation as OperationInspectionUponReceiptDto).nonCompliances.length === 0 &&
                  (operation as OperationInspectionUponReceiptDto).nonCompliancesTreated === 0
                    ? i18next.t('shared:deliveryCompliant')
                    : i18next.t('shared:deliveryNotCompliant')}
                </span>
              </p>
            ) : (
              <></>
            )}
          </Col>
        </Row>
        {getDetailOperation()}
        {nonCompliances && nonCompliances.length ? (
          <Row>
            {nonCompliancesNotTreated && nonCompliancesNotTreated.length ? (
              <Col md={6} className="mb-3">
                <h5 className={`${styles.message} px-2`}>
                  {i18next.t('shared:nonComplianceNotTraited')}
                </h5>
                <ListGroup className="mb-5">
                  {nonCompliancesNotTreated.map((nonCompliance, index: number) => (
                    <ListGroup.Item
                      key={index}
                      className={styles.noProcessed}
                      style={{ cursor: 'pointer' }}
                    >
                      {/* Type */}
                      <span className={styles.operation}>
                        {i18next.t(
                          `operationsHandling:table.types.${nonCompliance.operation.type}`,
                        )}{' '}
                        :
                      </span>
                      <br />
                      {/* Nature */}
                      <span className={styles.nature}>{nonCompliance.nature}</span>
                      {/* Subject */}
                      <p className={`${styles.subject} mt-2`}>
                        <span className="font-weight-bold">
                          {i18next.t('shared:nonComplianceModal.subject')}
                        </span>{' '}
                        {nonCompliance.subject}
                      </p>
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              </Col>
            ) : null}
            {nonCompliancesTreated && nonCompliancesTreated.length ? (
              <Col md={6} className="mb-3">
                <h5 className={`${styles.message} px-2`}>
                  {i18next.t('shared:nonComplianceTraited')}
                </h5>
                <ListGroup className="mb-5">
                  {nonCompliancesTreated.map((nonCompliance, index: number) => (
                    <ListGroup.Item
                      key={index}
                      className={styles.processed}
                      style={{ cursor: 'pointer' }}
                    >
                      {/* Type */}
                      {nonCompliance.operation.type ? (
                        <>
                          <span className={styles.operation}>
                            {i18next.t(
                              `operationsHandling:table.types.${nonCompliance.operation.type}`,
                            )}{' '}
                            :
                          </span>
                          <br />
                        </>
                      ) : (
                        <></>
                      )}
                      {/* Nature */}
                      {operation.type === OperationTypeEnum.analysis ? (
                        <p className={`${styles.subject} mt-2`}>
                          <span className="font-weight-bold">
                            {i18next.t('shared:nonComplianceModal.subject')}
                          </span>{' '}
                          {nonCompliance.subject}
                        </p>
                      ) : (
                        <>
                          {/* Nature */}
                          <span className={styles.nature}>{nonCompliance.nature}</span>
                          {/* Subject */}
                          <p className={`${styles.subject} mt-2`}>
                            <span className="font-weight-bold">
                              {i18next.t('shared:nonComplianceModal.subject')}
                            </span>{' '}
                            {nonCompliance.subject}
                          </p>
                        </>
                      )}
                      {/* Cause */}
                      <p className={`${styles.subject} mt-2`}>
                        <span className="font-weight-bold">
                          {i18next.t('shared:nonComplianceModal.causes')}
                        </span>{' '}
                        {nonCompliance.causes.map((cause: CauseDto) => cause.label).join(', ')}
                      </p>
                      {/* Action corrective */}
                      <p className={`${styles.subject} mt-2`}>
                        <span className="font-weight-bold">
                          {i18next.t('shared:nonComplianceModal.correctiveActions')}
                        </span>{' '}
                        {nonCompliance.correctiveActions
                          .map((action: CorrectiveActionDto) => action.label)
                          .join(', ')}
                      </p>
                      {/* Commentaire */}
                      <p className={`${styles.subject} mt-2`}>
                        <span className="font-weight-bold">
                          {i18next.t('shared:nonComplianceModal.comment')}
                        </span>{' '}
                        {nonCompliance.comment ?? '--'}
                      </p>
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              </Col>
            ) : null}
          </Row>
        ) : (
          <></>
        )}
      </Container>
    </>
  );
};

export default OperationsHistoryDetails;
