import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import i18next from 'i18next';
import { Form } from 'react-bootstrap';
import { FrequencyJsonDto } from '@models/dto/FrequencyJson.dto';
import { DAYS_FREQUENCY, FREQUENCY_JSON } from '@utils/constants';
import { FormControlLabel, Checkbox } from '@material-ui/core';
import styles from './BaseWeekdayCheckboxes.module.scss';

export interface IDaysArray {
  day: string;
  checked: boolean;
}

type Props = {
  setFrequencyJson: Dispatch<SetStateAction<FrequencyJsonDto | undefined>>;
  frequencyJson?: FrequencyJsonDto;
  label?: string;
};

const BaseWeekdayCheckboxes = (props: Props) => {
  const { setFrequencyJson, frequencyJson, label } = props;
  const [selectedDays, setSelectedDays] = useState<IDaysArray[]>([]);

  // Create array of days to display checkbox
  const createDaysArray = () => {
    // Get default value
    let updatedDaysFrequency = { ...DAYS_FREQUENCY };
    if (frequencyJson) {
      // If frequency json already filled we get existing values (boolean)
      updatedDaysFrequency = {
        dayAll: frequencyJson.dayAll,
        dayMonday: frequencyJson.dayMonday,
        dayTuesday: frequencyJson.dayTuesday,
        dayWednesday: frequencyJson.dayWednesday,
        dayThursday: frequencyJson.dayThursday,
        dayFriday: frequencyJson.dayFriday,
        daySaturday: frequencyJson.daySaturday,
        daySunday: frequencyJson.daySunday,
      };
    }
    const result = Object.entries(updatedDaysFrequency).map((item) => ({
      day: item[0],
      checked: item[1],
    }));

    return result;
  };

  // Initialize checkbox
  useEffect(() => {
    const daysArray = createDaysArray();
    setSelectedDays(daysArray);
  }, [frequencyJson]);

  // Allow to change checkbox state to checked or unchecked and update frequencyJson in parent
  const handleCheckBoxDays = (index: number, checked: boolean, day: string) => {
    const result = [...selectedDays];
    let frequency = frequencyJson ? { ...frequencyJson } : { ...FREQUENCY_JSON };

    // To check all checkbox
    if (day === 'dayAll') {
      result.forEach((item: IDaysArray, idx: number) => {
        result[index] = { ...result[idx], checked };
        frequency = { ...frequency, [result[idx].day]: checked };
      });
      // To uncheck "dayAll" checkbox if not all checkboxes are checked
    } else if (result.find((x) => x.day === 'dayAll')?.checked === true && day !== 'dayAll') {
      const indexDayAll = result.findIndex((x) => x.day === 'dayAll');
      result[index] = { ...result[indexDayAll], checked: false };
      result[index] = { ...result[index], checked };
      frequency = { ...frequency, [day]: checked, dayAll: false };
      // To check "dayAll" checkbox if all other checkboxes are checked
    } else if (result.find((x) => x.day === 'dayAll')?.checked === false && day !== 'dayAll') {
      result[index] = { ...result[index], checked };
      // Check if all days are checked apart from dayAll
      if (result.filter((a) => a.day !== 'dayAll').every((el) => el.checked === true)) {
        const indexDayAll = result.findIndex((x) => x.day === 'dayAll');
        result[index] = { ...result[indexDayAll], checked: true };
        frequency = { ...frequency, [day]: checked, dayAll: true };
      } else {
        frequency = { ...frequency, [day]: checked };
      }
      // To check one checkbox
    } else {
      result[index] = { ...result[index], checked };
      frequency = { ...frequency, [day]: checked };
    }

    setFrequencyJson(frequency);
    setSelectedDays(result);
  };

  return (
    <Form.Group className="mt-5 d-flex flex-column" controlId="weekday">
      <Form.Label className={styles.inputLabel}>
        {label || i18next.t('productTemp:settings.shipping.modal.form.weekday')}
      </Form.Label>
      <div className="mt-4">
        {selectedDays &&
          selectedDays.length > 0 &&
          selectedDays.map((item, index) => (
            <FormControlLabel
              key={index}
              className={`${item.checked ? 'checked' : ''}`}
              control={
                <Checkbox
                  value={item.day}
                  className={`${item.checked ? 'checked' : ''}`}
                  checked={item.checked ?? false}
                  onChange={(e: any) => handleCheckBoxDays(index, e.target.checked, item.day)}
                />
              }
              label={i18next.t(`shared:daysShort.${item.day}`)}
            />
          ))}
      </div>
    </Form.Group>
  );
};

export default BaseWeekdayCheckboxes;
