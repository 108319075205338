enum OperationTypeEnum {
  equipment = 'operation_equipment_temp_reading',
  cleaning = 'operation_cleaning_plan',
  product = 'operation_product_temp_reading',
  inspection = 'operation_inspection_upon_receipt',
  photo = 'operation_label_photo',
  printing = 'operation_label_printing',
  oil = 'operation_oil_monitoring',
  document = 'operation_document_storage',
  analysis = 'operation_analysis',
}

export default OperationTypeEnum;
