import BaseNavbarWithHistory from '@components/shared/BaseNavbarWithHistory/BaseNavbarWithHistory';
import DashboardToday from '@components/DashboardToday/DashboardToday';
import OperationTodoItem from '@components/OperationTodoItem/OperationTodoItem';
import OperationDoneItem from '@components/OperationDoneItem/OperationDoneItem';
import i18next from 'i18next';
import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Spinner } from 'react-bootstrap';
import { format, add, set } from 'date-fns';
import OperationTypeEnum from '@models/enums/OperationType';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import {
  getOperationsTodayDone,
  getTaskTodo,
  OperationsListDto,
  resetOperations,
} from '@store/reducers/history';

import { TaskDto } from '@models/dto/response/Task.dto';
import {
  OperationTypeAllEnum,
  OperationTypeEnumExtended,
} from '@pages/OperationsHistory/OperationsHistory';
import FlatList from 'flatlist-react';
import TaskTypeEnum from '@models/enums/TaskType';
import styles from './OperationsToday.module.scss';

const OperationTypes = { ...OperationTypeEnum, ...OperationTypeAllEnum };

const OperationsToday = () => {
  // Store
  const establishmentId = useAppSelector((state) => state.account.selectedEstablishment?.id);
  const todoCompleted = useAppSelector((state) => state.history.todayOperationsDone);
  // Component state
  const [tasksCompleted, setTasksCompleted] = useState<OperationsListDto[]>([]);
  const [allTasksTodo, setAllTasksTodo] = useState<TaskDto[]>([]); // Group all data we have according all request made
  const [tasksTodo, setTasksTodo] = useState<TaskDto[]>([]); // Display tasks with filters
  const [categorySelected, setCategorySelected] = useState<OperationTypeEnumExtended>(
    OperationTypes.all,
  );
  // Set initial count to keep track of total todos when changing filters
  const [todosCount, setTodosCount] = useState<number>(0);
  const [todosEquipmentCount, setTodosEquipmentCount] = useState<number>(0);
  const [todosCleaningCount, setTodosCleaningCount] = useState<number>(0);
  const [todosProductCount, setTodosProductCount] = useState<number>(0);
  const dispatch = useAppDispatch();

  // Pagination of tasks to do
  const [page, setPage] = useState<number>(0);
  const [isLoading, setIsLoading] = useState(false);
  const [hasMoreTodoTask, setHasMoreTodoTask] = useState(false);
  const loadNextPage = () => {
    if (hasMoreTodoTask && !isLoading) {
      setIsLoading(true);
      setPage((currentPage: number) => currentPage + 1);
    }
  };

  useEffect(() => {
    if (establishmentId) {
      const today = set(new Date(), { hours: 0, minutes: 0, seconds: 0 });
      // Request to have task that have been done
      dispatch(
        getOperationsTodayDone({
          establishmentId,
          startDate: today,
          endDate: add(today, { days: 1 }),
        }),
      );

      // Requests to have todo tasks
      dispatch(getTaskTodo({ establishmentId, page, limit: 50 }))
        .unwrap()
        .then((res) => {
          if (res) {
            // Get all data according all request made for different pages
            const currentTasksTodo = tasksTodo.concat(res.data);
            setAllTasksTodo(currentTasksTodo);

            const counts = res.meta.amount || {};
            // Define if we need an other request or not, depends on if we have all data
            setHasMoreTodoTask(currentTasksTodo.length < counts.total);
            // Set total counts to show
            setTodosCount(counts.total);
            setTodosEquipmentCount(counts.equipment);
            setTodosCleaningCount(counts.cleaning);
            setTodosProductCount(counts.product);
          }
          setIsLoading(false);
        });
    }

    return () => {
      dispatch(resetOperations());
    };
  }, [page]);

  useEffect(() => {
    setTasksCompleted(
      todoCompleted.filter(
        (operation) =>
          operation.operation.completed &&
          (operation.operation.type === categorySelected ||
            categorySelected === OperationTypes.all),
      ),
    );

    // Applying filter when categorySelected change
    setTasksTodo(
      allTasksTodo.filter((task) => {
        switch (categorySelected) {
          case OperationTypes.cleaning:
            return !task.completed && task.taskType === TaskTypeEnum.cleaning;
          case OperationTypes.equipment:
            return !task.completed && task.taskType === TaskTypeEnum.equipmentReading;
          case OperationTypes.product:
            return (
              (!task.completed && task.taskType === TaskTypeEnum.productReadingDelivery) ||
              task.taskType === TaskTypeEnum.productReadingService
            );
          case OperationTypes.all:
          default:
            return !task.completed;
        }
      }),
    );
  }, [todoCompleted, allTasksTodo, categorySelected]);

  return (
    <>
      {/* Navbar */}
      <BaseNavbarWithHistory
        title={`${i18next.t('operationsToday:navbar.title')} - ${format(new Date(), 'dd/MM/YYY')}`}
        showSettings={false}
        showHistory={false}
      />
      <Container fluid className="cornerRound px-5">
        {/* Action type */}
        <Row className={`${styles.statisticsContainer} py-5`}>
          <Col md={12}>
            <DashboardToday
              categorySelected={categorySelected}
              setCategorySelected={setCategorySelected}
              allOperation={todosCount}
              equipmentCount={todosEquipmentCount}
              cleaningCount={todosCleaningCount}
              productCount={todosProductCount}
            />
          </Col>
        </Row>
        <Row className="my-5">
          {tasksCompleted.length || tasksTodo.length ? (
            <>
              <Col sm={5} md={5} className="mb-5 border-right">
                <h2 className={`${styles.title}`}>{i18next.t('operationsToday:operationDone')}</h2>
                {tasksCompleted.map((operation: OperationsListDto) => (
                  <OperationDoneItem
                    key={operation.operation.id + operation.operation.type}
                    data={operation.operation}
                  />
                ))}
                {!tasksCompleted.length && i18next.t('operationsToday:emptyDone')}
              </Col>
              <Col sm={7} md={7} className="mb-5 border-left">
                <h2 className={styles.title}>{i18next.t('operationsToday:operationTodo')}</h2>

                {/* We need this div with the height set, to define a scrollable container for the Flatlist */}
                {/* Without it, it loads all data at the beginning instead after end of scrolling */}
                {/* https://github.com/beforesemicolon/flatlist-react/issues/102 */}
                <div style={{ overflow: 'auto', height: '500px' }}>
                  <FlatList
                    list={tasksTodo}
                    renderItem={(task: TaskDto) => <OperationTodoItem key={task.id} task={task} />}
                    hasMoreItems={hasMoreTodoTask}
                    loadMoreItems={loadNextPage}
                    paginationLoadingIndicator={
                      <Spinner animation="border" role="status" variant="info">
                        <span className="sr-only">{i18next.t('shared:loading')}</span>
                      </Spinner>
                    }
                    paginationLoadingIndicatorPosition="center"
                  />
                </div>

                {!tasksTodo.length && i18next.t('operationsToday:emptyTodo')}
              </Col>
            </>
          ) : (
            <span className="tableHeader">{i18next.t('operationsToday:empty')}</span>
          )}
        </Row>
      </Container>
    </>
  );
};

export default OperationsToday;
