import React, { Dispatch, SetStateAction, useState } from 'react';
import i18next from 'i18next';
import { Modal, Form } from 'react-bootstrap';
import { DishDto } from '@models/dto/response/Dish.dto';
import { toast } from 'react-toastify';
import BaseModalHeader from '@components/shared/BaseModalHeader/BaseModalHeader';
import BaseModalFooter from '@components/shared/BaseModalFooter/BaseModalFooter';
import styles from './ProductTempSettingsDishesModal.module.scss';

type Props = {
  showModal: boolean;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  selectedDish?: DishDto;
  isEdition: boolean;
  editDish: (id: number, name: string) => void;
  addDish: (name: string) => void;
};

const ProductTempSettingsDishesModal = (props: Props) => {
  const { showModal, setShowModal, selectedDish, isEdition, editDish, addDish } = props;
  const [name, setName] = useState<string>(selectedDish?.name ?? '');

  const handleValidate = () => {
    if (!name) {
      toast.warn(i18next.t('productTemp:settings.toasts.warn.addDish'));
    }
    if (isEdition && selectedDish) {
      editDish(selectedDish.id, name);
    } else {
      addDish(name);
    }
    setShowModal(false);
  };
  return (
    <Modal
      show={showModal}
      onHide={() => setShowModal(false)}
      aria-labelledby="settings-dishes-modal"
      centered
    >
      <BaseModalHeader
        id="settings-dishes-modal"
        title={i18next.t('productTemp:settings.dishes.modal.title')}
      />
      <Modal.Body>
        {/* Name */}
        <Form.Group className="d-flex flex-row align-items-center mt-5" controlId="name">
          <Form.Label className={styles.inputLabel}>
            {i18next.t('productTemp:settings.types.modal.form.name')}
          </Form.Label>
          <Form.Control
            type="text"
            autoComplete="off"
            className="baseInputLgWithFont ml-3"
            value={name}
            onChange={(e) => {
              setName(e.target.value);
            }}
          />
        </Form.Group>
      </Modal.Body>
      <BaseModalFooter handleValidate={handleValidate} setShowModal={setShowModal} />
    </Modal>
  );
};

export default ProductTempSettingsDishesModal;
