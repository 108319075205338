import BaseNavbarWithHistory from '@components/shared/BaseNavbarWithHistory/BaseNavbarWithHistory';
import i18next from 'i18next';
import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Spinner } from 'react-bootstrap';
import OperationsHistoryFilters from '@components/OperationsHistoryFilters/OperationsHistoryFilters';
import OperationsHistoryList from '@components/OperationsHistoryList/OperationsHistoryList';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import {
  getOperations,
  resetHasMoreOperation,
  resetHistory,
  setStartDateValue,
  setEndDateValue,
  setPageValue,
  setOperationTypeValue,
  setNonCompliantValue,
} from '@store/reducers/history';
import OperationTypeEnum from '@models/enums/OperationType';
import BaseSaveButton from '@components/shared/BaseSaveButton/BaseSaveButton';
import { useLocation } from 'react-router-dom';
import OperationTempReadingTypeEnum from '@models/enums/OperationTempReadingType';
import historyService from '@services/history.service';
import { formatISO } from 'date-fns';
import { getHasNonComplianceParam } from '@utils/operationsUtils';
import styles from './OperationsHistory.module.scss';

export enum OperationTypeAllEnum {
  all = 'all',
}

export type OperationTypeEnumExtended =
  | OperationTypeEnum
  | OperationTypeAllEnum
  | OperationTempReadingTypeEnum;

const OperationTypes = {
  ...OperationTypeAllEnum,
  ...OperationTypeEnum,
  ...OperationTempReadingTypeEnum,
};

const OperationsHistory = () => {
  // Store
  const establishmentId = useAppSelector((state) => state.account.selectedEstablishment?.id);
  const operations = useAppSelector((state) => state.history.operations);
  const hasMoreOperation = useAppSelector((state) => state.history.hasMoreOperation);
  const startDateValue = useAppSelector((state) => state.history.startDate);
  const endDateValue = useAppSelector((state) => state.history.endDate);
  const pageValue = useAppSelector((state) => state.history.page);
  const operationTypeValue = useAppSelector((state) => state.history.operationType);
  const isLoading = useAppSelector((state) => state.history.isLoading);
  const hasNonComplianceValue = useAppSelector((state) => state.history.hasNonCompliance);
  const dispatch = useAppDispatch();
  // Component state
  const [isReady, setIsReady] = useState<boolean>(false);
  const [startDate, setStartDate] = useState<Date>();
  const [endDate, setEndDate] = useState<Date>();
  const [page, setPage] = useState<number>(0);
  const location = useLocation<{ typeOperation: OperationTypeEnum }>();
  const fromOperation = location.state?.typeOperation ?? undefined;
  const [operationType, setOperationType] = useState<OperationTypeEnumExtended | undefined>(
    fromOperation,
  );
  const [hasNonCompliance, setHasNonCompliance] = useState<number>(hasNonComplianceValue);

  useEffect(() => {
    dispatch(setStartDateValue(startDate));
    dispatch(setEndDateValue(endDate));
    dispatch(setPageValue(page));
    dispatch(setOperationTypeValue(operationType));
    dispatch(setNonCompliantValue(hasNonCompliance));
  }, [startDate, endDate, page, operationType, hasNonCompliance]);

  // Fot infinite scroll loading
  const getListOperations = () => {
    if (establishmentId && !isLoading) {
      dispatch(
        getOperations({
          establishmentId,
          startDate: startDate
            ? new Date(`${formatISO(startDate, { representation: 'date' })}T02:00:00Z`)
            : undefined,
          endDate: endDate
            ? new Date(`${formatISO(endDate, { representation: 'date' })}T21:59:00Z`)
            : undefined,
          operationType: operationType === OperationTypeAllEnum.all ? undefined : operationType,
          page,
          hasNonCompliance: getHasNonComplianceParam(hasNonCompliance),
        }),
      );
    }
  };

  const exportListOperations = async () => {
    if (establishmentId) {
      await historyService.getExportOperations(establishmentId, {
        establishmentId,
        startDate: startDate
          ? new Date(`${formatISO(startDate, { representation: 'date' })}T01:00:00Z`)
          : undefined,
        endDate: endDate
          ? new Date(`${formatISO(endDate, { representation: 'date' })}T22:59:00Z`)
          : undefined,
        operationType: operationType === OperationTypeAllEnum.all ? undefined : operationType,
        hasNonCompliance: getHasNonComplianceParam(hasNonCompliance),
      });
    }
  };

  const loadNextPage = () => {
    if (hasMoreOperation && !isLoading) {
      setPage((currentPage: number) => currentPage + 1);
    }
  };

  const resetFilters = () => {
    setStartDateValue(undefined);
    setStartDate(undefined);
    setEndDateValue(undefined);
    setEndDate(undefined);
    setOperationType(OperationTypes.all);
    setPage(0);
    setHasNonCompliance(0);
    dispatch(resetHistory());
    dispatch(resetHasMoreOperation());
    if (establishmentId && !isLoading) {
      dispatch(
        getOperations({
          establishmentId,
          page: 0,
        }),
      );
    }
  };

  // When you hit button "filters"
  const applyFilters = () => {
    if (establishmentId && !isLoading) {
      dispatch(resetHasMoreOperation());
      setPage(0);
      dispatch(
        getOperations({
          establishmentId,
          startDate: startDate
            ? new Date(`${formatISO(startDate, { representation: 'date' })}T02:00:00Z`)
            : undefined,
          endDate: endDate
            ? new Date(`${formatISO(endDate, { representation: 'date' })}T21:59:00Z`)
            : undefined,
          operationType: operationType === OperationTypeAllEnum.all ? undefined : operationType,
          page: 0,
          hasNonCompliance: getHasNonComplianceParam(hasNonCompliance),
        }),
      );
    }
  };

  useEffect(() => {
    if (hasMoreOperation && isReady && !isLoading) {
      getListOperations();
    }
  }, [page, location, isReady]);

  useEffect(() => {
    Promise.all([
      setHasNonCompliance(hasNonComplianceValue),
      setStartDate(startDateValue),
      setEndDate(endDateValue),
      setPage(pageValue),
      setOperationType(operationTypeValue),
    ]).then(() => setTimeout(() => setIsReady(true), 1000));
  }, []);

  return (
    <>
      {/* Navbar */}
      <BaseNavbarWithHistory
        title={i18next.t('operationsHistory:navbar.title')}
        showSettings={false}
        showHistory={false}
        onNavigate={() => {
          resetFilters();
        }}
      />
      <Container fluid className="px-md-5 px-3 pt-md-5 pt-3 pb-5 mb-5 cornerRound">
        <Row>
          <Col md={12}>
            <OperationsHistoryFilters
              applyFilters={applyFilters}
              operationType={operationType}
              setOperationType={setOperationType}
              startDate={startDate}
              setStartDate={setStartDate}
              endDate={endDate}
              setEndDate={setEndDate}
              resetFilters={resetFilters}
              operationTypes={OperationTypes}
              hasNonCompliance={hasNonCompliance}
              setHasNonCompliance={setHasNonCompliance}
            />
          </Col>
        </Row>
        <Row className="my-5">
          <Col md={12}>
            {!isReady || isLoading ? (
              <Spinner animation="border" role="status" variant="info">
                <span className="sr-only">{i18next.t('shared:loading')}</span>
              </Spinner>
            ) : null}
            {operations?.length > 0 ? (
              <OperationsHistoryList
                operations={operations}
                loadMore={loadNextPage}
                isLoading={isLoading}
              />
            ) : (
              <span className="tableHeader">{i18next.t('operationsHistory:empty')}</span>
            )}
          </Col>
        </Row>
        <div className={styles.containerFixed}>
          <BaseSaveButton
            onSave={exportListOperations}
            text={i18next.t('shared:buttons.export')}
            hoverText={i18next.t('operationsHistory:filters.warningButton')}
          />
        </div>
      </Container>
    </>
  );
};

export default OperationsHistory;
