import React, { useState, Dispatch, SetStateAction, useRef, useEffect } from 'react';
import i18next from 'i18next';
import { format } from 'date-fns';
import { Button, Spinner, Table } from 'react-bootstrap';
import { NonComplianceDto } from '@models/dto/response/NonCompliance.dto';
import { NonComplianceAnalysisDto } from '@models/dto/response/NonComplianceAnalysis.dto';
import { history } from '@router/routes';
import { useAppDispatch } from '@store/hooks';
import NonComplianceTypeEnum from '@models/enums/NonComplianceType';
import { setNonCompliance, setNonComplianceAnalysis } from '@store/reducers/non-compliance';
import OperationsHandlingDeleteModal from '@components/OperationsHandlingDeleteModal/OperationsHandlingDeleteModal';
import { deleteNonCompliance } from '@store/reducers/history';
import { toast } from 'react-toastify';
import { getTypeNonCompliance, hasOwnProperty } from '@utils/operationsUtils';
import styles from './OperationsHandlingTable.module.scss';

export enum NonComplianceOperation {
  analysis = 'analysis',
  operationnal = 'operationnal',
}

type Props = {
  operations: (NonComplianceDto | NonComplianceAnalysisDto)[];
  loadNextPage: () => void;
  isLoading: boolean;
};

// Display row for a non compliance
const NonComplianceTableRow = (
  index: number,
  nonCompliantOperation: NonComplianceDto,
  solve: (
    type: NonComplianceOperation,
    nonCompliantOperation: NonComplianceDto | NonComplianceAnalysisDto,
  ) => void,
  setShowModal: Dispatch<SetStateAction<boolean>>,
  setSelectedNonComplianceId: Dispatch<SetStateAction<number | undefined>>,
  setSelectedOperationType: Dispatch<SetStateAction<NonComplianceOperation | undefined>>,
) => {
  const handleClick = () => {
    setSelectedNonComplianceId(nonCompliantOperation.id);
    setSelectedOperationType(NonComplianceOperation.operationnal);
    setShowModal(true);
  };
  return (
    <tr key={index} className="py-5">
      <td className="pl-5">
        <div className={styles.operationDate}>
          {nonCompliantOperation.operation.userCreationDate
            ? format(new Date(nonCompliantOperation.operation.userCreationDate), 'dd/MM/yyyy')
            : ''}{' '}
          -{' '}
          {nonCompliantOperation.operation.userCreationDate
            ? format(new Date(nonCompliantOperation.operation.userCreationDate), 'HH:mm')
            : ''}
        </div>
        <div className={styles.operationType}>
          {getTypeNonCompliance(
            nonCompliantOperation.noncomplianceType,
            nonCompliantOperation.operation.type,
          )}
        </div>
      </td>
      <td className={styles.subject}>{nonCompliantOperation.subject}</td>
      <td className={styles.action}>{nonCompliantOperation.nature}</td>
      <td className="pr-5">
        <Button
          className={`${styles.fixButton} btnMd d-flex align-items-center w-100 justify-content-center`}
          onClick={() => solve(NonComplianceOperation.operationnal, nonCompliantOperation)}
        >
          {i18next.t('shared:buttons.handle')}
        </Button>
        <Button
          className={`${styles.editButton} btnMd d-flex align-items-center mt-3 w-100 justify-content-center`}
          onClick={handleClick}
        >
          {i18next.t('shared:buttons.delete')}
        </Button>
      </td>
    </tr>
  );
};

// Display row for a non compliance analysis
const NonComplianceAnalysisTableRow = (
  index: number,
  nonCompliantOperation: NonComplianceAnalysisDto,
  solve: (
    type: NonComplianceOperation,
    nonCompliantOperation: NonComplianceDto | NonComplianceAnalysisDto,
  ) => void,
  setShowModal: Dispatch<SetStateAction<boolean>>,
  setSelectedNonComplianceId: Dispatch<SetStateAction<number | undefined>>,
  setSelectedOperationType: Dispatch<SetStateAction<NonComplianceOperation | undefined>>,
) => {
  const handleClick = () => {
    setSelectedNonComplianceId(nonCompliantOperation.id);
    setSelectedOperationType(NonComplianceOperation.analysis);
    setShowModal(true);
  };

  return (
    <tr key={index} className="py-5">
      <td className="pl-5">
        <div className={styles.operationDate}>
          {format(new Date(nonCompliantOperation.creationDate), 'dd/MM/yyyy')} -{' '}
          {format(new Date(nonCompliantOperation.creationDate), 'HH:mm')}
        </div>
        <div className={styles.operationType}>
          {nonCompliantOperation.analysisTypology
            ? i18next.t(
                `operationsHandling:analysis.types.${nonCompliantOperation.analysisTypology}`,
              )
            : i18next.t(`operationsHandling:analysis.navbar.title`)}
        </div>
      </td>
      <td className={styles.subject}>
        {nonCompliantOperation.causes.map((el) => el.label).join(',')}
      </td>
      <td className={styles.action}> </td>
      <td className="pr-5">
        <Button
          className={`${styles.fixButton} btnMd d-flex align-items-center w-100 justify-content-center`}
          onClick={() => solve(NonComplianceOperation.analysis, nonCompliantOperation)}
        >
          {i18next.t('shared:buttons.handle')}
        </Button>
        <Button
          className={`${styles.editButton} btnMd d-flex align-items-center mt-3 w-100 justify-content-center`}
          onClick={handleClick}
        >
          {i18next.t('shared:buttons.delete')}
        </Button>
      </td>
    </tr>
  );
};

const OperationsHandlingTable = (props: Props) => {
  const dispatch = useAppDispatch();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [selectedNonComplianceId, setSelectedNonComplianceId] = useState<number>();
  const [selectedOperationType, setSelectedOperationType] = useState<NonComplianceOperation>();

  // Update operation
  const handleDeleteOperation = (type: NonComplianceOperation, id: number) => {
    // If analysis we go to edit screen
    toast.promise(dispatch(deleteNonCompliance(id)).unwrap(), {
      pending: i18next.t('operationsHandling:toasts.pending.deleteNonCompliance'),
      success: i18next.t('operationsHandling:toasts.success.deleteNonCompliance'),
      error: i18next.t('operationsHandling:toasts.error.deleteNonCompliance'),
    });
  };

  const handleSolveTask = (
    type: NonComplianceOperation,
    nonCompliantOperation: NonComplianceDto | NonComplianceAnalysisDto,
  ) => {
    // If analysis we go to solve analysis screen
    if (type === NonComplianceOperation.analysis) {
      dispatch(setNonComplianceAnalysis(nonCompliantOperation as NonComplianceAnalysisDto));
      history.push('/operations-handling/analysis/solve');
      // If operationnal we go to solve non compliance screen
    } else if (type === NonComplianceOperation.operationnal) {
      dispatch(setNonCompliance(nonCompliantOperation as NonComplianceDto));
      history.push('/operations-handling/solve');
    }
  };

  const loader = useRef(null);

  const handleObserver = (entities: any) => {
    const target = entities[0];
    if (target.isIntersecting) {
      props.loadNextPage();
    }
  };

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 1.0,
    };

    // initialize IntersectionObserver
    // and attaching to Load More div
    const observer = new IntersectionObserver(handleObserver, options);
    if (loader.current) {
      observer.observe(loader.current);
    }
  }, []);

  return (
    <Table responsive className="mt-5 border-top-0">
      <tbody>
        {props.operations.map(
          (operation: NonComplianceDto | NonComplianceAnalysisDto, index: number) => {
            if (
              (operation as NonComplianceDto).noncomplianceType ===
                NonComplianceTypeEnum.analysis_operation ||
              hasOwnProperty(operation, 'analysisTypology')
            ) {
              return NonComplianceAnalysisTableRow(
                index,
                operation as NonComplianceAnalysisDto,
                handleSolveTask,
                setShowModal,
                setSelectedNonComplianceId,
                setSelectedOperationType,
              );
            }
            return NonComplianceTableRow(
              index,
              operation as NonComplianceDto,
              handleSolveTask,
              setShowModal,
              setSelectedNonComplianceId,
              setSelectedOperationType,
            );
          },
        )}
        <tr ref={loader} onClick={props.loadNextPage}>
          {props.isLoading && (
            <td>
              <Spinner animation="border" role="status" variant="info">
                <span className="sr-only">{i18next.t('shared:loading')}</span>
              </Spinner>
            </td>
          )}
        </tr>
      </tbody>
      {selectedNonComplianceId && selectedOperationType && (
        <OperationsHandlingDeleteModal
          showModal={showModal}
          setShowModal={setShowModal}
          handleDelete={() => handleDeleteOperation(selectedOperationType, selectedNonComplianceId)}
        />
      )}
    </Table>
  );
};

export default OperationsHandlingTable;
