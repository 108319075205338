import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import i18next from 'i18next';
import { Button, Form } from 'react-bootstrap';
import {
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
} from '@material-ui/core';
import {
  CauseDto,
  CorrectiveActionDto,
  NonComplianceDto,
} from '@models/dto/response/NonCompliance.dto';
import { NonComplianceAnalysisDto } from '@models/dto/response/NonComplianceAnalysis.dto';
import styles from './BaseNonComplianceForm.module.scss';

type Props = {
  causes: CauseDto[];
  correctiveActions: CorrectiveActionDto[];
  selectedCausesIds: string[];
  setSelectedCausesIds: Dispatch<SetStateAction<string[]>>;
  selectedCorrectiveActionsIds: string[];
  setSelectedCorrectiveActionsIds: Dispatch<SetStateAction<string[]>>;
  comment: string;
  setComment: Dispatch<SetStateAction<string>>;
  nonCompliantOperation?: NonComplianceDto | NonComplianceAnalysisDto;
};

const BaseNonComplianceForm = (props: Props) => {
  const {
    causes,
    selectedCausesIds,
    correctiveActions,
    selectedCorrectiveActionsIds,
    setSelectedCorrectiveActionsIds,
    setSelectedCausesIds,
    comment,
    setComment,
    nonCompliantOperation,
  } = props;

  const [correctiveActionsName, setCorrectiveActionsName] = useState<string[]>([]);
  const [causesName, setCausesName] = useState<string[]>([]);
  const [causesSelectOpen, setCausesSelectOpen] = useState<boolean>(false);
  const [correctiveActionsOpen, setCorrectiveActionsOpen] = useState<boolean>(false);

  useEffect(() => {
    if (nonCompliantOperation) {
      // Get label for selected corrective actions
      setCorrectiveActionsName(nonCompliantOperation.correctiveActions.map((item) => item.label));
      // Get label for selected causes
      setCausesName(nonCompliantOperation.causes.map((item) => item.label));
      // setComment(nonCompliantOperation.comment)
    }
  }, [nonCompliantOperation]);

  const handleChangeSelectAction = (value: string[]) => {
    if (value && value.length && value[value.length - 1] === undefined) {
      return;
    }
    // value is array with json so we parse it to get an object
    // also we take the last value from this array ex: [already entered value, last value added]
    const action: CorrectiveActionDto = JSON.parse(
      value[value.length ? value.length - 1 : value.length],
    );

    // Delete occurence if already in the array
    if (
      selectedCorrectiveActionsIds.length > 0 &&
      selectedCorrectiveActionsIds.indexOf(action.id.toString()) !== -1 &&
      action.active
    ) {
      setCorrectiveActionsName((x) => x.filter((item) => item !== action.label));
      setSelectedCorrectiveActionsIds((x) => x.filter((item) => item !== action.id.toString()));
      // Otherwise add to names list (for display) and ids list (for request)
    } else {
      setCorrectiveActionsName([...correctiveActionsName, action.label]);
      setSelectedCorrectiveActionsIds([...selectedCorrectiveActionsIds, action.id.toString()]);
    }
  };

  const handleChangeSelectCause = (value: string[]) => {
    if (value && value.length && value[value.length - 1] === undefined) {
      return;
    }
    // value is array with json so we parse it to get an object
    // also we take the last value from this array ex: [already entered value, last value added]
    const cause: CauseDto = JSON.parse(value[value.length - 1]);

    // Delete occurence if already in the array
    if (
      selectedCausesIds.length > 0 &&
      selectedCausesIds.indexOf(cause.id.toString()) !== -1 &&
      cause.active
    ) {
      setCausesName((x) => x.filter((item) => item !== cause.label));
      setSelectedCausesIds((x) => x.filter((item) => item !== cause.id.toString()));
      // Otherwise add to names list (for display) and ids list (for request)
    } else {
      setCausesName([...causesName, cause.label]);
      setSelectedCausesIds([...selectedCausesIds, cause.id.toString()]);
    }
  };

  return (
    <Form className="d-flex flex-column">
      {/* Causes */}
      <Form.Group className="mt-5" controlId="causes">
        <FormControl className="w-100">
          <InputLabel id="causes-select" className={styles.inputsLabel}>
            {i18next.t('operationsHandling:analysis.form.cause')}
          </InputLabel>
          <Select
            labelId="causes-select"
            value={causesName}
            open={causesSelectOpen}
            onClick={(e: any) => {
              if (!e.target.value) {
                setCausesSelectOpen(!causesSelectOpen);
              }
            }}
            onChange={(e) => {
              handleChangeSelectCause(e.target.value as string[]);
            }}
            displayEmpty
            inputProps={{
              className: `${
                selectedCausesIds.length === 0
                  ? styles.selectInputEmpty
                  : `${styles.selectInputFilled} mt-3`
              }`,
            }}
            multiple
            renderValue={(selected) => (selected as string[]).join(', ')}
          >
            <MenuItem value="" disabled>
              {i18next.t('operationsHandling:analysis.form.causePlaceholder')}
            </MenuItem>
            {causes
              .filter((x) => x.active)
              .map((cause: CauseDto) => (
                <MenuItem key={cause.id} value={JSON.stringify(cause)}>
                  <Checkbox
                    className="checkBoxCustom"
                    checked={selectedCausesIds.indexOf(cause.id.toString()) !== -1}
                  />
                  <ListItemText primary={cause.label} />
                </MenuItem>
              ))}
            <Button
              className={`btnMd d-flex align-items-center my-3 ${styles.validateButton}`}
              onClick={() => setCausesSelectOpen(false)}
            >
              {i18next.t('shared:buttons.validate')}
            </Button>
          </Select>
        </FormControl>
      </Form.Group>
      {/* Corrective action */}
      <Form.Group className="mt-5" controlId="correctiveActions">
        <FormControl className="w-100">
          <InputLabel id="actions-select" className={styles.inputsLabel}>
            {i18next.t('operationsHandling:analysis.form.correctiveAction')}
          </InputLabel>
          <Select
            value={correctiveActionsName}
            open={correctiveActionsOpen}
            onClick={(e: any) => {
              if (!e.target.value) {
                setCorrectiveActionsOpen(!correctiveActionsOpen);
              }
            }}
            onChange={(e) => {
              handleChangeSelectAction(e.target.value as string[]);
            }}
            displayEmpty
            inputProps={{
              className: `${
                selectedCorrectiveActionsIds.length === 0
                  ? styles.selectInputEmpty
                  : `${styles.selectInputFilled} mt-3`
              }`,
            }}
            multiple
            renderValue={(selected) => (selected as string[]).join(', ')}
          >
            <MenuItem value="" disabled>
              {i18next.t('operationsHandling:analysis.form.actionPlaceholder')}
            </MenuItem>
            {correctiveActions
              .filter((x) => x.active)
              .map((action: CorrectiveActionDto) => (
                <MenuItem key={action.id} value={JSON.stringify(action)}>
                  <Checkbox
                    className="checkBoxCustom"
                    checked={selectedCorrectiveActionsIds.indexOf(action.id.toString()) !== -1}
                  />
                  <ListItemText primary={action.label} />
                </MenuItem>
              ))}
            <Button
              className={`btnMd d-flex align-items-center my-3 ${styles.validateButton}`}
              onClick={() => setCorrectiveActionsOpen(false)}
            >
              {i18next.t('shared:buttons.validate')}
            </Button>
          </Select>
        </FormControl>
      </Form.Group>
      {/* comment */}
      <Form.Group className="mt-5" controlId="comment">
        <Form.Label className={styles.inputsLabel}>
          {i18next.t('operationsHandling:analysis.form.comment')}{' '}
        </Form.Label>
        <Form.Control
          as="textarea"
          type="text"
          autoComplete="off"
          value={comment ?? ''}
          className={`${styles.textarea} pl-5 mt-4`}
          placeholder={i18next.t('operationsHandling:analysis.form.commentPlaceholder')}
          onChange={(e) => setComment(e.target.value)}
        />
      </Form.Group>
    </Form>
  );
};

export default BaseNonComplianceForm;
