import React from 'react';
import { Card, Col, Image, Row } from 'react-bootstrap';
// SVG
import tempDownIcon from '@assets/images/svg/temp-down.svg';
import tempUpIcon from '@assets/images/svg/temp-up.svg';
import tempShippingIcon from '@assets/images/svg/temp-livraison.svg';
import tempServiceIcon from '@assets/images/svg/temp-service.svg';

import { SubTempOperationsDto } from '@models/dto/response/ProductTemperatureData.dto';
import OperationTempReadingTypeEnum from '@models/enums/OperationTempReadingType';
import { setSelectedOperationType } from '@store/reducers/product-temperature';
import { useAppDispatch } from '@store/hooks';
import styles from './ProductTempItem.module.scss';

type Props = {
  action: SubTempOperationsDto;
  isSelected: boolean;
  disableSelection: boolean;
};

const ProductTempItem = (props: Props) => {
  const { action, isSelected, disableSelection } = props;
  const dispatch = useAppDispatch();
  const getIcon = (icon: OperationTempReadingTypeEnum) => {
    switch (icon) {
      case OperationTempReadingTypeEnum.cooling:
        return tempDownIcon;
      case OperationTempReadingTypeEnum.reheating:
        return tempUpIcon;
      case OperationTempReadingTypeEnum.delivery:
        return tempShippingIcon;
      case OperationTempReadingTypeEnum.service:
        return tempServiceIcon;
      default:
        return '';
    }
  };

  const handleSelect = () => {
    if (!disableSelection) {
      dispatch(setSelectedOperationType(action.type));
    }
  };

  return (
    <div className={`${isSelected ? styles.highlightContainer : undefined} h-100`}>
      <Card
        style={{ backgroundColor: action.color }}
        className={`h-100 ${styles.cardText} ${isSelected ? styles.active : ''}`}
        onClick={handleSelect}
      >
        <Card.Body>
          <Row>
            <Col
              md={12}
              className="d-flex justify-content-center align-items-center flex-column px-0"
            >
              <Card.Title>
                <Image src={getIcon(action.type)} height={60} fluid />
              </Card.Title>
              <Card.Text className={styles.cardText}>{action.name}</Card.Text>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </div>
  );
};

export default ProductTempItem;
