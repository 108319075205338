import Cleaning from '@pages/Cleaning/Cleaning';
import Dashboard from '@pages/Dashboard/Dashboard';
import HardwareTemp from '@pages/HardwareTemp/HardwareTemp';
import ProductTemp from '@pages/ProductTemp/ProductTemp';
import HardwareTempSettings from '@pages/HardwareTempSettings/HardwareTempSettings';
import ProductTempSettings from '@pages/ProductTempSettings/ProductTempSettings';
import CleaningZoneSettings from '@pages/CleaningZoneSettings/CleaningZoneSettings';
import CleaningSurfaceSettings from '@pages/CleaningSurfaceSettings/CleaningSurfaceSettings';
import ProductTempSettingsDishes from '@pages/ProductTempSettingsDishes/ProductTempSettingsDishes';
import ProductTempSettingsDishesTypes from '@pages/ProductTempSettingsDishesTypes/ProductTempSettingsDishesTypes';
import ProductTempSettingsShipping from '@pages/ProductTempSettingsShipping/ProductTempSettingsShipping';
import ReceptionControl from '@pages/ReceptionControl/ReceptionControl';
import ReceptionControlSettings from '@pages/ReceptionControlSettings/ReceptionControlSettings';
import OilMonitoring from '@pages/OilMonitoring/OilMonitoring';
import OilMonitoringSettings from '@pages/OilMonitoringSettings/OilMonitoringSettings';
import PrintingLabels from '@pages/PrintingLabels/PrintingLabels';
import PhotosLabels from '@pages/PhotosLabels/PhotosLabels';
import Login from '@pages/Login/Login';
import LoginForgotPassword from '@pages/LoginForgotPassword/LoginForgotPassword';
import OperationsHistory from '@pages/OperationsHistory/OperationsHistory';
import Settings from '@pages/Settings/Settings';
import SettingsCalendarExceptions from '@pages/SettingsCalendarExceptions/SettingsCalendarExceptions';
import SettingsFrequencies from '@pages/SettingsFrequencies/SettingsFrequencies';
import SettingsSignatories from '@pages/SettingsSignatories/SettingsSignatories';
import SettingsNotifications from '@pages/SettingsNotifications/SettingsNotifications';
import OperationsHistoryDetails from '@pages/OperationsHistoryDetails/OperationsHistoryDetails';
import OperationsDelayed from '@pages/OperationsDelayed/OperationsDelayed';
import OperationsHandling from '@pages/OperationsHandling/OperationsHandling';
import OperationsToday from '@pages/OperationsToday/OperationsToday';
import Documents from '@pages/Documents/Documents';
import DocumentsFile from '@pages/DocumentsFile/DocumentsFile';
import DocumentsSettings from '@pages/DocumentsSettings/DocumentsSettings';
import DocumentsFileSettings from '@pages/DocumentsFileSettings/DocumentsFileSettings';
import { createBrowserHistory } from 'history';
import HardwareTempListView from '@pages/HardwareTempListView/HardwareTempListView';
import CleaningListView from '@pages/CleaningListView/CleaningListView';
import OilMonitoringListView from '@pages/OilMonitoringListView/OilMonitoringListView';
import ProductTempSettingsService from '@pages/ProductTempSettingsService/ProductTempSettingsService';
import SelectDishView from '@pages/SelectDishView/SelectDishView';
import CreateNonCompliantAnalysis from '@pages/CreateNonCompliantAnalysis/CreateNonCompliantAnalysis';
import SolveNonCompliantTask from '@pages/SolveNonCompliantTask/SolveNonCompliantTask';
import SolveNonCompliantAnalysis from '@pages/SolveNonCompliantAnalysis/SolveNonCompliantAnalysis';
import Account from '@pages/Account/Account';
import ProductTempUnfinished from '@pages/ProductTempUnfinished/ProductTempUnfinished';
import ProductTempUnfinishedSolve from '@pages/ProductTempUnfinishedSolve/ProductTempUnfinishedSolve';

export const history = createBrowserHistory();

const routes: any[] = [
  // LOGIN
  {
    path: '/login/forgot-password',
    component: LoginForgotPassword,
  },
  {
    path: '/login',
    component: Login,
  },
  // DASHBOARD
  {
    path: '/dashboard',
    component: Dashboard,
  },
  // SETTINGS
  {
    path: '/settings/notifications',
    component: SettingsNotifications,
  },
  {
    path: '/settings/signatories',
    component: SettingsSignatories,
  },
  {
    path: '/settings/frequencies',
    component: SettingsFrequencies,
  },
  {
    path: '/settings/calendar-exceptions',
    component: SettingsCalendarExceptions,
  },
  {
    path: '/settings',
    component: Settings,
  },
  // OPERATIONS
  {
    path: '/operations-today',
    component: OperationsToday,
  },
  {
    path: '/operations-handling/analysis/solve',
    component: SolveNonCompliantAnalysis,
  },
  {
    path: '/operations-handling/analysis',
    component: CreateNonCompliantAnalysis,
  },
  {
    path: '/operations-handling/solve',
    component: SolveNonCompliantTask,
  },
  {
    path: '/operations-handling',
    component: OperationsHandling,
  },
  {
    path: '/operations-delayed',
    component: OperationsDelayed,
  },
  {
    path: '/operations-history/details',
    component: OperationsHistoryDetails,
  },
  {
    path: '/operations-history',
    component: OperationsHistory,
  },
  // CLEANING
  {
    path: '/cleaning/settings/surfaces',
    component: CleaningSurfaceSettings,
  },
  {
    path: '/cleaning/settings',
    component: CleaningZoneSettings,
  },
  {
    path: '/cleaning/list',
    component: CleaningListView,
  },
  {
    path: '/cleaning',
    component: Cleaning,
  },
  // HARDWARE / EQUIPMENT
  {
    path: '/hardware-temperature/settings',
    component: HardwareTempSettings,
  },
  {
    path: '/hardware-temperature/list',
    component: HardwareTempListView,
  },
  {
    path: '/hardware-temperature',
    component: HardwareTemp,
  },
  // PRODUCT TEMP
  {
    path: '/product-temperature/settings/dishes/types',
    component: ProductTempSettingsDishesTypes,
  },
  {
    path: '/product-temperature/settings/dishes',
    component: ProductTempSettingsDishes,
  },
  {
    path: '/product-temperature/settings/shipping',
    component: ProductTempSettingsShipping,
  },
  {
    path: '/product-temperature/settings/service',
    component: ProductTempSettingsService,
  },
  {
    path: '/product-temperature/settings',
    component: ProductTempSettings,
  },
  {
    path: '/product-temperature/unfinished/solve',
    component: ProductTempUnfinishedSolve,
  },
  {
    path: '/product-temperature/unfinished',
    component: ProductTempUnfinished,
  },
  {
    path: '/product-temperature',
    component: ProductTemp,
  },
  // RECEPTION CONTROL
  {
    path: '/reception-control/settings',
    component: ReceptionControlSettings,
  },
  {
    path: '/reception-control',
    component: ReceptionControl,
  },
  // OIL MONITORING
  {
    path: '/oil-monitoring/settings',
    component: OilMonitoringSettings,
  },
  {
    path: '/oil-monitoring/list',
    component: OilMonitoringListView,
  },
  {
    path: '/oil-monitoring',
    component: OilMonitoring,
  },
  // LABELS
  {
    path: '/printing-labels',
    component: PrintingLabels,
  },
  {
    path: '/photos-labels',
    component: PhotosLabels,
  },
  // ACCOUNT
  {
    path: '/account',
    component: Account,
  },
  // DOCUMENTS
  {
    path: '/documents/settings/file',
    component: DocumentsFileSettings,
  },
  {
    path: '/documents/settings',
    component: DocumentsSettings,
  },
  {
    path: '/documents/file',
    component: DocumentsFile,
  },
  {
    path: '/documents',
    component: Documents,
  },
  // OTHERS
  {
    path: '/select-dish',
    component: SelectDishView,
  },
];

export default routes;
