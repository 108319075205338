import SettingsNotificationsCard from '@components/SettingsNotificationsCard/SettingsNotificationsCard';
import BaseNavbarWithHistory from '@components/shared/BaseNavbarWithHistory/BaseNavbarWithHistory';
import i18next from 'i18next';
import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import SettingsNotificationsAddModal from '@components/SettingsNotificationsAddModal/SettingsNotificationsAddModal';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { RootState } from '@store/index';
import {
  addDailyNotification,
  addWeeklyNotification,
  removeDailyNotification,
  removeWeeklyNotification,
  getEmails,
} from '@store/reducers/notifications';
import BaseNavbar from '@components/shared/BaseNavbar/BaseNavbar';
import styles from './SettingsNotifications.module.scss';

const SettingsNotifications = () => {
  const dispatch = useAppDispatch();
  const [showAddModal, setShowAddModal] = useState<boolean>(false);
  const [isDailyModal, setIsDailyModal] = useState<boolean>(true);
  const emailListDaily = useAppSelector((state: RootState) => state.notification.emailListDaily);
  const emailListWeekly = useAppSelector((state: RootState) => state.notification.emailListWeekly);
  const establishmentId = useAppSelector(
    (state: RootState) => state.account.selectedEstablishment?.id,
  );
  const accountType = useAppSelector((state) => state.account.type);

  useEffect(() => {
    if (establishmentId && emailListDaily.length === 0 && emailListWeekly.length === 0) {
      dispatch(getEmails(establishmentId)).unwrap();
    }
  }, []);

  const openModalCreate = (isDaily: boolean) => {
    setIsDailyModal(isDaily);
    setShowAddModal(true);
  };
  const createDailyEmail = (email: string) => {
    if (establishmentId) {
      dispatch(addDailyNotification({ establishmentId, data: { email } })).unwrap();
      setShowAddModal(false);
    }
  };
  const deleteDailyEmail = (email: string) => {
    if (establishmentId) {
      dispatch(removeDailyNotification({ establishmentId, data: { email } })).unwrap();
      setShowAddModal(false);
    }
  };

  const createWeeklyEmail = (email: string) => {
    if (establishmentId) {
      dispatch(addWeeklyNotification({ establishmentId, data: { email } })).unwrap();
      setShowAddModal(false);
    }
  };
  const deleteWeeklyEmail = (email: string) => {
    if (establishmentId) {
      dispatch(removeWeeklyNotification({ establishmentId, data: { email } })).unwrap();
      setShowAddModal(false);
    }
  };

  return (
    <>
      <BaseNavbar accountType={accountType} />
      <BaseNavbarWithHistory
        title={i18next.t('settings:notifications.navbar.title')}
        showSettings={false}
        showHistory={false}
      />
      <Container fluid className="h-100 pt-5 cornerRound px-5">
        {/* Text */}
        <Row>
          <Col md={12} className="d-flex justify-content-start">
            <div className={styles.listTitle}>{i18next.t('settings:notifications.list')}</div>
          </Col>
        </Row>
        {/* Emails */}
        <Row className="mt-4">
          <Col md={6} lg={4} className="mb-md-0 mb-5">
            <SettingsNotificationsCard
              title={i18next.t('settings:notifications.cards.dailyEmail')}
              text={i18next.t('settings:notifications.cards.dailyText')}
              emailList={emailListDaily}
              onAdd={() => openModalCreate(true)}
              onDelete={deleteDailyEmail}
            />
          </Col>
          <Col md={6} lg={4}>
            <SettingsNotificationsCard
              title={i18next.t('settings:notifications.cards.weeklyEmail')}
              text={i18next.t('settings:notifications.cards.weeklyText')}
              emailList={emailListWeekly}
              onAdd={() => openModalCreate(false)}
              onDelete={deleteWeeklyEmail}
            />
          </Col>
        </Row>
        <SettingsNotificationsAddModal
          showModal={showAddModal}
          isDailyModal={isDailyModal}
          setShowModal={setShowAddModal}
          addNewSignatory={(email: string) =>
            isDailyModal ? createDailyEmail(email) : createWeeklyEmail(email)
          }
        />
      </Container>
    </>
  );
};

export default SettingsNotifications;
