import React, { Dispatch, SetStateAction } from 'react';
import i18next from 'i18next'; // i18next.t('pageName:exemple.exemple')
import { Button, Col, Row, Image } from 'react-bootstrap';
import ScrollMenu from 'react-horizontal-scrolling-menu';
import DeepFryerListItem from '@components/DeepFryerListItem/DeepFryerListItem';
import { DeepFryerDto } from '@models/dto/response/DeepFryer.dto';
import { history } from '@router/routes';
import chevronIcon from '@assets/images/svg/chevron-right.svg';
import styles from './DeepFryerList.module.scss';

type Props = {
  deepFryerList: DeepFryerDto[];
  selectedDeepFryer?: DeepFryerDto;
  setSelectedDeepFryer: Dispatch<SetStateAction<DeepFryerDto | undefined>>;
};

const List = (list: DeepFryerDto[], selectedId: number | string) => {
  return list.map((deepFryer) => {
    const isSelected = selectedId === deepFryer.id;
    return (
      <DeepFryerListItem
        key={deepFryer.id}
        name={deepFryer.name}
        image={deepFryer.picture}
        isSelected={isSelected}
        noItemSelected={!selectedId}
      />
    );
  });
};

const DeepFryerList = (props: Props) => {
  const { deepFryerList, selectedDeepFryer, setSelectedDeepFryer } = props;

  const handleSelectDeepFryer = (key: string | number | null) => {
    if (key) {
      const selectedItem = deepFryerList.find((el) => el.id === Number(key));
      if (selectedItem) {
        setSelectedDeepFryer(selectedItem);
      }
    }
  };

  const goToListView = () => {
    history.push('/oil-monitoring/list');
  };
  return (
    <div>
      {deepFryerList.length > 0 ? (
        <>
          <Row>
            <Col md={12} className="d-flex justify-content-between">
              <div className={styles.title}>{i18next.t('oilMonitoring:title')}</div>
              <Button
                className={`btnSm d-flex flex-row align-items-center ${styles.seeAll}`}
                onClick={goToListView}
              >
                {i18next.t('shared:buttons.seeAll')}
                <Image src={chevronIcon} width={6} height={11} className="ml-2" />
              </Button>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <ScrollMenu
                data={List(deepFryerList, selectedDeepFryer ? selectedDeepFryer.id : '')}
                onSelect={handleSelectDeepFryer}
                transition={2}
                alignCenter={false}
              />
            </Col>
          </Row>
        </>
      ) : (
        <Row>
          <Col>{i18next.t('oilMonitoring:noEquipment')}</Col>
        </Row>
      )}
    </div>
  );
};

export default DeepFryerList;
