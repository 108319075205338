import React, { useEffect, useState } from 'react';
import i18next from 'i18next';
import { Modal } from 'react-bootstrap';
import { ICalendarException } from '@models/dto/request/CalendarExceptionParams.dto';
import { CalendarExceptionDto } from '@models/dto/response/CalendarExceptionList.dto';
import BaseModalHeader from '@components/shared/BaseModalHeader/BaseModalHeader';
import BaseDateIntervalPicker from '@components/shared/BaseDateIntervalPicker/BaseDateIntervalPicker';
import BaseModalFooter from '@components/shared/BaseModalFooter/BaseModalFooter';
import { toast } from 'react-toastify';

type Props = {
  selectedItem?: CalendarExceptionDto;
  showModal: boolean;
  setShowModal: () => void;
  validAddForm: (data: ICalendarException) => void;
  validEditForm: (data: CalendarExceptionDto) => void;
};

const SettingsCalendarExceptionsModal = (props: Props) => {
  const { selectedItem, showModal, setShowModal, validAddForm, validEditForm } = props;
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [startDate, setStartDate] = useState<Date>();
  const [endDate, setEndDate] = useState<Date>();

  useEffect(() => {
    setIsEditMode(!!selectedItem);
    setStartDate(selectedItem?.startDate ? new Date(selectedItem.startDate) : undefined);
    setEndDate(selectedItem?.endDate ? new Date(selectedItem.endDate) : undefined);
  }, [selectedItem]);

  const handleValidate = () => {
    // Form check if completed
    if (!startDate) {
      toast.warn(i18next.t('settings:calendarExceptions.toasts.warn.startDate'));
      return;
    }
    if (!endDate) {
      toast.warn(i18next.t('settings:calendarExceptions.toasts.warn.endDate'));
      return;
    }
    if (isEditMode && selectedItem) {
      validEditForm({
        id: selectedItem.id,
        startDate: new Date(startDate.setHours(2, 0, 0, 0)),
        endDate: new Date(endDate.setHours(23, 59, 0, 0)),
      });
    } else {
      // setHour because default value is 22 and GMT+2 = next day
      validAddForm({
        startDate: new Date(startDate.setHours(2, 0, 0, 0)),
        endDate: new Date(endDate.setHours(23, 59, 0, 0)),
      });
    }
    setStartDate(undefined);
    setEndDate(undefined);
  };

  return (
    <Modal
      show={showModal}
      onHide={setShowModal}
      size="lg"
      aria-labelledby="settings-calendar-modal"
      centered
    >
      <BaseModalHeader
        id="settings-calendar-modal"
        title={i18next.t('settings:calendarExceptions.modal.addTitle')}
      />
      <Modal.Body>
        {/* Date picker */}
        <BaseDateIntervalPicker
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
          inline
        />
      </Modal.Body>
      <BaseModalFooter handleValidate={handleValidate} setShowModal={setShowModal} />
    </Modal>
  );
};

export default SettingsCalendarExceptionsModal;
