import { INotification } from '@models/dto/request/NotificationsParams.dto';
import Axios from './index';

export default {
  getEmails: (establishmentId: number) => {
    return Axios.get(`/establishment/${establishmentId}/notification`);
  },

  createDailyNotification: (establishmentId: number, notificationData: INotification) => {
    return Axios.put(`/establishment/${establishmentId}/notification/daily/add`, notificationData);
  },

  createWeeklyNotification: (establishmentId: number, notificationData: INotification) => {
    return Axios.put(`/establishment/${establishmentId}/notification/weekly/add`, notificationData);
  },

  deleteDailyNotification: (establishmentId: number, notificationData: INotification) => {
    return Axios.put(
      `/establishment/${establishmentId}/notification/daily/remove`,
      notificationData,
    );
  },

  deleteWeeklyNotification: (establishmentId: number, notificationData: INotification) => {
    return Axios.put(
      `/establishment/${establishmentId}/notification/weekly/remove`,
      notificationData,
    );
  },
};
