import SettingsItem from '@components/SettingsItem/SettingsItem';
import BaseNavbarWithHistory from '@components/shared/BaseNavbarWithHistory/BaseNavbarWithHistory';
import i18next from 'i18next';
import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

const SETTINGS = [
  {
    id: 1,
    name: i18next.t('settings:cards.notifications'),
    icon: 'gestion-notifs',
  },
  {
    id: 2,
    name: i18next.t('settings:cards.signatories'),
    icon: 'compte',
  },
  {
    id: 3,
    name: i18next.t('settings:cards.frequencies'),
    icon: 'frequences',
  },
  {
    id: 4,
    name: i18next.t('settings:cards.calendarExceptions'),
    icon: 'calendar-white',
  },
];

const Settings = () => {
  return (
    <>
      {/* Navbar */}
      <BaseNavbarWithHistory
        title={i18next.t('settings:navbar.title')}
        showSettings={false}
        showHistory={false}
      />
      <Container fluid className="cornerRound px-0 px-md-5">
        <Container>
          <Row className="mb-5 pt-5">
            <Col md={6} xs={6} className="mb-4">
              <SettingsItem item={SETTINGS[0]} type="notifications" />
            </Col>
            <Col md={6} xs={6} className="mb-4">
              <SettingsItem item={SETTINGS[1]} type="signatories" />
            </Col>
            <Col md={6} xs={6} className="mb-4">
              <SettingsItem item={SETTINGS[2]} type="frequencies" />
            </Col>
            <Col md={6} xs={6} className="mb-4">
              <SettingsItem item={SETTINGS[3]} type="calendar-exceptions" />
            </Col>
          </Row>
        </Container>
      </Container>
    </>
  );
};

export default Settings;
