import React, { useState } from 'react';
import i18next from 'i18next';
import { Table } from 'react-bootstrap';
import { EquipmentDto } from '@models/dto/response/Equipment.dto';
import { useAppDispatch } from '@store/hooks';
import { deleteEquipment, updateEquipment } from '@store/reducers/equipment';
import HardwareTempSettingsEditModal from '@components/HardwareTempSettingsEditModal/HardwareTempSettingsEditModal';
import { CreateEquipmentDto } from '@models/dto/request/CreateEquipment.dto';
import { toast } from 'react-toastify';
import BaseTableButtons from '@components/shared/BaseTableButtons/BaseTableButtons';
import { getTitleFrequencySurface } from '@utils/frequenciesUtils';

type Props = {
  hardwareList: EquipmentDto[];
};

const HardwareTempSettingsTable = (props: Props) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [equipmentToUpdate, setEquipmentToUpdate] = useState<EquipmentDto | undefined>();
  const dispatch = useAppDispatch();

  const removeEquipment = (id: number) => {
    toast.promise(dispatch(deleteEquipment(id)).unwrap(), {
      pending: i18next.t('hardwareTemp:toasts.pending.removeEquipment'),
      success: i18next.t('hardwareTemp:toasts.success.removeEquipment'),
      error: i18next.t('hardwareTemp:toasts.error.removeEquipment'),
    });
  };

  const editEquipment = (equipment: CreateEquipmentDto) => {
    if (equipmentToUpdate) {
      toast.promise(
        dispatch(updateEquipment({ ...equipment, equipmentId: equipmentToUpdate.id })).unwrap(),
        {
          pending: i18next.t('hardwareTemp:toasts.pending.updateEquipment'),
          success: i18next.t('hardwareTemp:toasts.success.updateEquipment'),
          error: i18next.t('hardwareTemp:toasts.error.updateEquipment'),
        },
      );
    }
  };

  const openModal = (hardware: EquipmentDto) => {
    setEquipmentToUpdate(hardware);
    setShowModal(true);
  };

  return (
    <>
      <Table responsive className="mt-5">
        <thead className="border-top-0">
          <tr className="tableHeader">
            <th>{i18next.t('hardwareTemp:settings.table.headers.hardware')}</th>
            <th>{i18next.t('hardwareTemp:settings.table.headers.temperature')}</th>
            <th>{i18next.t('hardwareTemp:settings.table.headers.occurence')}</th>
            <th> </th>
          </tr>
        </thead>
        <tbody>
          {props.hardwareList.map((hardware, index) => {
            return (
              <tr key={index} className="py-5">
                <td className="tableFirstColumn">{hardware.name}</td>
                <td className="tableOtherColumn">
                  {i18next.t('shared:temperature.min')} {hardware.minTemperature} °C /{' '}
                  {i18next.t('shared:temperature.max')} {hardware.maxTemperature} °C
                </td>
                <td className="tableOtherColumn">
                  {getTitleFrequencySurface(JSON.parse(hardware.frequency.json))}
                </td>
                <td>
                  <BaseTableButtons
                    editAction={() => openModal(hardware)}
                    deleteAction={() => removeEquipment(hardware.id)}
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
      {equipmentToUpdate && (
        <HardwareTempSettingsEditModal
          showModal={showModal}
          setShowModal={setShowModal}
          updateCurrentEquipment={editEquipment}
          equipmentToUpdate={equipmentToUpdate}
          setEquipmentToUpdate={setEquipmentToUpdate}
        />
      )}
    </>
  );
};

export default HardwareTempSettingsTable;
