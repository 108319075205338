import BaseNavbarWithHistory from '@components/shared/BaseNavbarWithHistory/BaseNavbarWithHistory';
import i18next from 'i18next';
import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import ProductTempSettingsTypeEnum from '@models/enums/ProductTempSettingsType';
import ProductTempSettingsItem from '@components/ProductTempSettingsItem/ProductTempSettingsItem';
import { useAppSelector } from '@store/hooks';
import BaseNavbar from '@components/shared/BaseNavbar/BaseNavbar';

const PRODUCT_TEMP_SETTINGS = [
  {
    name: i18next.t('productTemp:settings.cards.dishes'),
    icon: 'famille-de-plats',
    colorClass: 'blueColor',
    type: ProductTempSettingsTypeEnum.dishes,
  },
  {
    name: i18next.t('productTemp:settings.cards.shipping'),
    icon: 'temp-livraison',
    colorClass: 'greenColor',
    type: ProductTempSettingsTypeEnum.shipping,
  },
  {
    name: i18next.t('productTemp:settings.cards.service'),
    icon: 'temp-service',
    colorClass: 'orangeColor',
    type: ProductTempSettingsTypeEnum.service,
  },
];

const ProductTempSettings = () => {
  const accountType = useAppSelector((state) => state.account.type);

  return (
    <>
      <BaseNavbar accountType={accountType} parametersUrl="/settings" />
      {/* Navbar */}
      <BaseNavbarWithHistory
        title={i18next.t('productTemp:settings.navbar.title')}
        showSettings={false}
        showHistory={false}
      />
      <Container>
        <Row className="my-5">
          {PRODUCT_TEMP_SETTINGS.map((setting, index: number) => (
            <Col key={index} md={6} className="mb-4">
              <ProductTempSettingsItem
                name={setting.name}
                icon={setting.icon}
                colorClass={setting.colorClass}
                type={setting.type}
              />
            </Col>
          ))}
        </Row>
      </Container>
    </>
  );
};

export default ProductTempSettings;
