import ProductTempItem from '@components/ProductTempItem/ProductTempItem';
import BaseDatePicker from '@components/shared/BaseDatePicker/BaseDatePicker';
import BaseNavbarWithHistory from '@components/shared/BaseNavbarWithHistory/BaseNavbarWithHistory';
import BaseSaveButton from '@components/shared/BaseSaveButton/BaseSaveButton';
import BaseSignatory from '@components/shared/BaseSignatory/BaseSignatory';
import BaseTemperature from '@components/shared/BaseTemperature/BaseTemperature';
import BaseDishesForm from '@components/shared/BaseDishesForm/BaseDishesForm';
import i18next from 'i18next';
import React, { useState, useEffect } from 'react';
import { Col, Container, Form, Row } from 'react-bootstrap';
import { format, formatISO, isValid, setHours, setMinutes } from 'date-fns';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import {
  createCoolingTempReading,
  createDeliveryTempReading,
  createReheatingTempReading,
  createServiceTempReading,
  getProductTempData,
  getRunningProductTemp,
  selectDishFamilyType,
  selectOperationProductType,
  setSelectedOperationType,
} from '@store/reducers/product-temperature';
import { SignatoryDto } from '@models/dto/response/Signatory.dto';
import OperationTempReadingTypeEnum from '@models/enums/OperationTempReadingType';
import { getSignatories } from '@store/reducers/signatory';
import { DishFamilyDto } from '@models/dto/response/DishFamily.dto';
import { DishSubFamilyDto } from '@models/dto/response/DishSubFamily.dto';
import { toast } from 'react-toastify';
import { history } from '@router/routes';
import {
  CreateProductTempReadingDeliveryDto,
  CreateProductTempReadingDto,
  CreateProductTempReadingServiceDto,
} from '@models/dto/request/ProductTempReading.dto';
import {
  setSelectedDish,
  setSelectedDishesList,
  setSelectedDishFamily as setSelectedDishFamilyAction,
  setSelectedDishSubFamily as setSelectedDishSubFamilyAction,
} from '@store/reducers/dish';
import AccountTypeEnum from '@models/enums/AccountType';
import OperationTypeEnum from '@models/enums/OperationType';
import {
  getCustomers,
  setSelectedCustomer as setSelectedCustomerAction,
} from '@store/reducers/customer';
import {
  FormControl,
  FormControlLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
} from '@material-ui/core';
import { CustomerDto } from '@models/dto/response/Customer.dto';
import { setSelectedTaskId } from '@store/reducers/history';
import { setNonCompliance } from '@store/reducers/non-compliance';
import NonComplianceRedirectModal from '@components/NonComplianceRedirectModal/NonComplianceRedirectModal';
import { TEMPERATURE_MIN, TEMPERATURE_MAX } from '@utils/constants';
import styles from './ProductTemp.module.scss';

enum DeliveryTemperatureTypeEnum {
  delivery = 'delivery',
  production = 'production',
}

const ProductTemp = () => {
  // From store
  const productTempData = useAppSelector((state) => state.productTemperature.productTempData);
  const signatories = useAppSelector((state) => state.signatory.signatories);
  const accountType = useAppSelector((state) => state.account.type);
  const establishmentId = useAppSelector((state) => state.account.selectedEstablishment?.id);
  const customers = useAppSelector((state) => state.customer.customers);
  const selectedTaskId = useAppSelector((state) => state.history.selectedTaskId);
  const dishFamilySelected = useAppSelector((state) => state.productTemperature.dishFamilySelected);
  const operationTempReadingTypeSelected = useAppSelector(
    (state) => state.productTemperature.operationTempReadingTypeSelected,
  );
  // Get data from store when navigating back from dishes list
  const dishFromListView = useAppSelector((state) => state.dish.selectedDish);
  const dishFamilyFromListView = useAppSelector((state) => state.dish.selectedDishFamily);
  const dishSubFamilyFromListView = useAppSelector((state) => state.dish.selectedDishSubFamily);
  const operationTypeFromListView = useAppSelector(
    (state) => state.productTemperature.selectedOperationType,
  );
  const storedCustomer = useAppSelector((state) => state.customer.selectedCustomer);

  const dispatch = useAppDispatch();

  // Component state
  const [selectedActionType, setSelectedActionType] = useState<OperationTempReadingTypeEnum>(
    OperationTempReadingTypeEnum.cooling,
  );
  const [currentDate, setCurrentDate] = useState<Date>(new Date());
  const [currentTime, setCurrentTime] = useState<string>(format(new Date(), 'HH:mm'));
  const [selectedSignatory, setSelectedSignatory] = useState<SignatoryDto>();
  const [temperature, setTemperature] = useState<string>();
  const [selectedDishFamily, setSelectedDishFamily] = useState<DishFamilyDto | undefined>(
    dishFamilyFromListView,
  );
  const [selectedDishSubFamily, setSelectedDishSubFamily] = useState<DishSubFamilyDto | undefined>(
    dishSubFamilyFromListView,
  );
  const [dishName, setDishName] = useState<string>(dishFromListView?.name ?? '');
  const [selectedCustomer, setSelectedCustomer] = useState<CustomerDto | undefined>(storedCustomer);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [deliveryTemperatureType, setDeliveryTemperatureType] =
    useState<DeliveryTemperatureTypeEnum>(DeliveryTemperatureTypeEnum.production);

  // Get mandatory data
  useEffect(() => {
    if (establishmentId) {
      dispatch(getProductTempData(establishmentId)).unwrap();
    }

    if ((!signatories || signatories.length === 0) && establishmentId) {
      dispatch(getSignatories(establishmentId)).unwrap();
    }
    return () => {
      setSelectedTaskId(undefined);
    };
  }, []);

  // Get customers list if operation is delivery
  useEffect(() => {
    if (selectedActionType === OperationTempReadingTypeEnum.delivery) {
      if ((!customers || customers.length === 0) && establishmentId) {
        dispatch(getCustomers(establishmentId)).unwrap();
      }
    }
  }, [selectedActionType]);

  // FROM TASKS CREATION

  // Get if exists customer if coming from task
  useEffect(() => {
    if (dishFamilySelected && productTempData) {
      if (dishFamilySelected === 'dish_entree') {
        setSelectedDishFamily(productTempData.dishes[0]);
      } else if (dishFamilySelected === 'dish_dish') {
        setSelectedDishFamily(productTempData.dishes[1]);
      } else if (dishFamilySelected === 'dish_garnish') {
        setSelectedDishFamily(productTempData.dishes[2]);
      } else if (dishFamilySelected === 'dish_cheese') {
        setSelectedDishFamily(productTempData.dishes[3]);
      } else if (dishFamilySelected === 'dish_dessert') {
        setSelectedDishFamily(productTempData.dishes[4]);
      } else if (dishFamilySelected === 'dish_other') {
        setSelectedDishFamily(productTempData.dishes[5]);
      } else {
        setSelectedDishFamily(undefined);
      }
    }
  }, [dishFamilySelected, productTempData]);

  // Get if exists customer if coming from task
  useEffect(() => {
    if (storedCustomer) {
      setSelectedCustomer(storedCustomer);
    }
  }, [storedCustomer]);

  // to retrieve operation type when preselected
  useEffect(() => {
    if (operationTempReadingTypeSelected) {
      setSelectedActionType(operationTempReadingTypeSelected);
      dispatch(setSelectedOperationType(operationTempReadingTypeSelected));
    }
  }, [operationTempReadingTypeSelected]);

  // HANDLE NAVIGATE TO DISH LIST

  useEffect(() => {
    if (operationTypeFromListView) {
      setSelectedActionType(operationTypeFromListView);
    }
  }, [operationTypeFromListView]);

  // STANDARD CREATION

  // Update Date with custom time
  const buildDate = () => {
    let formattedDate = currentDate;
    const timeArray = currentTime.split(':');
    formattedDate = setMinutes(formattedDate, Number(timeArray[1]));
    formattedDate = setHours(formattedDate, Number(timeArray[0]));
    return formattedDate;
  };

  const resetForm = () => {
    dispatch(setSelectedDishFamilyAction(undefined));
    dispatch(setSelectedDishSubFamilyAction(undefined));
    dispatch(setSelectedDishesList([]));
    dispatch(setSelectedDish(undefined));
    dispatch(setSelectedOperationType(undefined));
    dispatch(selectOperationProductType(undefined));
    dispatch(setSelectedCustomerAction(undefined));
    dispatch(selectDishFamilyType(undefined));
  };

  const redirectToNonCompliance = () => {
    setShowModal(false);
    history.push('/operations-handling/solve');
  };

  const redirectToDashboard = () => {
    history.push('/dashboard');
  };

  const handleNonCompliance = (res: any) => {
    // Check if payload is present
    if (res.nonCompliances && res.nonCompliances.length > 0) {
      // Get non compliances
      const nonCompliance = res.nonCompliances[0];
      // Show modal
      if (nonCompliance) {
        // Set in store to solve
        dispatch(setNonCompliance(nonCompliance));
        // Show modal solve non compliance
        setShowModal(true);
      } else {
        redirectToDashboard();
      }
    } else {
      redirectToDashboard();
    }
  };

  const create = (date: Date) => {
    let data;
    switch (selectedActionType) {
      case OperationTempReadingTypeEnum.cooling:
        data = {
          signatoryId: selectedSignatory!.id,
          dishSubFamilyId: selectedDishSubFamily!.id,
          startTemperature: parseFloat(temperature!),
          dishName,
          realisationDate: formatISO(new Date(date)),
        } as CreateProductTempReadingDto;

        dispatch(createCoolingTempReading(data))
          .unwrap()
          .then((res) => {
            toast.success(i18next.t('productTemp:toasts.success.createCooling'));
            handleNonCompliance(res);
          })
          .catch(() => {
            toast.error(i18next.t('productTemp:toasts.error.createOperation'));
          })
          .finally(() => {
            resetForm();
            if (establishmentId) {
              dispatch(getRunningProductTemp(establishmentId));
            }
          });
        break;
      case OperationTempReadingTypeEnum.reheating:
        data = {
          signatoryId: selectedSignatory!.id,
          dishSubFamilyId: selectedDishSubFamily!.id,
          startTemperature: parseFloat(temperature!),
          dishName,
          realisationDate: formatISO(new Date(date)),
        } as CreateProductTempReadingDto;

        dispatch(createReheatingTempReading(data))
          .unwrap()
          .then((res) => {
            toast.success(i18next.t('productTemp:toasts.success.createReheating'));
            handleNonCompliance(res);
          })
          .catch(() => {
            toast.error(i18next.t('productTemp:toasts.error.createOperation'));
          })
          .finally(() => {
            resetForm();
            if (establishmentId) {
              dispatch(getRunningProductTemp(establishmentId));
            }
          });
        break;
      case OperationTempReadingTypeEnum.service:
        data = {
          signatoryId: selectedSignatory!.id,
          dishSubFamilyId: selectedDishSubFamily!.id,
          temperature: parseFloat(temperature!),
          dishName,
          realisationDate: formatISO(new Date(date)),
        } as CreateProductTempReadingServiceDto;

        // Link to task if exists
        if (selectedTaskId) {
          data.taskId = selectedTaskId;
        }

        dispatch(createServiceTempReading(data))
          .unwrap()
          .then((res) => {
            toast.success(i18next.t('productTemp:toasts.success.createService'));
            handleNonCompliance(res);
          })
          .catch(() => {
            toast.error(i18next.t('productTemp:toasts.error.createOperation'));
          })
          .finally(() => {
            resetForm();
            dispatch(setSelectedTaskId(undefined));
          });
        break;
      case OperationTempReadingTypeEnum.delivery:
        data = {
          customerId: selectedCustomer?.id,
          signatoryId: selectedSignatory!.id,
          dishSubFamilyId: selectedDishSubFamily!.id,
          dishName,
          realisationDate: formatISO(new Date(date)),
        } as CreateProductTempReadingDeliveryDto;

        if (deliveryTemperatureType === DeliveryTemperatureTypeEnum.delivery) {
          data.deliveryTemperature = parseFloat(temperature!);
        } else {
          data.productionSiteExitTemperature = parseFloat(temperature!);
        }

        // Link to task if exists
        if (selectedTaskId) {
          data.taskId = selectedTaskId;
        }

        dispatch(createDeliveryTempReading(data))
          .unwrap()
          .then((res) => {
            toast.success(i18next.t('productTemp:toasts.success.createDelivery'));
            handleNonCompliance(res);
          })
          .catch(() => {
            toast.error(i18next.t('productTemp:toasts.error.createOperation'));
          })
          .finally(() => {
            resetForm();
            dispatch(setSelectedTaskId(undefined));
            if (establishmentId) {
              dispatch(getRunningProductTemp(establishmentId));
            }
          });
        break;
      default:
        break;
    }
  };

  const handleSave = () => {
    // Form check if completed
    if (!selectedCustomer && selectedActionType === OperationTempReadingTypeEnum.delivery) {
      toast.warn(i18next.t('productTemp:toasts.warn.customer'));
      return;
    }
    if (!selectedDishFamily) {
      toast.warn(i18next.t('productTemp:toasts.warn.dishFamily'));
      return;
    }
    if (!selectedDishSubFamily) {
      toast.warn(i18next.t('productTemp:toasts.warn.dishSubFamily'));
      return;
    }
    if (!temperature && temperature !== '0') {
      toast.warn(i18next.t('productTemp:toasts.warn.temperature'));
      return;
    }
    if (!currentDate) {
      toast.warn(i18next.t('productTemp:toasts.warn.date'));
      return;
    }
    if (!currentTime) {
      toast.warn(i18next.t('productTemp:toasts.warn.time'));
      return;
    }
    if (!selectedSignatory) {
      toast.warn(i18next.t('productTemp:toasts.warn.signatory'));
      return;
    }

    const date = buildDate();

    // Is date valid format
    if (isValid(date)) {
      create(date);
    }
  };

  const getTMax = () => {
    switch (selectedActionType) {
      case OperationTempReadingTypeEnum.cooling:
        return undefined;
      case OperationTempReadingTypeEnum.reheating:
        return TEMPERATURE_MIN;
      case OperationTempReadingTypeEnum.delivery:
        if (selectedCustomer && selectedCustomer.deliveryTemp === 'delivery_cold') {
          return selectedCustomer.maxTemp;
        }
        return selectedDishSubFamily?.maxTemperature;
      default:
        return selectedDishSubFamily?.maxTemperature;
    }
  };

  const getTMin = () => {
    switch (selectedActionType) {
      case OperationTempReadingTypeEnum.cooling:
        return TEMPERATURE_MAX;
      case OperationTempReadingTypeEnum.reheating:
        return undefined;
      case OperationTempReadingTypeEnum.delivery:
        if (selectedCustomer && selectedCustomer.deliveryTemp === 'delivery_cold') {
          return selectedCustomer.minTemp;
        }
        return selectedDishSubFamily?.minTemperature;
      default:
        return selectedDishSubFamily?.minTemperature;
    }
  };

  const getTemperatureLabel = () => {
    switch (selectedActionType) {
      case OperationTempReadingTypeEnum.cooling:
      case OperationTempReadingTypeEnum.reheating:
        return i18next.t('productTemp:firstMeasure');
      case OperationTempReadingTypeEnum.delivery:
        if (deliveryTemperatureType === DeliveryTemperatureTypeEnum.delivery) {
          return i18next.t('productTemp:radios.delivery');
        }
        return i18next.t('productTemp:radios.production');

      default:
        return undefined;
    }
  };

  if (productTempData) {
    return (
      <>
        {/* Navbar */}
        <BaseNavbarWithHistory
          title={i18next.t('productTemp:navbar.title')}
          showSettings={accountType === AccountTypeEnum.admin}
          typeOperation={OperationTypeEnum.product}
          onNavigate={() => {
            resetForm();
          }}
        />
        <Container fluid className="cornerRound">
          {/* Action type */}
          <Row className={`${styles.actionContainer} p-5`}>
            {productTempData.subTempOperations.map((item) => {
              return (
                <Col sm={6} md={3} key={item.name} className="mb-md-0 mb-3">
                  <ProductTempItem
                    action={item}
                    isSelected={item.type === selectedActionType}
                    disableSelection={operationTempReadingTypeSelected !== undefined}
                  />
                </Col>
              );
            })}
          </Row>
          <Row className="px-md-5 px-3">
            <Col md={12}>
              {/* Customer */}
              {selectedActionType === OperationTempReadingTypeEnum.delivery && (
                <Form.Group className="mt-5" controlId="customer">
                  <FormControl className="w-100">
                    <Select
                      value={JSON.stringify(selectedCustomer) ?? ''}
                      onChange={(e) => {
                        setSelectedCustomer(JSON.parse(e.target.value as string) as CustomerDto);
                      }}
                      displayEmpty
                      inputProps={{
                        className: `${
                          !selectedCustomer ? styles.selectInputEmpty : styles.selectInputFilled
                        }`,
                      }}
                    >
                      <MenuItem value="" disabled>
                        {i18next.t('shared:dishes.customer')}
                      </MenuItem>
                      {customers.map((customerItem: CustomerDto) => (
                        <MenuItem key={customerItem.id} value={JSON.stringify(customerItem)}>
                          {customerItem.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Form.Group>
              )}
              <BaseDishesForm
                readOnlyDishFamily={dishFamilySelected !== undefined}
                selectedDishFamily={selectedDishFamily}
                selectedDishSubFamily={selectedDishSubFamily}
                setSelectedDishFamily={setSelectedDishFamily}
                setSelectedDishSubFamily={setSelectedDishSubFamily}
                dishFamilies={productTempData.dishes}
                dishName={dishName}
                setDishName={setDishName}
                selectedCustomer={selectedCustomer}
                selectedActionType={selectedActionType}
              />
            </Col>
          </Row>
          <hr style={{ width: '80%' }} />
          {/* Temperature */}
          <div className="my-5">
            {selectedActionType === OperationTempReadingTypeEnum.delivery && (
              <FormControl component="fieldset" className="px-5 mb-3">
                <Form.Label className={styles.radioLabel}>
                  {i18next.t('productTemp:saveAs')}
                </Form.Label>
                <RadioGroup
                  aria-label="deliveryTemperatureType"
                  name="deliveryTemperatureType"
                  className="d-flex flex-row"
                  value={deliveryTemperatureType}
                  onChange={(e) =>
                    setDeliveryTemperatureType(
                      e.target.value as string as DeliveryTemperatureTypeEnum,
                    )
                  }
                >
                  <FormControlLabel
                    value={DeliveryTemperatureTypeEnum.production}
                    control={<Radio className="checkBoxCustom" />}
                    label={i18next.t('productTemp:radios.production')}
                  />
                  <FormControlLabel
                    value={DeliveryTemperatureTypeEnum.delivery}
                    control={<Radio className="checkBoxCustom" />}
                    label={i18next.t('productTemp:radios.delivery')}
                  />
                </RadioGroup>
              </FormControl>
            )}
            <BaseTemperature
              temperature={temperature}
              setTemperature={setTemperature}
              label={getTemperatureLabel()}
              tMin={getTMin()}
              tMax={getTMax()}
            />
          </div>
          <hr style={{ width: '80%' }} />
          {/*  Date  / Time */}
          <Row className="mb-5 px-5">
            <Col md={12}>
              <BaseDatePicker
                currentDate={currentDate}
                setCurrentDate={setCurrentDate}
                currentTime={currentTime}
                setCurrentTime={setCurrentTime}
              />
            </Col>
          </Row>
          <hr style={{ width: '80%' }} />
          {/* Signatories */}
          <BaseSignatory
            signatories={signatories}
            selectedSignatory={selectedSignatory}
            setSelectedSignatory={setSelectedSignatory}
          />
          <BaseSaveButton
            onSave={handleSave}
            text={
              selectedActionType === OperationTempReadingTypeEnum.service ||
              selectedActionType === OperationTempReadingTypeEnum.delivery
                ? i18next.t('shared:buttons.save')
                : i18next.t('productTemp:firstMeasureButton')
            }
          />
          <NonComplianceRedirectModal
            showModal={showModal}
            setShowModal={setShowModal}
            handleValidate={redirectToNonCompliance}
            handleCancel={redirectToDashboard}
          />
        </Container>
      </>
    );
  }

  return null; // TODO: Prévoir un affichage en cas de données non présente
};

export default ProductTemp;
