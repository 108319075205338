import React, { useEffect, useState } from 'react';
import BaseNavbarWithHistory from '@components/shared/BaseNavbarWithHistory/BaseNavbarWithHistory';
import i18next from 'i18next';
import { Col, Row, Container } from 'react-bootstrap';
import { useAppSelector, useAppDispatch } from '@store/hooks';
import { getLateTasks, resetHistory } from '@store/reducers/history';

import OperationsDelayList from './OperationsDelayList';

const OperationsDelayed = () => {
  // Store
  const establishment = useAppSelector((state) => state.account.selectedEstablishment);
  const hasMoreLateTask = useAppSelector((state) => state.history.hasMoreLateTask);
  const lateTasks = useAppSelector((state) => state.history.lateTasks);
  const isLoading = useAppSelector((state) => state.history.isLoading);
  const dispatch = useAppDispatch();
  // Component state
  const [page, setPage] = useState<number>(0);

  const loadNextPage = () => {
    if (hasMoreLateTask && !isLoading) {
      setPage((currentPage: number) => currentPage + 1);
    }
  };

  useEffect(() => {
    return () => {
      dispatch(resetHistory());
    };
  }, []);

  const getLateTaks = async () => {
    await dispatch(
      getLateTasks({
        establishmentId: establishment!.id,
        page,
      }),
    );
  };

  useEffect(() => {
    if (hasMoreLateTask) {
      getLateTaks();
    }
  }, [page]);

  return (
    <>
      {/* Navbar */}
      <BaseNavbarWithHistory
        title={i18next.t('operationsDelayed:navbar.title')}
        showSettings={false}
        showHistory={false}
        onNavigate={() => {
          dispatch(resetHistory());
        }}
      />
      <Container fluid className="h-100 cornerRound">
        <Row className="h-100 mb-5">
          <Col md={12} className="h-100">
            {lateTasks && lateTasks.length > 0 ? (
              <OperationsDelayList
                lateTasks={lateTasks}
                isLoading={isLoading}
                loadMore={loadNextPage}
              />
            ) : (
              <div className="d-flex justify-content-center align-items-center h-100 tableHeader">
                {i18next.t('operationsDelayed:empty')}
              </div>
            )}
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default OperationsDelayed;
