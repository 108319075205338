import React, { Dispatch, SetStateAction } from 'react';
import { Col, Row, Card, Image } from 'react-bootstrap';
import i18next from 'i18next';
import productTempIcon from '@assets/images/svg/releve-des-temp-produit.svg';
import hardwareTempIcon from '@assets/images/svg/releve-temp-materiel.svg';
import cleaningIcon from '@assets/images/svg/suivi-nettoyage.svg';
import OperationTypeEnum from '@models/enums/OperationType';

import {
  OperationTypeAllEnum,
  OperationTypeEnumExtended,
} from '@pages/OperationsHistory/OperationsHistory';
import styles from './DashboardToday.module.scss';

type Props = {
  categorySelected: OperationTypeEnumExtended;
  setCategorySelected: Dispatch<SetStateAction<OperationTypeEnumExtended>>;
  allOperation: number;
  equipmentCount: number;
  cleaningCount: number;
  productCount: number;
};

const OperationTypes = { ...OperationTypeEnum, ...OperationTypeAllEnum };

const DashboardToday = (props: Props) => {
  const {
    categorySelected,
    setCategorySelected,
    allOperation,
    equipmentCount,
    cleaningCount,
    productCount,
  } = props;

  return (
    <div>
      {/* Today */}
      <Row>
        {/* All operations */}
        <Col sm={6} md={3} className="mb-4 mb-md-0">
          <div
            className={`${
              categorySelected === OperationTypes.all && styles.highlightContainer
            } h-100`}
          >
            <Card
              className={`h-100 ${styles.cardText} ${
                categorySelected === OperationTypes.all && styles.active
              } ${styles.allOperationCard}`}
              onClick={() => setCategorySelected(OperationTypes.all)}
            >
              <Card.Body className="d-flex justify-content-between align-items-end flex-column p-md-3">
                <Card.Title className={styles.cardNumber}>{allOperation}</Card.Title>
                <div className="d-flex w-100 justify-content-between align-items-center">
                  <Card.Text className={styles.cardText}>
                    {i18next.t('operationsToday:allOperation')}
                  </Card.Text>
                </div>
              </Card.Body>
            </Card>
          </div>
        </Col>
        {/* Reading temperature material */}
        <Col sm={6} md={3} className="mb-4 mb-md-0">
          <div
            className={`${
              categorySelected === OperationTypeEnum.equipment && styles.highlightContainer
            } h-100`}
          >
            <Card
              className={`h-100 ${styles.cardText} ${
                categorySelected === OperationTypeEnum.equipment && styles.active
              } ${styles.materialTemperatureReadingCard}`}
              onClick={() => setCategorySelected(OperationTypeEnum.equipment)}
            >
              <Card.Body className="d-flex justify-content-between align-items-end flex-column p-md-3">
                <Card.Title className={styles.cardNumber}>{equipmentCount}</Card.Title>
                <div className="d-flex w-100 justify-content-between align-items-center">
                  <Image src={hardwareTempIcon} height={35} className={styles.icons} fluid />
                  <div>
                    <Card.Text className={`${styles.cardText} mb-0 ml-2`}>
                      {i18next.t('operationsToday:temperatureReading')}
                    </Card.Text>
                    <Card.Text className={`${styles.cardText} font-weight-normal mt-0 ml-2`}>
                      {i18next.t('operationsToday:material')}
                    </Card.Text>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </div>
        </Col>
        {/* Cleaning */}
        <Col sm={6} md={3} className="mb-4 mb-md-0">
          <div
            className={`${
              categorySelected === OperationTypeEnum.cleaning && styles.highlightContainer
            } h-100`}
          >
            <Card
              className={`h-100 ${styles.cardText} ${
                categorySelected === OperationTypeEnum.cleaning && styles.active
              } ${styles.cleaningCard}`}
              onClick={() => setCategorySelected(OperationTypeEnum.cleaning)}
            >
              <Card.Body className="d-flex justify-content-between align-items-end flex-column p-md-3">
                <Card.Title className={styles.cardNumber}>{cleaningCount}</Card.Title>
                <div className="d-flex w-100 justify-content-between align-items-center">
                  <Image src={cleaningIcon} height={35} fluid className={styles.icons} />
                  <Card.Text className={styles.cardText}>
                    {i18next.t('operationsToday:cleaning')}
                  </Card.Text>
                </div>
              </Card.Body>
            </Card>
          </div>
        </Col>
        {/* Reading temperature product */}
        <Col sm={6} md={3} className="mb-4 mb-md-0">
          <div
            className={`${
              categorySelected === OperationTypeEnum.product && styles.highlightContainer
            } h-100`}
          >
            <Card
              className={`h-100 ${styles.cardText} ${
                categorySelected === OperationTypeEnum.product && styles.active
              } ${styles.productTemperatureReadingCard}`}
              onClick={() => setCategorySelected(OperationTypeEnum.product)}
            >
              <Card.Body className="d-flex justify-content-between align-items-end flex-column p-md-3">
                <Card.Title className={styles.cardNumber}>{productCount}</Card.Title>
                <div className="d-flex w-100 justify-content-between align-items-center">
                  <Image src={productTempIcon} height={35} fluid className={styles.icons} />
                  <div>
                    <Card.Text className={`${styles.cardText} mb-0 ml-2`}>
                      {i18next.t('operationsToday:temperatureReading')}
                    </Card.Text>
                    <Card.Text className={`${styles.cardText} font-weight-normal mt-0 ml-2`}>
                      {i18next.t('operationsToday:product')}
                    </Card.Text>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default DashboardToday;
