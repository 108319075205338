import BaseNavbarWithHistory from '@components/shared/BaseNavbarWithHistory/BaseNavbarWithHistory';
import i18next from 'i18next';
import React, { useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { getFolders } from '@store/reducers/documents';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import FolderSettingsItem from '@components/FolderSettingsItem/FolderSettingsItem';
import { FolderDto } from '@models/dto/response/Folder.dto';
import AccountTypeEnum from '@models/enums/AccountType';
import OperationTypeEnum from '@models/enums/OperationType';

const Documents = () => {
  const establishmentId = useAppSelector((state) => state.account.selectedEstablishment?.id);
  const folders = useAppSelector((state) => state.document.folders);
  const accountType = useAppSelector((state) => state.account.type);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (establishmentId && (!folders || folders.length === 0)) {
      dispatch(getFolders(establishmentId)).unwrap();
    }
  }, []);

  return (
    <>
      <BaseNavbarWithHistory
        title={i18next.t('documents:navbar.title')}
        showSettings={accountType === AccountTypeEnum.admin}
        showHistory={false}
        typeOperation={OperationTypeEnum.document}
      />
      <Container fluid className="h-100 pt-5 cornerRound px-5">
        {folders?.length > 0 ? (
          <Row>
            {folders.map((folder: FolderDto) => (
              <Col sm={6} md={4} key={folder.id} className="mt-4 px-0">
                <FolderSettingsItem folder={folder} isEditMode={false} />
              </Col>
            ))}
          </Row>
        ) : (
          <Row className="d-flex justify-content-center align-items-center h-100">
            <Col md={12} className="text-center tableHeader">
              {i18next.t('documents:folder.empty')}
            </Col>
          </Row>
        )}
      </Container>
    </>
  );
};

export default Documents;
