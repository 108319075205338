import React from 'react';
import { Image } from 'react-bootstrap';
import editIcon from '@assets/images/svg/edit.svg';
import trashIcon from '@assets/images/svg/trash.svg';
import styles from './BaseTableButtons.module.scss';

type Props = {
  editAction: any;
  deleteAction: any;
};

const BaseTableButtons = (props: Props) => {
  const { editAction, deleteAction } = props;
  return (
    <div className="d-flex flex-row align-items-center justify-content-end">
      <div className={styles.circle}>
        <Image src={editIcon} width={32} height={32} className={styles.icon} onClick={editAction} />
      </div>
      <div className={`${styles.circle} ml-4`}>
        <Image
          src={trashIcon}
          width={32}
          height={32}
          className={styles.icon}
          onClick={deleteAction}
        />
      </div>
    </div>
  );
};

export default BaseTableButtons;
