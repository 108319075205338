// import { AccountDto } from '@models/dto/response/Account.dto';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import accountService from '@services/account.service';
import { LoginParams } from '@models/dto/request/LoginParams.dto';
import { AccountParams } from '@models/dto/request/AccountParams.dto';
import AccountTypeEnum from '@models/enums/AccountType';
import { AccountDto, EstablishmentDto } from '@models/dto/response/Account.dto';
import { AxiosResponse } from 'axios';
import { ApiResponse, ApiResponseMeta } from '@models/dto/ApiResponse.dto';
import { clearSession } from '@services/ErrorHandler';
// Define a type for the slice state
interface AccountState {
  type?: AccountTypeEnum;
  login?: string;
  establishments: EstablishmentDto[];
  selectedEstablishment?: EstablishmentDto;
  active?: boolean;
}

// Define the initial state using that type
const initialState: AccountState = {
  type: undefined,
  login: undefined,
  establishments: [],
  selectedEstablishment: undefined,
  active: undefined,
};

// REQUESTS
export const login = createAsyncThunk<ApiResponse<AccountDto, ApiResponseMeta>, LoginParams>(
  'account/login',
  async (params: LoginParams, { rejectWithValue }) => {
    try {
      const response = await accountService.login(params.login, params.password);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const refreshToken = createAsyncThunk<any, string>(
  'account/refreshToken',
  async (token: string) => {
    const response = await accountService.refreshToken(token);
    return response.data.data;
  },
);

export const getAccount = createAsyncThunk<AccountDto, void>('account/get', async () => {
  const response = await accountService.getAccount();
  return response.data.data;
});

export const refreshAccount = createAsyncThunk<AccountDto, void>('account/refresh', async () => {
  const response = await accountService.getAccount();
  return response.data.data;
});

export const updatePassword = createAsyncThunk<AxiosResponse<void>, AccountParams>(
  'password/update',
  async (params: AccountParams, { rejectWithValue }) => {
    try {
      const response = await accountService.editPassword(params.oldPassword, params.newPassword);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const forgotPassword = createAsyncThunk<any, string>(
  'password/reset',
  async (email: string) => {
    const response = await accountService.forgotPassword(email);
    return response.data;
  },
);

export const AccountSlice = createSlice({
  name: 'account',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setSelectedEstablishment: (state, action: PayloadAction<EstablishmentDto>) => {
      state.selectedEstablishment = action.payload;
    },
    logout: (state) => {
      clearSession();
      state.type = undefined;
      state.login = undefined;
      state.establishments = [];
      state.selectedEstablishment = undefined;
      state.active = undefined;
    },
  },
  extraReducers: (builder) => {
    // Side effects
    // Login
    builder.addCase(login.fulfilled, (state, action) => {
      state.login = action.payload.data.login;
      state.establishments = action.payload.data.establishments;
      state.type = action.payload.data.type;
    });
    // Get account
    builder.addCase(getAccount.fulfilled, (state, action) => {
      state.login = action.payload.login;
      state.establishments = action.payload.establishments;
      state.type = action.payload.type;
      state.selectedEstablishment = action.payload.establishments[0];
      state.active = action.payload.active;
    });
    // Refresh account
    builder.addCase(refreshAccount.fulfilled, (state, action) => {
      state.login = action.payload.login;
      state.establishments = action.payload.establishments;
      state.type = action.payload.type;
      state.active = action.payload.active;
      state.selectedEstablishment = action.payload.establishments.find(
        (est: EstablishmentDto) =>
          state.selectedEstablishment && est.id === state.selectedEstablishment.id,
      );
    });
  },
});

export const { setSelectedEstablishment, logout } = AccountSlice.actions;

export default AccountSlice.reducer;
