import React from 'react';
import { Table } from 'react-bootstrap';
import i18next from 'i18next';
import { SurfaceDto } from '@models/dto/response/Surface.dto';
import { getTitleFrequencySurface } from '@utils/frequenciesUtils';
import BaseTableButtons from '@components/shared/BaseTableButtons/BaseTableButtons';

type Props = {
  cleaningSurfaceList: SurfaceDto[];
  setShowModalEdit: (data: SurfaceDto) => void;
  removeSurface: (id: number) => void;
};

const CleaningSurfaceSettingsTable = (props: Props) => {
  return (
    <Table responsive className="mt-5">
      <thead className="border-top-0">
        <tr className="tableHeader">
          <th>{i18next.t('cleaning:settings.surfaces.table.headers.zoneAndTools')}</th>
          <th>{i18next.t('cleaning:settings.surfaces.table.headers.occurence')}</th>
          <th> </th>
        </tr>
      </thead>
      <tbody>
        {props.cleaningSurfaceList.map((surface, index) => {
          return (
            <tr key={index} className="py-5">
              <td className="tableFirstColumn">{surface.name}</td>
              <td className="tableOtherColumn">
                {getTitleFrequencySurface(JSON.parse(surface.frequency.json))}
              </td>
              <td>
                <BaseTableButtons
                  editAction={() => props.setShowModalEdit(surface)}
                  deleteAction={() => props.removeSurface(surface.id)}
                />
              </td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};

export default CleaningSurfaceSettingsTable;
