import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import service from '@services/frequencies.service';
import { FrequencyDto } from '@models/dto/response/Frequency.dto';
import { PutFrequenciesParams } from '@models/dto/request/FrequenciesParam.dto';
import { AxiosResponse } from 'axios';

// Define a type for the slice state
interface FrequenciesState {
  loading: boolean;
  listFrequencies: FrequencyDto[];
}

// Define the initial state using that type
const initialState: FrequenciesState = {
  loading: false,
  listFrequencies: [],
};

export const getFrequenciesList = createAsyncThunk<AxiosResponse<FrequencyDto[]>, number>(
  'frequencies/getList',
  async (establishmentId: number) => {
    try {
      const list = await service.getFrequencies(establishmentId);
      return list.data;
    } catch (err) {
      return err;
    }
  },
);

export const updateFrequencyDetail = createAsyncThunk<
  AxiosResponse<FrequencyDto[]>,
  PutFrequenciesParams
>('frequencies/update', async ({ establishmentId, frequency }: PutFrequenciesParams) => {
  try {
    const list = await service.updateFrequency(establishmentId, frequency);
    return list.data;
  } catch (err) {
    return null;
  }
});

export const FrequenciesSlice = createSlice({
  name: 'frequencies',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getFrequenciesList.fulfilled, (state, action) => {
      state.listFrequencies = action.payload.data;
    });
    builder.addCase(updateFrequencyDetail.fulfilled, (state, action) => {
      state.listFrequencies = action.payload.data;
    });
  },
});

export default FrequenciesSlice.reducer;
