import React, { Dispatch, SetStateAction } from 'react';
import i18next from 'i18next';
import { Modal } from 'react-bootstrap';
import BaseModalHeader from '@components/shared/BaseModalHeader/BaseModalHeader';
import BaseModalFooter from '@components/shared/BaseModalFooter/BaseModalFooter';
import styles from './OperationsHandlingDeleteModal.module.scss';

type Props = {
  showModal: boolean;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  handleDelete: () => void;
};

const OperationsHandlingDeleteModal = (props: Props) => {
  const { showModal, setShowModal, handleDelete } = props;

  const handleValidate = () => {
    handleDelete();
    setShowModal(false);
  };
  return (
    <Modal
      show={showModal}
      onHide={() => setShowModal(false)}
      aria-labelledby="delete-non-compliance-modal"
      centered
    >
      <BaseModalHeader
        id="delete-non-compliance-modal"
        title={i18next.t('operationsHandling:modal.title')}
      />
      <Modal.Body className={styles.message}>
        {i18next.t('operationsHandling:modal.message')}
      </Modal.Body>
      <BaseModalFooter handleValidate={handleValidate} setShowModal={setShowModal} />
    </Modal>
  );
};

export default OperationsHandlingDeleteModal;
