import BaseNavbarWithHistory from '@components/shared/BaseNavbarWithHistory/BaseNavbarWithHistory';
import SettingsSignatoriesDeleteModal from '@components/SettingsSignatoriesDeleteModal/SettingsSignatoriesDeleteModal';
import i18next from 'i18next';
import React, { useEffect, useState } from 'react';
import {
  Container,
  Row,
  Col,
  Button,
  Image,
  Popover,
  ListGroup,
  OverlayTrigger,
} from 'react-bootstrap';
import moreOptionsBlueIcon from '@assets/images/svg/more-options-blue.svg';
import SettingsSignatoriesAddModal from '@components/SettingsSignatoriesAddModal/SettingsSignatoriesAddModal';
import { useAppSelector, useAppDispatch } from '@store/hooks';
import {
  getSignatories,
  addSignatory,
  deleteSignatory,
  editSignatory,
} from '@store/reducers/signatory';
import { SignatoryDto } from '@models/dto/response/Signatory.dto';
import { ISignatory } from '@models/dto/request/SignatoriesParams.dto';
import { toast } from 'react-toastify';
import BaseNavbar from '@components/shared/BaseNavbar/BaseNavbar';
import accountIcon from '@assets/images/svg/account.svg';
import styles from './SettingsSignatories.module.scss';

const SettingsSignatories = () => {
  const [showAddModal, setShowAddModal] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [selectedSignatory, setSelectedSignatory] = useState<SignatoryDto>();
  const signatories = useAppSelector((state) => state.signatory.signatories);
  const establishmentId = useAppSelector((state) => state.account.selectedEstablishment?.id);
  const accountType = useAppSelector((state) => state.account.type);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (establishmentId) {
      dispatch(getSignatories(establishmentId));
    }
  }, []);

  const dismissModal = () => {
    setShowAddModal(false);
    setSelectedSignatory(undefined);
  };

  const addNewSignatory = (params: ISignatory) => {
    if (establishmentId) {
      toast.promise(dispatch(addSignatory({ establishmentId, signatory: params })), {
        pending: i18next.t('settings:signatories.toasts.pending.create'),
        success: i18next.t('settings:signatories.toasts.success.create'),
        error: i18next.t('settings:signatories.toasts.error.create'),
      });
      setShowAddModal(false);
    }
  };

  const editSignatoryForm = (signatory: SignatoryDto) => {
    setSelectedSignatory(signatory);
    setShowAddModal(true);
  };

  const confirmEditSignatory = (signatory: ISignatory, signatoryId: number) => {
    toast.promise(dispatch(editSignatory({ signatoryId, signatory })), {
      pending: i18next.t('settings:signatories.toasts.pending.update'),
      success: i18next.t('settings:signatories.toasts.success.update'),
      error: i18next.t('settings:signatories.toasts.error.update'),
    });
    setShowAddModal(false);
    setSelectedSignatory(undefined);
  };

  const deleteSignatoryForm = (signatory: SignatoryDto) => {
    setSelectedSignatory(signatory);
    setShowDeleteModal(true);
  };

  const confirmDeleteSignatory = (signatoryId: number) => {
    toast.promise(dispatch(deleteSignatory(signatoryId)), {
      pending: i18next.t('settings:signatories.toasts.pending.update'),
      success: i18next.t('settings:signatories.toasts.success.update'),
      error: i18next.t('settings:signatories.toasts.error.update'),
    });
    setShowDeleteModal(true);
  };

  return (
    <>
      <BaseNavbar accountType={accountType} />
      <BaseNavbarWithHistory
        title={i18next.t('settings:signatories.navbar.title')}
        showSettings={false}
        showHistory={false}
      />
      <Container fluid className="h-100 pt-5 cornerRound px-5">
        {/* Text */}
        <Row>
          <Col md={12} className="d-flex justify-content-between align-items-center">
            <div className={styles.listTitle}>{i18next.t('settings:signatories.list')}</div>
            <Button className={`${styles.addButton} btnXl`} onClick={() => setShowAddModal(true)}>
              {i18next.t('settings:signatories.addSignatory')}
            </Button>
          </Col>
        </Row>
        <hr className="my-5" />
        {/* Signatories */}
        {signatories?.length > 0 && (
          <Row>
            {signatories.map((signatory: SignatoryDto) => (
              <Col key={signatory.id} sm={6} md={4} lg={3}>
                <div className="text-center">
                  <div className={styles.moreIconContainer}>
                    <OverlayTrigger
                      trigger="click"
                      placement="bottom"
                      rootClose
                      overlay={
                        <Popover id={`zone-popover-${signatory.id}`}>
                          <Popover.Content>
                            <ListGroup>
                              <ListGroup.Item
                                className="border-0"
                                onClick={() => editSignatoryForm(signatory)}
                              >
                                {i18next.t('settings:signatories.edit')}
                              </ListGroup.Item>
                              <ListGroup.Item
                                className="border-0"
                                onClick={() => deleteSignatoryForm(signatory)}
                              >
                                {i18next.t('settings:signatories.delete')}
                              </ListGroup.Item>
                            </ListGroup>
                          </Popover.Content>
                        </Popover>
                      }
                    >
                      <Image
                        src={moreOptionsBlueIcon}
                        className={styles.moreIcon}
                        width={27}
                        height={27}
                      />
                    </OverlayTrigger>
                  </div>
                  <Image
                    src={signatory.image ?? accountIcon}
                    roundedCircle
                    className={`menu-item ${styles.picture} ${
                      signatory.image ? '' : styles.backgroundImage
                    }`}
                    width={200}
                    height={200}
                  />
                  <div
                    className={styles.signatoryName}
                  >{`${signatory.firstName} ${signatory.lastName[0]}.`}</div>
                </div>
              </Col>
            ))}
          </Row>
        )}
        <SettingsSignatoriesAddModal
          selectedSignatory={selectedSignatory}
          showModal={showAddModal}
          setShowModal={dismissModal}
          addNewSignatory={addNewSignatory}
          editNewSignatory={confirmEditSignatory}
        />
        {selectedSignatory ? (
          <SettingsSignatoriesDeleteModal
            picture={selectedSignatory.image}
            name={`${selectedSignatory.firstName} ${selectedSignatory.lastName}`}
            showModal={showDeleteModal}
            setShowModal={setShowDeleteModal}
            onValid={() => confirmDeleteSignatory(selectedSignatory.id)}
          />
        ) : undefined}
      </Container>
    </>
  );
};

export default SettingsSignatories;
