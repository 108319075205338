import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import nonComplianceService from '@services/non-compliance.service';
import { NonComplianceAnalysisDto } from '@models/dto/response/NonComplianceAnalysis.dto';
import {
  AddPhotoAnalysisNonComplianceParams,
  CreateAnalysisNonComplianceParams,
} from '@models/dto/request/AnalysisNonCompliance.dto';
import { NonComplianceDto } from '@models/dto/response/NonCompliance.dto';
import { UpdateNonComplianceParams } from '@models/dto/request/UpdateNonCompliance.dto';
import { CausesAndActionsDto } from '@models/dto/response/CausesAndActions.dto';
import { OperationAnalysisResponseDto } from '@models/dto/response/OperationAnalysis.dto';

export interface NonComplianceParams {
  analysis: number;
  id: number;
}

// Define a type for the slice state
interface NonComplianceState {
  selectedNonCompliance?: NonComplianceDto;
  selectedNonComplianceAnalysis?: NonComplianceAnalysisDto;
  causesAndCorrectivesActions: CausesAndActionsDto[];
}

// Define the initial state using that type
const initialState: NonComplianceState = {
  causesAndCorrectivesActions: [],
};

// REQUESTS
export const createAnalysis = createAsyncThunk<
  AxiosResponse<OperationAnalysisResponseDto>,
  CreateAnalysisNonComplianceParams
>('analysis/create', async (params: CreateAnalysisNonComplianceParams) => {
  const { establishmentId, ...rest } = params;
  const formData = new FormData();
  if (rest.analysisReport) {
    formData.append('analysisReport', rest.analysisReport);
  }
  if (rest.causeIds) {
    formData.append('causeIds', rest.causeIds);
  }
  if (rest.correctiveActionIds) {
    formData.append('correctiveActionIds', rest.correctiveActionIds);
  }
  if (rest.comment) {
    formData.append('comment', rest.comment);
  }
  formData.append('signatoryId', rest.signatoryId.toString());
  if (rest.analysisTypology) {
    formData.append('analysisTypology', rest.analysisTypology);
  }
  if (rest.realisationDate) {
    formData.append('realisationDate', rest.realisationDate);
  }
  const response = await nonComplianceService.createAnalysis(formData);
  return response.data;
});

export const updateAnalysis = createAsyncThunk<
  AxiosResponse<OperationAnalysisResponseDto>,
  AddPhotoAnalysisNonComplianceParams
  // eslint-disable-next-line consistent-return
>('analysis/addPhoto', async (data: AddPhotoAnalysisNonComplianceParams, { rejectWithValue }) => {
  const formData = new FormData();
  formData.append('picture', data.picture);
  try {
    const result = await nonComplianceService.addPhotoToAnalysis(data.analysisId, formData);
    return result.data;
  } catch (e) {
    rejectWithValue(e);
  }
});

export const getNonCompliance = createAsyncThunk<
  NonComplianceDto | NonComplianceAnalysisDto,
  NonComplianceParams
>('nonCompliance/get', async (params: NonComplianceParams) => {
  const { id, analysis } = params;
  const response = await nonComplianceService.getNonCompliance(id, analysis);
  return response.data.data;
});

export const updateNonCompliance = createAsyncThunk<
  NonComplianceDto | NonComplianceAnalysisDto,
  UpdateNonComplianceParams
>('nonCompliance/update', async (params: UpdateNonComplianceParams) => {
  const { nonComplianceId, analysis, ...rest } = params;
  const response = await nonComplianceService.updateNonCompliance(nonComplianceId, rest, analysis);
  return response.data.data;
});

export const getCausesAndActions = createAsyncThunk<AxiosResponse<CausesAndActionsDto[]>, void>(
  'nonCompliance/getCausesAndActions',
  async () => {
    const response = await nonComplianceService.getCausesAndActions();
    return response.data;
  },
);

export const NonComplianceSlice = createSlice({
  name: 'nonCompliance',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setNonCompliance: (state, action: PayloadAction<NonComplianceDto | undefined>) => {
      state.selectedNonCompliance = action.payload;
    },
    setNonComplianceAnalysis: (
      state,
      action: PayloadAction<NonComplianceAnalysisDto | undefined>,
    ) => {
      state.selectedNonComplianceAnalysis = action.payload;
    },
  },
  extraReducers: (builder) => {
    // Side effects
    // Get causes et corrective actions
    builder.addCase(getCausesAndActions.fulfilled, (state, action) => {
      state.causesAndCorrectivesActions = action.payload.data ?? [];
    });
    // Get non compliance
    builder.addCase(getNonCompliance.fulfilled, (state, action) => {
      if (action.meta.arg.analysis === 1) {
        state.selectedNonComplianceAnalysis = action.payload as NonComplianceAnalysisDto;
      } else {
        state.selectedNonCompliance = action.payload as NonComplianceDto;
      }
    });
  },
});

export const { setNonCompliance, setNonComplianceAnalysis } = NonComplianceSlice.actions;

export default NonComplianceSlice.reducer;
