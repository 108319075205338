import BaseNavbarWithHistory from '@components/shared/BaseNavbarWithHistory/BaseNavbarWithHistory';
import i18next from 'i18next';
import React, { useEffect, useState } from 'react';
import { Col, ListGroup, ListGroupItem, Row } from 'react-bootstrap';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { getAreas, selectArea, selectSurfaces } from '@store/reducers/area';
import { history } from '@router/routes';
import { AreaDto } from '@models/dto/response/Area.dto';
import CleaningZoneModal from '@components/CleaningZoneModal/CleaningZoneModal';
import { SurfaceDto } from '@models/dto/response/Surface.dto';
import styles from './CleaningListView.module.scss';

const CleaningListView = () => {
  const selectedArea = useAppSelector((state) => state.area.selectedArea);
  const areas = useAppSelector((state) =>
    state.area.areas.filter((area) => area.surfaces.length > 0),
  );
  const establishmentId = useAppSelector((state) => state.account.selectedEstablishment?.id);
  const [showModal, setShowModal] = useState<boolean>(false);

  const dispatch = useAppDispatch();

  const handleSelectArea = (item: AreaDto) => {
    dispatch(selectArea(item));
    setShowModal(true);
  };

  const setSelectedSurfaces = (surfaces: SurfaceDto[]) => {
    dispatch(selectSurfaces(surfaces));
    history.goBack();
  };

  useEffect(() => {
    if ((!areas || areas.length === 0) && establishmentId) {
      dispatch(getAreas(establishmentId)).unwrap();
    }
  }, []);

  return (
    <>
      <BaseNavbarWithHistory
        title={i18next.t('cleaning:navbar.listTitle')}
        showSettings={false}
        showHistory={false}
      />
      <Row noGutters className="cornerRound">
        <Col md={12}>
          {areas && areas.length > 0 ? (
            <ListGroup>
              {areas.map((item: AreaDto, index: number) => (
                <ListGroupItem
                  key={index}
                  onClick={() => handleSelectArea(item)}
                  className={`${styles.groupItem} px-5 py-4`}
                >
                  {item.name}
                </ListGroupItem>
              ))}
            </ListGroup>
          ) : (
            <p>{i18next.t('cleaning:zones.noZone')}</p>
          )}
        </Col>
      </Row>
      {selectedArea ? (
        <CleaningZoneModal
          zoneName={selectedArea.name}
          surfaceList={selectedArea.surfaces}
          setSelectedSurfaces={setSelectedSurfaces}
          showModal={showModal}
          setShowModal={setShowModal}
          cancelModal={() => setShowModal(false)}
        />
      ) : undefined}
    </>
  );
};

export default CleaningListView;
