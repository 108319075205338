import React, { Dispatch, SetStateAction } from 'react';
import i18next from 'i18next'; // i18next.t('pageName:exemple.exemple')
import { FrequencyJsonDto } from '@models/dto/FrequencyJson.dto';
import { FREQUENCY_JSON } from '@utils/constants';
import { Form } from 'react-bootstrap';
import styles from './BaseAmountInput.module.scss';

type Props = {
  setFrequencyJson: Dispatch<SetStateAction<FrequencyJsonDto | undefined>>;
  frequencyJson?: FrequencyJsonDto;
};

const BaseAmountInput = (props: Props) => {
  const { frequencyJson, setFrequencyJson } = props;

  const handleChangeAmount = (amount: number) => {
    if (frequencyJson) {
      setFrequencyJson({ ...frequencyJson, amount: amount ?? null });
    } else {
      setFrequencyJson({ ...FREQUENCY_JSON, amount: amount ?? null });
    }
  };
  return (
    <Form.Group
      className="d-flex flex-column flex-lg-row align-items-lg-center mt-5 text-nowrap"
      controlId="amount"
    >
      <Form.Label className={styles.inputLabel}>
        {i18next.t('hardwareTemp:settings.modal.form.repeat')}
      </Form.Label>
      <Form.Control
        as="select"
        custom
        className="baseInputLg ml-lg-3 ml-0 mt-2 mt-lg-0"
        value={frequencyJson?.amount ?? 0}
        onChange={(e) => handleChangeAmount(Number(e.target.value))}
      >
        <option value={0} disabled>
          {i18next.t('hardwareTemp:settings.modal.form.amount')}
        </option>
        <option value={1}>1</option>
        <option value={2}>2</option>
        <option value={3}>3</option>
        <option value={4}>4</option>
      </Form.Control>
    </Form.Group>
  );
};

export default BaseAmountInput;
