import React from 'react';
import i18next from 'i18next'; // i18next.t('pageName:exemple.exemple')
import { Button, Col, Row, Image } from 'react-bootstrap';
import ScrollMenu from 'react-horizontal-scrolling-menu';
import HardwareTempListItem from '@components/HardwareTempListItem/HardwareTempListItem';
import { EquipmentDto } from '@models/dto/response/Equipment.dto';
import { history } from '@router/routes';
import chevronIcon from '@assets/images/svg/chevron-right.svg';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { deleteEquipmentTemperature } from '@store/reducers/equipment';
import styles from './HardwareTempList.module.scss';

type Props = {
  hardwareList: EquipmentDto[];
  selectedHardware?: EquipmentDto[];
  setSelectedHardware: (item: EquipmentDto[] | undefined) => void;
  disableSelection: boolean;
};

const HardwareTempList = (props: Props) => {
  const { hardwareList, selectedHardware, setSelectedHardware, disableSelection } = props;

  const selectedEquipmentId = useAppSelector((state) => state.equipment.selectedEquipmentId);

  const dispatch = useAppDispatch();

  const handleSelectZone = (key: string | number | null) => {
    if (key && !disableSelection) {
      const selectedItem = hardwareList.find((el) => el.id === Number(key));
      if (selectedItem) {
        const isChecked = selectedHardware?.find(
          (hardware: EquipmentDto) => hardware.id === selectedItem.id,
        );
        if (selectedHardware && isChecked) {
          dispatch(deleteEquipmentTemperature(selectedItem.id));
          setSelectedHardware(
            selectedHardware.filter((hardware: EquipmentDto) => hardware.id !== selectedItem.id),
          );
          return;
        }
        if (selectedHardware) {
          setSelectedHardware([...selectedHardware, selectedItem]);
          return;
        }
        setSelectedHardware([selectedItem]);
      }
    }
  };

  const goToListView = () => {
    history.push('/hardware-temperature/list');
  };

  const List = (list: EquipmentDto[], selectedIds: EquipmentDto[]) => {
    return list.map((hardware) => {
      const isSelected = !!selectedIds?.find((item: EquipmentDto) => item?.id === hardware.id);
      return (
        <HardwareTempListItem
          key={hardware.id}
          name={hardware.name}
          image={hardware.picture}
          isSelected={isSelected}
          noItemSelected={selectedIds.length === 0}
          hardware={hardware}
          onSelect={handleSelectZone}
          disableSelection={disableSelection && selectedEquipmentId !== hardware.id}
        />
      );
    });
  };

  return (
    <div>
      <Row>
        <Col md={12} className="d-flex justify-content-between">
          <div className={styles.title}>{i18next.t('hardwareTemp:title')}</div>
          <Button
            className={`btnSm d-flex flex-row align-items-center ${styles.seeAll}`}
            onClick={goToListView}
            disabled={disableSelection}
          >
            {i18next.t('shared:buttons.seeAll')}
            <Image src={chevronIcon} width={6} height={11} className="ml-2" />
          </Button>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <ScrollMenu
            data={List(hardwareList, selectedHardware ?? [])}
            transition={2}
            alignCenter={false}
          />
        </Col>
      </Row>
    </div>
  );
};

export default HardwareTempList;
