import { CustomerDto } from '@models/dto/response/Customer.dto';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import customerService from '@services/customer.service';
import { AxiosResponse } from 'axios';
import { CreateCustomerParams, UpdateCustomerParams } from '@models/dto/request/CustomerParams.dto';

// Define a type for the slice state
interface CustomerState {
  customers: CustomerDto[];
  selectedCustomer?: CustomerDto;
}

// Define the initial state using that type
const initialState: CustomerState = {
  customers: [],
};

// REQUESTS
export const getCustomers = createAsyncThunk<AxiosResponse<CustomerDto[]>, number>(
  'customer/getAll',
  async (establishmentId: number) => {
    const response = await customerService.getCustomers(establishmentId);
    return response.data;
  },
);

export const getCustomer = createAsyncThunk<AxiosResponse<CustomerDto>, number>(
  'customer/get',
  async (customerId: number) => {
    const response = await customerService.getCustomer(customerId);
    return response.data;
  },
);

export const createCustomer = createAsyncThunk<AxiosResponse<CustomerDto>, CreateCustomerParams>(
  'customer/create',
  async (params: CreateCustomerParams) => {
    const { establishmentId, ...rest } = params;
    const response = await customerService.createCustomer(establishmentId, rest);
    return response.data;
  },
);

export const updateCustomer = createAsyncThunk<AxiosResponse<CustomerDto>, UpdateCustomerParams>(
  'customer/update',
  async (params: UpdateCustomerParams) => {
    const { customerId, ...rest } = params;
    const response = await customerService.updateCustomer(customerId, rest);
    return response.data;
  },
);

export const deleteCustomer = createAsyncThunk<void, number>(
  'customer/delete',
  async (customerId: number) => {
    const response = await customerService.deleteCustomer(customerId);
    return response.data;
  },
);

export const CustomerSlice = createSlice({
  name: 'customer',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setSelectedCustomer: (state, action: PayloadAction<CustomerDto | undefined>) => {
      state.selectedCustomer = action.payload;
    },
  },
  extraReducers: (builder) => {
    // Side effects
    // Get customer list
    builder.addCase(getCustomers.fulfilled, (state, action) => {
      state.customers = action.payload.data ?? [];
    });
    // Get customer
    builder.addCase(getCustomer.fulfilled, (state, action) => {
      state.selectedCustomer = action.payload.data;
    });
    // Add new customer
    builder.addCase(createCustomer.fulfilled, (state, action) => {
      state.customers.push(action.payload.data);
    });
    // Update customer
    builder.addCase(updateCustomer.fulfilled, (state, action) => {
      const index = state.customers.findIndex((el) => el.id === action.payload.data.id);
      state.customers[index] = action.payload.data;
    });
    // Delete customer
    builder.addCase(deleteCustomer.fulfilled, (state, action) => {
      const index = state.customers.findIndex((el) => el.id === action.meta.arg);
      state.customers.splice(index, 1);
    });
  },
});

export const { setSelectedCustomer } = CustomerSlice.actions;

export default CustomerSlice.reducer;
