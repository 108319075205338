import CleaningZoneSettingsModal from '@components/CleaningZoneSettingsModal/CleaningZoneSettingsModal';
import CleaningZoneSettingsItem from '@components/CleaningZoneSettingsItem/CleaningZoneSettingsItem';
import BaseNavbarWithHistory from '@components/shared/BaseNavbarWithHistory/BaseNavbarWithHistory';
import i18next from 'i18next';
import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Button, Image } from 'react-bootstrap';
import { useAppSelector, useAppDispatch } from '@store/hooks';
import { getAreas, addAreas, deleteArea } from '@store/reducers/area';
import { toast } from 'react-toastify';
import plusWhite from '@assets/images/svg/plus-rond.svg';
import BaseNavbar from '@components/shared/BaseNavbar/BaseNavbar';
import styles from './CleaningZoneSettings.module.scss';

const CleaningZoneSettings = () => {
  const areas = useAppSelector((state) => state.area.areas);
  const establishmentId = useAppSelector((state) => state.account.selectedEstablishment?.id);
  const accountType = useAppSelector((state) => state.account.type);
  const [showModal, setShowModal] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if ((!areas || areas.length === 0) && establishmentId) {
      dispatch(getAreas(establishmentId)).unwrap();
    }
  }, []);

  const removeArea = (areaId: number) => {
    toast.promise(dispatch(deleteArea(areaId)).unwrap(), {
      pending: i18next.t('cleaning:toasts.pending.deleteArea'),
      success: i18next.t('cleaning:toasts.success.deleteArea'),
      error: i18next.t('cleaning:toasts.error.deleteArea'),
    });
  };

  const addCleaningZone = () => {
    setShowModal(true);
  };

  const createZone = (name: string) => {
    if (establishmentId) {
      toast.promise(dispatch(addAreas({ establishmentId, name })).unwrap(), {
        pending: i18next.t('cleaning:toasts.pending.createArea'),
        success: i18next.t('cleaning:toasts.success.createArea'),
        error: i18next.t('cleaning:toasts.error.createArea'),
      });
    }
  };

  return (
    <>
      <BaseNavbar accountType={accountType} parametersUrl="/settings" />
      <BaseNavbarWithHistory
        title={i18next.t('cleaning:settings.title')}
        showSettings={false}
        showHistory={false}
      />
      <Container fluid className="h-100 pt-5 cornerRound px-5">
        <Row>
          <Col md={12} className="d-flex justify-content-between align-items-center">
            <div className={styles.listTitle}>{i18next.t('cleaning:settings.zones.list')}</div>
            <Button
              className={`${styles.addButton} d-flex justify-content-center align-items-center btnXl`}
              onClick={addCleaningZone}
            >
              <Image src={plusWhite} width={24} height={24} className="mr-2" />
              {i18next.t('cleaning:settings.zones.addZone')}
            </Button>
          </Col>
        </Row>
        {areas?.length > 0 ? (
          <Row>
            {areas.map((zone: any) => (
              <Col sm={6} md={4} key={zone.id} className="mt-4">
                <CleaningZoneSettingsItem
                  zone={zone}
                  setShowModalDelete={() => removeArea(zone.id)}
                />
              </Col>
            ))}
          </Row>
        ) : (
          <Row className="d-flex justify-content-center align-items-center h-100">
            <Col md={12} className="text-center tableHeader">
              {i18next.t('cleaning:settings.zones.empty')}
            </Col>
          </Row>
        )}
        <CleaningZoneSettingsModal
          showModal={showModal}
          setShowModal={setShowModal}
          onValidForm={createZone}
        />
      </Container>
    </>
  );
};

export default CleaningZoneSettings;
