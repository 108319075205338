import { configureStore } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { combineReducers } from 'redux';
import { AccountSlice } from './reducers/account';
import { AreaSlice } from './reducers/area';
import { CalendarExceptionsSlice } from './reducers/calendar-exceptions';
import { CustomerSlice } from './reducers/customer';
import { DeepFryerSlice } from './reducers/deep-fryer';
import { DishSlice } from './reducers/dish';
import { EquipmentSlice } from './reducers/equipment';
import { FrequenciesSlice } from './reducers/frequencies';
import { MediaSlice } from './reducers/media';
import { OperationSlice } from './reducers/operation';
import { ProductSlice } from './reducers/product';
import { ProfileSlice } from './reducers/profile';
import { SignatorySlice } from './reducers/signatory';
import { SupplierSlice } from './reducers/supplier';
import { NotificationsSlice } from './reducers/notifications';
import { ServiceParametersSlice } from './reducers/service-parameters';
import { HistorySlice } from './reducers/history';
import { DocumentsSlice } from './reducers/documents';
import { NonComplianceSlice } from './reducers/non-compliance';
import { ProductTemperatureSlice } from './reducers/product-temperature';

const reducers = combineReducers({
  account: AccountSlice.reducer,
  area: AreaSlice.reducer,
  calendarExceptions: CalendarExceptionsSlice.reducer,
  customer: CustomerSlice.reducer,
  deepFryer: DeepFryerSlice.reducer,
  dish: DishSlice.reducer,
  equipment: EquipmentSlice.reducer,
  frequencies: FrequenciesSlice.reducer,
  media: MediaSlice.reducer,
  operation: OperationSlice.reducer,
  product: ProductSlice.reducer,
  profile: ProfileSlice.reducer,
  signatory: SignatorySlice.reducer,
  supplier: SupplierSlice.reducer,
  notification: NotificationsSlice.reducer,
  serviceParameters: ServiceParametersSlice.reducer,
  history: HistorySlice.reducer,
  document: DocumentsSlice.reducer,
  nonCompliance: NonComplianceSlice.reducer,
  productTemperature: ProductTemperatureSlice.reducer,
});

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['account'],
};

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export default store;
