import React from 'react';
import { Table } from 'react-bootstrap';
import { format } from 'date-fns';
import { CalendarExceptionDto } from '@models/dto/response/CalendarExceptionList.dto';
import BaseTableButtons from '@components/shared/BaseTableButtons/BaseTableButtons';

type Props = {
  exceptions: CalendarExceptionDto[];
  onEditItem: (item: CalendarExceptionDto) => void;
  onDeleteItem: (item: CalendarExceptionDto) => void;
};

const SettingsCalendarExceptionsTable = (props: Props) => {
  return (
    <Table responsive>
      <thead className="border-top-0">
        <tr>
          <th> </th>
          <th> </th>
        </tr>
      </thead>
      <tbody>
        {props.exceptions.map((exception: CalendarExceptionDto, index: number) => {
          return (
            <tr key={index} className="py-5">
              <td className="tableFirstColumn">{`Du ${format(
                new Date(exception.startDate),
                'dd/MM/yyyy',
              )} au ${format(new Date(exception.endDate), 'dd/MM/yyyy')}`}</td>
              <td>
                <BaseTableButtons
                  editAction={() => props.onEditItem(exception)}
                  deleteAction={() => props.onDeleteItem(exception)}
                />
              </td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};

export default SettingsCalendarExceptionsTable;
