import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import i18next from 'i18next';
import { FormControlLabel, Checkbox } from '@material-ui/core';

interface BaseNonCompliantCheckboxeProps {
  hasNonCompliance: number;
  onChange: (hasNonCompliance: number) => void;
}

export default function BaseNonCompliantCheckboxe({
  hasNonCompliance,
  onChange,
}: BaseNonCompliantCheckboxeProps) {
  const [checked, setChecked] = useState(false);

  return (
    <Form.Group className="d-flex flex-column" controlId="hasNonCompliance">
      <FormControlLabel
        className={`${checked ? 'checked' : ''}`}
        control={
          <Checkbox
            value={hasNonCompliance}
            className={`${checked ? 'checked' : ''}`}
            checked={checked ?? false}
            onChange={(e: any) => {
              const newValue = !!e.target.checked;
              setChecked(newValue);
              onChange(newValue ? 1 : 0);
            }}
          />
        }
        label={i18next.t('operationsHistory:filters.complianceCheckbox')}
      />
    </Form.Group>
  );
}
