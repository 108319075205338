import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { Card, Image } from 'react-bootstrap';
import { selectEquipment, selectEquipmentTemperature } from '@store/reducers/equipment';
import roundedCheckIcon from '@assets/images/svg/check-rond.svg';
import placeholderImage from '@assets/images/svg/placeholder.svg';
import BaseTemperature from '@components/shared/BaseTemperature/BaseTemperature';
import { EquipmentDto, EquipmentTemperatureDto } from '@models/dto/response/Equipment.dto';

import styles from './HardwareTempListItem.module.scss';

type Props = {
  name: string;
  image: string;
  isSelected: boolean;
  noItemSelected: boolean;
  hardware: EquipmentDto;
  onSelect: (hardwareId: number) => void;
  disableSelection: boolean;
};

const HardwareTempListItem = (props: Props) => {
  const { name, image, isSelected, noItemSelected, hardware } = props;

  const selectedEquipmentTemparature = useAppSelector(
    (state) => state.equipment.selectedEquipmentTemparature,
  );
  const selectedEquipmentId = useAppSelector((state) => state.equipment.selectedEquipmentId);

  const dispatch = useAppDispatch();

  const [temperature, setTemperature] = useState<string | undefined>();
  const selectedHardware = useAppSelector((state) => state.equipment.selectedEquipment);

  useEffect(() => {
    if (temperature) {
      dispatch(
        selectEquipmentTemperature({
          equipmentId: hardware.id,
          temperature: parseFloat(temperature),
        }),
      );
      const isAlreadySelected = selectedHardware?.find(
        (equipment: EquipmentDto) => equipment.id === hardware.id,
      );
      if (!isAlreadySelected) {
        if (selectedHardware) {
          dispatch(selectEquipment([...selectedHardware, hardware]));
        } else {
          dispatch(selectEquipment([hardware]));
        }
      }
    }
  }, [temperature]);

  useEffect(() => {
    const isCompleted = selectedEquipmentTemparature?.find(
      (equipment: EquipmentTemperatureDto) => equipment.equipmentId === hardware.id,
    );
    if (isCompleted) {
      setTemperature(`${isCompleted.temperature}`);
    }
  }, []);

  return (
    <Card
      className={`${styles.cardContainer} ${
        !isSelected && !noItemSelected ? styles.fade : ''
      } h-100 mr-3 my-5`}
      onClick={isSelected ? undefined : () => props.onSelect(hardware.id)}
    >
      <Card.Img
        variant="top"
        src={image ?? placeholderImage}
        className={styles.imgContainer}
        onClick={() => {
          props.onSelect(hardware.id);
          if (selectedEquipmentId !== hardware.id) {
            setTemperature(undefined);
          }
        }}
      />
      <Card.Body className="d-flex justify-content-center align-items-center flex-column text-wrap text-center border-top">
        {isSelected && (
          <Image src={roundedCheckIcon} className={styles.roundedCheck} width={48} height={48} />
        )}
        <Card.Text className={styles.cardText}>{name}</Card.Text>
        <div style={{ width: '100%' }}>
          <BaseTemperature
            size="small"
            temperature={temperature}
            setTemperature={(val) =>
              props.disableSelection ? setTemperature(undefined) : setTemperature(val)
            }
            tMin={hardware?.minTemperature}
            tMax={hardware?.maxTemperature}
          />
        </div>
      </Card.Body>
    </Card>
  );
};

export default HardwareTempListItem;
