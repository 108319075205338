export interface FrequencyDto {
  type: FrequencySlotType;
  startHour: number;
  startMinutes: number;
  endHour: number;
  endMinutes: number;
  active: boolean;
}
export interface IFrequencyDto {
  type: FrequencySlotType;
  startHour: number;
  startMinutes: number;
  endHour: number;
  endMinutes: number;
  active: number;
}

export enum FrequencySlotType {
  MORNING = 'frequency_morning',
  MIDDAY = 'frequency_midday',
  AFTERNOON = 'frequency_afternoon',
  EVENING = 'frequency_evening',
}
