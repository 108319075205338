import { UpdateServiceParametersDto } from '@models/dto/request/ServiceParametersParams.dto';
import Axios from './index';

export default {
  getServiceParameters: (establishmentId: number) => {
    return Axios.get(`/establishment/${establishmentId}/serviceTempParameter`);
  },

  updateServiceParameters: (establishmentId: number, parameters: UpdateServiceParametersDto) => {
    return Axios.put(`/establishment/${establishmentId}/serviceTempParameter`, parameters);
  },
};
