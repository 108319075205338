import BaseNavbarWithHistory from '@components/shared/BaseNavbarWithHistory/BaseNavbarWithHistory';
import ReceptionControlForm from '@components/ReceptionControlForm/ReceptionControlForm';
import i18next from 'i18next';
import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import BaseTemperature from '@components/shared/BaseTemperature/BaseTemperature';
import BaseDatePicker from '@components/shared/BaseDatePicker/BaseDatePicker';
import BaseSignatory from '@components/shared/BaseSignatory/BaseSignatory';
import BasePhotoPicker from '@components/shared/BasePhotoPicker/BasePhotoPicker';
import { format, formatISO, isValid, setMinutes, setHours } from 'date-fns';
import { getSignatories } from '@store/reducers/signatory';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { getSuppliers } from '@store/reducers/supplier';
import { SignatoryDto } from '@models/dto/response/Signatory.dto';
import { toast } from 'react-toastify';
import {
  CreateReceptionControlOperation,
  UpdateReceptionControlOperation,
} from '@store/reducers/operation';
import { CreateReceptionControlOperationDto } from '@models/dto/request/CreateReceptionControlOperation.dto';
import { history } from '@router/routes';
import AccountTypeEnum from '@models/enums/AccountType';
import OperationTypeEnum from '@models/enums/OperationType';

import { getProductFamilies } from '@store/reducers/product';
import { SupplierDto } from '@models/dto/response/Supplier.dto';
import { ProductFamilyDto } from '@models/dto/response/ProductFamily.dto';
import { ProductSubFamilyDto } from '@models/dto/response/ProductSubFamily.dto';
import { setNonCompliance } from '@store/reducers/non-compliance';
import NonComplianceRedirectModal from '@components/NonComplianceRedirectModal/NonComplianceRedirectModal';
import styles from './ReceptionControl.module.scss';

export interface IReceptionControlData {
  supplier?: SupplierDto;
  family?: ProductFamilyDto;
  subFamily?: ProductSubFamilyDto;
}

const ReceptionControl = () => {
  // Store
  const suppliers = useAppSelector((state) => state.supplier.suppliers);
  const signatories = useAppSelector((state) => state.signatory.signatories);
  const productFamilies = useAppSelector((state) => state.product.productFamilies);
  const establishmentId = useAppSelector((state) => state.account.selectedEstablishment?.id);
  const accountType = useAppSelector((state) => state.account.type);
  // Component State
  const [form, setForm] = useState<IReceptionControlData>();
  const [temperature, setTemperature] = useState<string>();
  const [isCompliant, setIsCompliant] = useState<boolean>(false);
  const [currentDate, setCurrentDate] = useState<Date>(new Date());
  const [currentTime, setCurrentTime] = useState<string>(format(new Date(), 'HH:mm'));
  const [selectedSignatory, setSelectedSignatory] = useState<SignatoryDto>();
  const [photos, setPhotos] = useState<any>([]);
  const [showModal, setShowModal] = useState<boolean>(false);

  const dispatch = useAppDispatch();

  // load dishes family and signatories
  useEffect(() => {
    if (!productFamilies || productFamilies.length === 0) {
      dispatch(getProductFamilies()).unwrap();
    }
    if ((!signatories || signatories.length === 0) && establishmentId) {
      dispatch(getSignatories(establishmentId)).unwrap();
    }
    if ((!suppliers || suppliers.length === 0) && establishmentId) {
      dispatch(getSuppliers(establishmentId)).unwrap();
    }
  }, []);

  const redirectToNonCompliance = () => {
    setShowModal(false);
    history.push('/operations-handling/solve');
  };

  const redirectToDashboard = () => {
    history.push('/dashboard');
  };

  // Update Date with custom time
  const buildDate = () => {
    let formattedDate = currentDate;
    const timeArray = currentTime.split(':');
    formattedDate = setMinutes(formattedDate, Number(timeArray[1]));
    formattedDate = setHours(formattedDate, Number(timeArray[0]));
    return formattedDate;
  };

  const create = (date: Date, isNonCompliance: number) => {
    const data = {
      signatoryId: selectedSignatory!.id,
      temperature: parseFloat(temperature!),
      productSubFamilyId: form!.subFamily!.id,
      supplierId: form!.supplier!.id,
      realisationDate: formatISO(date),
      nonCompliance: isNonCompliance,
    } as CreateReceptionControlOperationDto;

    dispatch(CreateReceptionControlOperation(data))
      .unwrap()
      .then((res) => {
        const receptControlID = res.operation.id;
        const requests = photos.map((photo: string) => {
          return dispatch(
            UpdateReceptionControlOperation({
              id: receptControlID,
              picture: photo,
            }),
          );
        });
        Promise.all(requests)
          .then(() => {
            toast.success(i18next.t('receptionControl:toasts.success.createOperation'));
          })
          .catch(() => {
            toast.error(i18next.t('receptionControl:toasts.error.createOperation'));
          });
        // Check if payload is present
        if (res.nonCompliances && res.nonCompliances.length > 0) {
          // Get non compliances
          const nonCompliance = res.nonCompliances[0];
          // Show modal
          if (nonCompliance) {
            // Set in store to solve
            dispatch(setNonCompliance(nonCompliance));
            // Show modal solve non compliance
            setShowModal(true);
          } else {
            redirectToDashboard();
          }
        } else {
          redirectToDashboard();
        }
      })
      .catch(() => {
        toast.error(i18next.t('receptionControl:toasts.error.createOperation'));
      });
  };

  const onClickValidForm = (nonCompliance: number) => {
    if (!form || !form.supplier) {
      toast.warn(i18next.t('receptionControl:toasts.warn.noSupplier'));
      return;
    }
    if (!form || !form.subFamily) {
      toast.warn(i18next.t('receptionControl:toasts.warn.noDishType'));
      return;
    }
    if (
      !temperature &&
      temperature !== '0' &&
      form &&
      form.family &&
      form.family.name !== 'EPICERIE'
    ) {
      toast.warn(i18next.t('receptionControl:toasts.warn.noTemperature'));
      return;
    }
    if (!selectedSignatory) {
      toast.warn(i18next.t('receptionControl:toasts.warn.noSignatory'));
      return;
    }
    const date = buildDate();

    if (!isValid(date)) {
      toast.warn(i18next.t('receptionControl:toasts.warn.noDate'));
      return;
    }

    create(date, nonCompliance);
  };

  return (
    <>
      <div style={{ display: 'block' }}>
        {/* Navbar */}
        <BaseNavbarWithHistory
          title={i18next.t('receptionControl:navbar.title')}
          showSettings={accountType === AccountTypeEnum.admin}
          typeOperation={OperationTypeEnum.inspection}
        />
        <Container fluid className="cornerRound">
          {/* Select form */}
          <Row className={`p-md-5 p-2 ${styles.formContainer}`}>
            <Col md={12} className="mb-1">
              <ReceptionControlForm
                onChange={setForm}
                productFamilies={productFamilies}
                suppliers={suppliers}
              />
            </Col>
          </Row>
          {/* Date picker */}
          <div className="my-5 px-md-5 px-3">
            <BaseDatePicker
              currentDate={currentDate}
              setCurrentDate={setCurrentDate}
              currentTime={currentTime}
              setCurrentTime={setCurrentTime}
              showTitle
            />
          </div>
          <hr style={{ width: '80%' }} />
          <div className="my-5 px-md-5 px-3">
            <BasePhotoPicker
              multiPictureAllowed
              photos={photos}
              setPhotos={setPhotos}
              title={i18next.t('shared:photoPicker.titleProduct')}
              acceptType="image/jpeg, image/jpg,image/png,application/pdf"
            />
          </div>
          {/* Temperature input (only available if not EPICERIE) */}
          {form && form.family && form.family.name !== 'EPICERIE' && (
            <>
              <hr style={{ width: '80%' }} />
              <div className="my-5">
                <BaseTemperature
                  temperature={temperature}
                  setTemperature={setTemperature}
                  tMin={form.family.minTemperature}
                  tMax={form.family.maxTemperature}
                  setIsCompliant={setIsCompliant}
                />
              </div>
            </>
          )}
          <hr style={{ width: '80%' }} />
          {/* Signatories */}
          <BaseSignatory
            signatories={signatories}
            selectedSignatory={selectedSignatory}
            setSelectedSignatory={setSelectedSignatory}
          />
          <Row className={`${styles.buttonsContainer} justify-content-center`}>
            <Col md={8} className="d-flex flex-column justify-content-center">
              {!isCompliant ? (
                <Button
                  onClick={() => onClickValidForm(0)}
                  className={`${styles.buttonCompliant} btnXxl mb-4`}
                >
                  {i18next.t('receptionControl:buttons.compliant')}
                </Button>
              ) : null}
              <Button
                onClick={() => onClickValidForm(!isCompliant ? 1 : 0)}
                className={`${styles.buttonNonCompliant} btnXxl`}
              >
                {i18next.t('receptionControl:buttons.nonCompliant')}
              </Button>
            </Col>
          </Row>
          <NonComplianceRedirectModal
            showModal={showModal}
            setShowModal={setShowModal}
            handleValidate={redirectToNonCompliance}
            handleCancel={redirectToDashboard}
          />
        </Container>
      </div>
    </>
  );
};

export default ReceptionControl;
