import React, { Dispatch, SetStateAction, useRef } from 'react';
import { Form, Image } from 'react-bootstrap';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import fr from 'date-fns/locale/fr';
import calendarIcon from '@assets/images/svg/calendar.svg';
import i18next from 'i18next'; // i18next.t('pageName:exemple.exemple')
import styles from './PrintingLabelsDates.module.scss';

registerLocale('fr', fr);

type Props = {
  openDate: any;
  setOpenDate: Dispatch<SetStateAction<any>>;
  dlc: any;
  setDlc: Dispatch<SetStateAction<any>>;
};

const PrintingLabelsDates = (props: Props) => {
  const { openDate, dlc, setOpenDate, setDlc } = props;
  const calendarRef = useRef<DatePicker<any>>(null);

  const handleClickCalendarIcon = () => {
    if (calendarRef && calendarRef.current) {
      calendarRef.current.setOpen(true);
    }
  };

  return (
    <Form>
      {/* Date picker */}
      <Form.Group
        className="mb-3 mr-4 d-flex flex-md-row flex-column align-items-md-center"
        controlId="openDate"
      >
        <Form.Label className={styles.inputLabel}>
          {i18next.t('printingLabels:openDate')}
        </Form.Label>
        <div className="d-flex flex-row">
          <DatePicker
            locale="fr"
            selected={openDate}
            dateFormat="dd/MM/yyyy"
            onChange={(date: Date) => setOpenDate(date)}
            className={`${styles.datePicker} ml-0 ml-md-3`}
            wrapperClassName="custom-datepicker"
            ref={calendarRef}
          />
          <Image
            src={calendarIcon}
            className={styles.calendarIcon}
            onClick={handleClickCalendarIcon}
          />
        </div>
      </Form.Group>
      {/* DLC */}
      <Form.Group className="d-flex flex-row align-items-center mt-5 text-nowrap" controlId="dlc">
        <Form.Label className={styles.inputLabel}>{i18next.t('printingLabels:dlc')}</Form.Label>
        <Form.Control
          as="select"
          custom
          className={`baseInputLg ml-3 ${styles.dlcInput}`}
          value={dlc}
          onChange={(e) => {
            setDlc(parseInt(e.target.value, 10));
          }}
        >
          <option value={0}>0</option>
          <option value={1}>J+1</option>
          <option value={2}>J+2</option>
          <option value={3}>J+3</option>
          <option value={5}>J+5</option>
          <option value={7}>J+7</option>
          <option value={15}>J+15</option>
          <option value={20}>J+20</option>
          <option value={30}>J+30</option>
          <option value={60}>J+60</option>
        </Form.Control>
      </Form.Group>
    </Form>
  );
};

export default PrintingLabelsDates;
