import BaseDatePicker from '@components/shared/BaseDatePicker/BaseDatePicker';
import BaseNavbarWithHistory from '@components/shared/BaseNavbarWithHistory/BaseNavbarWithHistory';
import BaseSaveButton from '@components/shared/BaseSaveButton/BaseSaveButton';
import BaseSignatory from '@components/shared/BaseSignatory/BaseSignatory';
import BaseTemperature from '@components/shared/BaseTemperature/BaseTemperature';
import { SignatoryDto } from '@models/dto/response/Signatory.dto';
import { useAppSelector, useAppDispatch } from '@store/hooks';
import i18next from 'i18next';
import { format, isValid, setMinutes, setHours, formatISO } from 'date-fns';
import React, { useState, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import OperationTempReadingTypeEnum from '@models/enums/OperationTempReadingType';
import { UpdateProductTempReadingParams } from '@models/dto/request/ProductTempReading.dto';
import {
  UnfinishedProductTempReadingCoolingDto,
  UnfinishedProductTempReadingReheatingDto,
} from '@models/dto/response/UnfinishedProductTempReading.dto';
import {
  getRunningProductTemp,
  setSelectedUnfinishedTempReading,
  updateCoolingTempReading,
  updateReheatingTempReading,
} from '@store/reducers/product-temperature';
import { history } from '@router/routes';
import ProductTempUnfinishedSolveInfo from '@components/ProductTempUnfinishedSolveInfo/ProductTempUnfinishedSolveInfo';
import { getSignatories } from '@store/reducers/signatory';
import { setSelectedTaskId } from '@store/reducers/history';
import { setNonCompliance } from '@store/reducers/non-compliance';
import NonComplianceRedirectModal from '@components/NonComplianceRedirectModal/NonComplianceRedirectModal';
import { TEMPERATURE_MAX, TEMPERATURE_MIN } from '@utils/constants';

const ProductTempUnfinishedSolve = () => {
  // From store
  const signatories = useAppSelector((state) => state.signatory.signatories);
  const establishmentId = useAppSelector((state) => state.account.selectedEstablishment?.id);
  const unfinishedTempReading = useAppSelector(
    (state) => state.productTemperature.selectedUnfinishedTempReading,
  );
  const selectedTaskId = useAppSelector((state) => state.history.selectedTaskId);
  const dispatch = useAppDispatch();
  // Component state
  const [currentDate, setCurrentDate] = useState<Date>(new Date());
  const [currentTime, setCurrentTime] = useState<string>(format(new Date(), 'HH:mm'));
  const [selectedSignatory, setSelectedSignatory] = useState<SignatoryDto | undefined>(
    unfinishedTempReading?.signatory,
  );
  const [temperature, setTemperature] = useState<string>();
  const [showModal, setShowModal] = useState<boolean>(false);

  const redirectToDashboard = () => {
    history.push('/dashboard');
  };

  const redirectToNonCompliance = () => {
    setShowModal(false);
    history.push('/operations-handling/solve');
  };

  const handleNonCompliance = (res: any) => {
    // Check if payload is present
    if (res.nonCompliances && res.nonCompliances.length > 0) {
      // Get non compliances
      const nonCompliance = res.nonCompliances[0];
      // Show modal
      if (nonCompliance) {
        // Set in store to solve
        dispatch(setNonCompliance(nonCompliance));
        // Show modal solve non compliance
        setShowModal(true);
      } else {
        redirectToDashboard();
      }
    } else {
      redirectToDashboard();
    }
  };

  const save = (
    date: Date,
    measure: UnfinishedProductTempReadingCoolingDto | UnfinishedProductTempReadingReheatingDto,
  ) => {
    let data;
    switch (measure.subType) {
      case OperationTempReadingTypeEnum.cooling:
        data = {
          productTempReadingId: measure.id,
          endTemperature: parseFloat(temperature!),
          signatoryId: selectedSignatory!.id,
          secondReadingDate: formatISO(new Date(date)),
        } as UpdateProductTempReadingParams;

        // Link to task if exists
        if (selectedTaskId) {
          data.taskId = selectedTaskId;
        }

        dispatch(updateCoolingTempReading(data))
          .unwrap()
          .then((res) => {
            toast.success(i18next.t('hardwareTemp:toasts.success.create'));
            handleNonCompliance(res);
          })
          .catch(() => {
            toast.error(i18next.t('hardwareTemp:toasts.error.create'));
          })
          .finally(() => {
            dispatch(setSelectedUnfinishedTempReading(undefined));
            if (establishmentId) {
              dispatch(getRunningProductTemp(establishmentId));
            }
          });
        break;
      case OperationTempReadingTypeEnum.reheating:
        data = {
          productTempReadingId: measure.id,
          endTemperature: parseFloat(temperature!),
          signatoryId: selectedSignatory!.id,
          secondReadingDate: formatISO(new Date(date)),
        } as UpdateProductTempReadingParams;

        // Link to task if exists
        if (selectedTaskId) {
          data.taskId = selectedTaskId;
        }

        dispatch(updateReheatingTempReading(data))
          .unwrap()
          .then((res) => {
            toast.success(i18next.t('hardwareTemp:toasts.success.create'));
            handleNonCompliance(res);
          })
          .catch(() => {
            toast.error(i18next.t('hardwareTemp:toasts.error.create'));
          })
          .finally(() => {
            dispatch(setSelectedUnfinishedTempReading(undefined));
            if (establishmentId) {
              dispatch(getRunningProductTemp(establishmentId));
            }
          });
        break;
      default:
        break;
    }
  };

  // Update Date with custom time
  const buildDate = () => {
    let formattedDate = currentDate;
    const timeArray = currentTime.split(':');
    formattedDate = setMinutes(formattedDate, Number(timeArray[1]));
    formattedDate = setHours(formattedDate, Number(timeArray[0]));
    return formattedDate;
  };

  const handleSave = () => {
    // Form check if completed
    if (!temperature && temperature !== '0') {
      toast.warn(i18next.t('hardwareTemp:toasts.warn.temperature'));
      return;
    }
    if (!currentDate) {
      toast.warn(i18next.t('hardwareTemp:toasts.warn.date'));
      return;
    }
    if (!currentTime) {
      toast.warn(i18next.t('hardwareTemp:toasts.warn.time'));
      return;
    }
    if (!selectedSignatory) {
      toast.warn(i18next.t('hardwareTemp:toasts.warn.signatory'));
      return;
    }

    const date = buildDate();

    // Is date valid format
    if (isValid(date) && unfinishedTempReading) {
      save(date, unfinishedTempReading);
    }
  };

  useEffect(() => {
    if (!unfinishedTempReading) {
      history.goBack();
    }
  }, []);

  useEffect(() => {
    if ((!signatories || signatories.length === 0) && establishmentId) {
      dispatch(getSignatories(establishmentId)).unwrap();
    }

    return () => {
      dispatch(setSelectedTaskId(undefined));
    };
  }, []);

  const getTMax = () => {
    switch (unfinishedTempReading?.subType) {
      case OperationTempReadingTypeEnum.cooling:
        return TEMPERATURE_MIN;
      case OperationTempReadingTypeEnum.reheating:
        return undefined;
      default:
        return unfinishedTempReading?.dishSubFamily.maxTemperature;
    }
  };

  const getTMin = () => {
    switch (unfinishedTempReading?.subType) {
      case OperationTempReadingTypeEnum.cooling:
        return undefined;
      case OperationTempReadingTypeEnum.reheating:
        return TEMPERATURE_MAX;
      default:
        return unfinishedTempReading?.dishSubFamily.minTemperature;
    }
  };

  return (
    <>
      {/* Navbar */}
      <BaseNavbarWithHistory
        title={i18next.t('productTemp:navbar.solveTitle')}
        showSettings={false}
        showHistory={false}
      />
      <Container fluid>
        {/* Action type */}
        {unfinishedTempReading && (
          <ProductTempUnfinishedSolveInfo informations={unfinishedTempReading} />
        )}
        {/* Temperature */}
        <div className="my-5">
          <BaseTemperature
            temperature={temperature}
            setTemperature={setTemperature}
            label={i18next.t('productTemp:unfinished.finishMeasure')}
            tMax={getTMax()}
            tMin={getTMin()}
          />
        </div>
        <hr style={{ width: '80%' }} />
        {/*  Date  / Time */}
        <Row className="mb-5 px-5">
          <Col md={12}>
            <BaseDatePicker
              currentDate={currentDate}
              setCurrentDate={setCurrentDate}
              currentTime={currentTime}
              setCurrentTime={setCurrentTime}
            />
          </Col>
        </Row>
        <hr style={{ width: '80%' }} />
        {/* Signatories */}
        <BaseSignatory
          signatories={signatories}
          selectedSignatory={selectedSignatory}
          setSelectedSignatory={setSelectedSignatory}
        />
        <BaseSaveButton
          onSave={handleSave}
          text={i18next.t('productTemp:unfinished.endMeasureButton')}
        />
        <NonComplianceRedirectModal
          showModal={showModal}
          setShowModal={setShowModal}
          handleValidate={redirectToNonCompliance}
          handleCancel={redirectToDashboard}
        />
      </Container>
    </>
  );
};

export default ProductTempUnfinishedSolve;
