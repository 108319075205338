import Axios from './index';

export default {
  getProfile: () => {
    return Axios.get(`/profile`);
  },

  editProfile: (firstName: string, lastname: string) => {
    return Axios.put(`/profile`, {
      firstName,
      lastname,
    });
  },
};
