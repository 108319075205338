import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Modal, Form } from 'react-bootstrap';
import { SurfaceDto } from '@models/dto/response/Surface.dto';
import BaseModalHeader from '@components/shared/BaseModalHeader/BaseModalHeader';
import BaseModalFooter from '@components/shared/BaseModalFooter/BaseModalFooter';
import { FormControlLabel, Checkbox } from '@material-ui/core';

type Props = {
  zoneName: string;
  surfaceList: SurfaceDto[];
  setSelectedSurfaces: (surfaces: SurfaceDto[]) => void;
  showModal: boolean;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  cancelModal: () => void;
};

const CleaningZoneModal = (props: Props) => {
  const { zoneName, setSelectedSurfaces, surfaceList, showModal, setShowModal, cancelModal } =
    props;
  const [currentSelectedSurfaces, setCurrentSelectedSurfaces] = useState<SurfaceDto[]>([]);

  // Create controlled array for checkbox
  useEffect(() => {
    const formattedSurfaceList = surfaceList.map((item) => {
      return { ...item, checked: false };
    });
    setCurrentSelectedSurfaces(formattedSurfaceList);
  }, [surfaceList]);

  const handleCheckBox = (index: number, checked: boolean) => {
    const result = [...currentSelectedSurfaces];
    result[index] = { ...result[index], checked };
    setCurrentSelectedSurfaces(result);
  };

  const handleValidate = () => {
    setSelectedSurfaces(
      currentSelectedSurfaces.filter((surface: SurfaceDto) => surface.checked === true),
    );
    setShowModal(false);
  };

  return (
    <Modal show={showModal} onHide={cancelModal} aria-labelledby="cleaning-zone-modal" centered>
      <BaseModalHeader id="cleaning-zone-modal" title={zoneName} />
      <Modal.Body>
        <Form>
          {currentSelectedSurfaces.map((surface, index) => (
            <div key={surface.id} className="mb-3">
              <FormControlLabel
                key={index}
                className={`${surface.checked ? 'checked' : ''}`}
                control={
                  <Checkbox
                    value={surface.id}
                    className={`${surface.checked ? 'checked' : ''}`}
                    onChange={(e) => handleCheckBox(index, e.target.checked)}
                    checked={surface.checked}
                  />
                }
                label={surface.name}
              />
            </div>
          ))}
        </Form>
      </Modal.Body>
      <BaseModalFooter
        handleValidate={handleValidate}
        setShowModal={setShowModal}
        handleCancel={cancelModal}
      />
    </Modal>
  );
};

export default CleaningZoneModal;
