import React, { useEffect, useState } from 'react';
import BaseNavbarWithHistory from '@components/shared/BaseNavbarWithHistory/BaseNavbarWithHistory';
import ProductTempSettingsShippingClient from '@components/ProductTempSettingsShippingClient/ProductTempSettingsShippingClient';
import i18next from 'i18next';
import { Container, Row, Col, Button, Image } from 'react-bootstrap';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import ProductTempSettingsShippingAddModal from '@components/ProductTempSettingsShippingAddModal/ProductTempSettingsShippingAddModal';
import ProductTempSettingsShippingEditModal from '@components/ProductTempSettingsShippingEditModal/ProductTempSettingsShippingEditModal';
import { FREQUENCY_JSON } from '@utils/constants';
import { FrequencyJsonDto } from '@models/dto/FrequencyJson.dto';
import { CreateCustomerParams, UpdateCustomerParams } from '@models/dto/request/CustomerParams.dto';
import { toast } from 'react-toastify';
import {
  createCustomer,
  deleteCustomer,
  getCustomers,
  updateCustomer,
} from '@store/reducers/customer';
import { CustomerDto } from '@models/dto/response/Customer.dto';
import plusWhite from '@assets/images/svg/plus-rond.svg';
import BaseNavbar from '@components/shared/BaseNavbar/BaseNavbar';
import styles from './ProductTempSettingsShipping.module.scss';

const ProductTempSettingsShipping = () => {
  const customers = useAppSelector((state) => state.customer.customers);
  const establishmentId = useAppSelector((state) => state.account.selectedEstablishment?.id);
  const accountType = useAppSelector((state) => state.account.type);
  const dispatch = useAppDispatch();

  const [showAddModal, setShowAddModal] = useState<boolean>(false);
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [selectedClient, setSelectedClient] = useState<CustomerDto>();
  const [frequencyJson, setFrequencyJson] = useState<FrequencyJsonDto | undefined>(FREQUENCY_JSON);

  const addClient = (data: CreateCustomerParams) => {
    toast.promise(dispatch(createCustomer(data)).unwrap(), {
      pending: i18next.t('productTemp:toasts.pending.createCustomer'),
      success: i18next.t('productTemp:toasts.success.createCustomer'),
      error: i18next.t('productTemp:toasts.error.createCustomer'),
    });
  };

  const removeClient = (id: number) => {
    toast.promise(dispatch(deleteCustomer(id)).unwrap(), {
      pending: i18next.t('productTemp:toasts.pending.deleteCustomer'),
      success: i18next.t('productTemp:toasts.success.deleteCustomer'),
      error: i18next.t('productTemp:toasts.error.deleteCustomer'),
    });
  };

  const updateClient = (data: UpdateCustomerParams) => {
    setSelectedClient(undefined);
    toast.promise(dispatch(updateCustomer(data)).unwrap(), {
      pending: i18next.t('productTemp:toasts.pending.updateCustomer'),
      success: i18next.t('productTemp:toasts.success.updateCustomer'),
      error: i18next.t('productTemp:toasts.error.updateCustomer'),
    });
  };

  const openUpdateModal = (customer: CustomerDto) => {
    setSelectedClient(customer);
    setFrequencyJson(JSON.parse(customer.frequency.json));
    setShowEditModal(true);
  };

  const openAddModal = () => {
    setShowAddModal(true);
  };

  useEffect(() => {
    if ((!customers || customers.length === 0) && establishmentId) {
      dispatch(getCustomers(establishmentId)).unwrap();
    }
  }, []);

  // Reset data when modal close
  useEffect(() => {
    if (!showAddModal) {
      setFrequencyJson(FREQUENCY_JSON);
    }
  }, [showAddModal]);

  // Reset data when modal close
  useEffect(() => {
    if (!showEditModal) {
      setSelectedClient(undefined);
      setFrequencyJson(FREQUENCY_JSON);
    }
  }, [showEditModal]);

  return (
    <>
      <BaseNavbar accountType={accountType} parametersUrl="/settings" />
      <BaseNavbarWithHistory
        title={i18next.t('productTemp:settings.navbar.title')}
        showSettings={false}
        showHistory={false}
      />
      <Container fluid className="h-100 pt-5 cornerRound px-5">
        <Row>
          <Col md={12} className="d-flex justify-content-between align-items-center">
            <div className={styles.listTitle}>
              {i18next.t('productTemp:settings.shipping.title')}
            </div>
            <Button
              className={`${styles.addButton} d-flex justify-content-center align-items-center btnXl`}
              onClick={openAddModal}
            >
              <Image src={plusWhite} width={24} height={24} className="mr-2" />
              {i18next.t('productTemp:settings.shipping.button')}
            </Button>
          </Col>
        </Row>
        {customers && customers.length > 0 ? (
          <>
            {customers.map((client: any) => (
              <Row key={client.id} className="mt-5">
                <Col md={12}>
                  <ProductTempSettingsShippingClient
                    client={client}
                    removeClient={removeClient}
                    editClient={openUpdateModal}
                  />
                </Col>
              </Row>
            ))}
          </>
        ) : (
          <Row className="d-flex justify-content-center align-items-center h-100">
            <Col md={12} className="text-center tableHeader">
              {i18next.t('productTemp:settings.shipping.empty')}
            </Col>
          </Row>
        )}
        <ProductTempSettingsShippingAddModal
          showModal={showAddModal}
          setShowModal={setShowAddModal}
          frequencyJson={frequencyJson}
          setFrequencyJson={setFrequencyJson}
          establishmentId={establishmentId}
          addClient={addClient}
        />
        {selectedClient && (
          <ProductTempSettingsShippingEditModal
            showModal={showEditModal}
            setShowModal={setShowEditModal}
            frequencyJson={frequencyJson}
            setFrequencyJson={setFrequencyJson}
            clientToUpdate={selectedClient}
            editClient={updateClient}
          />
        )}
      </Container>
    </>
  );
};

export default ProductTempSettingsShipping;
