import DeepFryerList from '@components/DeepFryerList/DeepFryerList';
import BaseDatePicker from '@components/shared/BaseDatePicker/BaseDatePicker';
import BaseNavbarWithHistory from '@components/shared/BaseNavbarWithHistory/BaseNavbarWithHistory';
import BasePhotoPicker from '@components/shared/BasePhotoPicker/BasePhotoPicker';
import BaseSaveButton from '@components/shared/BaseSaveButton/BaseSaveButton';
import BaseSignatory from '@components/shared/BaseSignatory/BaseSignatory';
import { FormControl, MenuItem, Select } from '@material-ui/core';
import { format, formatISO, setMinutes, setHours, isValid } from 'date-fns';
import i18next from 'i18next';
import { Col, Container, Form, Row } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';

import { useAppDispatch, useAppSelector } from '@store/hooks';
import { getSignatories } from '@store/reducers/signatory';
import { getDeepFryers } from '@store/reducers/deep-fryer';
import { DeepFryerDto } from '@models/dto/response/DeepFryer.dto';
import { SignatoryDto } from '@models/dto/response/Signatory.dto';
import AccountTypeEnum from '@models/enums/AccountType';
import { toast } from 'react-toastify';
import { history } from '@router/routes';
import { CreateOilMonitoringOperationDto } from '@models/dto/request/CreateOilMonitoringOperation.dto';
import OilMonitoringActionTypeEnum from '@models/enums/OilMonitoringActionType';
import {
  CreateOilMonitoringOperation,
  updateOilMonitoringOperation,
} from '@store/reducers/operation';
import OperationTypeEnum from '@models/enums/OperationType';
import styles from './OilMonitoring.module.scss';

const OilMonitoring = () => {
  const signatories = useAppSelector((state) => state.signatory.signatories);
  const deepFryers = useAppSelector((state) => state.deepFryer.deepFryers);
  const selectedFryer = useAppSelector((state) => state.deepFryer.selectedDeepFryer);
  const establishmentId = useAppSelector((state) => state.account.selectedEstablishment?.id);
  const accountType = useAppSelector((state) => state.account.type);
  const dispatch = useAppDispatch();

  const [selectedDeepFryer, setSelectedDeepFryer] = useState<DeepFryerDto | undefined>(
    selectedFryer,
  );
  const [selectedActionType, setSelectedActionType] = useState<OilMonitoringActionTypeEnum>();
  const [currentDate, setCurrentDate] = useState<Date>(new Date());
  const [currentTime, setCurrentTime] = useState<string>(format(new Date(), 'HH:mm'));
  const [selectedSignatory, setSelectedSignatory] = useState<SignatoryDto>();
  const [photos, setPhotos] = useState<any[]>([]);

  // Update Date with custom time
  const buildDate = () => {
    let formattedDate = currentDate;
    const timeArray = currentTime.split(':');
    formattedDate = setMinutes(formattedDate, Number(timeArray[1]));
    formattedDate = setHours(formattedDate, Number(timeArray[0]));
    return formattedDate;
  };

  // Create oil monitoring operation
  const save = (date: Date) => {
    const data: CreateOilMonitoringOperationDto = {
      signatoryId: selectedSignatory!.id,
      actionType: selectedActionType!,
      deepFryerId: selectedDeepFryer!.id,
      realisationDate: formatISO(new Date(date)),
    };

    dispatch(CreateOilMonitoringOperation(data))
      .unwrap()
      .then((result) => {
        // get the operation ID to add other photo
        // @ts-ignore
        const oilMonitoringId = result.id;
        const requests = photos.map((photo: string) => {
          return dispatch(
            updateOilMonitoringOperation({
              oilMonitoringId,
              picture: photo,
            }),
          );
        });
        Promise.all(requests)
          .then(() => {
            toast.success(i18next.t('oilMonitoring:toasts.success.createOperation'));
            history.push('/dashboard');
          })
          .catch(() => {
            toast.error(i18next.t('oilMonitoring:toasts.error.createOperation'));
          });
      })
      .catch(() => {
        toast.error(i18next.t('oilMonitoring:toasts.error.createOperation'));
      });
  };

  const handleSave = () => {
    // Form check if completed
    if (!selectedDeepFryer) {
      toast.warn(i18next.t('oilMonitoring:toasts.warn.deepFryer'));
      return;
    }
    if (!currentDate) {
      toast.warn(i18next.t('oilMonitoring:toasts.warn.date'));
      return;
    }
    if (!currentTime) {
      toast.warn(i18next.t('oilMonitoring:toasts.warn.time'));
      return;
    }
    if (!selectedActionType) {
      toast.warn(i18next.t('oilMonitoring:toasts.warn.actionType'));
      return;
    }
    if (!selectedSignatory) {
      toast.warn(i18next.t('oilMonitoring:toasts.warn.signatory'));
      return;
    }

    const date = buildDate();

    // Is date valid format
    if (isValid(date)) {
      save(date);
    }
  };

  // Get mandatory data
  useEffect(() => {
    if ((!deepFryers || deepFryers.length === 0) && establishmentId) {
      dispatch(getDeepFryers(establishmentId)).unwrap();
    }

    if ((!signatories || signatories.length === 0) && establishmentId) {
      dispatch(getSignatories(establishmentId)).unwrap();
    }
  }, []);

  return (
    <>
      <div style={{ display: 'block' }}>
        {/* Navbar */}
        <BaseNavbarWithHistory
          title={i18next.t('oilMonitoring:navbar.title')}
          showSettings={accountType === AccountTypeEnum.admin}
          typeOperation={OperationTypeEnum.oil}
        />
        <Container fluid className="cornerRound px-5">
          {/* Select hardware */}
          <Row noGutters className={`${styles.hardwareContainer} pt-5 mb-5`}>
            <Col md={12}>
              {deepFryers && deepFryers.length > 0 ? (
                <DeepFryerList
                  deepFryerList={deepFryers}
                  selectedDeepFryer={selectedDeepFryer}
                  setSelectedDeepFryer={setSelectedDeepFryer}
                />
              ) : (
                <p className="tableHeader">{i18next.t('oilMonitoring:noEquipment')}</p>
              )}
            </Col>
          </Row>
          <hr style={{ width: '80%' }} />
          {/* Date picker */}
          <div className="my-5">
            <BaseDatePicker
              currentDate={currentDate}
              setCurrentDate={setCurrentDate}
              currentTime={currentTime}
              setCurrentTime={setCurrentTime}
              showTitle
            />
          </div>
          <hr style={{ width: '80%' }} />
          {/* Photos */}
          <div className="my-5">
            <BasePhotoPicker
              photos={photos}
              setPhotos={setPhotos}
              title={i18next.t('shared:photoPicker.titleOil')}
              acceptType="image/jpeg, image/jpg,image/png,application/pdf"
              multiPictureAllowed
            />
          </div>
          <hr style={{ width: '80%' }} />
          {/* Action */}
          <Form className="my-5 pt-3">
            {/* Supplier */}
            <Form.Group controlId="action">
              <FormControl className="w-100">
                <Select
                  value={selectedActionType ?? ''}
                  onChange={(e) => setSelectedActionType(e.target.value as any)}
                  displayEmpty
                  inputProps={{
                    className: `${
                      !selectedActionType ? styles.selectInputEmpty : styles.selectInputFilled
                    }`,
                  }}
                >
                  <MenuItem value="" disabled>
                    {i18next.t('oilMonitoring:form.actionType')}
                  </MenuItem>
                  <MenuItem value={OilMonitoringActionTypeEnum.none}>
                    {i18next.t(`oilMonitoring:form.actions.${OilMonitoringActionTypeEnum.none}`)}
                  </MenuItem>
                  <MenuItem value={OilMonitoringActionTypeEnum.change}>
                    {i18next.t(`oilMonitoring:form.actions.${OilMonitoringActionTypeEnum.change}`)}
                  </MenuItem>
                  <MenuItem value={OilMonitoringActionTypeEnum.filtration}>
                    {i18next.t(
                      `oilMonitoring:form.actions.${OilMonitoringActionTypeEnum.filtration}`,
                    )}
                  </MenuItem>
                </Select>
              </FormControl>
            </Form.Group>
          </Form>
          {/* Signatories */}
          <BaseSignatory
            signatories={signatories}
            selectedSignatory={selectedSignatory}
            setSelectedSignatory={setSelectedSignatory}
          />
          <BaseSaveButton onSave={handleSave} text={i18next.t('shared:buttons.save')} />
        </Container>
      </div>
    </>
  );
};

export default OilMonitoring;
