import React from 'react';
import { format } from 'date-fns';
import { Card, Button } from 'react-bootstrap';
import i18next from 'i18next';
import { getDishType, getTitleTask, getUrlByTask } from '@utils/operationsUtils';
import { history } from '@router/routes';
import { useAppDispatch } from '@store/hooks';
import { TaskDto } from '@models/dto/response/Task.dto';
import TaskTypeEnum from '@models/enums/TaskType';
import OperationTempReadingTypeEnum from '@models/enums/OperationTempReadingType';
import { selectSurfaceId } from '@store/reducers/area';
import { selectEquipmentId } from '@store/reducers/equipment';
import { getCustomer } from '@store/reducers/customer';
import {
  selectOperationProductType,
  selectDishFamilyType,
} from '@store/reducers/product-temperature';
import { setSelectedTaskId } from '@store/reducers/history';
import styles from './OperationTodoItem.module.scss';

type Props = {
  task: TaskDto;
};

const OperationTodoItem = (props: Props) => {
  const { task } = props;

  const dispatch = useAppDispatch();

  const goToOperation = () => {
    dispatch(setSelectedTaskId(task.id));
    if (task.taskType === TaskTypeEnum.equipmentReading) {
      dispatch(selectEquipmentId(task.equipmentId));
    } else if (task.taskType === TaskTypeEnum.cleaning) {
      dispatch(selectSurfaceId(task.surfaceId));
    } else if (task.taskType === TaskTypeEnum.productReadingDelivery) {
      dispatch(selectOperationProductType(OperationTempReadingTypeEnum.delivery));
      dispatch(selectDishFamilyType(task.dishFamilyType));
      dispatch(getCustomer(task.customerId)).unwrap();
    } else if (task.taskType === TaskTypeEnum.productReadingService) {
      dispatch(selectOperationProductType(OperationTempReadingTypeEnum.service));
      dispatch(selectDishFamilyType(task.dishFamilyType));
    }
    history.push(getUrlByTask(task.taskType));
  };

  return (
    <Card.Body
      className={`d-flex text-wrap align-items-center justify-content-between ${styles.cardContainer} mb-3`}
    >
      <div className="d-flex flex-column">
        <Card.Text className={`mb-1 ${styles.date}`}>{`${format(
          new Date(task.deadLine),
          'dd/MM/yyyy - HH:mm',
        )}`}</Card.Text>
        <Card.Title className={`mb-1 ${styles.title}`}>{getTitleTask(task.taskType)}</Card.Title>
        <div className="d-flex align-items-baseline">
          <Card.Text className={`mb-0 ${styles.info}`}>
            {task.taskType === TaskTypeEnum.cleaning ? (
              <>{`${task.areaName} - ${task.surfaceName}`}</>
            ) : (
              <>{task.subject}</>
            )}
            {(task.taskType === TaskTypeEnum.productReadingService ||
              task.taskType === TaskTypeEnum.productReadingDelivery) &&
            task.dishFamilyType
              ? `${task.taskType === TaskTypeEnum.productReadingDelivery ? ' - ' : ''}${getDishType(
                  task.dishFamilyType,
                )}`
              : ''}
          </Card.Text>
        </div>
      </div>
      <div>
        <Button
          className={`${styles.todoButton} btnMd d-flex align-items-center w-100 justify-content-center`}
          onClick={goToOperation}
        >
          {i18next.t('shared:buttons.toDo')}
        </Button>
      </div>
    </Card.Body>
  );
};

export default OperationTodoItem;
