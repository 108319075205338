import React, { Fragment, useState, useEffect } from 'react';
import i18next from 'i18next';
import { DishSubFamilyDto } from '@models/dto/response/DishSubFamily.dto';
import ProductTempSettingsSubFamiliesEditModal from '@components/ProductTempSettingsSubFamiliesEditModal/ProductTempSettingsSubFamiliesEditModal';
import { useAppDispatch } from '@store/hooks';
import { toast } from 'react-toastify';
import { deleteDishSubFamily, updateDishSubFamily } from '@store/reducers/dish';
import { UpdateDishSubFamilyParams } from '@models/dto/request/UpdateDishSubFamilyParams.dto';
import { Collapse } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import ProductTempSettingsDishesSubTable from '@components/ProductTempSettingsDishesSubTable/ProductTempSettingsDishesSubTable';
import BaseTableButtons from '@components/shared/BaseTableButtons/BaseTableButtons';
import styles from './ProductTempSettingsDishesTypesTable.module.scss';

type Props = {
  subFamilies: DishSubFamilyDto[];
};

const ProductTempSettingsDishesTypesTable = (props: Props) => {
  const { subFamilies } = props;
  const [showModal, setShowModal] = useState<boolean>(false);
  const [selectedSubFamily, setSelectedSubFamily] = useState<DishSubFamilyDto>();
  const [expandedRow, setExpandedRow] = useState<DishSubFamilyDto | undefined>(
    props.subFamilies[0],
  );

  const dispatch = useAppDispatch();

  const editSubFamily = (data: UpdateDishSubFamilyParams) => {
    toast.promise(dispatch(updateDishSubFamily(data)), {
      pending: i18next.t('productTemp:toasts.pending.updateSubFamily'),
      success: i18next.t('productTemp:toasts.success.updateSubFamily'),
      error: i18next.t('productTemp:toasts.error.updateSubFamily'),
    });
  };

  const handleEditModal = (subFamily: DishSubFamilyDto) => {
    setSelectedSubFamily(subFamily);
    setShowModal(true);
  };

  const deleteSubFamily = (subFamilyId: number) => {
    toast.promise(dispatch(deleteDishSubFamily(subFamilyId)), {
      pending: i18next.t('productTemp:toasts.pending.deleteSubFamily'),
      success: i18next.t('productTemp:toasts.success.deleteSubFamily'),
      error: i18next.t('productTemp:toasts.error.deleteSubFamily'),
    });
  };

  useEffect(() => {
    if (!showModal) {
      setSelectedSubFamily(undefined);
    }
  }, [showModal]);

  const handleClickRow = (subFamily: DishSubFamilyDto) => {
    if (expandedRow && expandedRow.id === subFamily.id) {
      // Close row
      setExpandedRow(undefined);
    } else {
      // Expand row
      setExpandedRow(subFamily);
    }
  };

  return (
    <>
      <TableContainer>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell className={styles.tableHeader}>
                {i18next.t('productTemp:settings.table.headers.types')}
              </TableCell>
              <TableCell />
              <TableCell />
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {subFamilies.map((subFamily) => (
              <Fragment key={subFamily.id}>
                <TableRow
                  className={`${styles.columnRow} py-5`}
                  onClick={() => handleClickRow(subFamily)}
                >
                  <TableCell component="th" scope="row" className="tableFirstColumn">
                    {subFamily.name}
                  </TableCell>
                  <TableCell align="right" className={styles.tableTempMinColumn}>
                    T°C {i18next.t('shared:temperature.min')} : {subFamily.minTemperature}
                  </TableCell>
                  <TableCell align="right" className={styles.tableTempMaxColumn}>
                    T°C {i18next.t('shared:temperature.max')} : {subFamily.maxTemperature}
                  </TableCell>
                  <TableCell align="right" className={styles.tableTempMaxColumn}>
                    <BaseTableButtons
                      editAction={() => handleEditModal(subFamily)}
                      deleteAction={() => deleteSubFamily(subFamily.id)}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className="p-0" colSpan={6}>
                    <Collapse in={expandedRow?.id === subFamily.id} timeout="auto" unmountOnExit>
                      <Box marginY={2} marginX={15}>
                        <ProductTempSettingsDishesSubTable subFamily={subFamily} />
                      </Box>
                    </Collapse>
                  </TableCell>
                </TableRow>
              </Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {selectedSubFamily && (
        <ProductTempSettingsSubFamiliesEditModal
          showModal={showModal}
          setShowModal={setShowModal}
          editDishSubFamily={editSubFamily}
          selectedDishSubFamily={selectedSubFamily}
        />
      )}
    </>
  );
};

export default ProductTempSettingsDishesTypesTable;
