import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import notificationService from '@services/notification';
import { NotificationsParams, NotificationDto } from '@models/dto/request/NotificationsParams.dto';

// Define a type for the slice state
interface NotificationsState {
  emailListDaily: string[];
  emailListWeekly: string[];
}

// Define the initial state using that type
const initialState: NotificationsState = {
  emailListDaily: [],
  emailListWeekly: [],
};

// REQUESTS
export const getEmails = createAsyncThunk<AxiosResponse<NotificationDto>, number>(
  'notifications/getAllEmails',
  async (establishmentId: number) => {
    const response = await notificationService.getEmails(establishmentId);
    return response.data;
  },
);

export const addDailyNotification = createAsyncThunk<
  AxiosResponse<NotificationDto>,
  NotificationsParams
>('notifications/addDaily', async (params: NotificationsParams) => {
  const response = await notificationService.createDailyNotification(
    params.establishmentId,
    params.data,
  );
  return response.data;
});

export const addWeeklyNotification = createAsyncThunk<
  AxiosResponse<NotificationDto>,
  NotificationsParams
>('notifications/addWeekly', async (params: NotificationsParams) => {
  const response = await notificationService.createWeeklyNotification(
    params.establishmentId,
    params.data,
  );
  return response.data;
});

export const removeDailyNotification = createAsyncThunk<
  AxiosResponse<NotificationDto>,
  NotificationsParams
>('notifications/removeDaily', async (params: NotificationsParams) => {
  const response = await notificationService.deleteDailyNotification(
    params.establishmentId,
    params.data,
  );
  return response.data;
});

export const removeWeeklyNotification = createAsyncThunk<
  AxiosResponse<NotificationDto>,
  NotificationsParams
>('notifications/removeWeekly', async (params: NotificationsParams) => {
  const response = await notificationService.deleteWeeklyNotification(
    params.establishmentId,
    params.data,
  );
  return response.data;
});

export const NotificationsSlice = createSlice({
  name: 'notifications',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Side effects
    // Get notifications list (daily + weekly)
    builder.addCase(getEmails.fulfilled, (state, action) => {
      state.emailListDaily = action.payload.data.emailDailyList ?? [];
      state.emailListWeekly = action.payload.data.emailWeeklyList ?? [];
    });
    builder.addCase(addDailyNotification.fulfilled, (state, action) => {
      state.emailListDaily = action.payload.data.emailDailyList ?? [];
    });
    builder.addCase(addWeeklyNotification.fulfilled, (state, action) => {
      state.emailListWeekly = action.payload.data.emailWeeklyList ?? [];
    });
    builder.addCase(removeDailyNotification.fulfilled, (state, action) => {
      state.emailListDaily = action.payload.data.emailDailyList ?? [];
    });
    builder.addCase(removeWeeklyNotification.fulfilled, (state, action) => {
      state.emailListWeekly = action.payload.data.emailWeeklyList ?? [];
    });
  },
});

export default NotificationsSlice.reducer;
