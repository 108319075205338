import React from 'react';
import notifIcon from '@assets/images/svg/gestion-notifs.svg';
import accountIcon from '@assets/images/svg/compte.svg';
import frequencyIcon from '@assets/images/svg/frequences.svg';
import calendarIcon from '@assets/images/svg/calendar-white.svg';
import { history } from '@router/routes';
import { Card, Image } from 'react-bootstrap';
import styles from './SettingsItem.module.scss';

type Props = {
  item: any;
  type: any;
};

const SettingsItem = (props: Props) => {
  const { icon, name } = props.item;

  const getIcon = () => {
    switch (icon) {
      case 'gestion-notifs':
        return notifIcon;
      case 'frequences':
        return frequencyIcon;
      case 'compte':
        return accountIcon;
      case 'calendar-white':
        return calendarIcon;
      default:
        return '';
    }
  };

  const handleClick = () => {
    history.push(`/settings/${props.type}`);
  };
  return (
    <Card className={styles.cardContainer} onClick={() => handleClick()}>
      <Card.Body className="d-flex flex-column align-items-start justify-content-center p-5">
        <Card.Title className="text-left">
          <Image src={getIcon()} height={60} fluid />
        </Card.Title>
        <Card.Text className={styles.cardText}>{name}</Card.Text>
      </Card.Body>
    </Card>
  );
};

export default SettingsItem;
