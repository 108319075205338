import React from 'react';
import { Col, Row, Card, Image } from 'react-bootstrap';
import arrow from '@assets/images/svg/arrow-big-right.svg';
import i18next from 'i18next';
import { history } from '@router/routes';
import { format } from 'date-fns';
import { HistoryStatsDto } from '@models/dto/response/HistoryStats.dto';
import styles from './DashboardStatistics.module.scss';

type Props = {
  establishmentName?: string;
  stats: HistoryStatsDto;
};

const DashboardStatistics = (props: Props) => {
  const { establishmentName, stats } = props;
  const goToToday = () => {
    history.push('/operations-today');
  };

  const goToHistory = () => {
    history.push('/operations-history');
  };

  const goToDelayed = () => {
    history.push('/operations-delayed');
  };

  const goToHandling = () => {
    history.push('/operations-handling');
  };

  return (
    <div className="px-3 px-lg-0 px-md-0">
      <Row className="mb-5">
        <Col
          md={12}
          className="d-flex flex-column-reverse flex-sm-row justify-content-sm-between align-items-baseline"
        >
          <h2 className={styles.statisticsTitle}>
            {i18next.t('dashboard:statistics.yourOperations')}
            <span className={styles.statisticsSubTitle}>
              {' '}
              {i18next.t('dashboard:statistics.from')} {format(new Date(), 'dd/MM/yyyy')}
            </span>
          </h2>
          <div className={`${styles.statisticsEstablishment} mb-3 mb-lg-0`}>
            {establishmentName ?? ''}
          </div>
        </Col>
      </Row>
      {/* Today */}
      <Row>
        <Col xs={6} sm={3} className="mb-4 mb-sm-0">
          <Card className={styles.todayCard} onClick={goToToday}>
            <Card.Body className="d-flex flex-column justify-content-start p-3 p-md-4">
              <div className={`${styles.cardNumber} text-nowrap`}>
                {stats.taskDone}
                {stats.tasks > 0 && (
                  <span className={styles.cardNumberSmall}> / {stats.tasks}</span>
                )}
              </div>
              <div className={styles.cardText}>
                {i18next.t('dashboard:statistics.currentTasks')}
              </div>
            </Card.Body>
          </Card>
        </Col>
        {/* Late */}
        <Col xs={6} sm={3} className="mb-4 mb-sm-0">
          <Card className={styles.lateCard} onClick={goToDelayed}>
            <Card.Body className="d-flex flex-column justify-content-start p-3 p-md-4">
              <div className={styles.cardNumber}>{stats.taskLate}</div>
              <div className={styles.cardText}>{i18next.t('dashboard:statistics.lateTasks')}</div>
            </Card.Body>
          </Card>
        </Col>
        {/* Compliance */}
        <Col xs={6} sm={3} className="mb-4 mb-sm-0">
          <Card className={styles.complianceCard} onClick={goToHandling}>
            <Card.Body className="d-flex flex-column justify-content-start p-3 p-md-4">
              <div className={styles.cardNumber}>{stats.nonCompliance}</div>
              <div className={styles.cardText}>
                {i18next.t('dashboard:statistics.nonCompliantTasks')}
              </div>
            </Card.Body>
          </Card>
        </Col>
        {/* History */}
        <Col xs={6} sm={3} className="mb-4 mb-sm-0">
          <Card className={styles.historyCard} onClick={goToHistory}>
            <Card.Body className="d-flex flex-column justify-content-start p-3 p-md-4">
              <Image
                src={arrow}
                width={43}
                height={35}
                className={`${styles.arrowIcon} mt-0 mt-lg-3`}
              />
              <div className={`${styles.historyCardTitle} mt-3`}>
                {i18next.t('dashboard:statistics.history')}
              </div>
              <div className={styles.historyCardSubTitle}>
                {i18next.t('dashboard:statistics.operations')}
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default DashboardStatistics;
