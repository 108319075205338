import React, { useState, useEffect } from 'react';
import { Col, ListGroup, ListGroupItem, Row, Image, Navbar, Button, Nav } from 'react-bootstrap';
import backButton from '@assets/images/svg/back.svg';
import { useAppSelector, useAppDispatch } from '@store/hooks';
import i18next from 'i18next';
import { DishDto } from '@models/dto/response/Dish.dto';
import { addDishToListView, getDishesSubFamily, setSelectedDish } from '@store/reducers/dish';
import { history } from '@router/routes';
import AddDishModal from '@components/AddDishModal/AddDishModal';
import { toast } from 'react-toastify';
import { CreateDishParams } from '@models/dto/request/DishParams.dto';
import plusGreen from '@assets/images/svg/plus-rond-vert.svg';
import { getProductTempData } from '@store/reducers/product-temperature';
import { useLocation } from 'react-router-dom';
import styles from './SelectDishView.module.scss';

const SelectDishView = () => {
  const selectedSubFamily = useAppSelector((state) => state.dish.selectedDishSubFamily);
  const dishes = useAppSelector((state) => state.dish.selectedDishesList);
  const establishmentId = useAppSelector((state) => state.account.selectedEstablishment?.id);

  const dispatch = useAppDispatch();
  const location = useLocation<{ dishSubFamilyId: number }>();

  const [showModal, setShowModal] = useState<boolean>(false);
  const [listDishes, setListDishes] = useState<DishDto[]>([]);

  const handleSelectDish = (item: DishDto) => {
    dispatch(setSelectedDish(item));
    history.goBack();
  };

  const openModal = () => {
    setShowModal(true);
  };

  useEffect(() => {
    if (location.state.dishSubFamilyId) {
      dispatch(getDishesSubFamily(location.state.dishSubFamilyId));
    }
    return () => {
      setListDishes([]);
    };
  }, []);

  useEffect(() => {
    if (!selectedSubFamily) {
      history.goBack();
    }
  }, [selectedSubFamily]);

  useEffect(() => {
    if (dishes && dishes.length > 0) {
      const newList = [...dishes].sort((a: DishDto, b: DishDto) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });
      setListDishes(newList);
    }
  }, [dishes]);

  const addDish = (name: string) => {
    if (selectedSubFamily) {
      const data: CreateDishParams = {
        name,
        dishSubFamilyId: selectedSubFamily?.id,
      };

      toast.promise(dispatch(addDishToListView(data)).unwrap(), {
        pending: i18next.t('productTemp:toasts.pending.createDish'),
        success: i18next.t('productTemp:toasts.success.createDish'),
        error: i18next.t('productTemp:toasts.error.createDish'),
      });

      if (establishmentId) {
        dispatch(getProductTempData(establishmentId)).unwrap();
      }
    }
  };

  return (
    <>
      <Navbar className="navbarHygiap d-flex justify-content-between py-3">
        <Navbar.Brand>
          <Image
            src={backButton}
            width={23}
            height={20}
            onClick={() => {
              history.goBack();
            }}
          />
        </Navbar.Brand>
        <Nav>
          <Button className={`${styles.historyButton} btnXl ml-2`} onClick={openModal}>
            <Image src={plusGreen} width={24} height={24} className="mr-2" />
            {i18next.t('productTemp:navbar.addDishButton')}
          </Button>
        </Nav>
      </Navbar>
      <Row noGutters className="mt-5">
        <Col md={12}>
          <h3 className={`${styles.title} px-5`}>{i18next.t('productTemp:selectDishes')}</h3>
          {listDishes && listDishes.length > 0 ? (
            <ListGroup className="mt-5">
              {listDishes.map((item: DishDto, index: number) => (
                <ListGroupItem
                  key={index}
                  onClick={() => handleSelectDish(item)}
                  className={`${styles.groupItem} px-5 py-4`}
                >
                  {item.name}
                </ListGroupItem>
              ))}
            </ListGroup>
          ) : (
            <p className="mt-5 px-5">{i18next.t('productTemp:emptyDishesList')}</p>
          )}
        </Col>
      </Row>
      <AddDishModal showModal={showModal} setShowModal={setShowModal} addDish={addDish} />
    </>
  );
};

export default SelectDishView;
