import BaseNavbarWithHistory from '@components/shared/BaseNavbarWithHistory/BaseNavbarWithHistory';
import LogoutConfirmModal from '@components/LogoutConfirmModal/LogoutConfirmModal';
import i18next from 'i18next';
import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { updatePassword } from '@store/reducers/account';
import { getProfile, updateProfile } from '@store/reducers/profile';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { toast } from 'react-toastify';
import AccountTypeEnum from '@models/enums/AccountType';
import { history } from '@router/routes';
import BaseSaveButton from '@components/shared/BaseSaveButton/BaseSaveButton';
import packageJson from '../../../package.json';

import styles from './Account.module.scss';

const Account = () => {
  const emailUser = useAppSelector((state) => state.account.login);
  const userType = useAppSelector((state) => state.account.type);
  const profile = useAppSelector((state) => state.profile.profile);
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [showModalLogout, setShowModalLogout] = useState<boolean>(false);
  const [firstName, setFirstName] = useState<string>(profile?.firstName ?? '');
  const [lastName, setLastName] = useState<string>(profile?.lastName ?? '');
  const [email, setEmail] = useState<string>(emailUser ?? '');
  const [currentPassword, setCurrentPassword] = useState<string>();
  const [newPassword, setNewPassword] = useState<string>();
  const [newPasswordConfirm, setNewPasswordConfirm] = useState<string>();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getProfile()).unwrap();
  }, []);

  useEffect(() => {
    if (profile) {
      setFirstName(profile?.firstName ?? '');
      setLastName(profile?.lastName ?? '');
    }
  }, [profile]);

  const handleEdit = () => {
    setIsEditMode(true);
  };

  const validForm = () => {
    // update profile (firstName + lastName)
    toast.promise(dispatch(updateProfile({ firstName, lastName })).unwrap(), {
      pending: i18next.t('account:toasts.pending.update'),
      success: i18next.t('account:toasts.success.update'),
      error: i18next.t('account:toasts.error.update'),
    });

    if (currentPassword || newPassword || newPasswordConfirm) {
      if (!currentPassword) {
        toast.warn(i18next.t('account:toasts.warn.noCurrentPassword'));
        return;
      }
      if (!newPassword) {
        toast.warn(i18next.t('account:toasts.warn.noNewPassword'));
        return;
      }
      if (!newPasswordConfirm) {
        toast.warn(i18next.t('account:toasts.warn.noNewPasswordConfirm'));
        return;
      }
      if (newPassword !== newPasswordConfirm) {
        toast.warn(i18next.t('account:toasts.warn.passwordNoEqual'));
        return;
      }
      toast.promise(
        dispatch(updatePassword({ oldPassword: currentPassword, newPassword })).unwrap(),
        {
          pending: i18next.t('account:toasts.pending.updatePassword'),
          success: i18next.t('account:toasts.success.updatePassword'),
          error: i18next.t('account:toasts.error.updatePassword'),
        },
      );
    }
    setIsEditMode(false);
  };

  const logout = () => {
    // eslint-disable-next-line no-undef
    localStorage.removeItem('token');
    history.push('/login');
    setShowModalLogout(false);
  };

  return (
    <>
      <BaseNavbarWithHistory
        title={i18next.t('account:navbar.title')}
        showSettings={false}
        showHistory={false}
      />
      <Container fluid className="h-100 pt-5 cornerRound px-5">
        {!isEditMode && userType === AccountTypeEnum.admin && (
          <Row className="justify-content-end">
            <Button className={`${styles.button} btnLg`} onClick={handleEdit}>
              {i18next.t('shared:buttons.modify')}
            </Button>
          </Row>
        )}
        <Form>
          <Row>
            <Form.Group className="mb-3 mr-3 flex-grow-1" controlId="formLastName">
              <Form.Label className={styles.inputLabel} column sm="2">
                {i18next.t('account:form.lastName')}
              </Form.Label>
              <Col sm="12">
                <Form.Control
                  plaintext
                  className={isEditMode ? 'baseInputLg pl-3' : ''}
                  readOnly={!isEditMode}
                  defaultValue={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                />
              </Col>
            </Form.Group>

            <Form.Group className="mb-3 ml-0 ml-lg-3 flex-grow-1" controlId="formFirstName">
              <Form.Label className={styles.inputLabel} column sm="2">
                {i18next.t('account:form.firstName')}
              </Form.Label>
              <Col sm="12">
                <Form.Control
                  plaintext
                  className={isEditMode ? 'baseInputLg pl-3' : ''}
                  readOnly={!isEditMode}
                  defaultValue={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                />
              </Col>
            </Form.Group>
          </Row>

          <Form.Group as={Row} className="mb-3 flex-column" controlId="formEmail">
            <Form.Label className={styles.inputLabel} column>
              {i18next.t('account:form.login')}
            </Form.Label>
            <Col sm="12">
              <Form.Control
                plaintext
                readOnly
                defaultValue={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Col>
          </Form.Group>
          {userType === AccountTypeEnum.admin && (
            <>
              {isEditMode ? (
                <>
                  <Form.Group as={Row} className="mb-3 flex-column" controlId="formCurrentPassword">
                    <Form.Label className={styles.inputLabel} column sm="12">
                      {i18next.t('account:form.currentPassword')}
                    </Form.Label>
                    <Col sm="12">
                      <Form.Control
                        type="password"
                        className="baseInputLg"
                        value={currentPassword}
                        readOnly={!isEditMode}
                        onChange={(e) => setCurrentPassword(e.target.value)}
                      />
                    </Col>
                  </Form.Group>
                  <Row>
                    <Form.Group className="mb-3 mr-3 flex-grow-1" controlId="formNewPassword">
                      <Form.Label className={styles.inputLabel} column sm="12">
                        {i18next.t('account:form.newPassword')}
                      </Form.Label>
                      <Col sm="12">
                        <Form.Control
                          type="password"
                          className="baseInputLg pl-3"
                          value={newPassword}
                          readOnly={!isEditMode}
                          onChange={(e) => setNewPassword(e.target.value)}
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group
                      className="mb-3 ml-3 flex-grow-1"
                      controlId="formNewPasswordConfirm"
                    >
                      <Form.Label className={styles.inputLabel} column sm="12">
                        {i18next.t('account:form.newPasswordConfirm')}
                      </Form.Label>
                      <Col sm="12">
                        <Form.Control
                          type="password"
                          className="baseInputLg pl-3"
                          value={newPasswordConfirm}
                          readOnly={!isEditMode}
                          onChange={(e) => setNewPasswordConfirm(e.target.value)}
                        />
                      </Col>
                    </Form.Group>
                  </Row>
                </>
              ) : (
                <Form.Group as={Row} className="mb-3 flex-column" controlId="formPassword">
                  <Form.Label className={styles.inputLabel} column sm="12">
                    {i18next.t('account:form.password')}
                  </Form.Label>
                  <Col sm="12">
                    <Form.Label sm={12} className="mt-2">
                      *****
                    </Form.Label>
                  </Col>
                </Form.Group>
              )}
            </>
          )}
        </Form>
        <hr />
        <Row>
          <Col md={12} className="d-flex justify-content-center mt-5">
            {!isEditMode ? (
              <BaseSaveButton
                text={i18next.t('account:form.logout')}
                onSave={() => setShowModalLogout(true)}
              />
            ) : (
              <BaseSaveButton text={i18next.t('shared:buttons.save')} onSave={validForm} />
            )}
          </Col>
        </Row>
        <LogoutConfirmModal
          showModal={showModalLogout}
          setShowModal={setShowModalLogout}
          onValid={logout}
        />
        <p className={styles.versionNumber}>{`v${packageJson.version}`}</p>
      </Container>
    </>
  );
};

export default Account;
