import React, { Dispatch, SetStateAction, useState, useEffect } from 'react';
import i18next from 'i18next'; // i18next.t('pageName:exemple.exemple')
import { Form, Modal } from 'react-bootstrap';
import { SupplierDto } from '@models/dto/response/Supplier.dto';
import { toast } from 'react-toastify';
import BaseModalHeader from '@components/shared/BaseModalHeader/BaseModalHeader';
import BaseModalFooter from '@components/shared/BaseModalFooter/BaseModalFooter';
import styles from './ReceptionControlSettingsModal.module.scss';

type Props = {
  selectedItem?: SupplierDto;
  showModal: boolean;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  onValidFormCreate: (name: string) => void;
  onValidFormEdit: (supplierId: number, name: string) => void;
};

const ReceptionControlSettingsModal = (props: Props) => {
  const { selectedItem, showModal, setShowModal, onValidFormCreate, onValidFormEdit } = props;

  const [name, setName] = useState<string>('');

  const handleValidate = () => {
    if (!name) {
      toast.warn(i18next.t('receptionControl:settings.toasts.warn.noName'));
    }
    if (selectedItem) {
      onValidFormEdit(selectedItem.id, name);
      return;
    }
    onValidFormCreate(name);
    setName('');
  };

  useEffect(() => {
    setName(selectedItem?.name ?? '');
  }, [selectedItem]);

  return (
    <Modal
      show={showModal}
      onHide={() => setShowModal(false)}
      size="lg"
      aria-labelledby="reception-control-settings-modal"
      centered
    >
      <BaseModalHeader
        id="reception-control-settings-modal"
        title={i18next.t('receptionControl:settings.modal.title')}
      />
      <Modal.Body>
        {/* Name */}
        <Form.Group className="d-flex flex-row align-items-center mt-5" controlId="name">
          <Form.Label className={styles.inputLabel}>
            {i18next.t('hardwareTemp:settings.modal.form.name')}
          </Form.Label>
          <Form.Control
            value={name}
            type="text"
            autoComplete="off"
            className="baseInputLg ml-3"
            onChange={(e) => setName(e.target.value)}
          />
        </Form.Group>
      </Modal.Body>
      <BaseModalFooter handleValidate={handleValidate} setShowModal={setShowModal} />
    </Modal>
  );
};

export default ReceptionControlSettingsModal;
