import React from 'react';
import { Card, Image } from 'react-bootstrap';
import editIcon from '@assets/images/svg/edit.svg';
import { FrequencyDto } from '@models/dto/response/Frequency.dto';
import { getTitleFrequency, displayNumberTime } from '@utils/frequenciesUtils';
import styles from './SettingsFrequenciesSlots.module.scss';

type Props = {
  slot: FrequencyDto;
  handleClickEdit: any;
};

const SettingsFrequenciesSlots = (props: Props) => {
  return (
    <Card className={`h-100 mr-3 ${styles.cardContainer} ${!props.slot.active && styles.disabled}`}>
      <Card.Body
        className="d-flex flex-column justify-content-end"
        onClick={() => props.handleClickEdit(props.slot)}
      >
        <div className={`${styles.circle} ${!props.slot.active && styles.disabled}`}>
          <Image src={editIcon} className={styles.edit} height={20} width={20} />
        </div>
        <Card.Text className={`${styles.cardText} mb-0`}>
          {getTitleFrequency(props.slot.type)}
        </Card.Text>
        <Card.Text className={styles.cardText}>{`${displayNumberTime(
          props.slot.startHour,
        )}:${displayNumberTime(props.slot.startMinutes)} - ${displayNumberTime(
          props.slot.endHour,
        )}:${displayNumberTime(props.slot.endMinutes)}`}</Card.Text>
      </Card.Body>
    </Card>
  );
};

export default SettingsFrequenciesSlots;
