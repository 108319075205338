import Axios from './index';

export default {
  getFryers: (establishmentId: number) => {
    return Axios.get(`/establishment/${establishmentId}/deepFryer`);
  },

  createFryer: (establishmentId: number, data: FormData) => {
    return Axios.post(`/establishment/${establishmentId}/deepFryer`, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },

  getFryer: (id: number) => {
    return Axios.get(`/establishment/deepFryer/${id}`);
  },

  updateFryer: (id: number, data: FormData) => {
    return Axios.post(`/establishment/deepFryer/${id}`, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },

  deleteFryer: (id: number) => {
    return Axios.delete(`/establishment/deepFryer/${id}`);
  },
};
