import React, { Dispatch, SetStateAction } from 'react';
import { Card, Col, Image, Row } from 'react-bootstrap';
// SVG
import receptionIcon from '@assets/images/svg/controle-reception.svg';
import hardwareTempIcon from '@assets/images/svg/releve-temp-materiel.svg';
import cleaningIcon from '@assets/images/svg/suivi-nettoyage.svg';
import productTempIcon from '@assets/images/svg/releve-des-temp-produit.svg';
import labelPrintIcon from '@assets/images/svg/impression-etiquettes.svg';
import oilIcon from '@assets/images/svg/suivi-des-huiles.svg';
import labelPhotosIcon from '@assets/images/svg/photos-etiquettes.svg';
import documentIcon from '@assets/images/svg/stockage-documents.svg';
import analysisIcon from '@assets/images/svg/analysis.svg';
import { OperationTypesDto } from '@models/dto/response/OperationTypes.dto';
import OperationTypeEnum from '@models/enums/OperationType';
import i18next from 'i18next';
import { history } from '@router/routes';
import i18n from '@locales/i18n';
import styles from './DashboardOperationItem.module.scss';

type Props = {
  operation: OperationTypesDto;
  unfinishedTempReading: number;
  setShowModal: Dispatch<SetStateAction<boolean>>;
};

const getIcon = (type: OperationTypeEnum) => {
  switch (type) {
    case OperationTypeEnum.equipment:
      return hardwareTempIcon;
    case OperationTypeEnum.cleaning:
      return cleaningIcon;
    case OperationTypeEnum.product:
      return productTempIcon;
    case OperationTypeEnum.inspection:
      return receptionIcon;
    case OperationTypeEnum.photo:
      return labelPhotosIcon;
    case OperationTypeEnum.oil:
      return oilIcon;
    case OperationTypeEnum.printing:
      return labelPrintIcon;
    case OperationTypeEnum.document:
      return documentIcon;
    case OperationTypeEnum.analysis:
      return analysisIcon;
    default:
      return '';
  }
};

const DashboardOperationItem = (props: Props) => {
  const { operation, unfinishedTempReading, setShowModal } = props;

  const handleClickCard = () => {
    if (unfinishedTempReading > 0 && operation.type === OperationTypeEnum.product) {
      setShowModal(true);
    } else {
      history.push(`/${i18n.t(`dashboard:operationUrls.${operation.type}`)}`);
    }
  };
  return (
    <Card
      style={{ backgroundColor: operation.color }}
      className={`h-100 ${styles.cardText}`}
      onClick={handleClickCard}
    >
      <Card.Body className="text-left">
        <Row>
          <Col
            md={unfinishedTempReading > 0 && operation.type === OperationTypeEnum.product ? 8 : 10}
            lg={8}
          >
            <Card.Title className="d-flex flex-row align-items-center">
              <Image src={getIcon(operation.type)} height={60} className={styles.icon} fluid />
              {unfinishedTempReading > 0 && operation.type === OperationTypeEnum.product && (
                <div className={`ml-1 ml-md-4 p-2 ${styles.badge} d-flex flex-row`}>
                  <div className="text-truncate w-100">
                    <span className={styles.badgeNumber}>{unfinishedTempReading}</span>{' '}
                    {i18next.t('dashboard:unfinishedMeasure')}
                  </div>
                </div>
              )}
            </Card.Title>
            <Card.Text className={styles.cardText}>{operation.name}</Card.Text>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default DashboardOperationItem;
