import React, { useState } from 'react';
import { Card, Modal } from 'react-bootstrap';
import { format } from 'date-fns';
import i18next from 'i18next';
import placeholderImage from '@assets/images/svg/placeholder.svg';
import styles from './OperationsHistoryDetailsPhoto.module.scss';

type Props = {
  picture?: string | null;
  date?: any;
  title?: string;
  temperature?: number;
};

const OperationsHistoryDetailsPhoto = (props: Props) => {
  const { picture, date, title, temperature } = props;
  const [showModal, setShowModal] = useState<boolean>(false);

  return (
    <>
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        size="xl"
        aria-labelledby="my-modal"
        centered
      >
        <Modal.Header closeButton />
        <Modal.Body>
          <Card.Img variant="top" src={picture ?? placeholderImage} />
        </Modal.Body>
      </Modal>
      <Card className={`h-100 mr-3 ${styles.cardContainer}`}>
        {picture && (
          <>
            {picture.slice(-3).toLocaleLowerCase() === 'pdf' ? (
              <Card.Body
                className="d-flex flex-column justify-content-center align-items-center text-wrap text-center"
                // eslint-disable-next-line no-undef
                onClick={() => window.open(picture, '_blank')}
              >
                <div className={`d-flex align-items-end ${styles.fileContainer}`}>
                  <span>.pdf</span>
                </div>
              </Card.Body>
            ) : (
              <Card.Img
                variant="top"
                src={picture ?? placeholderImage}
                style={{ maxWidth: 250 }}
                onClick={() => setShowModal(true)}
              />
            )}
          </>
        )}
        {title || date || temperature ? (
          <Card.Body className="d-flex flex-column justify-content-center">
            <Card.Text className={`${styles.cardText} mb-0 text-center`}>
              {title && <div className={styles.date}>{title}</div>}
              {date && <div className={styles.date}>{format(new Date(date), 'dd/MM/yyyy')}</div>}
              {date && <div className={styles.time}>{format(new Date(date), 'HH:mm')}</div>}
              {temperature ? (
                <div className={styles.time}>{`${i18next.t(
                  'shared:reading',
                )} ${temperature}°C`}</div>
              ) : (
                <></>
              )}
            </Card.Text>
          </Card.Body>
        ) : null}
      </Card>
    </>
  );
};

export default OperationsHistoryDetailsPhoto;
