import React from 'react';

type Props = {
  text: string;
  color: string;
};

const Headband = (props: Props) => {
  const { text, color } = props;

  return (
    <div
      className="d-flex py-2 justify-content-center"
      style={{ backgroundColor: color, color: '#fff' }}
    >
      {text}
    </div>
  );
};

export default Headband;
