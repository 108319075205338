import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import DashboardOperationsItem from '@components/DashboardOperationItem/DashboardOperationItem';
import { OperationTypesDto } from '@models/dto/response/OperationTypes.dto';
import DashboardOperationsProductTempModal from '@components/DashboardOperationsProductTempModal/DashboardOperationsProductTempModal';
import styles from './DashboardOperations.module.scss';

type Props = {
  operationsList: OperationTypesDto[];
  unfinishedTempReading: number;
};

const DashboardOperations = (props: Props) => {
  const [showModal, setShowModal] = useState<boolean>(false);

  return (
    <div>
      <Row className="my-1 my-lg-5 px-3 px-md-0">
        {props.operationsList.map((operation, index) => {
          return (
            <Col xl={3} lg={4} md={4} sm={4} xs={6} key={index} className={styles.operationsItem}>
              <DashboardOperationsItem
                operation={operation}
                unfinishedTempReading={props.unfinishedTempReading}
                setShowModal={setShowModal}
              />
            </Col>
          );
        })}
      </Row>
      <DashboardOperationsProductTempModal setShowModal={setShowModal} showModal={showModal} />
    </div>
  );
};

export default DashboardOperations;
