import React from 'react';
import { format } from 'date-fns';
import { Card } from 'react-bootstrap';
import {
  getTitleOperation,
  getSubjectOperation,
  getInformationOperation,
} from '@utils/operationsUtils';
import { AllOperationsDto } from '@models/dto/AllOperations.dto';
import OperationTypeEnum from '@models/enums/OperationType';
import styles from './OperationDoneItem.module.scss';

type Props = {
  data: AllOperationsDto;
};

const OperationDoneItem = (props: Props) => {
  const { data } = props;

  return (
    <Card.Body
      className={`d-flex flex-column text-wrap justify-content-between ${styles.cardContainer} mb-3`}
    >
      <Card.Text className={`mb-1 ${styles.date}`}>{`${format(
        new Date(data.userRealisationDate ?? data.realisationDate),
        'dd/MM/yyyy - HH:mm',
      )}`}</Card.Text>
      <Card.Title className={`mb-1 ${styles.title}`}>
        {data.type === OperationTypeEnum.product
          ? // @ts-ignore
            getTitleOperation(data.type, data.subType)
          : getTitleOperation(data.type)}
      </Card.Title>
      <div className="d-flex align-items-baseline">
        <Card.Text className={styles.infoBold}>
          {getSubjectOperation(data.type, data)
            .split('||')
            .map((res: any, i: number) => (
              <p key={i}>{res}</p>
            ))}
        </Card.Text>
      </div>
      <div className="d-flex align-items-baseline">
        <Card.Text className={`mb-0 ${styles.info}`}>
          {
            // @ts-ignore
            getInformationOperation(data.type, data, data.subType)
              .split('||')
              .map((res: any, i: number) => (
                <p key={i}>{res}</p>
              ))
          }
        </Card.Text>
      </div>
    </Card.Body>
  );
};

export default OperationDoneItem;
