import BaseNavbarWithHistory from '@components/shared/BaseNavbarWithHistory/BaseNavbarWithHistory';
import BaseNonComplianceForm from '@components/shared/BaseNonComplianceForm/BaseNonComplianceForm';
import BaseSaveButton from '@components/shared/BaseSaveButton/BaseSaveButton';
import BaseSignatory from '@components/shared/BaseSignatory/BaseSignatory';
import { SignatoryDto } from '@models/dto/response/Signatory.dto';
import { useAppSelector, useAppDispatch } from '@store/hooks';
import { getSignatories } from '@store/reducers/signatory';
import i18next from 'i18next';
import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { history } from '@router/routes';
import { format } from 'date-fns';
import { toast } from 'react-toastify';
import { UpdateNonComplianceParams } from '@models/dto/request/UpdateNonCompliance.dto';
import { updateNonCompliance, getCausesAndActions } from '@store/reducers/non-compliance';
import { CauseDto, CorrectiveActionDto } from '@models/dto/response/NonCompliance.dto';
import { getNonCompliantTasks } from '@store/reducers/history';
import styles from './SolveNonCompliantTask.module.scss';

const SolveNonCompliantTask = () => {
  // Store
  const signatories = useAppSelector((state) => state.signatory.signatories);
  const establishmentId = useAppSelector((state) => state.account.selectedEstablishment?.id);
  const nonCompliantOperation = useAppSelector(
    (state) => state.nonCompliance.selectedNonCompliance,
  );
  const causesAndActions = useAppSelector(
    (state) => state.nonCompliance.causesAndCorrectivesActions,
  );
  const dispatch = useAppDispatch();
  // Component state
  const [selectedSignatory, setSelectedSignatory] = useState<SignatoryDto>();
  const [causes, setCauses] = useState<CauseDto[]>([]);
  const [correctiveActions, setCorrectiveActions] = useState<CorrectiveActionDto[]>([]);
  const [selectedCausesIds, setSelectedCausesIds] = useState<string[]>(
    nonCompliantOperation?.causes.map((cause) => cause.id.toString()) ?? [],
  );
  const [selectedCorrectiveActionsIds, setSelectedCorrectiveActionsIds] = useState<string[]>(
    nonCompliantOperation?.correctiveActions.map((action) => action.id.toString()) ?? [],
  );
  const [comment, setComment] = useState<string>(nonCompliantOperation?.comment ?? '');

  // Get mandatory data
  useEffect(() => {
    if (!nonCompliantOperation) {
      history.goBack();
    }
    if (establishmentId && (!signatories || signatories.length === 0)) {
      dispatch(getSignatories(establishmentId)).unwrap();
    }
    if (!causesAndActions || causesAndActions.length === 0) {
      dispatch(getCausesAndActions()).unwrap();
    }
  }, []);

  // If non compliant operation we affect cause list and actions for the given non compliance type
  useEffect(() => {
    if (nonCompliantOperation) {
      const causeList = causesAndActions.find(
        (item) => item.type === nonCompliantOperation.noncomplianceType,
      );
      const actionList = causesAndActions.find(
        (item) => item.type === nonCompliantOperation.noncomplianceType,
      );
      setCauses(causeList?.causes ?? []);
      setCorrectiveActions(actionList?.correctiveActions ?? []);
    }
  }, [causesAndActions, nonCompliantOperation]);

  const handleValidateForm = () => {
    if (!selectedSignatory) {
      toast.warn(i18next.t('operationsHandling:toasts.warn.signatory'));
      return;
    }

    if (establishmentId && nonCompliantOperation) {
      const data: UpdateNonComplianceParams = {
        analysis: 0,
        nonComplianceId: nonCompliantOperation?.id,
        causeIds: selectedCausesIds.join(',') ?? undefined,
        correctiveActionIds: selectedCorrectiveActionsIds.join(',') ?? undefined,
        signatoryId: selectedSignatory.id,
        comment,
      };
      dispatch(updateNonCompliance(data))
        .unwrap()
        .then(() => {
          toast.success(i18next.t('operationsHandling:toasts.success.updateNonCompliant'));
          dispatch(getNonCompliantTasks({ establishmentId, page: 0 })).finally(() =>
            history.push('/dashboard'),
          );
        })
        .catch(() => {
          toast.error(i18next.t('operationsHandling:toasts.error.updateNonCompliant'));
        });
    }
  };

  if (nonCompliantOperation) {
    return (
      <>
        {/* Navbar */}
        <BaseNavbarWithHistory
          title={i18next.t('operationsHandling:navbar.title')}
          showSettings={false}
          showHistory={false}
        />
        <Container fluid>
          <Row className={`p-5 ${styles.background}`}>
            <Col md={12} className={`${styles.infoLabel} mb-5`}>
              <p>
                {i18next.t(`operationsHandling:solve.info.date`)}{' '}
                <span className={`${styles.valueInfoLabel}`}>
                  {format(new Date(nonCompliantOperation.creationDate), 'dd/MM/yyyy')}
                </span>
              </p>
              <p>
                {i18next.t(`operationsHandling:solve.info.time`)}{' '}
                <span className={`${styles.valueInfoLabel}`}>
                  {format(new Date(nonCompliantOperation.creationDate), 'HH:mm')}
                </span>
              </p>
              <p>
                {i18next.t(`operationsHandling:solve.info.operation`)}{' '}
                <span className={`${styles.valueInfoLabel} font-weight-bold`}>
                  {i18next.t(
                    `operationsHandling:table.types.${nonCompliantOperation.operation.type}`,
                  )}
                </span>
              </p>
              <p>
                {i18next.t(`operationsHandling:solve.info.subject`)}{' '}
                <span className={`${styles.valueInfoLabel} font-weight-bold`}>
                  {nonCompliantOperation.subject}
                </span>
              </p>
              <p>{i18next.t(`operationsHandling:solve.info.other`)} </p>
              <p>
                {i18next.t(`operationsHandling:solve.info.nature`)}{' '}
                <span className={`${styles.valueInfoLabel} font-weight-bold`}>
                  {nonCompliantOperation.nature}
                </span>
              </p>
            </Col>
          </Row>
          <Row className={`p-5 ${styles.formContainer}`}>
            <Col md={12} className="mb-5">
              <BaseNonComplianceForm
                selectedCausesIds={selectedCausesIds}
                setSelectedCausesIds={setSelectedCausesIds}
                selectedCorrectiveActionsIds={selectedCorrectiveActionsIds}
                setSelectedCorrectiveActionsIds={setSelectedCorrectiveActionsIds}
                comment={comment}
                setComment={setComment}
                correctiveActions={correctiveActions}
                causes={causes}
                nonCompliantOperation={nonCompliantOperation}
              />
            </Col>
          </Row>
          <hr style={{ width: '80%' }} />
          {/* Signatories */}
          <BaseSignatory
            signatories={signatories}
            selectedSignatory={selectedSignatory}
            setSelectedSignatory={setSelectedSignatory}
          />
          <BaseSaveButton onSave={handleValidateForm} text={i18next.t('shared:buttons.save')} />
        </Container>
      </>
    );
  }
  return null;
};

export default SolveNonCompliantTask;
