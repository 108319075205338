import React from 'react';
import { Navbar, Image, Button, Nav } from 'react-bootstrap';
import { Link, useRouteMatch } from 'react-router-dom';
import backButton from '@assets/images/svg/back.svg';
import editIcon from '@assets/images/svg/edit-white.svg';
import calendarIcon from '@assets/images/svg/small-calendar.svg';
import i18next from 'i18next';
import OperationTypeEnum from '@models/enums/OperationType';
import { history } from '@router/routes';
import styles from './BaseNavbarWithHistory.module.scss';

type Props = {
  title: string;
  showSettings: boolean;
  showHistory?: boolean;
  showValidate?: boolean;
  typeOperation?: OperationTypeEnum;
  onNavigate?: () => void;
};

const BaseNavbarWithHistory = (props: Props) => {
  const { path } = useRouteMatch();
  return (
    <Navbar className="navbarHygiap d-flex justify-content-between py-3">
      <Navbar.Brand
        style={{ zIndex: 2 }}
        onClick={() => {
          history.goBack();
          if (props.onNavigate) props.onNavigate();
        }}
      >
        <Image src={backButton} width={23} height={20} />
      </Navbar.Brand>
      <div className={styles.navbarTitle}>{props.title}</div>
      <Nav>
        {props.showSettings && (
          <Link
            to={{
              pathname: `${path}/settings`,
              state: null,
            }}
            style={{ zIndex: 2 }}
          >
            {/* Desktop */}
            <Button className={`${styles.settingsButton} btnXs d-none d-lg-block ml-2`}>
              <span className="mr-2">{i18next.t('shared:navbar.edit')}</span>
              <Image src={editIcon} width={15} height={14} />
            </Button>
            {/* Mobile / Ipad */}
            <Button className={`${styles.settingsButton} btnLg d-block d-lg-none ml-2`}>
              <Image src={editIcon} width={25} height={24} />
            </Button>
          </Link>
        )}
        {props.showHistory && (
          <Link
            to={{ pathname: '/operations-history', state: { typeOperation: props.typeOperation } }}
            style={{ zIndex: 2 }}
          >
            {/* Desktop */}
            <Button className={`${styles.historyButton} btnXs d-none d-lg-block ml-2`}>
              <span className="mr-2">{i18next.t('shared:navbar.history')}</span>
              <Image src={calendarIcon} width={15} height={15} />
            </Button>
            {/* Mobile / Ipad */}
            <Button className={`${styles.historyButton} btnLg d-block d-lg-none ml-2`}>
              <Image src={calendarIcon} width={25} height={25} />
            </Button>
          </Link>
        )}
        {props.showValidate && (
          <Navbar.Brand style={{ zIndex: 2 }} onClick={history.goBack}>
            {/* Desktop */}
            <Button className={`${styles.historyButton} btnXs d-none d-lg-block ml-2`}>
              <span>{i18next.t('shared:navbar.validate')}</span>
            </Button>
            {/* Mobile / Ipad */}
            <Button className={`${styles.historyButton} btnLg d-block d-lg-none ml-2`}>
              <Image src={calendarIcon} width={25} height={25} />
            </Button>
          </Navbar.Brand>
        )}
      </Nav>
    </Navbar>
  );
};

BaseNavbarWithHistory.defaultProps = {
  showHistory: true,
};

export default BaseNavbarWithHistory;
