import React from 'react';
import { Router, Switch, Route, Redirect } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import { history } from '@router/routes';
import { useAppSelector } from '@store/hooks';
import { ToastContainer } from 'react-toastify';
import i18next from 'i18next';
import routes from './router/routes';
import ScrollToTop from './components/ScrollToTop/ScrollToTop';
import Headband from './components/Headband/Headband';
import styles from './App.module.scss';
import 'react-toastify/dist/ReactToastify.css';

type PrivateRouteProps = {
  component: React.ComponentType<any>;
  path: string;
};

const PrivateRoute: any = (props: PrivateRouteProps) => {
  // eslint-disable-next-line no-undef
  const token = localStorage.getItem('token');
  const isAuthenticated = () => {
    return token !== undefined && token !== null;
  };
  return isAuthenticated() ? (
    <Route exact component={props.component} path={props.path} />
  ) : (
    <Route>
      <Redirect to="/login" />
    </Route>
  );
};

function App() {
  const establishment = useAppSelector((state) => state.account.selectedEstablishment);

  return (
    <Container className={`${styles.overflow} px-0 h-100`} fluid>
      <Router history={history}>
        {establishment && establishment.active === false ? (
          <Headband text={i18next.t('shared:establishmentDisabled')} color="#f9762d" />
        ) : null}
        <ScrollToTop />
        <Switch>
          <Route exact path="/">
            <Redirect to="/dashboard" />
          </Route>
          {routes.map((route, i) => {
            if (route.path.includes('/login')) {
              return <Route key={i} component={route.component} path={route.path} />;
            }
            return <PrivateRoute key={i} component={route.component} path={route.path} />;
          })}
          <Redirect to="/dashboard" />
        </Switch>
      </Router>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable={false}
        pauseOnHover
        theme="colored"
      />
    </Container>
  );
}

export default App;
