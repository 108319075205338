import { UpdateNonComplianceDto } from '@models/dto/request/UpdateNonCompliance.dto';
import { NonComplianceDto } from '@models/dto/response/NonCompliance.dto';
import { NonComplianceAnalysisDto } from '@models/dto/response/NonComplianceAnalysis.dto';
import { ApiResponse } from '@models/dto/ApiResponse.dto';
import { AxiosResponse } from 'axios';
import Axios from './index';

export default {
  createAnalysis: (data: FormData) => {
    return Axios.post(`/operation/analysis`, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },

  addPhotoToAnalysis: (analysisId: number, photo: FormData) => {
    return Axios.post(`/operation/analysis/${analysisId}/addPhoto`, photo, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },

  getNonCompliance: (
    id: number,
    analysis: number,
  ): Promise<AxiosResponse<ApiResponse<NonComplianceAnalysisDto | NonComplianceDto>>> => {
    return Axios.get(`/establishment/nonCompliance/${id}?analysis=${analysis}`);
  },

  updateNonCompliance: (
    id: number,
    data: UpdateNonComplianceDto,
    analysis: number,
  ): Promise<AxiosResponse<ApiResponse<NonComplianceAnalysisDto | NonComplianceDto>>> => {
    return Axios.put(`/establishment/nonCompliance/${id}?analysis=${analysis}`, data);
  },

  getCausesAndActions: () => {
    return Axios.get(`/establishment/causesCorrectiveActions`);
  },

  deleteNonCompliance: (id: number) => {
    return Axios.delete(`/establishment/nonCompliance/operation/${id}`);
  },
};
