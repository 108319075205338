import BaseNavbarWithHistory from '@components/shared/BaseNavbarWithHistory/BaseNavbarWithHistory';
import i18next from 'i18next';
import React, { useState } from 'react';
import { Container, Row, Col, Button, Image } from 'react-bootstrap';
import { createFile, deleteFile } from '@store/reducers/documents';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import FileSettingsItem from '@components/FileSettingsItem/FileSettingsItem';
import FileSettingsModal from '@components/FileSettingsModal/FileSettingsModal';
import { FolderDto } from '@models/dto/response/Folder.dto';
import { toast } from 'react-toastify';
import { useLocation } from 'react-router-dom';
import { FileDto } from '@models/dto/response/File.dto';
import { format } from 'date-fns';
import plusWhite from '@assets/images/svg/plus-rond.svg';
import styles from './DocumentsFileSettings.module.scss';

interface IFolder {
  folder: FolderDto;
}

const DocumentsFileSettings = () => {
  const establishmentId = useAppSelector((state) => state.account.selectedEstablishment?.id);
  const [showModal, setShowModal] = useState<boolean>(false);
  const folder = useLocation<IFolder>();
  const folderDetail = useAppSelector((state) =>
    state.document.folders.find((fold: FolderDto) => fold.id === folder.state.folder.id),
  );
  const filesList = folderDetail?.files;
  const dispatch = useAppDispatch();

  const addFolderModal = () => {
    setShowModal(true);
  };

  const addFile = (name: string, document: any) => {
    if (!name) {
      toast.warn(i18next.t('documents:settings.file.toasts.warn.noName'));
      return;
    }
    if (!document) {
      toast.warn(i18next.t('documents:settings.file.toasts.warn.noFile'));
      return;
    }

    if (establishmentId) {
      toast.promise(
        dispatch(
          createFile({ folderId: folder.state.folder.id, name, file: document[0] }),
        ).unwrap(),
        {
          pending: i18next.t('documents:settings.file.toasts.pending.create'),
          success: i18next.t('documents:settings.file.toasts.success.create'),
          error: i18next.t('documents:settings.file.toasts.error.create'),
        },
      );
    }
  };

  const removeFile = (folderId: number) => {
    toast.promise(dispatch(deleteFile(folderId)).unwrap(), {
      pending: i18next.t('documents:settings.file.toasts.pending.delete'),
      success: i18next.t('documents:settings.file.toasts.success.delete'),
      error: i18next.t('documents:settings.file.toasts.error.delete'),
    });
  };

  return (
    <>
      <BaseNavbarWithHistory
        title={i18next.t('documents:settings.navbar.title')}
        showSettings={false}
        showHistory={false}
      />
      <Container fluid className="h-100 pt-5 cornerRound px-5">
        <Row>
          <Col md={12} className="d-flex justify-content-between align-items-center">
            <div>
              <div className={styles.listTitle}>{folder.state.folder.name}</div>
              <div className={styles.listSubTitle}>
                {i18next.t('documents:folder.infoMAJ')}
                {!folder.state.folder.updateDate
                  ? format(new Date(folder.state.folder.creationDate), 'dd/MM/yyyy HH:mm')
                  : format(new Date(folder.state.folder.updateDate), 'dd/MM/yyyy HH:mm')}
              </div>
            </div>
            <Button
              className={`${styles.addButton} d-flex justify-content-center align-items-center btnXl`}
              onClick={addFolderModal}
            >
              <Image src={plusWhite} width={24} height={24} className="mr-2" />
              {i18next.t('documents:settings.file.addFile')}
            </Button>
          </Col>
        </Row>
        {filesList && filesList.length > 0 ? (
          <Row>
            {filesList.map((file: FileDto) => (
              <Col md={4} key={file.id} className="mt-4">
                <FileSettingsItem
                  file={file}
                  setShowModalDelete={() => removeFile(file.id)}
                  isEditMode
                />
              </Col>
            ))}
          </Row>
        ) : (
          <Row className="d-flex justify-content-center align-items-center h-100">
            <Col md={12} className="text-center tableHeader">
              {i18next.t('documents:settings.file.empty')}
            </Col>
          </Row>
        )}
        <FileSettingsModal
          showModal={showModal}
          setShowModal={setShowModal}
          onValidForm={addFile}
        />
      </Container>
    </>
  );
};

export default DocumentsFileSettings;
