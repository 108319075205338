import React, { Dispatch, SetStateAction, useState } from 'react';
import i18next from 'i18next';
import { Modal, Form } from 'react-bootstrap';
import { toast } from 'react-toastify';
import BaseModalFooter from '@components/shared/BaseModalFooter/BaseModalFooter';
import BaseModalHeader from '@components/shared/BaseModalHeader/BaseModalHeader';

type Props = {
  showModal: boolean;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  addDish: (name: string) => void;
};

const AddDishModal = (props: Props) => {
  const { showModal, setShowModal, addDish } = props;
  const [name, setName] = useState<string>();

  const handleValidate = () => {
    if (!name) {
      toast.warn(i18next.t('productTemp:toasts.warn.addDish'));
      return;
    }
    addDish(name);
    setName('');
    setShowModal(false);
  };
  return (
    <Modal
      show={showModal}
      onHide={() => setShowModal(false)}
      aria-labelledby="add-dish-modal"
      centered
    >
      <BaseModalHeader id="add-dish-modal" title={i18next.t('productTemp:addDishModal.title')} />
      <Modal.Body>
        <Form>
          {/* Name */}
          <Form.Group className="d-flex flex-row align-items-center mt-5" controlId="name">
            <Form.Control
              type="text"
              autoComplete="off"
              value={name}
              className="baseInputLg ml-3"
              onChange={(e) => setName(e.target.value)}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <BaseModalFooter handleValidate={handleValidate} setShowModal={setShowModal} />
    </Modal>
  );
};

export default AddDishModal;
