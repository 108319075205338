import React, { useEffect, useState } from 'react';
import { Navbar, Image, Container, Row, Col, Form } from 'react-bootstrap';
import navbarLogo from '@assets/images/svg/logo-hygiap-white.svg';
import i18next from 'i18next';
import BaseSaveButton from '@components/shared/BaseSaveButton/BaseSaveButton';
import LoginEstablishmentModal from '@components/LoginEstablishmentModal/LoginEstablishmentModal';
import { Link } from 'react-router-dom';
import { AxiosError } from 'axios';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { login as loginAction, setSelectedEstablishment, logout } from '@store/reducers/account';
import AccountTypeEnum from '@models/enums/AccountType';
import { history } from '@router/routes';
import { AccountDto, EstablishmentDto } from '@models/dto/response/Account.dto';
import { toast } from 'react-toastify';
import passwordIcon from '@assets/images/svg/visible.svg';
import { ApiResponse, ApiResponseMeta } from '@models/dto/ApiResponse.dto';
import ErrorTypeEnum from '@models/enums/ErrorType';
import styles from './Login.module.scss';

const Login = () => {
  const [login, setLogin] = useState<string>();
  const [password, setPassword] = useState<string>();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const dispatch = useAppDispatch();
  const account = useAppSelector((state) => state.account);

  const isFormValid = (): boolean => {
    return login !== undefined && password !== undefined && login !== '' && password !== '';
  };

  useEffect(() => {
    dispatch(logout());
  }, []);

  const handleSave = () => {
    setIsLoading(true);
    toast.loading(i18next.t('login:toasts.loading.connexion'));
    dispatch(loginAction({ login: login!, password: password! }))
      .unwrap()
      .then((res: ApiResponse<AccountDto, ApiResponseMeta>) => {
        toast.dismiss();
        // if token are present we saved them in local storage
        if (res && res.meta.token && res.meta.refreshToken) {
          // eslint-disable-next-line no-undef
          localStorage.setItem('refreshToken', res.meta.refreshToken);
          // eslint-disable-next-line no-undef
          localStorage.setItem('token', res.meta.token);
          // If more than one establishment = display modal
          if (res.data.type === AccountTypeEnum.admin && res.data.establishments.length > 1) {
            setShowModal(true);
          } else {
            toast.success(i18next.t('login:toasts.success.connexion'));
            // Auto select first establishment
            dispatch(setSelectedEstablishment(res.data.establishments[0]));
            history.replace('/dashboard');
          }
        } else {
          toast.error(i18next.t('login:toasts.error.connexion'));
        }
      })
      .catch((e: AxiosError) => {
        toast.dismiss();
        if (e.response?.data.meta.errorCode === ErrorTypeEnum.emailNotVerifed) {
          toast.error(i18next.t('login:toasts.error.emailNotVerifed'));
          return;
        }
        toast.error(i18next.t('login:toasts.error.connexion'));
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const closeModal = (selectedEstablishment: EstablishmentDto) => {
    setShowModal(false);
    dispatch(setSelectedEstablishment(selectedEstablishment));
    toast.success(i18next.t('login:toasts.success.connexion'));
    history.replace('/dashboard');
  };
  return (
    <>
      <Navbar className="navbarHygiap">
        <Navbar.Brand href="#home">
          <Image src={navbarLogo} width={171} height={49} />
        </Navbar.Brand>
      </Navbar>
      <Container fluid className={`px-5 ${styles.container} cornerRound`}>
        <Row>
          <Col md={12}>
            <Container fluid="lg">
              <Form>
                <h4 className={styles.title}>{i18next.t('login:connexion')}</h4>
                {/* Login */}
                <Form.Group className="mt-5" controlId="login">
                  <Form.Label className={styles.inputLabel}>{i18next.t('login:login')}</Form.Label>
                  <Form.Control
                    type="text"
                    className="baseInputLg mt-3"
                    value={login ?? ''}
                    onChange={(e) => setLogin(e.target.value)}
                  />
                </Form.Group>
                {/* Password */}
                <Form.Group className="mt-5" controlId="password">
                  <Form.Label className={styles.inputLabel}>
                    {i18next.t('login:password')}
                  </Form.Label>
                  <div className="d-flex flex-row">
                    <Form.Control
                      type={showPassword ? 'text' : 'password'}
                      className={`${styles.passwordInput} baseInputLg mt-3`}
                      value={password ?? ''}
                      onChange={(e) => setPassword(e.target.value)}
                      onKeyPress={(e: any) => {
                        if (e.key === 'Enter' && isFormValid()) {
                          handleSave();
                        }
                      }}
                    />
                    <Image
                      src={passwordIcon}
                      className={styles.passwordIcon}
                      onClick={() => setShowPassword(!showPassword)}
                    />
                  </div>
                  <Form.Text id="passwordHelpBlock" className="text-right">
                    <Link to="/login/forgot-password" className={styles.helperText}>
                      {i18next.t('login:forgotPassword')}
                    </Link>
                  </Form.Text>
                </Form.Group>
              </Form>
            </Container>
          </Col>
        </Row>
        {account.establishments && (
          <LoginEstablishmentModal
            showModal={showModal}
            setShowModal={setShowModal}
            onSelectEstablishment={closeModal}
            establishments={account.establishments}
          />
        )}
        <BaseSaveButton
          disabled={!isFormValid() || isLoading}
          onSave={handleSave}
          text={i18next.t('login:buttonConnect')}
        />
      </Container>
    </>
  );
};

export default Login;
