import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import i18next from 'i18next';
import { Col, Form, Row, Image, Button } from 'react-bootstrap';
import degresIconGreen from '@assets/images/svg/degres-green.svg';
import degresIconRed from '@assets/images/svg/degres-red.svg';
import styles from './BaseTemperature.module.scss';

type Props = {
  size?: 'small' | 'big';
  temperature?: string;
  setTemperature: Dispatch<SetStateAction<string | undefined>>;
  tMax?: number;
  tMin?: number;
  label?: string;
  setIsCompliant?: Dispatch<SetStateAction<boolean>>;
};

const BaseTemperature = (props: Props) => {
  const { temperature, setTemperature, tMin, tMax, label } = props;

  const [nonCompliantMeasure, setNonCompliantMeasure] = useState(false);
  const [isNegatif, setIsNegatif] = useState<boolean>(false);

  useEffect(() => {
    if (temperature !== undefined) {
      if (tMin !== undefined && parseFloat(temperature) < tMin) {
        setNonCompliantMeasure(true);
        if (props.setIsCompliant) {
          props.setIsCompliant(true);
        }
      } else if (tMax !== undefined && parseFloat(temperature) > tMax) {
        setNonCompliantMeasure(true);
        if (props.setIsCompliant) {
          props.setIsCompliant(true);
        }
      } else {
        setNonCompliantMeasure(false);
        if (props.setIsCompliant) {
          props.setIsCompliant(false);
        }
      }
    } else {
      setIsNegatif(false);
      setNonCompliantMeasure(false);
    }
  }, [tMax, tMin, temperature]);

  useEffect(() => {
    if (isNegatif && temperature && temperature.toString()[0] !== '-') {
      setTemperature(`-${temperature}`);
    }
    if (!isNegatif && temperature && temperature.toString()[0] === '-') {
      setTemperature(`${temperature.toString().substring(1)}`);
    }
  }, [isNegatif]);

  return (
    <Row className={props.size !== 'small' ? 'px-5' : ''} onClick={(e: any) => e.stopPropagation()}>
      <Col md={12} className="d-flex flex-column">
        <Form.Group
          className="mb-3 d-flex flex-md-row flex-column align-items-md-center"
          controlId="temperature"
        >
          {props.size !== 'small' ? (
            <Form.Label className={`${styles.inputLabel} mr-md-4 mr-3`}>
              {label || i18next.t('shared:measureTemp')}
            </Form.Label>
          ) : (
            <></>
          )}
          <div>
            <div className="d-flex flex-row">
              {props.size === 'small' ? (
                <Button
                  className={isNegatif ? styles.signButtonActiveSmall : styles.signButtonSmall}
                  onClick={() => setIsNegatif(!isNegatif)}
                >
                  -
                </Button>
              ) : (
                <Button
                  className={isNegatif ? styles.signButtonActive : styles.signButton}
                  onClick={() => setIsNegatif(!isNegatif)}
                >
                  -
                </Button>
              )}
              {props.size === 'small' ? (
                <Form.Control
                  type="text"
                  autoComplete="off"
                  value={temperature?.toString() ?? ''}
                  onChange={(e) => {
                    if (isNegatif && e.target.value[0] !== '-') {
                      setTemperature(`-${e.target.value.replace(',', '.')}`);
                    } else {
                      setTemperature(e.target.value.replace(',', '.'));
                    }
                    if (e.target.value[0] === '-') {
                      setIsNegatif(true);
                    }
                  }}
                  className={nonCompliantMeasure ? styles.nonCompliantSmall : styles.tempInputSmall}
                  inputMode="decimal"
                  step="0.1"
                />
              ) : (
                <Form.Control
                  type="text"
                  autoComplete="off"
                  value={temperature?.toString() ?? ''}
                  onChange={(e) => {
                    if (isNegatif && e.target.value[0] !== '-') {
                      setTemperature(`-${e.target.value.replace(',', '.')}`);
                    } else {
                      setTemperature(e.target.value.replace(',', '.'));
                    }
                    if (e.target.value[0] === '-') {
                      setIsNegatif(true);
                    }
                  }}
                  className={nonCompliantMeasure ? styles.nonCompliant : styles.tempInput}
                  inputMode="decimal"
                  step="0.1"
                />
              )}
              <Image
                src={nonCompliantMeasure ? degresIconRed : degresIconGreen}
                className={props.size === 'small' ? styles.degresIconSmall : styles.degresIcon}
              />
            </div>
            {nonCompliantMeasure && props.size !== 'small' && (
              <Form.Text className={styles.nonCompliantText}>
                {i18next.t('shared:temperature.nonCompliant')}
              </Form.Text>
            )}
          </div>
        </Form.Group>
      </Col>
    </Row>
  );
};

export default BaseTemperature;
