import axios from 'axios';
import { BASE_URL } from '@utils/constants';
import errorHandler from './ErrorHandler';

const axiosInstance = axios.create({
  baseURL: BASE_URL.trim(),
});

/**
 * Configuration globale de axios pour toutes les requêtes
 * de l'application
 */
axiosInstance.defaults.headers = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

// s'applique juste avant chaque requetes
axiosInstance.interceptors.request.use(
  (axiosConfig) => {
    // eslint-disable-next-line no-undef
    const token = localStorage.getItem('token');
    if (token) {
      axiosConfig.headers.Authorization = `Bearer ${token}`;
    }
    return axiosConfig;
  },
  (error) => {
    Promise.reject(error);
  },
);

// S'applique juste avant chaque réponse, on ajoute un error handler
axiosInstance.interceptors.response.use((response) => response, errorHandler);

export default axiosInstance;
