import { DeepFryerDto } from '@models/dto/response/DeepFryer.dto';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import deepFryerService from '@services/deep-fryer.service';
import { AxiosResponse } from 'axios';

// Define a type for the slice state
interface DeepFryerState {
  deepFryers: DeepFryerDto[];
  selectedDeepFryer?: DeepFryerDto;
}

// Define the initial state using that type
const initialState: DeepFryerState = {
  deepFryers: [],
};

interface CreateDeepFryerParams {
  name: string;
  establishmentId: number;
  picture: any;
}

interface UpdateDeepFryerParams {
  deepFryerId: number;
  name: string;
  picture: any;
}

// REQUESTS
export const getDeepFryers = createAsyncThunk<AxiosResponse<DeepFryerDto[]>, number>(
  'deepFryer/getAll',
  async (establishmentId: number) => {
    const response = await deepFryerService.getFryers(establishmentId);
    return response.data;
  },
);

export const createDeepFryer = createAsyncThunk<AxiosResponse<DeepFryerDto>, CreateDeepFryerParams>(
  'deepFryer/add',
  async (params: CreateDeepFryerParams) => {
    const { establishmentId, ...rest } = params;
    const formData = new FormData();
    if (rest.picture) {
      formData.append('picture', rest.picture);
    }
    formData.append('name', rest.name ?? '');
    const response = await deepFryerService.createFryer(establishmentId, formData);
    return response.data;
  },
);

export const updateDeepFryer = createAsyncThunk<AxiosResponse<DeepFryerDto>, UpdateDeepFryerParams>(
  'deepFryer/update',
  async (params: UpdateDeepFryerParams) => {
    const { deepFryerId, ...rest } = params;
    const formData = new FormData();
    if (rest.picture) {
      formData.append('picture', rest.picture);
    }
    formData.append('name', rest.name ?? '');
    const response = await deepFryerService.updateFryer(deepFryerId, formData);
    return response.data;
  },
);

export const deleteDeepFryer = createAsyncThunk<AxiosResponse<void>, number>(
  'deepFryer/delete',
  async (deepFryerId: number) => {
    const response = await deepFryerService.deleteFryer(deepFryerId);
    return response.data;
  },
);

export const DeepFryerSlice = createSlice({
  name: 'deepFryer',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    selectDeepFryer: (state, action: PayloadAction<DeepFryerDto>) => {
      state.selectedDeepFryer = action.payload;
    },
  },
  extraReducers: (builder) => {
    // Side effects
    // Get deep fryer list
    builder.addCase(getDeepFryers.fulfilled, (state, action) => {
      state.deepFryers = action.payload.data ?? [];
    });
    // Add deep fryer
    builder.addCase(createDeepFryer.fulfilled, (state, action) => {
      state.deepFryers.push(action.payload.data);
    });
    // Update deep fryer
    builder.addCase(updateDeepFryer.fulfilled, (state, action) => {
      const index = state.deepFryers.findIndex((el) => el.id === action.payload.data.id);
      state.deepFryers[index] = action.payload.data;
    });
    // Delete deep fryer
    builder.addCase(deleteDeepFryer.fulfilled, (state, action) => {
      const index = state.deepFryers.findIndex((el) => el.id === action.meta.arg);
      state.deepFryers.splice(index, 1);
    });
  },
});

export const { selectDeepFryer } = DeepFryerSlice.actions;

export default DeepFryerSlice.reducer;
