import Axios from './index';

export default {
  getSignatories: (establishmentId: number) => {
    return Axios.get(`/establishment/${establishmentId}/signatory`);
  },

  createSignatory: (establishmentId: number, data: FormData) => {
    return Axios.post(`/establishment/${establishmentId}/signatory`, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },

  getSignatory: (id: number) => {
    return Axios.get(`/establishment/signatory/${id}`);
  },

  updateSignatory: (id: number, data: FormData) => {
    return Axios.post(`/establishment/signatory/${id}`, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },

  deleteSignatory: (id: number) => {
    return Axios.delete(`/establishment/signatory/${id}`);
  },
};
