import BaseNavbarWithHistory from '@components/shared/BaseNavbarWithHistory/BaseNavbarWithHistory';
import BasePhotoPicker from '@components/shared/BasePhotoPicker/BasePhotoPicker';
import BaseSignatory from '@components/shared/BaseSignatory/BaseSignatory';
import { SignatoryDto } from '@models/dto/response/Signatory.dto';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import i18next from 'i18next';
import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Form } from 'react-bootstrap';
import BaseNonComplianceForm from '@components/shared/BaseNonComplianceForm/BaseNonComplianceForm';
import { getSignatories } from '@store/reducers/signatory';
import NonCompliantAnalysisTypology from '@components/NonCompliantAnalysisTypology/NonCompliantAnalysisTypology';
import AnalysisTypologyEnum from '@models/enums/AnalysisTypology';
import { toast } from 'react-toastify';
import { CreateAnalysisNonComplianceParams } from '@models/dto/request/AnalysisNonCompliance.dto';
import {
  createAnalysis,
  getCausesAndActions,
  updateAnalysis,
  updateNonCompliance,
} from '@store/reducers/non-compliance';
import { history } from '@router/routes';
import BaseSaveButton from '@components/shared/BaseSaveButton/BaseSaveButton';
import OperationModeEnum from '@models/enums/OperationMode';
import { UpdateNonComplianceParams } from '@models/dto/request/UpdateNonCompliance.dto';
import { CauseDto, CorrectiveActionDto } from '@models/dto/response/NonCompliance.dto';
import NonComplianceTypeEnum from '@models/enums/NonComplianceType';
import { getNonCompliantTasks } from '@store/reducers/history';
import { format, formatISO, setHours, setMinutes } from 'date-fns';
import BaseDatePicker from '@components/shared/BaseDatePicker/BaseDatePicker';
import { FormControl, FormControlLabel, Radio, RadioGroup } from '@material-ui/core';

const CreateNonCompliantAnalysis = () => {
  // State component
  const [photos, setPhotos] = useState<any>([]);
  const [selectedTypology, setSelectedTypology] = useState<AnalysisTypologyEnum>();
  const [selectedSignatory, setSelectedSignatory] = useState<SignatoryDto>();
  const [selectedCausesIds, setSelectedCausesIds] = useState<string[]>([]);
  const [selectedCorrectiveActionsIds, setSelectedCorrectiveActionsIds] = useState<string[]>([]);
  const [comment, setComment] = useState<string>('');
  const [mode, setMode] = useState<OperationModeEnum>(OperationModeEnum.create);
  const [causes, setCauses] = useState<CauseDto[]>([]);
  const [correctiveActions, setCorrectiveActions] = useState<CorrectiveActionDto[]>([]);
  const [currentDate, setCurrentDate] = useState<Date>(new Date());
  const [currentTime, setCurrentTime] = useState<string>(format(new Date(), 'HH:mm'));
  const [isCompliant, setIsCompliant] = useState<boolean>(true);

  // Store
  const signatories = useAppSelector((state) => state.signatory.signatories);
  const establishmentId = useAppSelector((state) => state.account.selectedEstablishment?.id);
  const nonCompliantAnalysis = useAppSelector(
    (state) => state.nonCompliance.selectedNonComplianceAnalysis,
  );
  const causesAndActions = useAppSelector(
    (state) => state.nonCompliance.causesAndCorrectivesActions,
  );
  const dispatch = useAppDispatch();

  // In case of update, not in use for the moment
  useEffect(() => {
    if (nonCompliantAnalysis) {
      setMode(OperationModeEnum.update);
      setSelectedCausesIds(nonCompliantAnalysis.causes.map((item) => item.id.toString()));
      setSelectedCorrectiveActionsIds(
        nonCompliantAnalysis.correctiveActions.map((item) => item.id.toString()),
      );
      setSelectedSignatory(nonCompliantAnalysis.signatory);
      setSelectedTypology(nonCompliantAnalysis.analysisTypology);
      setComment(nonCompliantAnalysis.analysisReport);
    }
  }, []);

  useEffect(() => {
    const causeList = causesAndActions.find((item) => item.type === NonComplianceTypeEnum.analysis);
    const actionList = causesAndActions.find(
      (item) => item.type === NonComplianceTypeEnum.analysis,
    );
    setCauses(causeList?.causes ?? []);
    setCorrectiveActions(actionList?.correctiveActions ?? []);
  }, [causesAndActions]);

  useEffect(() => {
    if (establishmentId && (!signatories || signatories.length === 0)) {
      dispatch(getSignatories(establishmentId)).unwrap();
    }
    if (!causesAndActions || causesAndActions.length === 0) {
      dispatch(getCausesAndActions()).unwrap();
    }
  }, []);

  // Update Date with custom time
  const buildDate = () => {
    let formattedDate = currentDate;
    const timeArray = currentTime.split(':');
    formattedDate = setMinutes(formattedDate, Number(timeArray[1]));
    formattedDate = setHours(formattedDate, Number(timeArray[0]));
    return formattedDate;
  };

  const create = () => {
    const data: CreateAnalysisNonComplianceParams = {
      establishmentId: establishmentId!,
      causeIds: isCompliant ? null : selectedCausesIds.join(',') ?? undefined,
      correctiveActionIds: isCompliant ? null : selectedCorrectiveActionsIds.join(',') ?? undefined,
      signatoryId: selectedSignatory!.id,
      comment: isCompliant ? undefined : comment,
      analysisTypology: isCompliant ? undefined : selectedTypology!,
      realisationDate: formatISO(buildDate()),
    };

    if (photos && photos.length > 0) {
      data.analysisReport = photos[0];
    }

    dispatch(createAnalysis(data))
      .unwrap()
      .then((res) => {
        if (photos && photos.length > 0) {
          const requests = photos.map((photo: string) => {
            return dispatch(updateAnalysis({ analysisId: res.data.operation.id, picture: photo }));
          });
          Promise.all(requests)
            .then(() => {
              toast.success(i18next.t('operationsHandling:toasts.success.createAnalysis'));
              dispatch(
                getNonCompliantTasks({ establishmentId: establishmentId!, page: 0 }),
              ).finally(() => history.goBack());
            })
            .catch(() => {
              toast.error(i18next.t('operationsHandling:toasts.error.createAnalysis'));
            });
        } else {
          toast.success(i18next.t('operationsHandling:toasts.success.createAnalysis'));
          dispatch(getNonCompliantTasks({ establishmentId: establishmentId!, page: 0 })).finally(
            () => history.goBack(),
          );
        }
      })
      .catch(() => {
        toast.error(i18next.t('operationsHandling:toasts.error.createAnalysis'));
      });
  };

  // In case of update, not in use for the moment
  const update = () => {
    const data: UpdateNonComplianceParams = {
      analysis: 1,
      nonComplianceId: nonCompliantAnalysis!.id,
      causeIds: selectedCausesIds.join(',') ?? undefined,
      correctiveActionIds: selectedCorrectiveActionsIds.join(',') ?? undefined,
      comment,
      signatoryId: selectedSignatory!.id,
    };

    dispatch(updateNonCompliance(data))
      .unwrap()
      .then(() => {
        toast.success(i18next.t('operationsHandling:toasts.success.updateAnalysis'));
        history.goBack();
      })
      .catch(() => {
        toast.error(i18next.t('operationsHandling:toasts.error.updateAnalysis'));
      });
  };

  const handleValidateForm = () => {
    if (!selectedTypology && !isCompliant) {
      toast.warn(i18next.t('operationsHandling:toasts.warn.typology'));
      return;
    }
    if (!selectedSignatory) {
      toast.warn(i18next.t('operationsHandling:toasts.warn.signatory'));
      return;
    }

    if (establishmentId && mode === OperationModeEnum.create) {
      create();
    } else if (mode === OperationModeEnum.update) {
      update();
    }
  };

  return (
    <>
      {/* Navbar */}
      <BaseNavbarWithHistory
        title={i18next.t('operationsHandling:analysis.navbar.title')}
        showSettings={false}
        showHistory={false}
      />
      <Container>
        <div className="my-5">
          <BasePhotoPicker
            photos={photos}
            setPhotos={setPhotos}
            title={i18next.t('operationsHandling:analysis.form.photo')}
            acceptType="image/jpeg, image/jpg,image/png,application/pdf"
            btnLabel={i18next.t('operationsHandling:analysis.form.label')}
            multiPictureAllowed
          />
        </div>
        {/* Date picker */}
        <div className="my-5">
          <BaseDatePicker
            currentDate={currentDate}
            setCurrentDate={setCurrentDate}
            currentTime={currentTime}
            setCurrentTime={setCurrentTime}
            showTitle
          />
        </div>
        <div className="my-5">
          <FormControl component="fieldset" className="mb-3">
            <Form.Label>{i18next.t('operationsHandling:analysis.form.compliance')}</Form.Label>
            <RadioGroup
              aria-label="deliveryTemperatureType"
              name="deliveryTemperatureType"
              className="d-flex flex-row"
              value={isCompliant}
              onChange={(e) => {
                if (e.target.value === 'true') {
                  setIsCompliant(true);
                } else if (e.target.value === 'false') {
                  setIsCompliant(false);
                }
              }}
            >
              <FormControlLabel
                value="true"
                checked={isCompliant}
                control={<Radio className="checkBoxCustom" />}
                label={i18next.t('shared:buttons.yes')}
              />
              <FormControlLabel
                value="false"
                checked={!isCompliant}
                control={<Radio className="checkBoxCustom" />}
                label={i18next.t('shared:buttons.no')}
              />
            </RadioGroup>
          </FormControl>
        </div>
        {!isCompliant ? (
          <>
            <hr style={{ width: '80%' }} />
            <NonCompliantAnalysisTypology
              selectedTypology={selectedTypology}
              setSelectedTypology={setSelectedTypology}
            />
            <hr style={{ width: '80%' }} />
            {/* Select form */}
            <Row className="py-5">
              <Col md={12} className="mb-5">
                <BaseNonComplianceForm
                  selectedCausesIds={selectedCausesIds}
                  setSelectedCausesIds={setSelectedCausesIds}
                  selectedCorrectiveActionsIds={selectedCorrectiveActionsIds}
                  setSelectedCorrectiveActionsIds={setSelectedCorrectiveActionsIds}
                  comment={comment}
                  setComment={setComment}
                  correctiveActions={correctiveActions}
                  causes={causes}
                  nonCompliantOperation={nonCompliantAnalysis}
                />
              </Col>
            </Row>
          </>
        ) : (
          <></>
        )}
        <hr style={{ width: '80%' }} />
        {/* Signatories */}
        <BaseSignatory
          signatories={signatories}
          selectedSignatory={selectedSignatory}
          setSelectedSignatory={setSelectedSignatory}
        />
        <BaseSaveButton onSave={handleValidateForm} text={i18next.t('shared:buttons.save')} />
      </Container>
    </>
  );
};

export default CreateNonCompliantAnalysis;
