import { DishFamilyDto } from '@models/dto/response/DishFamily.dto';
import { DishSubFamilyDto } from '@models/dto/response/DishSubFamily.dto';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import dishService from '@services/dish.service';
import { AxiosResponse } from 'axios';
import { CreateDishSubFamilyParams } from '@models/dto/request/CreateDishSubFamilyParams.dto';
import { UpdateDishSubFamilyParams } from '@models/dto/request/UpdateDishSubFamilyParams.dto';
import { DishDto } from '@models/dto/response/Dish.dto';
import {
  CreateDishParams,
  DeleteDishParams,
  UpdateDishParams,
} from '@models/dto/request/DishParams.dto';
// Define a type for the slice state
interface DishState {
  dishesFamily: DishFamilyDto[];
  selectedDishSubFamilies: DishSubFamilyDto[];
  // For Product temperature dish list
  selectedDishesList: DishDto[];
  selectedDish?: DishDto;
  selectedDishSubFamily?: DishSubFamilyDto;
  selectedDishFamily?: DishFamilyDto;
}

// Define the initial state using that type
const initialState: DishState = {
  dishesFamily: [],
  selectedDishSubFamilies: [],
  selectedDishesList: [],
};

// REQUESTS
export const getDishesFamily = createAsyncThunk<AxiosResponse<DishFamilyDto[]>, number>(
  'dishFamily/getAll',
  async (establishmentId: number) => {
    const response = await dishService.getDishFamilies(establishmentId);
    return response.data;
  },
);

export const getDishSubFamilies = createAsyncThunk<AxiosResponse<DishSubFamilyDto[]>, number>(
  'dishFamily/getSubFamilies',
  async (dishFamilyId: number) => {
    const response = await dishService.getDishSubFamilies(dishFamilyId);
    return response.data;
  },
);

export const getDishesSubFamily = createAsyncThunk<AxiosResponse<DishDto[]>, number>(
  'dishSubFamilyDishes/getAll',
  async (dishSubFamilyId: number) => {
    const response = await dishService.getDishesSubFamily(dishSubFamilyId);
    return response.data;
  },
);

// CRUD Dish sub family
export const createDishSubFamily = createAsyncThunk<
  AxiosResponse<DishSubFamilyDto>,
  CreateDishSubFamilyParams
>('dishSubFamily/create', async (params: CreateDishSubFamilyParams) => {
  const { dishFamilyId, ...rest } = params;
  const response = await dishService.createDishSubFamily(dishFamilyId, rest);
  return response.data;
});

export const updateDishSubFamily = createAsyncThunk<
  AxiosResponse<DishSubFamilyDto>,
  UpdateDishSubFamilyParams
>('dishSubFamily/update', async (params: UpdateDishSubFamilyParams) => {
  const { dishSubFamilyId, ...rest } = params;
  const response = await dishService.updateDishSubFamily(dishSubFamilyId, rest);
  return response.data;
});

export const deleteDishSubFamily = createAsyncThunk<void, number>(
  'dishSubFamily/delete',
  async (subFamilyId: number) => {
    const response = await dishService.deleteDishSubFamily(subFamilyId);
    return response.data;
  },
);

// CRUD Dish
export const createDish = createAsyncThunk<AxiosResponse<DishDto>, CreateDishParams>(
  'dish/create',
  async (params: CreateDishParams) => {
    const { dishSubFamilyId, name } = params;
    const response = await dishService.createDish(dishSubFamilyId, name);
    return response.data;
  },
);

export const addDishToListView = createAsyncThunk<AxiosResponse<DishDto>, CreateDishParams>(
  'dish/addDishToListView',
  async (params: CreateDishParams) => {
    const { dishSubFamilyId, name } = params;
    const response = await dishService.createDish(dishSubFamilyId, name);
    return response.data;
  },
);

export const updateDish = createAsyncThunk<AxiosResponse<DishDto>, UpdateDishParams>(
  'dish/update',
  async (params: UpdateDishParams) => {
    const { dishId, name } = params;
    const response = await dishService.updateDish(dishId, name);
    return response.data;
  },
);

export const deleteDish = createAsyncThunk<void, DeleteDishParams>(
  'dish/delete',
  async (params: DeleteDishParams) => {
    const response = await dishService.deleteDish(params.dishId);
    return response.data;
  },
);

export const DishSlice = createSlice({
  name: 'dish',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setSelectedDishesList: (state, action: PayloadAction<DishDto[] | []>) => {
      state.selectedDishesList = action.payload ?? [];
    },
    // For product temp dish list view
    setSelectedDish: (state, action: PayloadAction<DishDto | undefined>) => {
      state.selectedDish = action.payload;
    },
    setSelectedDishFamily: (state, action: PayloadAction<DishFamilyDto | undefined>) => {
      state.selectedDishFamily = action.payload;
    },
    setSelectedDishSubFamily: (state, action: PayloadAction<DishSubFamilyDto | undefined>) => {
      state.selectedDishSubFamily = action.payload;
    },
  },
  extraReducers: (builder) => {
    // Side effects
    // Get dishes family list
    builder.addCase(getDishesFamily.fulfilled, (state, action) => {
      state.dishesFamily = action.payload.data ?? [];
    });
    // Get dish sub family list
    builder.addCase(getDishSubFamilies.fulfilled, (state, action) => {
      state.selectedDishSubFamilies = action.payload.data ?? [];
    });
    // Get all dishes for dish sub family
    builder.addCase(getDishesSubFamily.fulfilled, (state, action) => {
      state.selectedDishesList = action.payload.data ?? [];
    });
    // Add newly created sub family
    builder.addCase(createDishSubFamily.fulfilled, (state, action) => {
      state.selectedDishSubFamilies.push(action.payload.data);
    });
    // Update subFamily
    builder.addCase(updateDishSubFamily.fulfilled, (state, action) => {
      const index = state.selectedDishSubFamilies.findIndex(
        (el) => el.id === action.payload.data.id,
      );
      state.selectedDishSubFamilies[index] = action.payload.data;
    });
    // Delete subFamily
    builder.addCase(deleteDishSubFamily.fulfilled, (state, action) => {
      const index = state.selectedDishSubFamilies.findIndex((el) => el.id === action.meta.arg);
      state.selectedDishSubFamilies.splice(index, 1);
    });
    // Add new dish
    builder.addCase(createDish.fulfilled, (state, action) => {
      // if in settings
      const index = state.selectedDishSubFamilies.findIndex(
        (el) => el.id === action.meta.arg.dishSubFamilyId,
      );
      state.selectedDishSubFamilies[index].dishes.push(action.payload.data);
    });
    builder.addCase(addDishToListView.fulfilled, (state, action) => {
      // if in list view
      state.selectedDishesList.push(action.payload.data);
    });
    // Update dish
    builder.addCase(updateDish.fulfilled, (state, action) => {
      const index = state.selectedDishSubFamilies.findIndex(
        (el) => el.id === action.meta.arg.dishSubFamilyId,
      );
      const dishIndex = state.selectedDishSubFamilies[index].dishes.findIndex(
        (el) => el.id === action.meta.arg.dishId,
      );
      state.selectedDishSubFamilies[index].dishes[dishIndex] = action.payload.data;
    });
    // Delete dish
    builder.addCase(deleteDish.fulfilled, (state, action) => {
      const index = state.selectedDishSubFamilies.findIndex(
        (el) => el.id === action.meta.arg.dishSubFamilyId,
      );
      const dishIndex = state.selectedDishSubFamilies[index].dishes.findIndex(
        (el) => el.id === action.meta.arg.dishId,
      );
      state.selectedDishSubFamilies[index].dishes.splice(dishIndex, 1);
    });
  },
});

export const {
  setSelectedDishesList,
  setSelectedDish,
  setSelectedDishFamily,
  setSelectedDishSubFamily,
} = DishSlice.actions;

export default DishSlice.reducer;
