import { CreateEquipmentDto } from '@models/dto/request/CreateEquipment.dto';
import { EquipmentDto, EquipmentTemperatureDto } from '@models/dto/response/Equipment.dto';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import equipmentService from '@services/equipment.service';
import { AxiosResponse } from 'axios';

// Define a type for the slice state
interface EquipmentState {
  equipments: EquipmentDto[];
  selectedEquipment?: EquipmentDto[];
  selectedEquipmentId?: number;
  selectedEquipmentTemparature: EquipmentTemperatureDto[];
}

// Define the initial state using that type
const initialState: EquipmentState = {
  equipments: [],
  selectedEquipment: [],
  selectedEquipmentTemparature: [],
};

interface CreateEquipmentParams extends CreateEquipmentDto {
  establishmentId: number;
}

interface UpdateEquipmentParams extends CreateEquipmentDto {
  equipmentId: number;
}

// REQUESTS
export const getEquipments = createAsyncThunk<AxiosResponse<EquipmentDto[]>, number>(
  'equipment/getAll',
  async (establishmentId: number) => {
    const response = await equipmentService.getEquipments(establishmentId);
    return response.data;
  },
);

export const createEquipment = createAsyncThunk<AxiosResponse<EquipmentDto>, CreateEquipmentParams>(
  'equipment/add',
  async (params: CreateEquipmentParams) => {
    const { establishmentId, ...rest } = params;
    const formData = new FormData();
    if (rest.picture) {
      formData.append('picture', rest.picture);
    }
    formData.append('name', rest.name ?? '');
    formData.append('minTemperature', rest.minTemperature.toString());
    formData.append('maxTemperature', rest.maxTemperature.toString());
    formData.append('frequencyJson', rest.frequencyJson ?? '');
    const response = await equipmentService.createEquipment(establishmentId, formData);
    return response.data;
  },
);

export const updateEquipment = createAsyncThunk<AxiosResponse<EquipmentDto>, UpdateEquipmentParams>(
  'equipment/update',
  async (params: UpdateEquipmentParams) => {
    const { equipmentId, ...rest } = params;
    const formData = new FormData();
    if (rest.picture) {
      formData.append('picture', rest.picture);
    }
    formData.append('name', rest.name ?? '');
    formData.append('minTemperature', rest.minTemperature.toString());
    formData.append('maxTemperature', rest.maxTemperature.toString());
    formData.append('frequencyJson', rest.frequencyJson ?? '');
    const response = await equipmentService.updateEquipment(equipmentId, formData);
    return response.data;
  },
);

export const deleteEquipment = createAsyncThunk<AxiosResponse<void>, number>(
  'equipment/delete',
  async (equipmentId: number) => {
    const response = await equipmentService.deleteEquipment(equipmentId);
    return response.data;
  },
);

export const EquipmentSlice = createSlice({
  name: 'equipment',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    selectEquipment: (state, action: PayloadAction<EquipmentDto[] | undefined>) => {
      state.selectedEquipment = action.payload;
    },
    selectEquipmentId: (state, action: PayloadAction<number | undefined>) => {
      state.selectedEquipmentId = action.payload;
    },
    selectEquipmentTemperature: (state, action: PayloadAction<EquipmentTemperatureDto>) => {
      const isAlreadyCheked = state.selectedEquipmentTemparature?.find(
        (equip: EquipmentTemperatureDto) => equip.equipmentId === action.payload?.equipmentId,
      );
      if (isAlreadyCheked) {
        state.selectedEquipmentTemparature =
          state.selectedEquipmentTemparature?.map((equip: EquipmentTemperatureDto) => {
            if (equip.equipmentId !== action.payload?.equipmentId) {
              return equip;
            }
            return {
              equipmentId: action.payload.equipmentId,
              temperature: action.payload.temperature,
            };
          }) ?? [];
      } else {
        state.selectedEquipmentTemparature.push(action.payload);
      }
    },
    deleteEquipmentTemperature: (state, action: PayloadAction<number>) => {
      state.selectedEquipmentTemparature = state.selectedEquipmentTemparature.filter(
        (equip: EquipmentTemperatureDto) => equip.equipmentId !== action.payload,
      );
    },
    resetEquipmentTemperature: (state) => {
      state.selectedEquipmentTemparature = [];
    },
  },
  extraReducers: (builder) => {
    // Side effects
    // Get equipment list
    builder.addCase(getEquipments.fulfilled, (state, action) => {
      state.equipments = action.payload.data ?? [];
    });
    // Add equipment
    builder.addCase(createEquipment.fulfilled, (state, action) => {
      state.equipments.push(action.payload.data);
    });
    // Update equipment
    builder.addCase(updateEquipment.fulfilled, (state, action) => {
      const index = state.equipments.findIndex((el) => el.id === action.payload.data.id);
      state.equipments[index] = action.payload.data;
    });
    // Delete equipment
    builder.addCase(deleteEquipment.fulfilled, (state, action) => {
      const index = state.equipments.findIndex((el) => el.id === action.meta.arg);
      state.equipments.splice(index, 1);
    });
  },
});

export const {
  selectEquipment,
  selectEquipmentId,
  selectEquipmentTemperature,
  deleteEquipmentTemperature,
  resetEquipmentTemperature,
} = EquipmentSlice.actions;

export default EquipmentSlice.reducer;
