import React, { Dispatch, SetStateAction, useState } from 'react';
import i18next from 'i18next'; // i18next.t('pageName:exemple.exemple')
import { Form, Modal } from 'react-bootstrap';
import BaseModalFooter from '@components/shared/BaseModalFooter/BaseModalFooter';
import BaseModalHeader from '@components/shared/BaseModalHeader/BaseModalHeader';
import styles from './SettingsNotificationsAddModal.module.scss';

type Props = {
  showModal: boolean;
  isDailyModal: boolean;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  addNewSignatory: (email: string) => void;
};

const SettingsNotificationsAddModal = (props: Props) => {
  const { showModal, isDailyModal, setShowModal, addNewSignatory } = props;
  const [email, setEmail] = useState<string>('');

  const handleValidate = () => {
    addNewSignatory(email);
    setEmail('');
  };

  return (
    <Modal
      show={showModal}
      onHide={() => setShowModal(false)}
      size="lg"
      aria-labelledby="settings-notifications-modal"
      centered
    >
      <BaseModalHeader
        id="settings-notifications-modal"
        title={
          isDailyModal
            ? i18next.t('settings:notifications.modals.addTitleDaily')
            : i18next.t('settings:notifications.modals.addTitleWeekly')
        }
      />
      <Modal.Body>
        <Form className="d-flex flex-row align-items-center mb-5">
          {/* Email */}
          <Form.Group
            className="d-flex flex-md-row flex-column align-items-md-center mt-5"
            controlId="email"
          >
            <Form.Label className={styles.inputLabel}>
              {i18next.t('settings:notifications.modals.form.email')}
            </Form.Label>
            <Form.Control
              type="text"
              autoComplete="off"
              className="baseInputLg ml-0 ml-md-3 col-12"
              value={email ?? ''}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <BaseModalFooter handleValidate={handleValidate} setShowModal={setShowModal} />
    </Modal>
  );
};

export default SettingsNotificationsAddModal;
