import { ApiResponse } from '@models/dto/ApiResponse.dto';
import { GetHistoryOperationParams } from '@models/dto/request/HistoryOperation.dto';
import { TodoTaskDto } from '@models/dto/response/Task.dto';
import { OperationsListDto } from '@store/reducers/history';
import { DEFAULT_LIMIT, DEFAULT_PAGE } from '@utils/constants';
import { AxiosResponse } from 'axios';
import fileSaver from 'file-saver';
import { format } from 'date-fns';
import { toast } from 'react-toastify';
import i18next from 'i18next';
import Axios from './index';

export default {
  getStats: (establishmentId: number) => {
    return Axios.get(`/establishment/${establishmentId}/history/stats`);
  },

  getOperations: (
    establishmentId: number,
    params?: GetHistoryOperationParams,
  ): Promise<AxiosResponse<ApiResponse<OperationsListDto[]>>> => {
    return Axios.get(`/establishment/${establishmentId}/history/operation`, {
      params: {
        startDate: params?.startDate,
        endDate: params?.endDate,
        operationType: params?.operationType,
        hasNonCompliance: params?.hasNonCompliance,
        page: params?.page,
        limit: 50,
      },
    });
  },
  getExportOperations: (establishmentId: number, params?: GetHistoryOperationParams) => {
    Axios.get(`/establishment/${establishmentId}/history/operation/export`, {
      responseType: 'arraybuffer',
      params: {
        startDate: params?.startDate,
        endDate: params?.endDate,
        operationType: params?.operationType,
        hasNonCompliance: params?.hasNonCompliance,
      },
    })
      .then((response) => {
        const blob = new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        fileSaver.saveAs(blob, `listing_operations_${format(Date.now(), 'dd/MM/yyyy')}.xlsx`);
        toast.success(i18next.t('operationsHistory:toasts.success.export'));
      })
      .catch(() => {
        toast.error(i18next.t('operationsHistory:toasts.error.export'));
      });
  },
  getLateTasks: (establishmentId: number, page?: number, limit?: number) => {
    return Axios.get(`/establishment/${establishmentId}/history/lateTasks`, {
      params: {
        page: page ?? DEFAULT_PAGE,
        limit: limit ?? DEFAULT_LIMIT,
      },
    });
  },
  getNonCompliantTasks: (establishmentId: number, page?: number, limit?: number) => {
    return Axios.get(`/establishment/${establishmentId}/history/noncompliance`, {
      params: {
        page: page ?? DEFAULT_PAGE,
        limit: limit ?? DEFAULT_LIMIT,
      },
    });
  },
  getTaskTodo: (
    establishmentId: number,
    page?: number,
    limit?: number,
  ): Promise<AxiosResponse<TodoTaskDto>> => {
    return Axios.get(`/establishment/${establishmentId}/history/tasksTodo`, {
      params: {
        page: page ?? DEFAULT_PAGE,
        limit: limit ?? DEFAULT_LIMIT,
      },
    });
  },
};
