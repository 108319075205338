import React, { Dispatch, SetStateAction, useState } from 'react';
import i18next from 'i18next';
import { Form, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { CreateDishSubFamilyParams } from '@models/dto/request/CreateDishSubFamilyParams.dto';
import BaseModalHeader from '@components/shared/BaseModalHeader/BaseModalHeader';
import BaseModalFooter from '@components/shared/BaseModalFooter/BaseModalFooter';
import styles from './ProductTempSettingsSubFamiliesModal.module.scss';

type Props = {
  showModal: boolean;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  dishFamilyId: number;
  createNewDishSubFamily: (data: CreateDishSubFamilyParams) => void;
};

const ProductTempSettingsSubFamiliesModal = (props: Props) => {
  const { showModal, setShowModal, dishFamilyId, createNewDishSubFamily } = props;

  const [name, setName] = useState<string>();
  const [minTemperature, setMinTemperature] = useState<number>();
  const [maxTemperature, setMaxTemperature] = useState<number>();

  const resetForm = () => {
    setName(undefined);
    setMinTemperature(undefined);
    setMaxTemperature(undefined);
  };

  const handleValidate = () => {
    if (!name) {
      toast.warn('Veuillez renseigner un nom');
      return;
    }

    if (!maxTemperature && maxTemperature !== 0) {
      toast.warn(i18next.t('productTemp:toasts.warn.minTemp'));
      return;
    }

    if (!minTemperature && minTemperature !== 0) {
      toast.warn(i18next.t('productTemp:toasts.warn.maxTemp'));
      return;
    }

    if (minTemperature >= maxTemperature) {
      toast.warn(i18next.t('hardwareTemp:toasts.warn.wrongTemperatures'));
      return;
    }

    const data: CreateDishSubFamilyParams = {
      dishFamilyId,
      name,
      minTemperature,
      maxTemperature,
    };

    createNewDishSubFamily(data);
    resetForm();
    setShowModal(false);
  };
  return (
    <Modal
      show={showModal}
      onHide={() => setShowModal(false)}
      aria-labelledby="dishes-subfamilies-modal"
      centered
    >
      <BaseModalHeader
        id="dishes-subfamilies-modal"
        title={i18next.t('productTemp:settings.types.modal.title')}
      />
      <Modal.Body>
        <Form>
          {/* Name */}
          <Form.Group className="d-flex flex-row align-items-center mt-5" controlId="name">
            <Form.Label className={styles.inputLabel}>
              {i18next.t('productTemp:settings.types.modal.form.name')}
            </Form.Label>
            <Form.Control
              type="text"
              autoComplete="off"
              className="baseInputLgWithFont ml-3"
              value={name || ''}
              onChange={(e) => {
                setName(e.target.value);
              }}
            />
          </Form.Group>
          {/* Temperature min */}
          <Form.Group
            className="d-flex flex-row align-items-center mt-5 text-nowrap"
            controlId="tMin"
          >
            <Form.Label className={`${styles.inputLabel} ${styles.temperatureLabel}`}>
              {i18next.t('productTemp:settings.types.modal.form.tMin')}
            </Form.Label>
            <Form.Control
              type="number"
              autoComplete="off"
              className={`baseInputLgWithFont ml-3 ${styles.temperatureInput}`}
              value={minTemperature || ''}
              onChange={(e) => {
                setMinTemperature(Number(e.target.value));
              }}
            />
            <span className="ml-2">°C</span>
          </Form.Group>
          {/* Temperature max */}
          <Form.Group
            className="d-flex flex-row align-items-center mt-5 text-nowrap"
            controlId="tMax"
          >
            <Form.Label className={`${styles.inputLabel} ${styles.temperatureLabel}`}>
              {i18next.t('productTemp:settings.types.modal.form.tMax')}
            </Form.Label>
            <Form.Control
              type="number"
              autoComplete="off"
              className={`baseInputLgWithFont ml-3 ${styles.temperatureInput}`}
              value={maxTemperature || ''}
              onChange={(e) => {
                setMaxTemperature(Number(e.target.value));
              }}
            />
            <span className="ml-2">°C</span>
          </Form.Group>
        </Form>
      </Modal.Body>
      <BaseModalFooter handleValidate={handleValidate} setShowModal={setShowModal} />
    </Modal>
  );
};

export default ProductTempSettingsSubFamiliesModal;
