import React from 'react';
import { Card, Image } from 'react-bootstrap';
import roundedCheckIcon from '@assets/images/svg/check-rond.svg';
import placeholderImage from '@assets/images/svg/placeholder.svg';
import styles from './DeepFryerListItem.module.scss';

type Props = {
  name: string;
  image: string;
  isSelected: boolean;
  noItemSelected: boolean;
};

const DeepFryerListItem = (props: Props) => {
  const { name, image, isSelected, noItemSelected } = props;
  return (
    <Card
      className={`${styles.cardContainer} ${
        !isSelected && !noItemSelected ? styles.fade : ''
      } h-100 mr-3 my-5`}
    >
      <Card.Img variant="top" src={image ?? placeholderImage} className={styles.imgContainer} />
      <Card.Body className="d-flex justify-content-center align-items-center text-wrap text-center border-top">
        {isSelected && (
          <Image src={roundedCheckIcon} className={styles.roundedCheck} width={48} height={48} />
        )}
        <Card.Text className={styles.cardText}>{name}</Card.Text>
      </Card.Body>
    </Card>
  );
};

export default DeepFryerListItem;
