import BaseNavbarWithHistory from '@components/shared/BaseNavbarWithHistory/BaseNavbarWithHistory';
import BaseNonComplianceForm from '@components/shared/BaseNonComplianceForm/BaseNonComplianceForm';
import BaseSaveButton from '@components/shared/BaseSaveButton/BaseSaveButton';
import BaseSignatory from '@components/shared/BaseSignatory/BaseSignatory';
import { SignatoryDto } from '@models/dto/response/Signatory.dto';
import { useAppSelector, useAppDispatch } from '@store/hooks';
import { getSignatories } from '@store/reducers/signatory';
import i18next from 'i18next';
import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { history } from '@router/routes';
import { format } from 'date-fns';
import { toast } from 'react-toastify';
import { UpdateNonComplianceParams } from '@models/dto/request/UpdateNonCompliance.dto';
import { getCausesAndActions, updateNonCompliance } from '@store/reducers/non-compliance';
import { CauseDto, CorrectiveActionDto } from '@models/dto/response/NonCompliance.dto';
import NonComplianceTypeEnum from '@models/enums/NonComplianceType';
import { getNonCompliantTasks } from '@store/reducers/history';
import styles from './SolveNonCompliantAnalysis.module.scss';

const SolveNonCompliantAnalysis = () => {
  // Store
  const signatories = useAppSelector((state) => state.signatory.signatories);
  const establishmentId = useAppSelector((state) => state.account.selectedEstablishment?.id);
  const nonCompliantAnalysis = useAppSelector(
    (state) => state.nonCompliance.selectedNonComplianceAnalysis,
  );
  const causesAndActions = useAppSelector(
    (state) => state.nonCompliance.causesAndCorrectivesActions,
  );
  const dispatch = useAppDispatch();
  // Component state
  const [selectedSignatory, setSelectedSignatory] = useState<SignatoryDto>();
  const [selectedCausesIds, setSelectedCausesIds] = useState<string[]>(
    nonCompliantAnalysis?.causes.map((cause) => cause.id.toString()) ?? [],
  );
  const [selectedCorrectiveActionsIds, setSelectedCorrectiveActionsIds] = useState<string[]>(
    nonCompliantAnalysis?.correctiveActions.map((action) => action.id.toString()) ?? [],
  );
  const [comment, setComment] = useState<string>('');
  const [causes, setCauses] = useState<CauseDto[]>([]);
  const [correctiveActions, setCorrectiveActions] = useState<CorrectiveActionDto[]>([]);

  useEffect(() => {
    const causeList = causesAndActions.find((item) => item.type === NonComplianceTypeEnum.analysis);
    const actionList = causesAndActions.find(
      (item) => item.type === NonComplianceTypeEnum.analysis,
    );
    setCauses(causeList?.causes ?? []);
    setCorrectiveActions(actionList?.correctiveActions ?? []);
  }, [causesAndActions, nonCompliantAnalysis]);

  useEffect(() => {
    if (!nonCompliantAnalysis) {
      history.goBack();
    }
    if (establishmentId && (!signatories || signatories.length === 0)) {
      dispatch(getSignatories(establishmentId)).unwrap();
    }
    if (!causesAndActions || causesAndActions.length === 0) {
      dispatch(getCausesAndActions()).unwrap();
    }
  }, []);

  const handleValidateForm = () => {
    if (!selectedSignatory) {
      toast.warn(i18next.t('operationsHandling:toasts.warn.signatory'));
      return;
    }

    if (establishmentId && nonCompliantAnalysis) {
      const data: UpdateNonComplianceParams = {
        analysis: 1,
        nonComplianceId: nonCompliantAnalysis?.id,
        causeIds: selectedCausesIds.join(',') ?? undefined,
        correctiveActionIds: selectedCorrectiveActionsIds.join(',') ?? undefined,
        signatoryId: selectedSignatory.id,
        comment,
      };
      dispatch(updateNonCompliance(data))
        .unwrap()
        .then(() => {
          toast.success(i18next.t('operationsHandling:toasts.success.updateNonCompliant'));
          dispatch(getNonCompliantTasks({ establishmentId, page: 0 })).finally(() =>
            history.push('/dashboard'),
          );
        })
        .catch(() => {
          toast.error(i18next.t('operationsHandling:toasts.error.updateNonCompliant'));
        });
    }
  };

  if (nonCompliantAnalysis) {
    return (
      <>
        {/* Navbar */}
        <BaseNavbarWithHistory
          title={i18next.t('operationsHandling:navbar.title')}
          showSettings={false}
          showHistory={false}
        />
        <Container fluid>
          <Row className={`p-5 ${styles.background}`}>
            <Col md={12} className={`${styles.infoLabel} mb-5`}>
              <p>
                {i18next.t(`operationsHandling:solve.info.date`)}{' '}
                <span className={`${styles.valueInfoLabel}`}>
                  {format(new Date(nonCompliantAnalysis.creationDate), 'dd/MM/yyyy')}
                </span>
              </p>
              <p>
                {i18next.t(`operationsHandling:solve.info.time`)}{' '}
                <span className={`${styles.valueInfoLabel}`}>
                  {format(new Date(nonCompliantAnalysis.creationDate), 'HH:mm')}
                </span>
              </p>
              <p>
                {i18next.t(`operationsHandling:solve.info.typology`)}{' '}
                <span className={`${styles.valueInfoLabel} font-weight-bold`}>
                  {i18next.t(
                    `operationsHandling:analysis.types.${nonCompliantAnalysis.analysisTypology}`,
                  )}
                </span>
              </p>
              <p>
                {i18next.t(`operationsHandling:solve.info.other`)}{' '}
                <span>{nonCompliantAnalysis.analysisReport}</span>
              </p>
              <p>
                {i18next.t(`operationsHandling:solve.info.nature`)}{' '}
                <span className={`${styles.valueInfoLabel} font-weight-bold`}>
                  {nonCompliantAnalysis.causes.map((cause) => cause.label).join(',')}
                </span>
              </p>
            </Col>
          </Row>
          <Row className={`p-5 ${styles.formContainer}`}>
            <Col md={12} className="mb-5">
              <BaseNonComplianceForm
                selectedCausesIds={selectedCausesIds}
                setSelectedCausesIds={setSelectedCausesIds}
                selectedCorrectiveActionsIds={selectedCorrectiveActionsIds}
                setSelectedCorrectiveActionsIds={setSelectedCorrectiveActionsIds}
                comment={comment}
                setComment={setComment}
                correctiveActions={correctiveActions}
                causes={causes}
                nonCompliantOperation={nonCompliantAnalysis}
              />
            </Col>
          </Row>
          <hr style={{ width: '80%' }} />
          {/* Signatories */}
          <BaseSignatory
            signatories={signatories}
            selectedSignatory={selectedSignatory}
            setSelectedSignatory={setSelectedSignatory}
          />
          <BaseSaveButton onSave={handleValidateForm} text={i18next.t('shared:buttons.save')} />
        </Container>
      </>
    );
  }
  return null;
};

export default SolveNonCompliantAnalysis;
