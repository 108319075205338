import React from 'react';
import { Card, ListGroup, OverlayTrigger, Popover } from 'react-bootstrap';
import { FileDto } from '@models/dto/response/File.dto';
import i18next from 'i18next';
import styles from './FileSettingsItem.module.scss';

type Props = {
  file: FileDto;
  isEditMode: boolean;
  setShowModalDelete?: () => void;
  children?: any;
};

const ContainerFile = (props: Props) => {
  const { file, setShowModalDelete } = props;

  const seeFile = () => {
    // eslint-disable-next-line no-undef
    window.open(file.file);
  };

  if (props.isEditMode) {
    return (
      <OverlayTrigger
        placement="bottom"
        trigger="click"
        rootClose
        overlay={
          <Popover id={`zone-popover-${file.id}`}>
            <Popover.Content>
              <ListGroup>
                <ListGroup.Item className="border-0" onClick={seeFile}>
                  {i18next.t('shared:buttons.see')}
                </ListGroup.Item>
                <ListGroup.Item className="border-0" onClick={setShowModalDelete}>
                  {i18next.t('shared:buttons.delete')}
                </ListGroup.Item>
              </ListGroup>
            </Popover.Content>
          </Popover>
        }
      >
        <Card className={`h-100 mr-3 ${styles.cardContainer}`}>{props.children}</Card>
      </OverlayTrigger>
    );
  }
  return (
    <Card className={`h-100 mr-3 ${styles.cardContainer}`} onClick={seeFile}>
      {props.children}
    </Card>
  );
};

const FileSettingsItem = (props: Props) => {
  const { file } = props;
  const fileUrl = file.file.split('.');

  return (
    <ContainerFile {...props}>
      <Card.Body className="d-flex flex-column justify-content-center align-items-center text-wrap text-center">
        <div className={`d-flex align-items-end ${styles.fileContainer}`}>
          {fileUrl[fileUrl.length - 1].length <= 5 ? (
            <span>{`.${fileUrl[fileUrl.length - 1]}`}</span>
          ) : (
            <span>Fichier</span>
          )}
        </div>
        <Card.Text className={styles.titleFolder}>{file.name}</Card.Text>
      </Card.Body>
    </ContainerFile>
  );
};

export default FileSettingsItem;
