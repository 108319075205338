import React, { Dispatch, SetStateAction } from 'react';
import i18next from 'i18next';
import { Badge, ListGroup, Modal } from 'react-bootstrap';
import { EstablishmentDto } from '@models/dto/response/Account.dto';
import BaseModalHeader from '@components/shared/BaseModalHeader/BaseModalHeader';
import styles from './LoginEstablishmentModal.module.scss';

type Props = {
  showModal: boolean;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  establishments: EstablishmentDto[];
  onSelectEstablishment: (establishment: EstablishmentDto) => void;
  currentEstablishment?: EstablishmentDto;
};

const LoginEstablishmentModal = (props: Props) => {
  const { showModal, setShowModal, establishments, currentEstablishment } = props;

  return (
    <Modal
      show={showModal}
      onHide={() => setShowModal(false)}
      aria-labelledby="establishment-modal"
      centered
    >
      <BaseModalHeader id="establishment-modal" title={i18next.t('login:modal.title')} />
      <Modal.Body className="p-0 border-top-0">
        <ListGroup>
          {establishments.map((establishment, index: number) => (
            <ListGroup.Item
              key={index}
              onClick={() => props.onSelectEstablishment(establishment)}
              disabled={
                (currentEstablishment && currentEstablishment.id === establishment.id) ||
                !establishment.active
              }
              className={
                (currentEstablishment && currentEstablishment.id === establishment.id) ||
                !establishment.active
                  ? styles.disabledItem
                  : ''
              }
            >
              <p className={styles.establishmentName}>
                {establishment.name}
                {currentEstablishment && currentEstablishment.id === establishment.id && (
                  <>
                    {' '}
                    <Badge pill variant="info">
                      Actuel
                    </Badge>
                  </>
                )}
                {!establishment.active && (
                  <>
                    {' '}
                    <Badge pill variant="danger">
                      Désactivé
                    </Badge>
                  </>
                )}
              </p>
              <p className={styles.establishmentAddress}>{establishment.address}</p>
            </ListGroup.Item>
          ))}
        </ListGroup>
      </Modal.Body>
    </Modal>
  );
};

export default LoginEstablishmentModal;
