import React, { useEffect, useState } from 'react';
import i18next from 'i18next';
import { Card, Col, Row, Form } from 'react-bootstrap';
import { CustomerDto } from '@models/dto/response/Customer.dto';
import BaseTableButtons from '@components/shared/BaseTableButtons/BaseTableButtons';
import styles from './ProductTempSettingsShippingClient.module.scss';

type Props = {
  client: CustomerDto;
  removeClient: (clientId: number) => void;
  editClient: (customer: CustomerDto) => void;
};

const ProductTempSettingsShippingClient = (props: Props) => {
  const { client, removeClient, editClient } = props;

  // Get selected days from client
  const getShippingDays = () => {
    const frequencyJson = JSON.parse(client.frequency.json);
    // Get only frequence starting by 'day' and true
    const array = Object.entries(frequencyJson).filter(
      (frequency) => frequency[1] === true && frequency[0].startsWith('day'),
    );
    const daysToDisplay: string[] = [];
    // Create an array with each extract days
    array.forEach((day: [string, unknown]) => {
      daysToDisplay.push(i18next.t(`shared:days.${day[0]}`));
    });
    // Return as string separated by comma or all day if selected
    if (daysToDisplay.includes(i18next.t(`shared:days.dayAll`))) {
      return i18next.t(`shared:days.allWeek`);
    }
    return daysToDisplay.join(', ');
  };

  // Get selected slots from client
  const getSlots = () => {
    const frequencyJson = JSON.parse(client.frequency.json);
    // Get only frequence starting by 'slot' and true
    const array = Object.entries(frequencyJson).filter(
      (frequency) => frequency[1] === true && frequency[0].startsWith('slot'),
    );
    const slotsToDisplay: string[] = [];
    // Create an array with each extract slots
    array.forEach((slot: [string, unknown]) => {
      slotsToDisplay.push(i18next.t(`shared:daySlots.${slot[0]}`));
    });
    // Return as string separated by comma
    return slotsToDisplay.join(', ');
  };

  const [shippingDays, setShippingDays] = useState<string>(getShippingDays());
  const [slots, setSlots] = useState<string>(getSlots());
  const [dishesArray, setDishesArray] = useState<{ name: string; value: number }[]>();

  useEffect(() => {
    setShippingDays(getShippingDays());
    setSlots(getSlots());
  }, [client.frequency.json]);

  const buildDishesArrayToDisplay = () => {
    const array: { name: string; value: number }[] = [];

    if (client.entree > 0) {
      array.push({
        name: i18next.t('productTemp:settings.shipping.modal.quantities.entree'),
        value: client.entree,
      });
    }
    if (client.dish > 0) {
      array.push({
        name: i18next.t('productTemp:settings.shipping.modal.quantities.dish'),
        value: client.dish,
      });
    }
    if (client.garnish > 0) {
      array.push({
        name: i18next.t('productTemp:settings.shipping.modal.quantities.garnish'),
        value: client.garnish,
      });
    }
    if (client.cheese > 0) {
      array.push({
        name: i18next.t('productTemp:settings.shipping.modal.quantities.cheese'),
        value: client.cheese,
      });
    }
    if (client.dessert > 0) {
      array.push({
        name: i18next.t('productTemp:settings.shipping.modal.quantities.dessert'),
        value: client.dessert,
      });
    }
    if (client.other > 0) {
      array.push({
        name: i18next.t('productTemp:settings.shipping.modal.quantities.other'),
        value: client.other,
      });
    }
    return array;
  };

  useEffect(() => {
    setDishesArray(buildDishesArrayToDisplay());
  }, [client.cheese, client.dessert, client.dish, client.entree, client.garnish]);

  return (
    <Card className={`h-100 mr-3 ${styles.cardContainer}`}>
      <Card.Body>
        <Card.Title>
          <Row>
            <Col md={12} className="d-flex flex-row justify-content-between align-items-center">
              <div className={styles.pageName}>{client.name}</div>
              <div className="d-flex flex-row">
                <BaseTableButtons
                  editAction={() => editClient(client)}
                  deleteAction={() => removeClient(client.id)}
                />
              </div>
            </Col>
          </Row>
        </Card.Title>
        <hr />
        <Form>
          <Row>
            <Col md={6}>
              {/* Shipping days */}
              <Form.Group controlId="shippingDays">
                <Form.Label className={styles.labels}>
                  {i18next.t('productTemp:settings.shipping.shippingDays')}
                </Form.Label>
                <Form.Control
                  className={styles.inputText}
                  plaintext
                  readOnly
                  value={shippingDays}
                />
              </Form.Group>
              {/* Day slots */}
              <Form.Group controlId="slots">
                <Form.Label className={styles.labels}>
                  {i18next.t('productTemp:settings.shipping.slots')}
                </Form.Label>
                <Form.Control className={styles.inputText} plaintext readOnly value={slots} />
              </Form.Group>
            </Col>
            {/* Dishes list */}
            <Col md={6}>
              <Form.Group as={Row} controlId="dishes">
                <Form.Label className={`${styles.labels} pt-0`} column md="12">
                  {i18next.t('productTemp:settings.shipping.dishes')}
                </Form.Label>
                {client &&
                  dishesArray &&
                  dishesArray.map((dish: { name: string; value: number }, index: number) => {
                    return (
                      <Col key={index} md={6} className="pr-0">
                        <Form.Control
                          className={styles.inputText}
                          plaintext
                          readOnly
                          value={`${dish.name} X${dish.value}`}
                        />
                      </Col>
                    );
                  })}
              </Form.Group>
            </Col>
          </Row>
        </Form>
      </Card.Body>
    </Card>
  );
};

export default ProductTempSettingsShippingClient;
