import React, { useState } from 'react';
import i18next from 'i18next'; // i18next.t('pageName:exemple.exemple')
import { Col, Container, Form, Modal, Row } from 'react-bootstrap';
import { FrequencyDto } from '@models/dto/response/Frequency.dto';
import { getTitleFrequency, displayNumberTime } from '@utils/frequenciesUtils';

import BaseModalHeader from '@components/shared/BaseModalHeader/BaseModalHeader';
import BaseModalFooter from '@components/shared/BaseModalFooter/BaseModalFooter';
import styles from './SettingsFrequenciesSlotsModal.module.scss';

type Props = {
  showModal: boolean;
  dimissModal: () => void;
  selectedSlot: FrequencyDto;
  onValidForm: any;
};

const SettingsFrequenciesSlotsModal = (props: Props) => {
  const { showModal, dimissModal, selectedSlot } = props;
  const [startHour, setStartHour] = useState<any>(
    `${displayNumberTime(selectedSlot.startHour)}:${displayNumberTime(selectedSlot.startMinutes)}`,
  );
  const [endHour, setEndHour] = useState<any>(
    `${displayNumberTime(selectedSlot.endHour)}:${displayNumberTime(selectedSlot.endMinutes)}`,
  );
  const [isActive, setIsActive] = useState<boolean>(selectedSlot.active);

  const handleValidate = () => {
    const newStart = startHour.split(':');
    const newEnd = endHour.split(':');

    const newSlot = {
      type: selectedSlot.type,
      startHour: parseInt(newStart[0], 10),
      startMinutes: parseInt(newStart[1], 10),
      endHour: parseInt(newEnd[0], 10),
      endMinutes: parseInt(newEnd[1], 10),
      active: isActive,
    } as FrequencyDto;

    props.onValidForm(newSlot);
  };

  return (
    <Modal
      show={showModal}
      onHide={dimissModal}
      size="lg"
      aria-labelledby="settings-frequencies-slots-modal"
      centered
    >
      <BaseModalHeader
        id="settings-frequencies-slots-modal"
        title={`${i18next.t('settings:frequencies.modal.title')} ${getTitleFrequency(
          selectedSlot.type,
        )}`}
      />
      <Modal.Body>
        <Container>
          <Row>
            <Col md={12}>
              <div>{i18next.t('settings:frequencies.modal.instructions')}</div>
              <Form className="mt-4">
                <div className="d-flex flex-row align-items-center">
                  {/* Start */}
                  <Form.Group className="mb-3 d-flex flex-row align-items-center" controlId="start">
                    <Form.Label className={styles.inputLabel}>
                      {i18next.t('settings:frequencies.modal.startTime')}
                    </Form.Label>
                    <Form.Control
                      type="time"
                      autoComplete="off"
                      className={styles.timePicker}
                      value={startHour}
                      onChange={(val) => setStartHour(val.target.value)}
                    />
                  </Form.Group>
                  {/* End */}
                  <Form.Group
                    className="mb-3 ml-3 d-flex flex-row align-items-center"
                    controlId="end"
                  >
                    <Form.Label className={styles.inputLabel}>
                      {i18next.t('settings:frequencies.modal.endTime')}
                    </Form.Label>
                    <Form.Control
                      type="time"
                      autoComplete="off"
                      className={styles.timePicker}
                      value={endHour}
                      onChange={(val) => setEndHour(val.target.value)}
                    />
                  </Form.Group>
                </div>
                {/* Desactivate frequence */}
                <Form.Group
                  className="mb-3 d-flex flex-row align-items-center"
                  controlId="desactivateFrequency"
                >
                  <div className="custom-control custom-switch custom-switch-lg d-flex align-items-center">
                    <label
                      className={`custom-control-label ${!isActive && 'checked'}`}
                      htmlFor="desactivateFrequence"
                    >
                      {i18next.t('settings:frequencies.modal.desactivate')}
                      <Form.Check
                        type="checkbox"
                        className="custom-control-input"
                        id="desactivateFrequence"
                        checked={isActive}
                        onChange={(val) => setIsActive(val.target.checked)}
                      />
                    </label>
                  </div>
                </Form.Group>
              </Form>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <BaseModalFooter handleValidate={handleValidate} setShowModal={dimissModal} />
    </Modal>
  );
};

export default SettingsFrequenciesSlotsModal;
