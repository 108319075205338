import BaseNavbarWithHistory from '@components/shared/BaseNavbarWithHistory/BaseNavbarWithHistory';
import BaseSaveButton from '@components/shared/BaseSaveButton/BaseSaveButton';
import BaseSignatory from '@components/shared/BaseSignatory/BaseSignatory';
import BasePhotoPicker from '@components/shared/BasePhotoPicker/BasePhotoPicker';
import i18next from 'i18next';
import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { getSignatories } from '@store/reducers/signatory';
import { createLabelPhotoOperation, updateLabelPhotoOperation } from '@store/reducers/operation';
import { SignatoryDto } from '@models/dto/response/Signatory.dto';
import { toast } from 'react-toastify';
import { history } from '@router/routes';
import OperationTypeEnum from '@models/enums/OperationType';
import BaseDatePicker from '@components/shared/BaseDatePicker/BaseDatePicker';
import { format, formatISO, setHours, setMinutes } from 'date-fns';
import styles from './PhotosLabels.module.scss';

const PhotosLabels = () => {
  const [selectedSignatory, setSelectedSignatory] = useState<SignatoryDto>();
  const [photos, setPhotos] = useState<any>([]);
  const [currentDate, setCurrentDate] = useState<Date>(new Date());
  const [currentTime, setCurrentTime] = useState<string>(format(new Date(), 'HH:mm'));

  const establishmentId = useAppSelector((state) => state.account.selectedEstablishment?.id);
  const signatories = useAppSelector((state) => state.signatory.signatories);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if ((!signatories || signatories.length === 0) && establishmentId) {
      dispatch(getSignatories(establishmentId));
    }
  }, []);

  // Update Date with custom time
  const buildDate = () => {
    let formattedDate = currentDate;
    const timeArray = currentTime.split(':');
    formattedDate = setMinutes(formattedDate, Number(timeArray[1]));
    formattedDate = setHours(formattedDate, Number(timeArray[0]));
    return formattedDate;
  };

  const handleSave = () => {
    if (!selectedSignatory) {
      toast.warn(i18next.t('photosLabels:toasts.warn.noSignatory'));
      return;
    }
    if (!photos || !photos.length) {
      toast.warn(i18next.t('photosLabels:toasts.warn.noPhoto'));
      return;
    }
    const date = buildDate();
    toast.loading(i18next.t('photosLabels:toasts.loading.photoLabel'));
    const firstPhoto = photos[0];
    // send the first photo
    dispatch(
      createLabelPhotoOperation({
        signatoryId: selectedSignatory?.id,
        picture: firstPhoto,
        realisationDate: formatISO(new Date(date)),
      }),
    )
      .unwrap()
      .then((result) => {
        // remove the first photo (already send)
        photos.shift();
        // get the operation ID to add other photo
        // @ts-ignore
        const labelPhotoId = result.data.operation.id;
        const requests = photos.map((photo: string) => {
          if (photo) {
            return dispatch(
              updateLabelPhotoOperation({
                labelPhotoId,
                picture: photo,
              }),
            ).unwrap();
          }
          return new Promise((resolve) => {
            resolve(true);
          });
        });
        Promise.all(requests)
          .then(() => {
            toast.dismiss();
            toast.success(i18next.t('photosLabels:toasts.success.photoLabel'));
            history.push('/dashboard');
          })
          .catch(() => {
            toast.dismiss();
            toast.error(i18next.t('photosLabels:toasts.error.photoLabel'));
          });
      });
  };

  return (
    <>
      {/* Navbar */}
      <BaseNavbarWithHistory
        title={i18next.t('photosLabels:navbar.title')}
        showSettings={false}
        typeOperation={OperationTypeEnum.photo}
      />
      <Container fluid className="cornerRound px-0">
        {/* Photos */}
        <div className={`p-5 ${styles.photosContainer}`}>
          <BasePhotoPicker
            isCenter
            photos={photos}
            setPhotos={setPhotos}
            acceptType="image/jpeg, image/jpg,image/png,application/pdf"
            multiPictureAllowed
          />
        </div>
        <Row className="mb-5 px-5">
          <Col md={12}>
            <BaseDatePicker
              currentDate={currentDate}
              setCurrentDate={setCurrentDate}
              currentTime={currentTime}
              setCurrentTime={setCurrentTime}
            />
          </Col>
        </Row>
        {/* Signatories */}
        <div className="pt-3">
          <BaseSignatory
            signatories={signatories}
            selectedSignatory={selectedSignatory}
            setSelectedSignatory={setSelectedSignatory}
          />
        </div>
        <BaseSaveButton onSave={handleSave} text={i18next.t('shared:buttons.save')} />
      </Container>
    </>
  );
};

export default PhotosLabels;
