enum NonComplianceTypeEnum {
  inspection = 'noncompliance_inspection_upon_receipt',
  product = 'noncompliance_equipment_temp_reading',
  cooling = 'noncompliance_product_temp_reading_cooling',
  reheating = 'noncompliance_product_temp_reading_reheating',
  service = 'noncompliance_product_temp_reading_service',
  delivery = 'noncompliance_product_temp_reading_delivery',
  analysis = 'noncompliance_analysis',
  analysis_operation = 'noncompliance_analysis_from_operation',
}

export default NonComplianceTypeEnum;
