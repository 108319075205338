import React, { Dispatch, SetStateAction, useState, useEffect } from 'react';
import i18next from 'i18next';
import { Form, Image } from 'react-bootstrap';
import { FormControl, MenuItem, Select } from '@material-ui/core';
import searchIcon from '@assets/images/svg/search.svg';
import { DishFamilyDto } from '@models/dto/response/DishFamily.dto';
import { DishSubFamilyDto } from '@models/dto/response/DishSubFamily.dto';
import { history } from '@router/routes';
import { useAppDispatch } from '@store/hooks';
import {
  setSelectedDish,
  setSelectedDishFamily as setSelectedDishFamilyAction,
  setSelectedDishSubFamily as setSelectedDishSubFamilyAction,
} from '@store/reducers/dish';
import { CustomerDto } from '@models/dto/response/Customer.dto';
import { setSelectedCustomer } from '@store/reducers/customer';
import OperationTempReadingTypeEnum from '@models/enums/OperationTempReadingType';
import styles from './BaseDishesForm.module.scss';

type Props = {
  selectedDishSubFamily?: DishSubFamilyDto;
  selectedDishFamily?: DishFamilyDto;
  setSelectedDishFamily: Dispatch<SetStateAction<DishFamilyDto | undefined>>;
  setSelectedDishSubFamily: Dispatch<SetStateAction<DishSubFamilyDto | undefined>>;
  dishFamilies: DishFamilyDto[];
  dishName: string;
  setDishName: Dispatch<SetStateAction<string>>;
  selectedCustomer?: CustomerDto;
  selectedActionType?: OperationTempReadingTypeEnum;
  readOnlyDishFamily?: boolean;
};

const BaseDishesForm = (props: Props) => {
  const {
    setSelectedDishFamily,
    selectedDishSubFamily,
    dishFamilies,
    setSelectedDishSubFamily,
    selectedDishFamily,
    dishName,
    setDishName,
    selectedCustomer,
  } = props;

  const [selectedSubFamilies, setSelectedSubFamilies] = useState<DishSubFamilyDto[]>();

  const dispatch = useAppDispatch();

  useEffect(() => {
    setSelectedSubFamilies(selectedDishFamily?.subFamilies);
  }, [selectedDishFamily]);

  const handleClickName = () => {
    if (selectedDishSubFamily && selectedDishFamily) {
      dispatch(setSelectedDishFamilyAction(selectedDishFamily));
      dispatch(setSelectedDishSubFamilyAction(selectedDishSubFamily));
      dispatch(setSelectedCustomer(selectedCustomer));
      history.push('/select-dish', { dishSubFamilyId: selectedDishSubFamily.id });
    }
  };

  const canDisplay = (family: DishFamilyDto) => {
    if (props.selectedActionType) {
      switch (props.selectedActionType) {
        case OperationTempReadingTypeEnum.cooling:
          return family.cooling;
        case OperationTempReadingTypeEnum.delivery:
          return family.deliveryTemperature;
        case OperationTempReadingTypeEnum.reheating:
          return family.reheating;
        case OperationTempReadingTypeEnum.service:
          return family.serviceTemperature;
        default:
          return false;
      }
    }
    return true;
  };
  return (
    <Form className="d-flex flex-column">
      {/* Dishes family */}
      <Form.Group className="mt-5" controlId="dishes-famiy">
        <FormControl className="w-100">
          <Select
            disabled={props.readOnlyDishFamily}
            value={JSON.stringify(selectedDishFamily) ?? ''}
            onChange={(e) => {
              setSelectedDishFamily(JSON.parse(e.target.value as string) as DishFamilyDto);
              // Reset other fields
              setSelectedDishSubFamily(undefined);
              setDishName('');
              dispatch(setSelectedDish(undefined));
            }}
            displayEmpty
            renderValue={(value) => (
              // To display placeholder, otherwise display value
              <span>
                {value
                  ? (JSON.parse(value as string) as DishFamilyDto).name
                  : i18next.t('shared:dishes.family')}
              </span>
            )}
            inputProps={{
              className: `${
                !selectedDishFamily ? styles.selectInputEmpty : styles.selectInputFilled
              }`,
            }}
          >
            <MenuItem value="" disabled>
              {i18next.t('shared:dishes.family')}
            </MenuItem>
            {dishFamilies.map((family: DishFamilyDto) =>
              canDisplay(family) ? (
                <MenuItem key={family.id} value={JSON.stringify(family)}>
                  {family.name}
                </MenuItem>
              ) : (
                <></>
              ),
            )}
          </Select>
        </FormControl>
      </Form.Group>
      <Form.Group className="mt-5" controlId="dishes-subfamily">
        <FormControl className="w-100" disabled={!selectedDishFamily}>
          <Select
            value={JSON.stringify(selectedDishSubFamily) ?? ''}
            onChange={(e) => {
              setSelectedDishSubFamily(JSON.parse(e.target.value as string) as DishSubFamilyDto);
              // Reset following fields
              dispatch(setSelectedDish(undefined));
              setDishName('');
            }}
            displayEmpty
            renderValue={(value) => (
              // To display disable style on placeholder, otherwise display value
              <span className={!selectedDishFamily ? styles.disabledSelect : ''}>
                {value
                  ? (JSON.parse(value as string) as DishSubFamilyDto).name
                  : i18next.t('shared:dishes.type')}
              </span>
            )}
            inputProps={{
              className: `${
                !selectedDishSubFamily ? styles.selectInputEmpty : styles.selectInputFilled
              }`,
            }}
          >
            <MenuItem value="" disabled>
              {i18next.t('shared:dishes.type')}
            </MenuItem>
            {selectedSubFamilies &&
              selectedSubFamilies.map((family) => (
                <MenuItem key={family.id} value={JSON.stringify(family)}>
                  {family.name}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </Form.Group>
      {/* Name */}
      <Form.Group className="mt-5 w-50" controlId="dishName">
        <Form.Label className={styles.searchLabel}>
          {i18next.t('shared:dishes.name')}{' '}
          <span className={styles.optionLabel}>{i18next.t('shared:dishes.option')}</span>
        </Form.Label>
        <Form.Control
          type="text"
          autoComplete="off"
          value={dishName ?? ''}
          className="baseInputLg pl-5"
          placeholder={i18next.t('shared:dishes.search')}
          onClick={handleClickName}
          onChange={(e) => setDishName(e.target.value)}
          disabled={!selectedDishSubFamily}
        />
        <Image src={searchIcon} className={styles.searchIcon} width={22} height={22} />
      </Form.Group>
    </Form>
  );
};

export default BaseDishesForm;
