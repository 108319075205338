import React, { useEffect, useRef } from 'react';
import i18next from 'i18next';
import { Table, Button, Spinner } from 'react-bootstrap';
import { format } from 'date-fns';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { toast } from 'react-toastify';
import { history } from '@router/routes';
import { TaskDto } from '@models/dto/response/Task.dto';
import { getDishType, getTitleTask, getUrlByTask } from '@utils/operationsUtils';
import TaskTypeEnum from '@models/enums/TaskType';
import { deleteTaskOperation, setSelectedTaskId } from '@store/reducers/history';
import { selectEquipmentId } from '@store/reducers/equipment';
import { selectSurfaceId } from '@store/reducers/area';
import {
  selectDishFamilyType,
  selectOperationProductType,
} from '@store/reducers/product-temperature';
import AccountTypeEnum from '@models/enums/AccountType';
import { getCustomer } from '@store/reducers/customer';
import OperationTempReadingTypeEnum from '@models/enums/OperationTempReadingType';
import styles from './OperationsDelayed.module.scss';

type Props = {
  lateTasks: TaskDto[];
  loadMore: () => void;
  isLoading: boolean;
};

const OperationsDelayList = (props: Props) => {
  const dispatch = useAppDispatch();
  const accountType = useAppSelector((state) => state.account.type);

  const loader = useRef(null);

  const handleObserver = (entities: any, observer: IntersectionObserver) => {
    const target = entities[0];
    if (target.isIntersecting) {
      props.loadMore();

      // FIX: double loading on this page.
      if (loader?.current) {
        observer.unobserve(loader?.current);
        setTimeout(() => {
          if (loader?.current) {
            observer.observe(loader?.current);
          }
        }, 500);
      }
    }
  };

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 1,
    };

    // initialize IntersectionObserver
    // and attaching to Load More div
    const observer = new IntersectionObserver(handleObserver, options);
    if (loader.current) {
      observer.observe(loader.current);
    }
    return () => {
      if (loader.current) {
        observer.unobserve(loader.current);
      }
    };
  }, []);

  const deleteTask = (task: TaskDto) => {
    toast.promise(dispatch(deleteTaskOperation(task.id)).unwrap(), {
      pending: i18next.t('operationsDelayed:toasts.pending.deleteTask'),
      success: i18next.t('operationsDelayed:toasts.success.deleteTask'),
      error: i18next.t('operationsDelayed:toasts.error.deleteTask'),
    });
  };

  const goToOperation = (task: TaskDto) => {
    dispatch(setSelectedTaskId(task.id));
    if (task.taskType === TaskTypeEnum.equipmentReading) {
      dispatch(selectEquipmentId(task.equipmentId));
    } else if (task.taskType === TaskTypeEnum.cleaning) {
      dispatch(selectSurfaceId(task.surfaceId));
    } else if (task.taskType === TaskTypeEnum.productReadingDelivery) {
      dispatch(selectOperationProductType(OperationTempReadingTypeEnum.delivery));
      dispatch(selectDishFamilyType(task.dishFamilyType));
      dispatch(getCustomer(task.customerId)).unwrap();
    } else if (task.taskType === TaskTypeEnum.productReadingService) {
      dispatch(selectOperationProductType(OperationTempReadingTypeEnum.service));
      dispatch(selectDishFamilyType(task.dishFamilyType));
    }
    history.push(getUrlByTask(task.taskType));
  };

  return (
    <Table responsive className="mt-5 border-top-0">
      <tbody>
        {props.lateTasks.map((operation: any, index: number) => {
          return (
            <tr key={index} className="py-5">
              <td>
                <div className={styles.operationDate}>
                  {format(new Date(operation.deadLine), 'dd/MM/yyyy - HH:mm')}
                </div>
                <div className={styles.operationType}>{getTitleTask(operation.taskType)}</div>
              </td>
              <td className={styles.subject}>
                {operation.taskType === TaskTypeEnum.productReadingService ||
                operation.taskType === TaskTypeEnum.productReadingDelivery
                  ? `${getDishType(operation.dishFamilyType)} ${
                      operation.taskType === TaskTypeEnum.productReadingDelivery ? '- ' : ''
                    }`
                  : ''}
                {operation.taskType === TaskTypeEnum.cleaning
                  ? `${operation.areaName} - ${operation.surfaceName}`
                  : operation.subject}
              </td>
              <td className="d-flex">
                <Button
                  className={`${styles.fixButton} btnMd d-flex align-items-center`}
                  onClick={() => goToOperation(operation)}
                >
                  {i18next.t('shared:buttons.handle')}
                </Button>
                {accountType === AccountTypeEnum.admin ? (
                  <Button
                    className={`${styles.deleteButton} btnMd d-flex align-items-center ml-2`}
                    onClick={() => deleteTask(operation)}
                  >
                    {i18next.t('shared:buttons.delete')}
                  </Button>
                ) : null}
              </td>
            </tr>
          );
        })}
        <tr ref={loader} onClick={props.loadMore}>
          {props.isLoading && (
            <td>
              <Spinner animation="border" role="status" variant="info">
                <span className="sr-only">{i18next.t('shared:loading')}</span>
              </Spinner>
            </td>
          )}
        </tr>
      </tbody>
    </Table>
  );
};

export default OperationsDelayList;
