import {
  CreateProductTempReadingDeliveryDto,
  CreateProductTempReadingDto,
  CreateProductTempReadingServiceDto,
  UpdateProductTempReadingDeliveryDto,
  UpdateProductTempReadingDto,
  UpdateProductTempReadingServiceDto,
} from '@models/dto/request/ProductTempReading.dto';
import { OperationCoolingResponseDto } from '@models/dto/response/OperationCooling.dto';
import { OperationDeliveryResponseDto } from '@models/dto/response/OperationDelivery.dto';
import { OperationReheatingResponseDto } from '@models/dto/response/OperationReheating.dto';
import { OperationServiceResponseDto } from '@models/dto/response/OperationService.dto';
import { ProductTemperatureDataDto } from '@models/dto/response/ProductTemperatureData.dto';
import {
  UnfinishedProductTempReadingCoolingDto,
  UnfinishedProductTempReadingReheatingDto,
} from '@models/dto/response/UnfinishedProductTempReading.dto';
import { ApiResponse } from '@models/dto/ApiResponse.dto';
import { AxiosResponse } from 'axios';

import Axios from './index';

export default {
  // PRODUCT TEMPERATURE

  // For temperature measure types in product temp
  getProductTempData: (
    establishmentId: number,
  ): Promise<AxiosResponse<ApiResponse<ProductTemperatureDataDto>>> => {
    return Axios.get(`/operation/establishment/${establishmentId}/productTempReading/types`);
  },

  // Unfinished product temperature reading
  getRunningProductTemp: (
    establishmentId: number,
  ): Promise<
    AxiosResponse<
      ApiResponse<
        (UnfinishedProductTempReadingCoolingDto | UnfinishedProductTempReadingReheatingDto)[]
      >
    >
  > => {
    return Axios.get(`/operation/establishment/${establishmentId}/productTempReading/unfinished`);
  },

  // COOLING

  getProductTempCooling: (id: number) => {
    return Axios.get(`/operation/productTempReading/${id}/cooling`);
  },

  // Creates a product temperature reading operation (cooling)
  createProductTempCooling: (
    data: CreateProductTempReadingDto,
  ): Promise<AxiosResponse<ApiResponse<OperationCoolingResponseDto>>> => {
    return Axios.post(`/operation/productTempReading/cooling`, data);
  },

  // Update a product temperature reading operation (cooling)
  updateProductTempCooling: (
    id: number,
    data: UpdateProductTempReadingDto,
  ): Promise<AxiosResponse<ApiResponse<OperationCoolingResponseDto>>> => {
    return Axios.put(`/operation/productTempReading/${id}/cooling`, data);
  },

  deleteProductTempCooling: (id: number): Promise<AxiosResponse<ApiResponse<boolean>>> => {
    return Axios.delete(`/operation/productTempReading/cooling/${id}`);
  },

  // REHEATING

  getProductTempReheating: (id: number) => {
    return Axios.get(`/operation/productTempReading/${id}/reheating`);
  },

  // Creates a product temperature reading operation (reheating)
  createProductTempReheating: (
    data: CreateProductTempReadingDto,
  ): Promise<AxiosResponse<ApiResponse<OperationReheatingResponseDto>>> => {
    return Axios.post(`/operation/productTempReading/reheating`, data);
  },

  // Update a product temperature reading operation (reheating)
  updateProductTempReheating: (
    id: number,
    data: UpdateProductTempReadingDto,
  ): Promise<AxiosResponse<ApiResponse<OperationReheatingResponseDto>>> => {
    return Axios.put(`/operation/productTempReading/${id}/reheating`, data);
  },

  deleteProductTempReheating: (id: number): Promise<AxiosResponse<ApiResponse<boolean>>> => {
    return Axios.delete(`/operation/productTempReading/reheating/${id}`);
  },

  // SERVICE

  getProductTempService: (id: number) => {
    return Axios.get(`/operation/productTempReading/${id}/service`);
  },

  // Creates a product temperature reading operation (service)
  createProductTempService: (
    data: CreateProductTempReadingServiceDto,
  ): Promise<AxiosResponse<ApiResponse<OperationServiceResponseDto>>> => {
    return Axios.post(`/operation/productTempReading/service`, data);
  },

  // Update a product temperature reading operation (service)
  updateProductTempService: (
    id: number,
    data: UpdateProductTempReadingServiceDto,
  ): Promise<AxiosResponse<ApiResponse<OperationServiceResponseDto>>> => {
    return Axios.put(`/operation/productTempReading/${id}/service`, data);
  },

  // DELIVERY

  getProductTempDelivery: (id: number) => {
    return Axios.get(`/operation/productTempReading/${id}/delivery`);
  },

  // Creates a product temperature reading operation (delivery)
  createProductTempDelivery: (
    data: CreateProductTempReadingDeliveryDto,
  ): Promise<AxiosResponse<ApiResponse<OperationDeliveryResponseDto>>> => {
    return Axios.post(`/operation/productTempReading/delivery`, data);
  },

  // Update a product temperature reading operation (delivery)
  updateProductTempDelivery: (
    id: number,
    data: UpdateProductTempReadingDeliveryDto,
  ): Promise<AxiosResponse<ApiResponse<OperationDeliveryResponseDto>>> => {
    return Axios.put(`/operation/productTempReading/${id}/delivery`, data);
  },

  deleteProductTempDelivery: (id: number): Promise<AxiosResponse<ApiResponse<boolean>>> => {
    return Axios.delete(`/operation/productTempReading/delivery/${id}`);
  },
};
