import React from 'react';
import { Modal } from 'react-bootstrap';
import styles from './BaseModalHeader.module.scss';

type Props = {
  id: string;
  title: string;
  closeButton?: boolean;
};

const BaseModalHeader = (props: Props) => {
  const { id, title } = props;
  return (
    <Modal.Header closeButton={props.closeButton !== false}>
      <Modal.Title id={id} className={styles.title}>
        {title}
      </Modal.Title>
    </Modal.Header>
  );
};

export default BaseModalHeader;
