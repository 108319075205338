import { ICalendarException } from '@models/dto/request/CalendarExceptionParams.dto';
import Axios from './index';

export default {
  getCalendarExceptions: (establishmentId: number) => {
    return Axios.get(`/establishment/${establishmentId}/calendarException`);
  },

  createCalendarException: (establishmentId: number, calendarException: ICalendarException) => {
    return Axios.post(`/establishment/${establishmentId}/calendarException`, calendarException);
  },

  getCalendarException: (id: number) => {
    return Axios.get(`/establishment/calendarException/${id}`);
  },

  updateCalendarException: (id: number, calendarException: ICalendarException) => {
    return Axios.put(`/establishment/calendarException/${id}`, calendarException);
  },

  deleteCalendarException: (id: number) => {
    return Axios.delete(`/establishment/calendarException/${id}`);
  },
};
